import { permissionService } from "services/Permission.service.js";

export const check = (feature) => {
  let features = permissionService.currentPermission;

  if (
    (features &&
      features.length > 0 &&
      features.find((x) => x.name.toUpperCase() === feature.toUpperCase())) ||
    feature.toUpperCase() === "/ADMIN/DASHBOARD"
  ) {
    return true;
  }

  return false;
};

const Can = (props) => {
  return check(props.feature) ? props.yes() : props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
