/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import { DropdownItem } from "reactstrap";

class DropdownItemDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = { status: null };
  }

  componentDidUpdate() {
    let status = this.props.status;

    this.setState({ status: status });
  }

  componentWillReceiveProps() {
    let status = this.props.status;

    if (this.state.status !== status) this.setState({ status: status });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.status !== nextProps.status;
  }

  render() {
    return (
      <div>
        <DropdownItem
          style={{
            fontWeight: "bold",
          }}
          onClick={this.props.onClick}
        >
          {this.props.status}
        </DropdownItem>
      </div>
    );
  }
}

export default DropdownItemDashboard;
