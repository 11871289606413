/* eslint-disable no-useless-constructor */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import FailingStudents from "./FailingStudents";

class FailingStudentsReport extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <FailingStudents tables={this.props.tables} />;
  }
}

export default FailingStudentsReport;
