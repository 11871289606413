/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import confirm from "reactstrap-confirm";
import { editMode, FormatFullDateList, FormatDate } from "helpers/Utils.js";

class Semester extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  render() {
    let semesters = this.state.mainState.semesters.map((semester) => {
      return (
        <tr key={semester.id}>
          {/* <td>{semester.id}</td> */}

          <td>
            <a
              onClick={this.openModalSemester.bind(
                this,
                editMode.EDIT,
                semester.id
              )}
              href="#"
            >
              {semester.name}
            </a>
          </td>

          <td>{semester.startDate}</td>
          <td>{semester.endDate}</td>
          {/* <td> */}
          {/* <Button
              color="success"
              size="sm"
              className="mr-2"
              onClick={this.openModalSemester.bind(
                this,
                editMode.EDIT,
                semester.id
              )}
            >
              Edit
            </Button> */}
          {/* <Button
              color="danger"
              size="sm"
              onClick={this.deleteSemester.bind(this, semester.id)}
            >
              Delete
            </Button> */}
          {/* </td> */}
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  {/* <CardTitle tag="h4">Campuses</CardTitle> */}
                  <Button
                    className="my-3"
                    color="primary"
                    onClick={this.openModalSemester.bind(this, editMode.INSERT)}
                  >
                    Add semester
                  </Button>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th>Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        {/* <th>Actions</th> */}
                      </tr>
                    </thead>

                    <tbody>{semesters}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.mainState.currentPage}
                    totalItemsCount={this.state.mainState.totalResults}
                    onChange={this.nextPage.bind(this)}
                  />

                  <Modal
                    isOpen={this.state.mainState.openModal}
                    toggle={this.toggleNewSemesterModal.bind(this)}
                    centered={true}
                    autoFocus={true}
                    backdrop="static"
                    onClosed={this.onCloseModal}
                    style={{
                      maxWidth: "500px",
                      width: "100%",
                    }}
                  >
                    <ModalHeader
                      toggle={this.toggleNewSemesterModal.bind(this)}
                    >
                      {this.state.modalState.editMode === editMode.INSERT
                        ? "Add a new semester"
                        : "Edit a semester"}
                    </ModalHeader>
                    <ModalBody>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="semesterName">Name</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Semester.Name.ErrorMsg
                              )}`}
                              type="text"
                              name="Name"
                              id="semesterName"
                              autoComplete="nope"
                              value={this.state.modalState.Semester.Name.Value}
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Semester.Name.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="semesterStartDate">Start Date</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Semester.StartDate
                                  .ErrorMsg
                              )}`}
                              type="date"
                              name="StartDate"
                              id="semesterStartDate"
                              autoComplete="nope"
                              value={
                                this.state.modalState.Semester.StartDate.Value
                              }
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {
                                this.state.modalState.Semester.StartDate
                                  .ErrorMsg
                              }
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="semesterEndtDate">End Date</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Semester.EndDate.ErrorMsg
                              )}`}
                              type="date"
                              name="EndDate"
                              id="semesterEndDate"
                              autoComplete="nope"
                              value={
                                this.state.modalState.Semester.EndDate.Value
                              }
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Semester.EndDate.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={
                          this.state.modalState.editMode === editMode.INSERT
                            ? this.addSemester.bind(this)
                            : this.updateSemester.bind(this)
                        }
                        disabled={!this.state.modalState.formValid}
                      >
                        {this.state.modalState.editMode === editMode.INSERT
                          ? "Add"
                          : "Update"}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.toggleNewSemesterModal.bind(this)}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  getInitialState = () => {
    const initialState = {
      mainState: this.getMainState(),
      modalState: this.getModalState(),
    };

    return initialState;
  };

  getMainState = () => {
    const mainState = {
      semesters: [],
      openModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      alert: { visible: false, color: "", message: "" },
    };

    return mainState;
  };

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getSemester = () => {
    const semester = {
      Id: this.getEntity(),
      Name: this.getEntity(),
      StartDate: this.getEntity(),
      EndDate: this.getEntity(),
    };
    return semester;
  };

  getModalState = () => {
    const modalState = {
      Semester: this.getSemester(),
      editMode: "",
      cards: [1, 2],
      alert: { visible: false, color: "", message: "" },
      formValid: false,
    };

    return modalState;
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          modalState: this.getModalState(),
        },
        resolve(true)
      );
    });
  };

  validateField(fieldName, value) {
    let { modalState } = this.state;

    switch (fieldName) {
      case "Name":
        modalState.Semester.Name.IsValid = value.length >= 1;
        modalState.Semester.Name.ErrorMsg = modalState.Semester.Name.IsValid
          ? ""
          : "Name is empty";
        break;
      case "StartDate":
        modalState.Semester.StartDate.IsValid = value.length >= 1;
        modalState.Semester.StartDate.ErrorMsg = modalState.Semester.StartDate
          .IsValid
          ? ""
          : "StartDate is empty";
        break;
      case "EndDate":
        modalState.Semester.EndDate.IsValid = value.length >= 1;
        modalState.Semester.EndDate.ErrorMsg = modalState.Semester.EndDate
          .IsValid
          ? ""
          : "NaEndDateme is empty";
        break;
      default:
        break;
    }

    this.setState(
      {
        modalState: modalState,
      },
      this.validateForm()
    );
  }

  validateForm() {
    let { modalState } = this.state;

    modalState.formValid =
      modalState.Semester.Name.IsValid === true &&
      modalState.Semester.StartDate.IsValid === true &&
      modalState.Semester.EndDate.IsValid === true;

    this.setState({
      modalState: modalState,
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { modalState } = this.state;
    modalState.Semester[name].Value = value;

    this.setState({ modalState: modalState }, () => {
      this.validateField(name, value);
    });
  };

  componentDidMount() {
    this._refreshSemesters();
  }

  toggleNewSemesterModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;
      mainState.openModal = !mainState.openModal;
      this.setState(
        {
          mainState: mainState,
        },
        resolve(true)
      );
    });
  };

  addSemester() {
    let semester = {
      Name: this.state.modalState.Semester.Name.Value,
      StartDate: this.state.modalState.Semester.StartDate.Value,
      EndDate: this.state.modalState.Semester.EndDate.Value,
    };

    axios.post("semesters", semester).then((response) => {
      this.toggleNewSemesterModal().then(() => {
        this._refreshSemesters().then(() => {
          this.resetModal();
        });
      });
    });
  }

  updateSemester() {
    let semester = {
      Id: this.state.modalState.Semester.Id.Value,
      Name: this.state.modalState.Semester.Name.Value,
      StartDate: this.state.modalState.Semester.StartDate.Value,
      EndDate: this.state.modalState.Semester.EndDate.Value,
    };

    axios.put("semesters", semester).then((response) => {
      this.toggleNewSemesterModal().then(() => {
        this._refreshSemesters().then(() => {
          this.resetModal();
        });
      });
    });
  }

  openModalSemester(mode, id) {
    let { modalState } = this.state;
    modalState.editMode = mode;

    this.setState({ modalState: modalState });

    if (mode === editMode.INSERT) {
      this.toggleNewSemesterModal();
    } else {
      axios.get("semesters/byid?id=" + id).then((response) => {
        modalState.Semester.Id.Value = response.data.data.id;

        modalState.Semester.Name.Value = response.data.data.name;
        modalState.Semester.Name.IsValid = true;

        modalState.Semester.StartDate.Value = FormatDate(
          response.data.data.startDate
        );
        modalState.Semester.StartDate.IsValid = true;

        modalState.Semester.EndDate.Value = FormatDate(
          response.data.data.endDate
        );
        modalState.Semester.EndDate.IsValid = true;

        this.validateForm();

        this.setState(
          {
            modalState: modalState,
          },
          () => {
            this.toggleNewSemesterModal();
          }
        );
      });
    }
  }

  async deleteSemester(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Are you sure you wish to delete this item?",
    });

    if (result) {
      axios.delete("semesters/" + id).then(() => {
        this._refreshSemesters();
      });
    }
  }

  _refreshSemesters = () => {
    return new Promise((resolve, reject) => {
      let page = this.state.mainState.currentPage;
      let itemsPerPage = this.state.mainState.itemsPerPage;

      axios
        .get("semesters?page=" + page + "&pageSize=" + itemsPerPage)
        .then((response) => {
          let { mainState } = this.state;

          mainState.semesters = [];
          response.data.data.items.map((semester, index) =>
            mainState.semesters.push({
              id: semester.id,
              name: semester.name,
              startDate: FormatFullDateList(semester.startDate),
              endDate: FormatFullDateList(semester.endDate),
            })
          );

          mainState.totalResults = response.data.data.totalCount;

          this.setState(
            {
              mainState: mainState,
            },
            resolve(true)
          );
        });
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  setCurrentPage(pageNumber) {
    let { mainState } = this.state;
    mainState.currentPage = pageNumber;
    this.setState({ mainState: mainState });
  }

  nextPage(pageNumber) {
    let itemsPerPage = this.state.mainState.itemsPerPage;

    axios
      .get("semesters?page=" + pageNumber + "&pageSize=" + itemsPerPage)
      .then((response) => {
        let { mainState } = this.state;

        mainState.semesters = [];
        response.data.data.items.map((semester, index) =>
          mainState.semesters.push({
            id: semester.id,
            name: semester.name,
            startDate: FormatFullDateList(semester.startDate),
            endDate: FormatFullDateList(semester.endDate),
          })
        );

        mainState.currentPage = pageNumber;
        mainState.totalResults = response.data.data.totalCount;

        this.setState({
          mainState: mainState,
        });
      });
  }
}

export default Semester;
