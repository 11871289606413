import React from "react";
import Pagination from "react-js-pagination";
import { Row, Button } from "reactstrap";

const CustomPagination = (props) => {
  let itemsPerPage = props.itemsPerPage || 10;

  return (
    <Row form style={{ display: "block" }}>
      {props.totalItemsCount > itemsPerPage ? (
        <Pagination
          activePage={props.activePage}
          itemsCountPerPage={itemsPerPage}
          totalItemsCount={props.totalItemsCount}
          onChange={props.onChange}
          activeLinkClass="page-link"
          linkClass="page-link"
          itemClass="page-item"
          hideDisabled
        />
      ) : (
        ""
      )}

      <Row>
        <h6
          style={{
            marginLeft: "30px",
            marginRight: "auto",
            marginTop: "15px",
            textTransform: "inherit",
          }}
        >
          <strong className="text-secondary">{props.totalItemsCount}</strong>{" "}
          {props.totalItemsCount > 1 ? "records" : "record"}
        </h6>

        {props.pdf4 && (
          <Button
            color="success"
            size="sm"
            style={{
              textTransform: "inherit",
              marginRight: "5px",
            }}
            onClick={props.pdf4}
          >
            {props.pdfButtonName4}
          </Button>
        )}

        {props.pdf3 && (
          <Button
            color="success"
            size="sm"
            style={{
              textTransform: "inherit",
              marginRight: "5px",
            }}
            onClick={props.pdf3}
          >
            {props.pdfButtonName3}
          </Button>
        )}

        {props.pdf2 && (
          <Button
            color="success"
            size="sm"
            style={{
              textTransform: "inherit",
              marginRight: "5px",
            }}
            onClick={props.pdf2}
          >
            {props.pdfButtonName2}
          </Button>
        )}

        {props.pdf && (
          <Button
            color="success"
            size="sm"
            style={{
              textTransform: "inherit",
              marginRight: "5px",
            }}
            onClick={props.pdf}
          >
            {props.pdfButtonName}
          </Button>
        )}

        {props.csv && (
          <Button
            color="success"
            size="sm"
            style={{
              textTransform: "inherit",
              marginRight: "30px",
            }}
            onClick={props.csv}
          >
            {props.buttonName}
          </Button>
        )}
      </Row>
    </Row>
  );
};

export default CustomPagination;
