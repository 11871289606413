/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import { Input } from "reactstrap";

class GradeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grade: null,
      verified: false,
      modified: false,
      disabled: false,
    };
  }

  componentDidUpdate() {
    let grade = this.props.grade;
    let verified = this.props.verified;
    let modified = this.props.modified;
    let disabled = this.props.disabled;

    this.setState({
      grade: grade,
      verified: verified,
      modified: modified,
      disabled: disabled,
    });
  }

  componentWillReceiveProps() {
    let grade = this.props.grade;
    let verified = this.props.verified;
    let modified = this.props.modified;
    let disabled = this.props.disabled;

    if (
      this.state.grade !== grade ||
      this.state.verified !== verified ||
      this.state.disabled !== disabled ||
      this.state.modified !== modified
    )
      this.setState({
        grade: grade,
        verified: verified,
        modified: modified,
        disabled: disabled,
      });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.grade !== nextProps.grade ||
      this.state.verified !== nextProps.verified ||
      this.state.disabled !== nextProps.disabled ||
      this.state.modified !== nextProps.modified
    );
  }

  render() {
    return (
      <div>
        <Input
          className="inputTest2"
          value={this.state.grade}
          onFocus={this.props.onFocus}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          style={{
            backgroundColor:
              this.state.grade === 0 &&
              !this.state.verified &&
              !this.state.modified
                ? "#ffc4c4"
                : "",
          }}
          disabled={this.props.disabled === true ? "disabled" : ""}
        ></Input>
      </div>
    );
  }
}

export default GradeInput;
