/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  CardFooter,
  CustomInput,
  CardHeader,
  CardTitle,
  Tooltip,
} from "reactstrap";
import axios from "axios";
import { editMode } from "helpers/Utils.js";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";
import { SavePdf } from "components/Report/GeneratePdf.js";
import MessageEdit from "components/Message/Message.component.edit.jsx";
import NoteCompose from "components/Note/Note.component.compose.jsx";

class AbsencesReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      studentId: "",
      studentName: "",
      noteType: "",
      showOnlyStudentsWithOutMessage: false,
      showAllStatuses: false,
      students16MoreAbsences: [],
      tooltipCallIsOpen: false,
      tooltipProbIsOpen: false,
      tooltipTermIsOpen: false,
      openModalMessage: false,
      openStudentModal: false,
      openNoteCompose: false,
    };
  };

  render() {
    let students16Absences = this.state.students16MoreAbsences
      .filter((x) =>
        this.state.showOnlyStudentsWithOutMessage === true
          ? x.totalMessages === 0
          : x.totalMessages > -1
      )
      .map((item) => {
        return (
          <tr key={item.id}>
            <td>
              <a
                onClick={this.setStudentIdAndOpenModal.bind(this, item.id)}
                href="#"
              >
                {item.name}
              </a>
            </td>

            <td>{item.statusName}</td>
            <td>{item.visaTypeName}</td>
            <td>{item.campusName}</td>
            <td className="text-center">{item.totalTardy}</td>
            <td className="text-center">{item.totalAbsent}</td>
            <td className="text-center"> {item.total} </td>

            {/* <td className="text-center">
              <Button
                color="info"
                size="sm"
                onClick={this.setStudentIdAndOpenMessage.bind(
                  this,
                  item.id,
                  item.name
                )}
              >
                {item.totalMessages}
              </Button>
            </td> */}

            {/* <td
              className="text-center customCheckbox"
              onClick={
                !item.hasCall &&
                this.setPropertiesNoteCompose.bind(
                  this,
                  item.id,
                  item.name,
                  "C"
                )
              }
            >
              <CustomInput
                onClick
                type="checkbox"
                inline
                checked={item.hasCall}
                disabled={item.hasCall}
              />
            </td> */}
            {/* <td
              className="text-center customCheckbox"
              onClick={
                !item.hasProbation &&
                this.setPropertiesNoteCompose.bind(
                  this,
                  item.id,
                  item.name,
                  "P"
                )
              }
            >
              <CustomInput
                type="checkbox"
                inline
                checked={item.hasProbation}
                disabled={item.hasProbation}
              />
            </td> */}
            {/* <td
              className="text-center customCheckbox"
              onClick={
                !item.hasTerminated &&
                this.setPropertiesNoteCompose.bind(
                  this,
                  item.id,
                  item.name,
                  "T"
                )
              }
            >
              <CustomInput
                type="checkbox"
                inline
                checked={item.hasTerminated}
                disabled={item.hasTerminated}
              />
            </td> */}
          </tr>
        );
      });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row style={{ marginLeft: "0" }}>
                    <CardTitle tag="h5">
                      Students with 16 or more absences
                    </CardTitle>

                    {/* <CustomInput
                      className="dashboardWithoutMessageCheckBox"
                      type="checkbox"
                      id="WithoutMessageCheckbox"
                      name="WithoutMessageCheckbox"
                      label="Filter students without messages"
                      checked={this.state.showOnlyStudentsWithOutMessage}
                      onChange={this.toggleShowOnlyStudentsWithOutMessage}
                    /> */}

                    <CustomInput
                      className="dashboardWithoutMessageCheckBox"
                      type="checkbox"
                      id="showAllStatuses"
                      name="showAllStatuses"
                      label="Show all statuses"
                      checked={this.state.showAllStatuses}
                      onChange={this.toggleShowAllStatuses}
                    />
                  </Row>
                </CardHeader>
                <CardBody>
                  {students16Absences.length > 0 && (
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Visa Type</th>
                          <th>Campus</th>
                          <th className="text-center">Tardies</th>
                          <th className="text-center">Absences</th>
                          <th className="text-center">Total</th>
                          {/* <th className="text-center">Message</th>
                          <th className="text-center">
                            PL Sent{"  "}
                            <i
                              id="callId"
                              className="fas fa-question-circle"
                              style={{ color: "#51cbce" }}
                            />
                          </th>
                          <Tooltip
                            placement="top-end"
                            isOpen={this.state.tooltipCallIsOpen}
                            target={"callId"}
                            toggle={this.toggleToolTip.bind(
                              this,
                              "tooltipCallIsOpen"
                            )}
                          >
                            {
                              <div>
                                <p>
                                  Select this option to indicate that a
                                  probation letter has been sent to the student.
                                </p>
                                <p>
                                  The note description will be available in the
                                  student's registration, under the "Note" tab.
                                </p>
                              </div>
                            }
                          </Tooltip>
                          <th className="text-center">
                            PL Signed{"  "}
                            <i
                              id="probId"
                              className="fas fa-question-circle"
                              style={{ color: "#51cbce" }}
                            />
                          </th>

                          <Tooltip
                            placement="top-end"
                            isOpen={this.state.tooltipProbIsOpen}
                            target={"probId"}
                            toggle={this.toggleToolTip.bind(
                              this,
                              "tooltipProbIsOpen"
                            )}
                          >
                            {
                              <div>
                                <p>
                                  Select this option to indicate that a
                                  probation letter has been signed by the
                                  student.
                                </p>
                                <p>
                                  The note description will be available in the
                                  student's registration, under the "Note" tab.
                                </p>
                              </div>
                            }
                          </Tooltip>

                          <th className="text-center">
                            TL{"  "}
                            <i
                              id="termId"
                              className="fas fa-question-circle"
                              style={{ color: "#51cbce" }}
                            />
                          </th>

                          <Tooltip
                            placement="top-end"
                            isOpen={this.state.tooltipTermIsOpen}
                            target={"termId"}
                            toggle={this.toggleToolTip.bind(
                              this,
                              "tooltipTermIsOpen"
                            )}
                          >
                            {
                              <div>
                                <p>
                                  Select this option to indicate that a
                                  termination letter has been issued to the
                                  student.
                                </p>
                                <p>
                                  The note description will be available in the
                                  student's registration, under the "Note" tab.
                                </p>
                              </div>
                            }
                          </Tooltip> */}
                        </tr>
                      </thead>
                      <tbody>{students16Absences}</tbody>
                    </Table>
                  )}
                </CardBody>

                {students16Absences.length > 0 && (
                  <CardFooter>
                    <hr />
                    <Row>
                      <h6
                        style={{
                          marginLeft: "25px",
                          marginRight: "auto",
                          marginTop: "15px",
                          textTransform: "inherit",
                        }}
                      >
                        <strong className="text-secondary">
                          {students16Absences.length}
                        </strong>{" "}
                        {students16Absences.length > 1 ? "records" : "record"}
                      </h6>

                      <Button
                        color="success"
                        size="sm"
                        style={{
                          marginLeft: "auto",
                          textTransform: "inherit",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                        onClick={SavePdf.bind(
                          this,
                          "Absences",
                          this.state.students16MoreAbsences,
                          [
                            {
                              name: "Name",
                              totalTardy: "Tardies",
                              totalAbsent: "Absences",
                              total: "Total",
                            },
                          ]
                        )}
                      >
                        Export to PDF
                      </Button>

                      <Button
                        color="success"
                        size="sm"
                        style={{
                          textTransform: "inherit",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                        onClick={this.downloadCSV.bind(this)}
                      >
                        Export to CSV
                      </Button>
                    </Row>
                  </CardFooter>
                )}
              </Card>
            </Col>
          </Row>
          <StudentEdit2
            isOpen={this.state.openStudentModal}
            toggleStudentModal={this.toggleStudentModal.bind(this)}
            editMode={editMode.EDIT}
            id={this.state.studentId}
            callback={this._refreshStudents.bind(this)}
            tables={this.props.tables}
          />
          <MessageEdit
            isOpen={this.state.openModalMessage}
            toggleMessageModal={this.toggleMessageModal.bind(this)}
            editMode={editMode.INSERT}
            tables={this.props.tables}
            StudentId={this.state.studentId}
            StudentName={this.state.studentName}
            callback={this._refreshStudents.bind(this)}
          />
          <NoteCompose
            isOpen={this.state.openNoteCompose}
            toggle={this.toggleNoteCompose.bind(this)}
            StudentId={this.state.studentId}
            StudentName={this.state.studentName}
            type={this.state.noteType}
            callback={this._refreshStudents.bind(this)}
          />
        </div>
      </>
    );
  }

  componentDidMount() {
    this._refreshStudents();
  }

  toggleStudentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openStudentModal = !mainState.openStudentModal;
      this.setState(mainState, resolve(true));
    });
  };

  setStudentIdAndOpenModal = (id) => {
    let mainState = this.state;

    mainState.studentId = id;

    this.setState(mainState, () => {
      this.toggleStudentModal();
    });
  };

  setStudentIdAndOpenMessage = (id, name) => {
    let mainState = this.state;

    mainState.studentId = id;
    mainState.studentName = name;

    this.setState(mainState, () => {
      this.toggleMessageModal();
    });
  };

  setPropertiesNoteCompose = (id, name, type) => {
    let mainState = this.state;

    mainState.studentId = id;
    mainState.studentName = name;
    mainState.noteType = type;

    this.setState(mainState, () => {
      this.toggleNoteCompose();
    });
  };

  toggleToolTip = (name) => {
    let modalState = this.state;

    modalState[name] = !modalState[name];

    this.setState(modalState);
  };

  _refreshStudents() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "dashboard/StudentsAbsences?page=1&pageSize=999999999&ShowAllStatuses=" +
            this.state.showAllStatuses
        )
        .then((response) => {
          let mainState = this.state;

          mainState.students16MoreAbsences = [];
          response.data.data.items.map((student, index) =>
            mainState.students16MoreAbsences.push({
              id: student.id,
              name: student.name,
              total: student.total,
              totalPresent: student.totalPresent,
              totalTardy: student.totalTardy,
              totalAbsent: student.totalAbsent,
              totalNotYetTaken: student.totalNotYetTaken,
              totalMessages: student.totalMessages,
              hasCall: student.hasCall,
              hasProbation: student.hasProbation,
              hasTerminated: student.hasTerminated,
              campusName: student.campusName,
              visaTypeName: student.visaTypeName,
              statusName: student.statusName,
            })
          );

          this.setState(mainState, resolve(true));
        });
    });
  }

  toggleShowOnlyStudentsWithOutMessage = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.showOnlyStudentsWithOutMessage =
        !mainState.showOnlyStudentsWithOutMessage;

      this.setState(mainState, resolve(true));
    });
  };

  toggleShowAllStatuses = () => {
    let mainState = this.state;

    mainState.showAllStatuses = !mainState.showAllStatuses;

    this.setState(mainState, () => {
      this._refreshStudents();
    });
  };

  toggleMessageModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.openModalMessage = !mainState.openModalMessage;

      this.setState(mainState, resolve(true));
    });
  };

  toggleNoteCompose = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.openNoteCompose = !mainState.openNoteCompose;

      this.setState(mainState, resolve(true));
    });
  };

  downloadCSV() {
    let arrayOfJson = [];

    this.state.students16MoreAbsences.map((student, index) =>
      arrayOfJson.push({
        Name: student.name,
        Tardies: student.totalTardy,
        Absences: student.totalAbsent,
        Total: student.total,
      })
    );

    this.downloadCSVFromJson("Absences", arrayOfJson);
  }

  downloadCSVFromJson(filename, arrayOfJson) {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default AbsencesReport;
