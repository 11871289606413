/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Table, Row, Col, Card, CardBody } from "reactstrap";
import axios from "axios";
import { editMode } from "helpers/Utils.js";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";

class FailingStudents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      students: [],
      openStudentModal: false,
      studentId: "",
    };
  }

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          students: [],
          openStudentModal: false,
          studentId: "",
        },
        resolve(true)
      );
    });
  };

  refresh() {
    return new Promise((resolve, reject) => {
      axios.get("dashboard/FailingStudents").then((response) => {
        let failingStudents = [];
        response.data.data.failingStudents.map((student, index) =>
          failingStudents.push({
            id: student.id,
            name: student.name,
            classCode: student.classCode,
            grade: student.grade.toFixed(2) + "%",
          })
        );

        this.setState({ students: failingStudents }, resolve(true));
      });
    });
  }

  componentDidMount() {
    this.refresh();
  }

  toggleStudentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openStudentModal = !mainState.openStudentModal;
      this.setState(mainState, resolve(true));
    });
  };

  openStudentEditModal = (id) => {
    this.toggleStudentModal();
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.studentId = id;
      this.setState(mainState, resolve(true));
    });
  };

  render() {
    let students = this.state.students.map((student) => {
      return (
        <tr key={student.id}>
          <td>
            <a
              onClick={this.openStudentEditModal.bind(this, student.id)}
              href="#"
            >
              {student.name}
            </a>
          </td>
          <td>{student.classCode}</td>
          <td>
            <strong>{student.grade}</strong>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Subject</th>
                        <th>Grade</th>
                      </tr>
                    </thead>

                    <tbody>{students}</tbody>
                  </Table>

                  <StudentEdit2
                    isOpen={this.state.openStudentModal}
                    toggleStudentModal={this.toggleStudentModal.bind(this)}
                    editMode={editMode.EDIT}
                    id={this.state.studentId}
                    callback={this.refresh.bind(this)}
                    tables={this.props.tables}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default FailingStudents;
