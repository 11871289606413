import React from "react";

class Loader extends React.Component {
  render() {
    return (
      <div
        className="spinner"
        style={{ display: this.props.isVisible ? "block" : "none" }}
      >
        <span></span>
      </div>
    );
  }
}

export default Loader;
