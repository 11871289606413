/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";

class ClassTeacherView extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getModalState();
  }

  getModalState = () => {
    return {
      classes: [],
      totalClasses: 0,
      currentClassesPage: 1,
      classesPerPage: 10,
      openClasseModal: false,
    };
  };

  toggleClasseModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openClasseModal = !mainState.openClasseModal;
      this.setState(mainState, resolve(true));
    });
  };

  refresh() {
    let page = this.state.currentClassesPage;
    let itemsPerPage = this.state.classesPerPage;

    return new Promise((resolve, reject) => {
      axios
        .get(
          "classes/byTeacher?teacherId=" +
            this.props.teacherId +
            "&page=" +
            page +
            "&pageSize=" +
            itemsPerPage
        )
        .then((response) => {
          let modalState = this.state;

          modalState.classes = [];
          response.data.data.items.map((classe, index) =>
            modalState.classes.push({
              id: classe.id,
              name: classe.code,
              campusName: classe.campus.name,
            })
          );

          modalState.totalClasses = response.data.data.totalCount;

          this.setState({ modalState }, resolve(true));
        });
    });
  }

  nextPage(pageNumber) {
    let classePerPage = this.state.classesPerPage;

    axios
      .get(
        "classes/byTeacher?teacherId=" +
          this.props.teacherId +
          "&page=" +
          pageNumber +
          "&pageSize=" +
          classePerPage
      )
      .then((response) => {
        let modalState = this.state;

        modalState.classes = [];
        response.data.data.items.map((classe, index) =>
          modalState.classes.push({
            id: classe.id,
            name: classe.code,
            campusName: classe.campus.name,
          })
        );

        modalState.currentClassesPage = pageNumber;
        modalState.totalClasses = response.data.data.totalCount;

        this.setState(modalState);
      });
  }

  onOpened() {
    this.refresh();
  }

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  render() {
    let classes = this.state.classes.map((classe) => {
      return (
        <tr key={classe.id}>
          <td>{classe.name}</td>
          <td>{classe.campusName}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggleClasseModal.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                unmountOnClose={true}
                onOpened={this.onOpened.bind(this)}
                onClosed={this.onCloseModal}
              >
                <ModalHeader toggle={this.props.toggleClasseModal.bind(this)}>
                  Subjects
                </ModalHeader>
                <ModalBody>
                  {classes.length > 0 ? (
                    <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Campus</th>
                        </tr>
                      </thead>

                      <tbody>{classes}</tbody>
                    </Table>
                  ) : (
                    <h3>No subjects found</h3>
                  )}

                  {this.state.totalClasses > this.state.classesPerPage ? (
                    <Pagination
                      activePage={this.state.currentClassesPage}
                      totalItemsCount={this.state.totalClasses}
                      onChange={this.nextPage.bind(this)}
                    />
                  ) : (
                    ""
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={this.props.toggleClasseModal.bind(this)}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
            {/* <ClasseEdit
              isOpen={this.state.openClasseModal}
              toggleClasseModal={this.toggleClasseModal.bind(this)}
              editMode={editMode.EDIT}
              id={this.state.classeId}
            /> */}
          </Row>
        </div>
      </>
    );
  }
}

export default ClassTeacherView;
