import React from "react";
import { Route, Redirect } from "react-router-dom";
import { authenticationService } from "services/Authentication.service.js";
import Can from "../Can/Can";

export const PrivateRoute = ({
  component: Component,
  roles,
  tables,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = authenticationService.currentUserValue;

      if (!currentUser) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      } else {
        return (
          <Can
            feature={props.location.pathname}
            yes={() => <Component {...props} tables={tables} />}
            no={() => <Redirect to={{ pathname: "/" }} />}
          ></Can>
        );
      }
    }}
  />
);
