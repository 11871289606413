/* eslint-disable no-eval */
/* eslint-disable no-useless-constructor */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
  CustomInput,
} from "reactstrap";
import { FinancialContractPdf } from "components/Report/FinancialContractPdf.js";
import DatePicker from "react-datepicker";
import Select from "react-select";
import * as dateFns from "date-fns";
import SignatureTokenModal from "../Signature/Signature.token.modal.component";
import axios from "axios";
import { authenticationService } from "services/Authentication.service.js";

class ModalFinancialContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();
  }

  getModalState = () => {
    return {
      canSchoolSendMessage: false,
      tuitionCost: "0.00",
      cardFeeCost: "0.00",
      totalCost: "0.00",
      downPaymentCost: "0.00",
      downPaymentFeeCost: "0.00",
      downPaymentType: -1,
      downPaymentCost2: "0.00",
      downPaymentFeeCost2: "0.00",
      downPaymentType2: -1,
      balanceCost: "0.00",
      balanceMonths: -1,
      firstPaymentDate: null,
      secondPaymentDate: null,
      thirdPaymentDate: null,
      fourthPaymentDate: null,
      date: new Date(),
      firstPaymentCost: "0.00",
      firstPaymentCardFee: "0.00",
      secondPaymentCost: "0.00",
      secondPaymentCardFee: "0.00",
      thirdPaymentCost: "0.00",
      thirdPaymentCardFee: "0.00",
      fourthPaymentCost: "0.00",
      fourthPaymentCardFee: "0.00",
      signatureModalIsOpen: false,
      trimmedDataURL: null,
      tokenSignature: "",
      errorSignature: "",
      currentUser: authenticationService.currentUserValue,
    };
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          centered
          onClosed={this.onCloseModal}
          onOpened={this.onOpened.bind(this)}
          size={"lg"}
          style={{
            maxWidth: "1100px",
            width: "80%",
          }}
        >
          <ModalHeader toggle={this.props.toggle}>
            Financial Contract
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={2}>
                <FormGroup>
                  <Label>Date</Label>
                  <DatePicker
                    selected={this.state.date}
                    onChange={this.handleDateInput.bind(this, "date")}
                    name="date"
                    dateFormat="MM/dd/yyyy"
                    tabIndex={0}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="tuitionCost">Tuition $</Label>
                  <Input
                    className={`form-group`}
                    type="number"
                    name="tuitionCost"
                    id="tuitionCost"
                    value={this.state.tuitionCost}
                    onChange={this.handleUserInput}
                    onFocus={this.handleFocus}
                    onBlur={this.onBlur}
                    tabIndex={1}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="cardFeeCost">Card Fee $</Label>
                  <Input
                    className={`form-group`}
                    type="number"
                    name="cardFeeCost"
                    id="cardFeeCost"
                    value={this.state.cardFeeCost}
                    onChange={this.handleUserInput}
                    onFocus={this.handleFocus}
                    onBlur={this.onBlur}
                    tabIndex={2}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="tuitionCost">Partial Payt. Type</Label>
                  <Select
                    className={`form-group`}
                    value={
                      this.state.downPaymentType &&
                      this.downPaymentTypes.find(
                        (x) => x.value === this.state.downPaymentType
                      )
                    }
                    onChange={this.handleDownPaymentTypeChange}
                    options={this.downPaymentTypes}
                    tabIndex={4}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Partial Payt. $</Label>
                  <Input
                    className={`form-group`}
                    type="number"
                    name="downPaymentCost"
                    id="downPaymentCost"
                    value={this.state.downPaymentCost}
                    onChange={this.handleUserInput}
                    onFocus={this.handleFocus}
                    onBlur={this.onBlur}
                    tabIndex={3}
                    disabled={
                      this.state.downPaymentType < 1 ||
                      this.state.downPaymentType > 3
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="downPaymentFeeCost">Partial Payt. Fee $</Label>
                  <Input
                    className={`form-group`}
                    type="number"
                    name="downPaymentFeeCost"
                    id="downPaymentFeeCost"
                    value={this.state.downPaymentFeeCost}
                    onChange={this.handleUserInput}
                    onFocus={this.handleFocus}
                    onBlur={this.onBlur}
                    tabIndex={2}
                    disabled={true}
                  />
                </FormGroup>
              </Col>

              <Col md={2}>
                <FormGroup>
                  <Label for="tuitionCost">Partial Payt. Type 2</Label>
                  <Select
                    className={`form-group`}
                    value={
                      this.state.downPaymentType2 &&
                      this.downPaymentTypes.find(
                        (x) => x.value === this.state.downPaymentType2
                      )
                    }
                    onChange={this.handleDownPaymentTypeChange2}
                    options={this.downPaymentTypes}
                    tabIndex={4}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label>Partial Payt. $ 2</Label>
                  <Input
                    className={`form-group`}
                    type="number"
                    name="downPaymentCost2"
                    id="downPaymentCost2"
                    value={this.state.downPaymentCost2}
                    onChange={this.handleUserInput}
                    onFocus={this.handleFocus}
                    onBlur={this.onBlur}
                    tabIndex={3}
                    disabled={
                      this.state.downPaymentType2 < 1 ||
                      this.state.downPaymentType2 > 3
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="downPaymentFeeCost2">Partial Payt. Fee $ 2</Label>
                  <Input
                    className={`form-group`}
                    type="number"
                    name="downPaymentFeeCost2"
                    id="downPaymentFeeCost2"
                    value={this.state.downPaymentFeeCost2}
                    onChange={this.handleUserInput}
                    onFocus={this.handleFocus}
                    onBlur={this.onBlur}
                    tabIndex={2}
                    disabled={true}
                  />
                </FormGroup>
              </Col>

              <Col md={2}>
                <FormGroup>
                  <Label for="totalCost"># Payts.</Label>
                  <Select
                    className={`form-group`}
                    value={
                      this.state.balanceMonths &&
                      this.balancePaymentTypes.find(
                        (x) => x.value === this.state.balanceMonths
                      )
                    }
                    onChange={this.handleBalancePaymentTypeChange}
                    options={this.balancePaymentTypes}
                    tabIndex={5}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="balanceCost">Balance $</Label>
                  <Input
                    className={`form-group`}
                    type="number"
                    name="balanceCost"
                    id="balanceCost"
                    value={this.state.balanceCost}
                    onChange={this.handleUserInput}
                    onFocus={this.handleFocus}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup>
                  <Label for="totalCost">Total $</Label>
                  <Input
                    className={`form-group`}
                    type="number"
                    name="totalCost"
                    id="totalCost"
                    value={this.state.totalCost}
                    onChange={this.handleUserInput}
                    onFocus={this.handleFocus}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
            </Row>

            {this.state.balanceMonths >= 0 && (
              <Row form>
                {this.state.balanceMonths >= 0 && (
                  <Col md={3}>
                    <FormGroup>
                      <Label>1st Payment Date</Label>
                      <DatePicker
                        selected={this.state.firstPaymentDate}
                        onChange={this.handleFirstDateInput.bind(
                          this,
                          "firstPaymentDate"
                        )}
                        name="firstPaymentDate"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>1st $ Tuition</Label>
                      <Input
                        className={`form-group`}
                        type="number"
                        name="firstPaymentCost"
                        id="firstPaymentCost"
                        value={this.state.firstPaymentCost}
                        onChange={this.handleUserInput}
                        onFocus={this.handleFocus}
                        onBlur={this.formatNumbers}
                        tabIndex={6}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>1st $ Card Fee</Label>
                      <Input
                        className={`form-group`}
                        type="number"
                        name="firstPaymentCardFee"
                        id="firstPaymentCardFee"
                        value={this.state.firstPaymentCardFee}
                        onChange={this.handleUserInput}
                        onFocus={this.handleFocus}
                        onBlur={this.formatNumbers}
                        tabIndex={6}
                      />
                    </FormGroup>
                  </Col>
                )}
                {this.state.balanceMonths >= 2 && (
                  <Col md={3}>
                    <FormGroup>
                      <Label for="tuitionCost">2nd Payment Date</Label>
                      <DatePicker
                        selected={this.state.secondPaymentDate}
                        onChange={this.handleDateInput.bind(
                          this,
                          "secondPaymentDate"
                        )}
                        name="secondPaymentDate"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>2nd $ Tuition</Label>
                      <Input
                        className={`form-group`}
                        type="number"
                        name="secondPaymentCost"
                        id="secondPaymentCost"
                        value={this.state.secondPaymentCost}
                        onChange={this.handleUserInput}
                        onFocus={this.handleFocus}
                        onBlur={this.formatNumbers}
                        tabIndex={7}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>2nd $ Card Fee</Label>
                      <Input
                        className={`form-group`}
                        type="number"
                        name="secondPaymentCardFee"
                        id="secondPaymentCardFee"
                        value={this.state.secondPaymentCardFee}
                        onChange={this.handleUserInput}
                        onFocus={this.handleFocus}
                        onBlur={this.formatNumbers}
                        tabIndex={6}
                      />
                    </FormGroup>
                  </Col>
                )}
                {this.state.balanceMonths >= 3 && (
                  <Col md={3}>
                    <FormGroup>
                      <Label for="thirdPaymentDate">3rd Payment Date</Label>
                      <DatePicker
                        selected={this.state.thirdPaymentDate}
                        onChange={this.handleDateInput.bind(
                          this,
                          "thirdPaymentDate"
                        )}
                        name="thirdPaymentDate"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>3rd $ Tuition</Label>
                      <Input
                        className={`form-group`}
                        type="number"
                        name="thirdPaymentCost"
                        id="thirdPaymentCost"
                        value={this.state.thirdPaymentCost}
                        onChange={this.handleUserInput}
                        onFocus={this.handleFocus}
                        onBlur={this.formatNumbers}
                        tabIndex={8}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>3rd $ Card Fee</Label>
                      <Input
                        className={`form-group`}
                        type="number"
                        name="thirdPaymentCardFee"
                        id="thirdPaymentCardFee"
                        value={this.state.thirdPaymentCardFee}
                        onChange={this.handleUserInput}
                        onFocus={this.handleFocus}
                        onBlur={this.formatNumbers}
                        tabIndex={6}
                      />
                    </FormGroup>
                  </Col>
                )}
                {this.state.balanceMonths >= 4 && (
                  <Col md={3}>
                    <FormGroup>
                      <Label for="totalCost">4th Payment Date</Label>
                      <DatePicker
                        selected={this.state.fourthPaymentDate}
                        onChange={this.handleDateInput.bind(
                          this,
                          "fourthPaymentDate"
                        )}
                        name="fourthPaymentDate"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>4th $ Tuition</Label>
                      <Input
                        className={`form-group`}
                        type="number"
                        name="fourthPaymentCost"
                        id="fourthPaymentCost"
                        value={this.state.fourthPaymentCost}
                        onChange={this.handleUserInput}
                        onFocus={this.handleFocus}
                        onBlur={this.formatNumbers}
                        tabIndex={9}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>4th $ Card Fee</Label>
                      <Input
                        className={`form-group`}
                        type="number"
                        name="fourthPaymentCardFee"
                        id="fourthPaymentCardFee"
                        value={this.state.fourthPaymentCardFee}
                        onChange={this.handleUserInput}
                        onFocus={this.handleFocus}
                        onBlur={this.formatNumbers}
                        tabIndex={6}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
            )}
            <Row form style={{ marginTop: "15px" }}>
              <Col md={12}>
                <FormGroup>
                  <CustomInput
                    className="defaultCheckBox"
                    type="checkbox"
                    id="activeCheckBox"
                    name="activeCheckBox"
                    label="Can the school send phone messages?"
                    checked={this.state.canSchoolSendMessage}
                    onChange={this.onCheckActiveChange.bind(this)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.setTokenSignatureAndOpenModal.bind(this)}
              tabIndex={10}
              disabled={!this.validateForm()}
            >
              Generate token
            </Button>{" "}
            <Button color="secondary" onClick={this.props.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <SignatureTokenModal
          isOpen={this.state.signatureModalIsOpen}
          toggle={this.toggleSignatureModal.bind(this)}
          proceedCallback={this.createPdf.bind(this)}
          tokenSignature={this.state.tokenSignature}
          errorSignature={this.state.errorSignature}
        />
      </div>
    );
  }

  setTokenSignatureAndOpenModal = () => {
    return new Promise((resolve, reject) => {
      let downPaymentType = this.downPaymentTypes.find(
        (x) => x.value === this.state.downPaymentType
      );

      let downPaymentType2 = this.downPaymentTypes.find(
        (x) => x.value === this.state.downPaymentType2
      );

      let description =
        "Payment $" +
        (
          eval(this.state.downPaymentCost) + eval(this.state.downPaymentFeeCost)
        ).toFixed(2) +
        " " +
        (downPaymentType ? downPaymentType.label : "") +
        (downPaymentType2 &&
        downPaymentType2.value > 0 &&
        downPaymentType2.value < 4
          ? " $" +
            (
              eval(this.state.downPaymentCost2) +
              eval(this.state.downPaymentFeeCost2)
            ).toFixed(2) +
            " " +
            downPaymentType2.label
          : "") +
        " on " +
        dateFns.format(this.state.date, "MM/dd/yyyy") +
        " - Balance $" +
        this.state.balanceCost +
        (this.state.balanceCost > 0 ? " to be paid in " : "") +
        (this.state.balanceCost > 0 ? this.state.balanceMonths : "") +
        (this.state.balanceCost > 0 ? " payments. " : "") +
        "\n\n" +
        (this.state.balanceMonths > 0
          ? "1st scheduled payment for " +
            dateFns.format(this.state.firstPaymentDate, "MM/dd/yyyy") +
            " $" +
            (
              eval(this.state.firstPaymentCost) +
              eval(this.state.firstPaymentCardFee)
            ).toFixed(2) +
            (" (Tuition: $" +
              eval(this.state.firstPaymentCost).toFixed(2) +
              " + Credit/Debit Card Fee: $" +
              eval(this.state.firstPaymentCardFee).toFixed(2) +
              ")") +
            "\n"
          : "") +
        (this.state.balanceMonths > 1
          ? "2st scheduled payment for " +
            dateFns.format(this.state.secondPaymentDate, "MM/dd/yyyy") +
            " $" +
            (
              eval(this.state.secondPaymentCost) +
              eval(this.state.secondPaymentCardFee)
            ).toFixed(2) +
            (" (Tuition: $" +
              eval(this.state.secondPaymentCost).toFixed(2) +
              " + Credit/Debit Card Fee: $" +
              eval(this.state.secondPaymentCardFee).toFixed(2) +
              ")") +
            "\n"
          : "") +
        (this.state.balanceMonths > 2
          ? "3st scheduled payment for " +
            dateFns.format(this.state.thirdPaymentDate, "MM/dd/yyyy") +
            " $" +
            (
              eval(this.state.thirdPaymentCost) +
              eval(this.state.thirdPaymentCardFee)
            ).toFixed(2) +
            (" (Tuition: $" +
              eval(this.state.thirdPaymentCost).toFixed(2) +
              " + Credit/Debit Card Fee: $" +
              eval(this.state.thirdPaymentCardFee).toFixed(2) +
              ")") +
            "\n"
          : "") +
        (this.state.balanceMonths > 3
          ? "4st scheduled payment for " +
            dateFns.format(this.state.fourthPaymentDate, "MM/dd/yyyy") +
            " $" +
            (
              eval(this.state.fourthPaymentCost) +
              eval(this.state.fourthPaymentCardFee)
            ).toFixed(2) +
            (" (Tuition: $" +
              eval(this.state.fourthPaymentCost).toFixed(2) +
              " + Credit/Debit Card Fee: $" +
              eval(this.state.fourthPaymentCardFee).toFixed(2) +
              ")")
          : "");

      let signaturePaymentList = [];

      if (this.state.balanceMonths > 0)
        signaturePaymentList.push({
          DueDate: this.state.firstPaymentDate,
          Amount: this.state.firstPaymentCost,
          CardFee: this.state.firstPaymentCardFee,
        });

      if (this.state.balanceMonths > 1)
        signaturePaymentList.push({
          DueDate: this.state.secondPaymentDate,
          Amount: this.state.secondPaymentCost,
          CardFee: this.state.secondPaymentCardFee,
        });

      if (this.state.balanceMonths > 2)
        signaturePaymentList.push({
          DueDate: this.state.thirdPaymentDate,
          Amount: this.state.thirdPaymentCost,
          CardFee: this.state.thirdPaymentCardFee,
        });

      if (this.state.balanceMonths > 3)
        signaturePaymentList.push({
          DueDate: this.state.fourthPaymentDate,
          Amount: this.state.fourthPaymentCost,
          CardFee: this.state.fourthPaymentCardFee,
        });

      let signature = {
        Student: { Id: this.props.student.Id.Value },
        Description: description,
        Tuition: this.state.tuitionCost,
        CardFee: this.state.cardFeeCost,
        DownPayment: this.state.downPaymentCost,
        DownPaymentType: this.state.downPaymentType,
        DownPayment2: this.state.downPaymentCost2,
        DownPaymentType2: this.state.downPaymentType2,
        Payments: this.state.balanceMonths,
        SignaturePaymentList: signaturePaymentList,
      };

      axios
        .post("users/GenerateSignatureToken", signature)
        .then((response) => {
          this.setState({ tokenSignature: response.data.data }, resolve(true));
        })
        .then(() => this.toggleSignatureModal());
    });
  };

  toggleSignatureModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          signatureModalIsOpen: !this.state.signatureModalIsOpen,
          errorSignature: "",
        },
        resolve(true)
      );
    });
  };

  onCheckActiveChange = (e) => {
    let modalState = this.state;

    modalState.canSchoolSendMessage = e.target.checked;

    this.setState(modalState);
  };

  handleDownPaymentTypeChange = (selectedOption) => {
    let modalState = this.state;

    modalState.downPaymentType = selectedOption.value;

    this.setState(modalState, () => {
      this.calculate();
    });
  };

  handleDownPaymentTypeChange2 = (selectedOption) => {
    let modalState = this.state;

    modalState.downPaymentType2 = selectedOption.value;

    this.setState(modalState, () => {
      this.calculate();
    });
  };

  handleBalancePaymentTypeChange = (selectedOption) => {
    let modalState = this.state;

    modalState.balanceMonths = selectedOption.value;

    this.setState(modalState, () => {
      this.calculate();
    });
  };

  handleFocus = (event) => {
    event.target.select();
  };

  handleFirstDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;

    mainState.firstPaymentDate = date;
    mainState.secondPaymentDate = dateFns.addMonths(date, 1);
    mainState.thirdPaymentDate = dateFns.addMonths(date, 2);
    mainState.fourthPaymentDate = dateFns.addMonths(date, 3);

    this.setState(mainState);
  };

  handleDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;
    mainState[name] = date;

    this.setState(mainState);
  };

  onCloseModal = () => {
    this.resetModal();
  };

  onBlur = (event) => {
    this.calculate();
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState[name] = value > "" ? value : 0;

    this.setState(modalState);
  };

  calculate = () => {
    let plain_tuitionCost = eval(this.state.tuitionCost).toFixed(2);
    let plain_downPaymentCost = eval(this.state.downPaymentCost).toFixed(2);
    let plain_downPaymentCost2 = eval(this.state.downPaymentCost2).toFixed(2);

    let calc_cardFeeCost = (
      (eval(plain_tuitionCost) -
        eval(plain_downPaymentCost) -
        eval(plain_downPaymentCost2)) *
      0.04
    ).toFixed(2);

    let calc_downPaymentFeeCost =
      this.state.downPaymentType === 3
        ? eval(plain_downPaymentCost * 0.04).toFixed(2)
        : eval(0).toFixed(2);

    let calc_downPaymentFeeCost2 =
      this.state.downPaymentType2 === 3
        ? eval(plain_downPaymentCost2 * 0.04).toFixed(2)
        : eval(0).toFixed(2);

    let calc_totalCost = (
      eval(plain_tuitionCost) +
      eval(calc_cardFeeCost) +
      eval(calc_downPaymentFeeCost) +
      eval(calc_downPaymentFeeCost2)
    ).toFixed(2);

    let calc_balanceCost = (
      eval(plain_tuitionCost) +
      eval(calc_cardFeeCost) -
      eval(plain_downPaymentCost) -
      eval(plain_downPaymentCost2)
    ).toFixed(2);

    let firstPaymentCost = "0.00";
    let secondPaymentCost = "0.00";
    let thirdPaymentCost = "0.00";
    let fourthPaymentCost = "0.00";

    if (calc_balanceCost > 0 && this.state.balanceMonths > 0) {
      if (this.state.balanceMonths === 1) {
        firstPaymentCost = (
          eval(plain_tuitionCost) -
          eval(plain_downPaymentCost) -
          eval(plain_downPaymentCost2)
        ).toFixed(2);
      } else {
        firstPaymentCost = (
          eval(plain_tuitionCost) / 2 -
          eval(plain_downPaymentCost) -
          eval(plain_downPaymentCost2)
        ).toFixed(2);

        if (firstPaymentCost > 0) {
          let splitPayment = eval(
            eval(plain_tuitionCost) / 2 / (this.state.balanceMonths - 1)
          ).toFixed(2);

          secondPaymentCost = this.state.balanceMonths > 1 ? splitPayment : 0;
          thirdPaymentCost = this.state.balanceMonths > 2 ? splitPayment : 0;
          fourthPaymentCost = this.state.balanceMonths > 3 ? splitPayment : 0;
        } else {
          let splitPayment = (
            (eval(plain_tuitionCost) -
              eval(plain_downPaymentCost) -
              eval(plain_downPaymentCost2)) /
            this.state.balanceMonths
          ).toFixed(2);

          firstPaymentCost = this.state.balanceMonths > 0 ? splitPayment : 0;
          secondPaymentCost = this.state.balanceMonths > 1 ? splitPayment : 0;
          thirdPaymentCost = this.state.balanceMonths > 2 ? splitPayment : 0;
          fourthPaymentCost = this.state.balanceMonths > 3 ? splitPayment : 0;
        }
      }
    }

    this.setState({
      tuitionCost: plain_tuitionCost,
      cardFeeCost: calc_cardFeeCost,
      downPaymentCost: plain_downPaymentCost,
      downPaymentCost2: plain_downPaymentCost2,
      totalCost: calc_totalCost,
      balanceCost: calc_balanceCost,
      downPaymentFeeCost: calc_downPaymentFeeCost,
      downPaymentFeeCost2: calc_downPaymentFeeCost2,

      firstPaymentCost: firstPaymentCost,
      firstPaymentCardFee: (firstPaymentCost * 0.04).toFixed(2),

      secondPaymentCost: secondPaymentCost,
      secondPaymentCardFee: (secondPaymentCost * 0.04).toFixed(2),

      thirdPaymentCost: thirdPaymentCost,
      thirdPaymentCardFee: (thirdPaymentCost * 0.04).toFixed(2),

      fourthPaymentCost: fourthPaymentCost,
      fourthPaymentCardFee: (fourthPaymentCost * 0.04).toFixed(2),
    });
  };

  formatNumbers = (e) => {
    let firstPaymentCost = eval(this.state.firstPaymentCost).toFixed(2);
    let firstPaymentCardFee = eval(this.state.firstPaymentCardFee).toFixed(2);
    let secondPaymentCost = eval(this.state.secondPaymentCost).toFixed(2);
    let secondPaymentCardFee = eval(this.state.secondPaymentCardFee).toFixed(2);
    let thirdPaymentCost = eval(this.state.thirdPaymentCost).toFixed(2);
    let thirdPaymentCardFee = eval(this.state.thirdPaymentCardFee).toFixed(2);
    let fourthPaymentCost = eval(this.state.fourthPaymentCost).toFixed(2);
    let fourthPaymentCardFee = eval(this.state.fourthPaymentCardFee).toFixed(2);

    this.setState({
      firstPaymentCost: firstPaymentCost,
      firstPaymentCardFee: firstPaymentCardFee,
      secondPaymentCost: secondPaymentCost,
      secondPaymentCardFee: secondPaymentCardFee,
      thirdPaymentCost: thirdPaymentCost,
      thirdPaymentCardFee: thirdPaymentCardFee,
      fourthPaymentCost: fourthPaymentCost,
      fourthPaymentCardFee: fourthPaymentCardFee,
    });
  };

  onOpened() {
    let element = document.getElementById("tuitionCost");
    element.focus();
  }

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  validateForm() {
    return (
      this.state.downPaymentType >= 0 &&
      (this.state.balanceMonths === -1 ||
        (this.state.balanceMonths === 1 &&
          this.state.firstPaymentDate !== null) ||
        (this.state.balanceMonths === 2 &&
          this.state.firstPaymentDate !== null &&
          this.state.secondPaymentDate !== null) ||
        (this.state.balanceMonths === 3 &&
          this.state.firstPaymentDate !== null &&
          this.state.secondPaymentDate !== null &&
          this.state.thirdPaymentDate !== null) ||
        (this.state.balanceMonths === 4 &&
          this.state.firstPaymentDate !== null &&
          this.state.secondPaymentDate !== null &&
          this.state.thirdPaymentDate !== null &&
          this.state.fourthPaymentDate !== null)) &&
      (this.state.downPaymentType === 4 ||
        (this.state.downPaymentType !== 4 &&
          eval(this.state.downPaymentCost) > 0)) &&
      (eval(this.state.balanceCost) === 0 ||
        (eval(this.state.balanceCost) > 0 && this.state.balanceMonths > 0)) &&
      (this.state.downPaymentType2 === 4 ||
        (this.state.downPaymentType2 !== 4 &&
          eval(this.state.downPaymentCost2) > 0))
    );
  }

  createPdf = () => {
    return new Promise((resolve, reject) => {
      let signPicture = null;
      let initialsPicture = null;

      this.setState({ errorSignature: "" }, () => {
        axios
          .get("users/GetSignaturePicture?token=" + this.state.tokenSignature, {
            responseType: "blob",
          })
          .then((response) => {
            if (response.data.size === 0)
              this.setState(
                {
                  errorSignature: "The token has not yet been signed",
                },
                () => {
                  reject("The token has not yet been signed");
                  return;
                }
              );

            const url = window.URL.createObjectURL(
              new Blob([response.data], {
                type: "image/png",
              })
            );

            signPicture = url;
          })
          .then(() => {
            axios
              .get(
                "users/GetSignaturePicture?token=" +
                  this.state.tokenSignature +
                  "&isInitial=true",
                {
                  responseType: "blob",
                }
              )
              .then((response) => {
                if (response.data.size === 0)
                  this.setState(
                    {
                      errorSignature: "The token has not yet been signed",
                    },
                    () => {
                      reject("The token has not yet been signed");
                      return;
                    }
                  );

                const url = window.URL.createObjectURL(
                  new Blob([response.data], {
                    type: "image/png",
                  })
                );

                initialsPicture = url;
              })
              .then(() => {
                let student = this.props.student;

                let downPaymentType = this.downPaymentTypes.find(
                  (x) => x.value === this.state.downPaymentType
                );

                let downPaymentType2 = this.downPaymentTypes.find(
                  (x) => x.value === this.state.downPaymentType2
                );

                let balanceMonths = this.balancePaymentTypes.find(
                  (x) => x.value === this.state.balanceMonths
                );

                let options = {
                  isSelfEnrollment: false,
                  canSchoolSendMessage: this.state.canSchoolSendMessage,

                  tuitionCost: eval(this.state.tuitionCost),
                  totalCost:
                    eval(this.state.tuitionCost) +
                    eval(this.state.cardFeeCost) +
                    eval(this.state.downPaymentFeeCost) +
                    eval(this.state.downPaymentFeeCost2),
                  balanceCost: eval(this.state.balanceCost),
                  cardFee:
                    eval(this.state.cardFeeCost) +
                    eval(this.state.downPaymentFeeCost) +
                    eval(this.state.downPaymentFeeCost2),

                  firstPaymentCost: eval(this.state.firstPaymentCost),
                  firstPaymentFee: eval(this.state.firstPaymentCardFee),

                  secondPaymentCost: eval(this.state.secondPaymentCost),
                  secondPaymentFee: eval(this.state.secondPaymentCardFee),

                  thirdPaymentCost: eval(this.state.thirdPaymentCost),
                  thirdPaymentFee: eval(this.state.thirdPaymentCardFee),

                  fourthPaymentCost: eval(this.state.fourthPaymentCost),
                  fourthPaymentFee: eval(this.state.fourthPaymentCardFee),

                  downPaymentCost: eval(this.state.downPaymentCost),
                  downPaymentFee: eval(this.state.downPaymentFeeCost),
                  downPaymentType: downPaymentType ? downPaymentType.label : "",

                  downPaymentCost2: eval(this.state.downPaymentCost2),
                  downPaymentFee2: eval(this.state.downPaymentFeeCost2),
                  downPaymentType2: downPaymentType2
                    ? downPaymentType2.label
                    : "",

                  balanceMonths: balanceMonths ? balanceMonths.label : "",
                  firstPaymentDate: this.state.firstPaymentDate,
                  secondPaymentDate: this.state.secondPaymentDate,
                  thirdPaymentDate: this.state.thirdPaymentDate,
                  fourthPaymentDate: this.state.fourthPaymentDate,

                  date: this.state.date,
                  currentSemesterName:
                    this.props.tables.selfEnrollmentSemester.name,
                  currentUser: this.state.currentUser,
                };

                let visaType = this.props.visaTypes.find(
                  (x) => x.value === student.VisaTypeId.Value
                );

                let studentObj = {
                  id: student.Id.Value,
                  name: student.Name.Value,
                  dateOfBirth: student.BirthDate.Value + "T00:00:00",
                  i20: student.I20.Value,
                  street: student.address.Street.Value,
                  city: student.address.City.Value,
                  state: student.address.State.Value,
                  zip: student.address.Zip.Value,
                  email: student.address.Email.Value,
                  cellPhone: student.address.CellPhone.Value,
                  visaType: visaType ? visaType.label : "",
                  signatureImg: signPicture,
                  initialsImg: initialsPicture,
                  signatureToken: this.state.tokenSignature,
                };

                FinancialContractPdf(studentObj, options);
              });
          })
          .then(() => resolve(true));
      });
    });
  };

  downPaymentTypes = [
    { value: 1, label: "Cash" },
    { value: 2, label: "Check" },
    { value: 3, label: "Credit Card" },
    { value: 4, label: "N/A" },
  ];

  balancePaymentTypes = [
    { value: -1, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
  ];
}

export default ModalFinancialContract;
