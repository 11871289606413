/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Table,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  CustomInput,
  DropdownMenu,
  DropdownItem,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import * as dateFns from "date-fns";
import confirm from "reactstrap-confirm";
import GradeInput from "./DashboardTeacher.gradeInput";
import DropdownItemDashboard from "./DashboardTeacher.DropdownItem";
import Countries from "Countries.json";
import profilePicture from "assets/img/default-avatar.png";
import Can from "components/Can/Can";

class Dashboard2Teacher extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();
  }

  getModalState = () => {
    return {
      students: [],
      attendances: [],
      attendanceStudents: [],
      assignments: [],
      assignmentStudents: [],
      finished: false,
    };
  };

  render() {
    let days = [];

    let startDate = dateFns.parseISO(this.props.classe.semesterStartDate);
    let endDate = dateFns.parseISO(this.props.classe.semesterEndDate);

    //Voltar com isso no inicio do semestre
    // if (endDate > new Date()) endDate = dateFns.addDays(new Date(), 1);

    while (endDate >= startDate) {
      let dateForTooltip = dateFns.format(endDate, "MM/dd/yyyy");

      let attendanceResult = this.getAttendance(endDate);
      let assignmentsResult = this.getAssignment(endDate);
      let students = this.state.students;

      let attendanceStudentsResult = [];
      if (attendanceResult !== undefined) {
        attendanceStudentsResult = this.state.attendanceStudents.filter(
          (x) => x.attendanceId === attendanceResult.id
        );
      }

      if (
        attendanceResult !== undefined ||
        (assignmentsResult !== undefined && assignmentsResult.length > 0)
      ) {
        days.push(
          <React.Fragment>
            <div className="dashboardSeparator"></div>
            {attendanceResult !== undefined && (
              <React.Fragment>
                <tr>
                  <td
                    key={dateForTooltip}
                    style={{ whiteSpace: "nowrap", verticalAlign: "middle" }}
                  >
                    <Label style={{ maxWidth: "100px", display: "inline" }}>
                      {dateFns.format(endDate, "MM/dd")}
                    </Label>
                  </td>

                  <td
                    style={{
                      whiteSpace: "nowrap",
                      verticalAlign: "middle",
                      backgroundColor: "#f3f3f3",
                    }}
                  >
                    <a
                      title="Click to fill all attendances with Present"
                      href="#"
                      onClick={this.handleAllAttendanceChange.bind(
                        this,
                        attendanceResult.id
                      )}
                      style={{
                        display: "block",
                        textAlign: "center",
                        verticalAlign: "middle",
                        color: "black",
                      }}
                    >
                      Attendance
                    </a>
                  </td>

                  {attendanceResult !== undefined &&
                    attendanceStudentsResult !== undefined &&
                    students.map((student, index) => {
                      let attendanceStudent = attendanceStudentsResult.find(
                        (x) => x.studentId === student.id
                      );

                      if (attendanceStudent !== undefined) {
                        return (
                          <td
                            id={"id_" + attendanceStudent.id}
                            key={index}
                            style={{
                              whiteSpace: "nowrap",
                              padding: "0",
                              verticalAlign: "middle",
                              textAlign: "center",
                              backgroundColor: "#f3f3f3",
                              minWidth: "71px",
                              opacity:
                                student.blocked ||
                                attendanceStudent.locked === true
                                  ? "0.3"
                                  : "",
                            }}
                          >
                            {attendanceStudent.status[0] === "E" && (
                              <UncontrolledTooltip
                                placement="right"
                                target={"id_" + attendanceStudent.id}
                              >
                                Excused marks cannot be changed.
                              </UncontrolledTooltip>
                            )}

                            <ButtonDropdown
                              style={{
                                width: "70px",
                              }}
                              isOpen={this.isButtonDropdownOpen(
                                attendanceStudent.id
                              )}
                              toggle={() =>
                                attendanceStudent.status[0] !== "E" &&
                                student.blocked === false &&
                                attendanceStudent.locked !== true &&
                                this.toggleButtonDropdown(attendanceStudent.id)
                              }
                            >
                              <DropdownToggle
                                caret={
                                  attendanceStudent.status[0] !== "E" &&
                                  student.blocked === false &&
                                  attendanceStudent.locked !== true
                                }
                                color={`${this.renderSwitch(
                                  attendanceStudent.status
                                )}`}
                              >
                                {attendanceStudent.status[0]}
                              </DropdownToggle>
                              <DropdownMenu
                                style={{ backgroundColor: "#e2e2e2" }}
                              >
                                <DropdownItemDashboard
                                  onClick={this.handleButtonAttendanceChange.bind(
                                    this,
                                    attendanceStudent.id,
                                    "P"
                                  )}
                                  status="Present"
                                ></DropdownItemDashboard>

                                <DropdownItem divider />

                                <DropdownItemDashboard
                                  onClick={this.handleButtonAttendanceChange.bind(
                                    this,
                                    attendanceStudent.id,
                                    "T"
                                  )}
                                  status="Tardy"
                                ></DropdownItemDashboard>

                                <DropdownItem divider />

                                <DropdownItemDashboard
                                  onClick={this.handleButtonAttendanceChange.bind(
                                    this,
                                    attendanceStudent.id,
                                    "A"
                                  )}
                                  status="Absent"
                                ></DropdownItemDashboard>

                                <DropdownItem divider />
                                <DropdownItem divider />
                                <Input
                                  style={{
                                    padding: "5px",
                                  }}
                                  type="textarea"
                                  defaultValue={attendanceStudent.comment}
                                  onBlur={this.handleCommentInputChange.bind(
                                    this,
                                    attendanceStudent.id
                                  )}
                                  placeholder={"Insert any comment here..."}
                                  maxLength={100}
                                ></Input>
                              </DropdownMenu>
                            </ButtonDropdown>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            key={index}
                            style={{
                              whiteSpace: "nowrap",
                              padding: "0",
                              verticalAlign: "middle",
                              textAlign: "center",
                              backgroundColor: "#f3f3f3",
                            }}
                          ></td>
                        );
                      }
                    })}
                </tr>
              </React.Fragment>
            )}

            {assignmentsResult !== undefined &&
              assignmentsResult.map((assignment, index) => {
                return (
                  <tr key={index}>
                    {attendanceResult === undefined && (
                      <td
                        key={dateForTooltip}
                        style={{
                          whiteSpace: "nowrap",
                          verticalAlign: "middle",
                        }}
                      >
                        <Label style={{ maxWidth: "100px", display: "inline" }}>
                          {dateFns.format(endDate, "MM/dd")}
                        </Label>
                      </td>
                    )}

                    {attendanceResult !== undefined && <td></td>}

                    <td
                      style={{
                        whiteSpace: "nowrap",
                        verticalAlign: "middle",
                        backgroundColor: "#f3f3f3",
                      }}
                    >
                      <a
                        title="Click to fill all grades with the maximum grade"
                        href="#"
                        style={{
                          display: "block",
                          textAlign: "center",
                          verticalAlign: "middle",
                          color: "black",
                        }}
                        onClick={this.handleAllAssignmentChange.bind(
                          this,
                          assignment.id,
                          assignment.maxGrade
                        )}
                      >
                        {assignment.name + " (" + assignment.maxGrade + ")"}
                      </a>
                    </td>

                    {this.state.assignmentStudents.filter(
                      (x) => x.assignmentId === assignment.id
                    ).length > 0 &&
                      students.map((student, index) => {
                        let assignmentStudent = this.state.assignmentStudents
                          .filter((x) => x.assignmentId === assignment.id)
                          .find((x) => x.studentId === student.id);

                        if (assignmentStudent !== undefined) {
                          return (
                            <td
                              key={index}
                              style={{
                                padding: "1px",
                                width: "50px",
                                verticalAlign: "middle",
                                textAlign: "-webkit-center",
                                backgroundColor: "#f3f3f3",
                                opacity:
                                  student.blocked ||
                                  assignmentStudent.locked === true
                                    ? "0.3"
                                    : "",
                              }}
                            >
                              <GradeInput
                                grade={assignmentStudent.grade}
                                verified={assignmentStudent.verified}
                                modified={assignmentStudent.modified}
                                onFocus={this.handleFocus}
                                onChange={this.handleGradeEdit.bind(
                                  this,
                                  assignmentStudent.id
                                )}
                                onBlur={this.gradeOnBlur.bind(
                                  this,
                                  assignmentStudent
                                )}
                                disabled={
                                  student.blocked ||
                                  assignmentStudent.locked === true
                                }
                              ></GradeInput>
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={index}
                              style={{
                                padding: "1px",
                                width: "50px",
                                verticalAlign: "middle",
                                textAlign: "-webkit-center",
                                backgroundColor: "#f3f3f3",
                              }}
                            ></td>
                          );
                        }
                      })}
                  </tr>
                );
              })}
          </React.Fragment>
        );
      }
      endDate = dateFns.addDays(endDate, -1);
    }

    let students = this.state.students.map((student, index) => {
      let country = Countries.find(
        (x) => x.value.toLowerCase() === student.nationalityId.toLowerCase()
      );

      let name = this.getName(student.name);

      let studentGrade = this.state.assignmentStudents
        .filter(
          (x) =>
            x.studentId === student.id &&
            (x.verified === true || x.modified === true) &&
            x.grade
        )
        .reduce((prev, next) => prev + parseFloat(next.grade), 0);

      let finalGrade =
        this.props.classe.totalPoints > 0
          ? Math.min(
              (studentGrade / this.props.classe.totalPoints) * 100,
              100
            ).toFixed(2)
          : (0).toFixed(2);

      return (
        <th key={index} className="dashboardHeader">
          {student.pictureUrl && (
            <div className="dashboardDiv" title={country && country.label}>
              <img
                alt="..."
                className="dashboardImg avatar2 border-gray "
                src={student.pictureUrl}
              />
              <div
                id={"id_" + student.id}
                title={country && country.label}
              ></div>
            </div>
          )}

          <Label className="dashboardLabel">
            {name.firstName}
            <br />
            {name.lastName}
          </Label>

          <CustomInput
            className="dashboardCheckBoxOrientation"
            type="checkbox"
            id={"orientation_" + student.id}
            checked={student.orientation}
            onChange={this.toggleCheckboxBooksOrientation.bind(
              this,
              student.id,
              "orientation"
            )}
          />

          <CustomInput
            className="dashboardCheckBoxBooks"
            type="checkbox"
            id={"books_" + student.id}
            checked={student.books}
            onChange={this.toggleCheckboxBooksOrientation.bind(
              this,
              student.id,
              "books"
            )}
          />

          <h5 style={{ fontSize: "1.4em", marginBottom: "0.5rem" }}>
            <Badge
              color={
                finalGrade < 70
                  ? "danger"
                  : finalGrade < 90
                  ? "warning"
                  : "success"
              }
            >
              {finalGrade + "%"}
            </Badge>
          </h5>
        </th>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Modal
              id={"modalDashboard"}
              isOpen={this.props.isOpen}
              centered={true}
              autoFocus={true}
              size={"lg"}
              backdrop="static"
              onClosed={this.onCloseModal}
              onOpened={this.onOpened.bind(this)}
              style={{
                maxWidth: "1600px",
                width: "80%",
              }}
              scrollable={true}
            >
              <ModalHeader>{this.props.classe.code}</ModalHeader>

              <ModalBody
                id="modalBodyDashboard"
                style={{
                  backgroundColor: "white",
                  paddingTop: "0",
                  paddingBottom: "0",
                  padding: "0",
                }}
              >
                <>
                  <div
                    className="container "
                    style={{ minHeight: days.length === 1 ? "400px" : "0px" }}
                  >
                    <Table borderless className="teacherDashboardTable">
                      <thead>
                        <tr align="center">
                          <th style={{ width: "20px" }}></th>
                          <th
                            style={{
                              verticalAlign: "bottom",
                              textAlign: "center",
                              width: "20px",
                              paddingLeft: "0",
                              paddingRight: "0",
                              paddingBottom: "0",
                            }}
                          >
                            {students.length > 0 && students && (
                              <React.Fragment>
                                <Label
                                  style={{
                                    marginBottom: "0.6rem",
                                    backgroundColor: "#f3f3f3",
                                    width: "100%",
                                    fontWeight: "normal",
                                    textTransform: "none",
                                    color: "black",
                                    display: "inline-block",
                                  }}
                                >
                                  Orientation
                                </Label>

                                <Label
                                  style={{
                                    marginBottom: "0.6rem",
                                    backgroundColor: "#f3f3f3",
                                    width: "100%",
                                    fontWeight: "normal",
                                    textTransform: "none",
                                    color: "black",
                                    display: "inline-block",
                                  }}
                                >
                                  Books
                                </Label>

                                <Label
                                  style={{
                                    marginBottom: "0.6rem",
                                    backgroundColor: "#f3f3f3",
                                    width: "100%",
                                    fontWeight: "normal",
                                    textTransform: "none",
                                    color: "black",
                                    display: "inline-block",
                                  }}
                                >
                                  Grade
                                </Label>
                              </React.Fragment>
                            )}
                          </th>
                          {(students.length > 0 && students) ||
                            (this.state.finished && (
                              <h2 style={{ marginTop: "20px" }}>
                                No students found
                              </h2>
                            ))}
                        </tr>
                      </thead>
                      <tbody>{this.state.finished && days}</tbody>
                    </Table>
                  </div>

                  <br />
                </>
              </ModalBody>
              <ModalFooter>
                <Can
                  feature="Subject_Dashboard_Export_Csv"
                  yes={() => (
                    <Button
                      color="success"
                      onClick={this.downloadCsv.bind(this)}
                      style={{ marginLeft: "10px", marginRight: "auto" }}
                    >
                      Export to CSV
                    </Button>
                  )}
                ></Can>

                <Button color="primary" onClick={this.saveDashboard.bind(this)}>
                  Save
                </Button>
                <Button
                  color="secondary"
                  onClick={this.renderCancel.bind(this)}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </Row>
        </div>
      </>
    );
  }

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  onOpened() {
    this.openModalDashboardTeacher();
  }

  openModalDashboardTeacher() {
    this.getDashboard().then(() => {
      this.setState({ finished: true });
    });
  }

  handleGradeEdit = (id, e) => {
    const value = e.target.value;

    if (/^[0-9.]+$/.test(value) || value === "") {
      this.setState({
        assignmentStudents: this.state.assignmentStudents.map((el) =>
          el.id === id ? { ...el, grade: value, modified: true } : el
        ),
      });
    }
  };

  getDashboard() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "dashboard/getTeacherDashboard?classId=" +
            this.props.classe.id +
            "&page=1" +
            "&pageSize=1000"
        )
        .then((response) => {
          let modalState = this.state;

          modalState.students = [];
          response.data.data.studentList.map((student, index) => {
            modalState.students.push({
              id: student.id,
              name: student.name,
              nationalityId: student.nationalityId.toLowerCase(),
              pictureUrl: student.pictureUrl || profilePicture,
              blocked: student.blocked,
              modified: false,
              orientation: student.orientation,
              books: student.books,
            });
          });

          let finalAttendanceStudents = [];

          response.data.data.attendanceList.map((attendance, index) => {
            attendance.attendanceStudentViewModels.map(
              (attendanceStudentViewModel, index) => {
                let blocked =
                  modalState.students.find(
                    (x) => x.id === attendanceStudentViewModel.student.id
                  ) &&
                  modalState.students.find(
                    (x) => x.id === attendanceStudentViewModel.student.id
                  ).blocked === true;

                finalAttendanceStudents.push({
                  id: attendanceStudentViewModel.id,
                  studentId: attendanceStudentViewModel.student.id,
                  status:
                    blocked === true &&
                    this.isToday(dateFns.parseISO(attendance.date))
                      ? "A"
                      : attendanceStudentViewModel.status,
                  comment: attendanceStudentViewModel.comment,
                  isButtonDropdownOpen: false,
                  attendanceId: attendance.id,
                  modified:
                    blocked === true &&
                    this.isToday(dateFns.parseISO(attendance.date))
                      ? true
                      : false,
                  blocked: blocked,
                  locked: attendanceStudentViewModel.locked,
                });
              }
            );

            modalState.attendances.push({
              id: attendance.id,
              date: attendance.date,
            });
          });

          modalState.attendanceStudents = finalAttendanceStudents;

          let finalAssignmentStudents = [];

          response.data.data.assignmentList.map((assignment, index) => {
            assignment.assignmentStudentViewModels.map(
              (assignmentStudentViewModel, index) => {
                let blocked =
                  modalState.students.find(
                    (x) => x.id === assignmentStudentViewModel.student.id
                  ) &&
                  modalState.students.find(
                    (x) => x.id === assignmentStudentViewModel.student.id
                  ).blocked;

                finalAssignmentStudents.push({
                  id: assignmentStudentViewModel.id,
                  studentId: assignmentStudentViewModel.student.id,
                  grade:
                    blocked === true &&
                    this.isToday(dateFns.parseISO(assignment.date))
                      ? 0
                      : assignmentStudentViewModel.grade,
                  maxGrade: assignment.maxGrade,
                  assignmentId: assignment.id,
                  modified:
                    blocked === true &&
                    this.isToday(dateFns.parseISO(assignment.date))
                      ? true
                      : false,
                  verified: assignmentStudentViewModel.verified,
                  blocked: blocked,
                  locked: assignmentStudentViewModel.locked,
                });
              }
            );

            modalState.assignments.push({
              id: assignment.id,
              date: assignment.date,
              maxGrade: assignment.maxGrade,
              name: assignment.name,
            });
          });

          modalState.assignmentStudents = finalAssignmentStudents;

          this.setState(modalState, resolve(true));
        });
    });
  }

  isToday = (someDate) => {
    var today = new Date();
    today.setHours(0, 0, 0, 0);

    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  };

  getName(name) {
    name = name.trim();

    let test = name.replace(new RegExp(" ", "g"), " ").split(" ");

    let firstName = test[0];
    let lastName = test[test.length - 1];

    return { firstName: firstName, lastName: lastName };
  }

  getAttendance(date) {
    let formatedDate = dateFns.format(date, "yyyy-MM-dd") + "T00:00:00";

    let attendance = this.state.attendances.filter(
      (x) => x.date === formatedDate
    )[0];

    return attendance;
  }

  getAssignment(date) {
    let formatedDate = dateFns.format(date, "yyyy-MM-dd") + "T00:00:00";

    let assignments = this.state.assignments.filter(
      (x) => x.date === formatedDate
    );

    return assignments;
  }

  onCloseModal = () => {
    this.resetModal();
  };

  isButtonDropdownOpen = (id) => {
    let prevAttendanceStudent = this.state.attendanceStudents.find(
      (x) => x.id === id
    );

    return prevAttendanceStudent.isButtonDropdownOpen;
  };

  toggleButtonDropdown = (id) => {
    this.setState({
      attendanceStudents: this.state.attendanceStudents.map(
        (attendanceStudent) =>
          attendanceStudent.id === id
            ? Object.assign({}, attendanceStudent, {
                isButtonDropdownOpen: !attendanceStudent.isButtonDropdownOpen,
              })
            : attendanceStudent
      ),
    });
  };

  handleButtonAttendanceChange = (id, status, e) => {
    e.preventDefault();

    let attendanceStudents = this.state.attendanceStudents.map((el) =>
      el.id === id
        ? { ...el, status: status, modified: true, isButtonDropdownOpen: false }
        : el
    );

    Promise.all(attendanceStudents).then(() => {
      this.setState({
        attendanceStudents,
      });
    });
  };

  handleAllAttendanceChange = (attendanceId, e) => {
    e.preventDefault();

    this.setState({
      attendanceStudents: this.state.attendanceStudents.map((el) =>
        el.attendanceId === attendanceId &&
        el.modified === false &&
        el.status !== "E" &&
        el.blocked === false &&
        el.locked !== true
          ? { ...el, status: "P", modified: true }
          : el
      ),
    });
  };

  handleAllAssignmentChange = (assignmentId, maxGrade, e) => {
    e.preventDefault();

    this.setState({
      assignmentStudents: this.state.assignmentStudents.map((el) =>
        el.assignmentId === assignmentId &&
        el.modified === false &&
        el.verified === false &&
        el.blocked === false &&
        el.locked !== true
          ? { ...el, grade: maxGrade, modified: true }
          : el
      ),
    });
  };

  handleCommentInputChange = (id, e) => {
    const value = e.target.value;

    this.setState({
      attendanceStudents: this.state.attendanceStudents.map((el) =>
        el.id === id ? { ...el, comment: value, modified: true } : el
      ),
    });
  };

  handleFocus = (event) => {
    if (event.target.value === "0" || event.target.value === 0)
      event.target.value = "";

    event.target.select();
  };

  gradeOnBlur = async (assignmentStudent, e) => {
    const value = e.target.value;

    if (value === "") {
      e.target.value = "0";
      this.setState({
        assignmentStudents: this.state.assignmentStudents.map((el) =>
          el.id === assignmentStudent.id
            ? { ...el, grade: "0", modified: true }
            : el
        ),
      });
    } else {
      let prevAssignment = this.state.assignments.find(
        (x) => x.id === assignmentStudent.assignmentId
      );

      if (prevAssignment !== undefined) {
        if (value > prevAssignment.maxGrade) {
          await confirm({
            title: <>Warning</>,
            message:
              "The grade ( " +
              value +
              " ) is greater than the maximum grade ( " +
              prevAssignment.maxGrade +
              " )",
            cancelText: null,
          });

          this.setState({
            assignmentStudents: this.state.assignmentStudents.map((el) =>
              el.id === assignmentStudent.id
                ? { ...el, grade: prevAssignment.maxGrade, modified: true }
                : el
            ),
          });
        }
      }
    }
  };

  renderSwitch(param) {
    switch (param) {
      case "P":
        return "success";
      case "T":
        return "warning";
      case "A":
        return "danger";
      case "NT":
        return "light-gray";
      case "E":
        return "info";
      case "L":
        return "info";
      default:
        return "light-gray";
    }
  }

  async renderCancel() {
    let hasModified =
      this.state.attendanceStudents.filter((x) => x.modified === true).length >
        0 ||
      this.state.assignmentStudents.filter((x) => x.modified === true).length >
        0;

    let result =
      !hasModified ||
      (await confirm({
        title: <>Warning</>,
        message: "You have unsaved changes. Exit without saving?",
      }));

    if (result) {
      this.props.toggleStudentModal();
    }
  }

  saveDashboard() {
    let attendanceStudentList = [];
    this.state.attendanceStudents
      .filter((x) => x.modified === true)
      .map((attendanceStudent, index) => {
        attendanceStudentList.push({
          Id: attendanceStudent.id,
          Status: attendanceStudent.status,
          Comment: attendanceStudent.comment,
        });
      });

    let assignmentStudentList = [];
    this.state.assignmentStudents
      .filter((x) => x.modified === true)
      .map((assignmentStudent, index) => {
        assignmentStudentList.push({
          Id: assignmentStudent.id,
          Grade: assignmentStudent.grade,
        });
      });

    let studentList = [];
    this.state.students
      .filter((x) => x.modified === true)
      .map((student, index) => {
        studentList.push({
          Id: student.id,
          Orientation: student.orientation,
          Books: student.books,
        });
      });

    let classeList = [];
    classeList.push({
      Id: this.props.classe.id,
    });

    let tables = {
      AttendanceStudentList: attendanceStudentList,
      AssignmentStudentList: assignmentStudentList,
      studentList: studentList,
      ClasseList: classeList,
    };

    axios.post("dashboard/teacherDashboard", tables).then((response) => {
      this.props.toggleStudentModal().then(() => {
        this.resetModal().then(() => {
          if (
            this.props.callback &&
            typeof this.props.callback === "function"
          ) {
            this.props.callback();
          }
        });
      });
    });
  }

  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

  toggle = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }
  };

  downloadCsv() {
    let studentsState = [...this.state.students];
    let attendancesState = [...this.state.attendances];
    let assignmentsState = [...this.state.assignments];
    let attendanceStudentsState = [...this.state.attendanceStudents];
    let assignmentStudentsState = [...this.state.assignmentStudents];

    let items = [];

    items.push({ date: "", assignment: "" });

    studentsState.forEach((student, index) => {
      let fullNameObj = this.getName(student.name);
      items[0]["col" + index] =
        fullNameObj.firstName + " " + fullNameObj.lastName;
    });

    items.push({ date: "", assignment: "Grade" });

    studentsState.forEach((student, index) => {
      let studentGrade = assignmentStudentsState
        .filter(
          (x) =>
            x.studentId === student.id &&
            (x.verified === true || x.modified === true) &&
            x.grade
        )
        .reduce((prev, next) => prev + parseFloat(next.grade), 0);

      let finalGrade =
        this.props.classe.totalPoints > 0
          ? Math.min(
              (studentGrade / this.props.classe.totalPoints) * 100,
              100
            ).toFixed(2)
          : (0).toFixed(2);

      items[1]["col" + index] = finalGrade;
    });

    assignmentsState.forEach((assignment, index) => {
      items.push({
        date: dateFns.format(dateFns.parseISO(assignment.date), "MM/dd/yyyy"),
        assignment: assignment.name,
      });

      studentsState.forEach((student, index) => {
        let assignmentStudent = assignmentStudentsState.filter(
          (x) => x.assignmentId === assignment.id && x.studentId === student.id
        );
        items[items.length - 1]["col" + index] =
          assignmentStudent && assignmentStudent.length > 0
            ? assignmentStudent[0].grade
            : "";
      });
    });

    attendancesState.forEach((attendance, index) => {
      items.push({
        date: dateFns.format(dateFns.parseISO(attendance.date), "MM/dd/yyyy"),
        assignment: "Attendance",
      });

      studentsState.forEach((student, index) => {
        let attendanceStudent = attendanceStudentsState.filter(
          (x) => x.attendanceId === attendance.id && x.studentId === student.id
        );
        items[items.length - 1]["col" + index] =
          attendanceStudent && attendanceStudent.length > 0
            ? attendanceStudent[0].status
            : "";
      });
    });

    items.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });

    this.downloadCSVFromJson(this.props.classe.code + ".csv", items);
  }

  downloadCSVFromJson = (filename, arrayOfJson) => {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  toggleCheckboxBooksOrientation = (studentId, type, e) => {
    if (type === "orientation") {
      this.setState({
        students: this.state.students.map((el) =>
          el.id === studentId
            ? { ...el, orientation: e.target.checked, modified: true }
            : el
        ),
      });
    } else if (type === "books") {
      this.setState({
        students: this.state.students.map((el) =>
          el.id === studentId
            ? { ...el, books: e.target.checked, modified: true }
            : el
        ),
      });
    }
  };
}

export default Dashboard2Teacher;
