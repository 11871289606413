/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import { editMode, FormatFullDateList } from "helpers/Utils.js";
import MessageEdit from "components/Message/Message.component.edit.jsx";
import MultiSelectMessage from "../components/Message/MultiSelectMessage.component";

class Message extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();

    this.timeout = 0;
  }

  render() {
    let messages = this.state.messages.map((message) => {
      return (
        <tr key={message.id}>
          {/* <td>{message.id}</td> */}

          <td>
            <a
              onClick={this.openModalMessage.bind(
                this,
                editMode.EDIT,
                message.id
              )}
              href="#"
            >
              {message.createdDate}
            </a>
          </td>

          <td>{message.title}</td>
          <td>{message.campusName}</td>
          <td>{message.courseName}</td>
          <td>{message.period}</td>
          <td>{message.className}</td>
          <td>{message.personName}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup>
                      <Label>&nbsp;</Label>
                      <Button
                        style={{ margin: "0" }}
                        color="primary"
                        onClick={this.openMultiModalMessage.bind(
                          this,
                          editMode.INSERT,
                          undefined
                        )}
                      >
                        Student Message
                      </Button>
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>&nbsp;</Label>
                      <Button
                        style={{ margin: "0" }}
                        color="primary"
                        onClick={this.openModalMessage.bind(
                          this,
                          editMode.INSERT,
                          undefined
                        )}
                      >
                        Subject Message
                      </Button>
                    </FormGroup>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Title</th>
                        <th>Campus</th>
                        <th>Level</th>
                        <th>Period</th>
                        <th>Subject</th>
                        <th>Recipient</th>
                      </tr>
                    </thead>

                    <tbody>{messages}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.currentPage}
                    totalItemsCount={this.state.totalResults}
                    onChange={this._refreshMessages.bind(this)}
                  />

                  <MessageEdit
                    isOpen={this.state.openModal}
                    toggleMessageModal={this.toggleMessageModal.bind(this)}
                    editMode={this.state.editMode}
                    id={this.state.id}
                    callback={this._refreshMessages.bind(this)}
                    tables={this.props.tables}
                  />

                  <MultiSelectMessage
                    isOpen={this.state.openMultiModal}
                    toggleMessageModal={this.toggleMessageMultiModal.bind(this)}
                    editMode={this.state.editMode}
                    id={this.state.id}
                    callback={this._refreshMessages.bind(this)}
                    tables={this.props.tables}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  toggleMessageModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openModal = !mainState.openModal;
      this.setState(mainState, resolve(true));
    });
  };

  toggleMessageMultiModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openMultiModal = !mainState.openMultiModal;
      this.setState(mainState, resolve(true));
    });
  };

  openModalMessage = (mode, id) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.editMode = mode;
      mainState.id = id;
      mainState.openModal = !mainState.openModal;

      this.setState(mainState, resolve(true));
    });
  };

  openMultiModalMessage = (mode, id) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.editMode = mode;
      mainState.id = id;
      mainState.openMultiModal = !mainState.openMultiModal;

      this.setState(mainState, resolve(true));
    });
  };

  getMainState = () => {
    return {
      messages: [],
      id: "",
      openModal: false,
      openMultiModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      alert: { visible: false, color: "", message: "" },
      editMode: "",
      searchInput: "",
      filterCampusId: "",
      filterCourseId: "",
      filterClassId: "",
      filterPeriod: "",
    };
  };

  componentDidMount() {
    this._refreshMessages();
  }

  _refreshMessages = (pageNumber) => {
    return new Promise((resolve, reject) => {
      let page = pageNumber || 1;
      let itemsPerPage = this.state.itemsPerPage;

      let campusId = this.state.filterCampusId;
      let courseId = this.state.filterCourseId;
      let classId = this.state.filterClassId;
      let period = this.state.filterPeriod;

      axios
        .get(
          "messages?" +
            "campusId=" +
            campusId +
            "&courseId=" +
            courseId +
            "&classId=" +
            classId +
            "&period=" +
            period +
            "&page=" +
            page +
            "&pageSize=" +
            itemsPerPage
        )
        .then((response) => {
          let mainState = this.state;

          mainState.messages = [];
          response.data.data.items.map((message, index) => {
            mainState.messages.push({
              id: message.id,
              sender: message.user.name,
              title: message.title,
              description: message.description,
              campusName:
                message.campus !== undefined ? message.campus.name : "",
              courseName:
                message.course !== undefined ? message.course.name : "",
              period: message.period,
              className:
                message.classe !== undefined ? message.classe.code : "",
              createdDate: FormatFullDateList(message.createdDate),
              personName:
                message.countPerson === 1
                  ? message.studentList[0].name
                  : message.countPerson + " recipients",
            });
          });

          mainState.totalResults = response.data.data.totalCount;
          mainState.currentPage = pageNumber;

          this.setState(mainState, resolve(true));
        });
    });
  };

  setCurrentPage(pageNumber) {
    let mainState = this.state;
    mainState.currentPage = pageNumber;
    this.setState(mainState);
  }
}

export default Message;
