/* eslint-disable array-callback-return */
import React from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { editMode } from "helpers/Utils.js";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

class MultiSelectMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();
  }

  render() {
    let isDisabled =
      this.props.editMode === editMode.EDIT && this.props.id !== undefined;

    const { selectedOptions, openMenu } = this.state;

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggleMessageModal.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                onClosed={this.onCloseModal}
                onOpened={this.onOpened.bind(this)}
                scrollable
              >
                <ModalHeader toggle={this.props.toggleMessageModal.bind(this)}>
                  {this.props.editMode === editMode.INSERT
                    ? "New message"
                    : "View message"}

                  {this.props.StudentName && " for " + this.props.StudentName}
                </ModalHeader>
                <ModalBody>
                  {this.props.StudentId === undefined && (
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="messageCampus">Students</Label>

                          <Select
                            id="selectIds"
                            options={this.getStudents()}
                            isDisabled={isDisabled}
                            isMulti
                            components={animatedComponents}
                            onChange={this.handleChange}
                            value={selectedOptions}
                            onInputChange={this.handleInputChange}
                            onBlur={this.hideMenu}
                            menuIsOpen={openMenu}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="messagePeriod">Title</Label>
                        <Input
                          className={`form-group`}
                          type="text"
                          name="Title"
                          id="Title"
                          autoComplete="nope"
                          value={this.state.Message.Title}
                          onChange={this.handleUserInput}
                          placeholder="Type the title here..."
                          disabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="messagePeriod">Message</Label>
                        <Input
                          className={`form-group`}
                          type="textarea"
                          name="Description"
                          id="Description"
                          autoComplete="nope"
                          value={this.state.Message.Description}
                          onChange={this.handleUserInput}
                          placeholder="Type the message here..."
                          disabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  {this.props.editMode === editMode.INSERT && (
                    <Button
                      color="primary"
                      onClick={this.addMessage.bind(this)}
                      disabled={!this.validateForm()}
                    >
                      Send
                    </Button>
                  )}{" "}
                  <Button
                    color="secondary"
                    onClick={this.props.toggleMessageModal.bind(this)}
                  >
                    {this.props.editMode === editMode.INSERT
                      ? "Cancel"
                      : "Close"}
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  handleInputChange = (query, { action }) => {
    if (action === "input-change") {
      this.setState({ openMenu: true });
    }
  };

  hideMenu = () => {
    this.setState({ openMenu: false });
  };

  getMessage = () => {
    const message = {
      Id: "",
      Title: "",
      Description: "",
    };

    return message;
  };

  getModalState = () => {
    return {
      Message: this.getMessage(),
      students: [],
      selectedOptions: [],
      openMenu: false,
    };
  };

  handleChange = (selectedOptions) => {
    this.setState({ selectedOptions });
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  validateForm() {
    return (
      this.state.selectedOptions &&
      this.state.selectedOptions.length > 0 &&
      this.state.Message.Title.length > 0 &&
      this.state.Message.Description.length > 0
    );
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState.Message[name] = value;

    this.setState(modalState);
  };

  onOpened() {
    this.openModalMessage();
  }

  addMessage() {
    let modalState = this.state;

    let studentList = [];
    this.state.selectedOptions.map((selectedOption, index) => {
      studentList.push({ Id: selectedOption.value });
    });

    let message = {
      Title: modalState.Message.Title,
      Description: modalState.Message.Description,
      StudentList: studentList,
    };

    axios.post("messages", message).then((response) => {
      this.props.toggleMessageModal().then(() => {
        this.resetModal().then(() => {
          if (
            this.props.callback &&
            typeof this.props.callback === "function"
          ) {
            this.props.callback();
          }
        });
      });
    });
  }

  getStudents() {
    return this.state.students.map((data) => ({
      label: data.Name,
      value: data.Id,
    }));
  }

  refreshStudents = () => {
    return new Promise((resolve, reject) => {
      axios.get("students/byname").then((response) => {
        let students = response.data.data.map((student) => ({
          Id: student.Id,
          Name: student.Name,
        }));

        this.setState({
          students: students,
        });
      });
    });
  };

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.courses.map((course, index) =>
        modalState.courses.push({
          value: course.id,
          label: course.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  openModalMessage() {
    let modalState = this.state;

    this.refreshStudents().then(() => {
      if (
        this.props.editMode === editMode.INSERT &&
        this.props.id === undefined
      ) {
        this.setState(modalState, () => {
          document.getElementById("selectIds").focus();
        });
      } else {
        axios.get("messages/byid?id=" + this.props.id).then((response) => {
          modalState.Message.Id = response.data.data.id;
          modalState.Message.Title = response.data.data.title;
          modalState.Message.Description = response.data.data.description;

          this.setState(modalState, () => {
            this.refreshClasses();
          });
          this.validateForm();
        });
      }
    });
  }

  onCloseModal = () => {
    this.resetModal();
  };
}

export default MultiSelectMessage;
