/* eslint-disable no-useless-constructor */
import React from "react";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import Countries from "Countries.json";
import Autosuggest from "react-autosuggest";
import Select from "react-select";

class Address extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countrySuggestions: [],
    };
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  getCountrySuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp("^" + escapedValue, "i");

    return Countries.filter((language) => regex.test(language.label));
  }

  onCountrySuggestionsFetchRequested = ({ value }) => {
    this.setState({ countrySuggestions: this.getCountrySuggestions(value) });
  };

  onCountrySuggestionsClearRequested = () => {
    this.setState({ countrySuggestions: [] });
  };

  getCountrySuggestionValue(suggestion) {
    return suggestion.label;
  }

  renderCountrySuggestion(suggestion) {
    let className = "";
    if (suggestion.value)
      className = "flag flag-" + suggestion.value.toString().toLowerCase();

    return (
      <div>
        <div className={className}></div> <span>{suggestion.label}</span>
      </div>
    );
  }

  onCountryChange = (event, { newValue }) => {
    this.props.OnCountryChange(this, newValue);
  };

  onBlur = (event) => {
    this.props.OnBlur(this);
  };

  render() {
    const { countrySuggestions } = this.state;

    const inputCountryProps = {
      value: this.props.Address.CountryName,
      onChange: this.onCountryChange,
      onBlur: this.onBlur,
    };

    return (
      <>
        <div className="content">
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="address">Address</Label>
                <Input
                  className={`form-group ${this.errorClass(
                    this.props.Address.Street.ErrorMsg
                  )}`}
                  type="text"
                  name="Street"
                  id="addressStreet"
                  autoComplete="nope"
                  value={this.props.Address.Street.Value}
                  onChange={this.props.handleAddressInput}
                />
                <span className="text-danger">
                  {this.props.Address.Street.ErrorMsg}
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={5}>
              <FormGroup>
                <Label for="studentNationality">Country</Label>
                <Autosuggest
                  className={`form-group ${this.errorClass(
                    this.props.Address.CountryId.ErrorMsg
                  )}`}
                  suggestions={countrySuggestions}
                  onSuggestionsFetchRequested={
                    this.onCountrySuggestionsFetchRequested
                  }
                  onSuggestionsClearRequested={
                    this.onCountrySuggestionsClearRequested
                  }
                  getSuggestionValue={this.getCountrySuggestionValue}
                  renderSuggestion={this.renderCountrySuggestion}
                  placeholder="Enter country name"
                  inputProps={inputCountryProps}
                  onSuggestionSelected={this.props.OnCountrySuggestionSelected}
                />
                <span className="text-danger">
                  {this.props.Address.CountryId.ErrorMsg}
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="address">City</Label>
                <Input
                  className={`form-group ${this.errorClass(
                    this.props.Address.City.ErrorMsg
                  )}`}
                  type="text"
                  name="City"
                  id="addressCity"
                  autoComplete="nope"
                  value={this.props.Address.City.Value}
                  onChange={this.props.handleAddressInput}
                />
                <span className="text-danger">
                  {this.props.Address.City.ErrorMsg}
                </span>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="state">State</Label>
                <Select
                  value={
                    this.props.Address.State.Value &&
                    this.props.Address.States.find(
                      (x) => x.value === this.props.Address.State.Value
                    )
                  }
                  onChange={this.props.HandleStateChange}
                  options={this.props.Address.States}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label for="address">ZIP Code</Label>
                <Input
                  className={`form-group ${this.errorClass(
                    this.props.Address.Zip.ErrorMsg
                  )}`}
                  type="text"
                  name="Zip"
                  id="addressZip"
                  autoComplete="nope"
                  value={this.props.Address.Zip.Value}
                  onChange={this.props.handleAddressInput}
                />
                <span className="text-danger">
                  {this.props.Address.Zip.ErrorMsg}
                </span>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="address">Home Phone</Label>
                <Input
                  className={`form-group ${this.errorClass(
                    this.props.Address.HomePhone.ErrorMsg
                  )}`}
                  type="text"
                  name="HomePhone"
                  id="addressHomePhone"
                  autoComplete="nope"
                  value={this.props.Address.HomePhone.Value}
                  onChange={this.props.handleAddressInput}
                />
                <span className="text-danger">
                  {this.props.Address.HomePhone.ErrorMsg}
                </span>
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Label for="address">Cell Phone</Label>
                <Input
                  className={`form-group ${this.errorClass(
                    this.props.Address.CellPhone.ErrorMsg
                  )}`}
                  type="text"
                  name="CellPhone"
                  id="addressCellPhone"
                  autoComplete="nope"
                  value={this.props.Address.CellPhone.Value}
                  onChange={this.props.handleAddressInput}
                />
                <span className="text-danger">
                  {this.props.Address.CellPhone.ErrorMsg}
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={9}>
              <FormGroup>
                <Label for="address">E-mail</Label>
                <Input
                  className={`form-group ${this.errorClass(
                    this.props.Address.Email.ErrorMsg
                  )}`}
                  type="email"
                  name="Email"
                  id="addressEmail"
                  autoComplete="nope"
                  value={this.props.Address.Email.Value}
                  onChange={this.props.handleAddressInput}
                />
                <span className="text-danger">
                  {this.props.Address.Email.ErrorMsg}
                </span>
              </FormGroup>
            </Col>

            {this.props.pinNumber && (
              <Col md={3}>
                <FormGroup>
                  <Label for="address">PIN</Label>
                  <Input
                    className={`form-group`}
                    type="text"
                    name="pinNumber"
                    id="pinNumber"
                    autoComplete="nope"
                    disabled={true}
                    value={this.props.pinNumber}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }
}

export default Address;
