/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  CardHeader,
  FormGroup,
  Label,
} from "reactstrap";
import axios from "axios";
import * as dateFns from "date-fns";
import Pagination from "components/Pagination/Pagination.js";
import DatePicker from "react-datepicker";
import "react-datasheet/lib/react-datasheet.css";

class TestRetakeReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      testRetakes: [],
      StartDateSearch: dateFns.startOfYear(new Date()),
      EndDateSearch: dateFns.endOfWeek(new Date()),
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
    };
  };

  render() {
    let testRetakes = this.state.testRetakes.map((tr, index) => {
      return (
        <tr key={index}>
          <td>{tr.i20}</td>
          <td>{tr.name}</td>
          <td>{tr.classCode}</td>
          <td>{tr.assignmentName}</td>
          <td>{dateFns.format(tr.scheduledDate, "MM/dd/yyyy")}</td>
          <td>{tr.amount.toFixed(2)}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.EndDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "EndDateSearch"
                        )}
                        name="EndDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>I-20</th>
                        <th>Name</th>
                        <th>Subject</th>
                        <th>Assignment</th>
                        <th>Scheduled Date</th>
                        <th>Amount</th>
                      </tr>
                    </thead>

                    <tbody>{testRetakes}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.currentPage}
                    totalItemsCount={this.state.totalResults}
                    onChange={this._refreshTestRetakes.bind(this)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    this._refreshTestRetakes();
  }

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;

    mainState[name] = date;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshTestRetakes();
    });
  };

  _refreshTestRetakes(pageNumber) {
    let page = pageNumber || this.state.currentPage || 1;
    let itemsPerPage = this.state.itemsPerPage;
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

    let getAllParam = {
      page: page,
      pageSize: itemsPerPage,
      startDate: startDate,
      endDate: endDate,
    };

    return new Promise((resolve, reject) => {
      axios.post("testretake/getall?", getAllParam).then((response) => {
        let mainState = this.state;

        mainState.testRetakes = [];
        response.data.data.items.map((tr, index) =>
          mainState.testRetakes.push({
            id: tr.id,
            name: tr.assignmentStudent.student.name,
            i20: tr.assignmentStudent.student.i20,
            scheduledDate: dateFns.parseISO(tr.scheduledDate),
            transactionDate: dateFns.parseISO(tr.transactionDate),
            assignmentName: tr.assignmentStudent.assignment.name,
            classCode: tr.assignmentStudent.assignment.classe.code,
            amount: tr.amount,
          })
        );

        mainState.currentPage = pageNumber;
        mainState.totalResults = response.data.data.totalCount;

        this.setState(mainState, resolve(true));
      });
    });
  }
}

export default TestRetakeReport;
