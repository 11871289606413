/* eslint-disable no-useless-constructor */
/* eslint-disable array-callback-return */
import React from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Label,
} from "reactstrap";
import axios from "axios";

class SignatureTokenModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // token: "",
    };
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                // onClosed={this.onCloseModal}
                // onOpened={this.onOpened.bind(this)}
                size={"lg"}
                backdrop="static"
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  Token for signature
                </ModalHeader>
                <ModalBody>
                  {this.props.tokenSignature.length > 0 && (
                    <Label style={{ textAlign: "center" }}>
                      Please enter the token below, so that the student can
                      sign. After the student confirms, click on the proceed
                      button. The token will expire in 30 minutes.
                    </Label>
                  )}

                  <h1
                    style={{
                      textAlign: "center",
                      marginTop: "15px",
                      marginBottom: "0",
                    }}
                  >
                    {this.props.tokenSignature}
                  </h1>

                  {this.props.errorSignature && (
                    <div
                      className={"loginAlert"}
                      style={{ textAlign: "center", marginTop: "15px" }}
                    >
                      {this.props.errorSignature}
                    </div>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={this.proceedButton.bind(this)}
                  >
                    Proceed
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.props.toggle.bind(this)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  // onOpened() {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get("users/GetSignatureToken?studentId=" + this.props.studentId)
  //       .then((response) => {
  //         this.setState({ token: response.data.data }, resolve(true));
  //       });
  //   });
  // }

  // resetModal = () => {
  //   return new Promise((resolve, reject) => {
  //     this.setState({ token: "" }, resolve(true));
  //   });
  // };

  // onCloseModal = () => {
  //   this.resetModal();
  // };

  // validateForm() {
  //   return this.props.note.length > 0;
  // }

  // setIsAuthenticated = (token) => {
  //   return new Promise((resolve, reject) => {
  //     authService.setAuth(token);
  //     resolve(true);
  //   });
  // };

  // handleAuthenticationInputChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;

  //   let modalState = this.state;
  //   modalState[name] = value;

  //   this.setState(modalState);
  // };

  proceedButton() {
    if (
      this.props.proceedCallback &&
      typeof this.props.proceedCallback === "function"
    ) {
      this.props.proceedCallback().then(() => this.props.toggle());
    }
  }
}

export default SignatureTokenModal;
