/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  CardFooter,
} from "reactstrap";
import axios from "axios";
import { editMode } from "helpers/Utils.js";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";
import * as dateFns from "date-fns";
import { SavePdf } from "components/Report/GeneratePdf.js";

class BlockedStudents extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      students: [],
      openModal: false,
      studentId: "",
    };
  };

  render() {
    let students = this.state.students.map((student) => {
      return (
        <tr key={student.id}>
          <td>
            <a
              onClick={this.openStudentEditModal.bind(this, student.id)}
              href="#"
            >
              {student.name}
            </a>
          </td>
          <td>{student.i20}</td>
          <td>{student.email}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>I20</th>
                        <th>Email</th>
                      </tr>
                    </thead>

                    <tbody>{students}</tbody>
                  </Table>

                  <StudentEdit2
                    isOpen={this.state.openModal}
                    toggleStudentModal={this.toggleStudentModal.bind(this)}
                    editMode={editMode.EDIT}
                    id={this.state.studentId}
                    callback={this._refreshStudents.bind(this)}
                    tables={this.props.tables}
                  />
                </CardBody>

                <CardFooter>
                  <Row form style={{ display: "block" }}>
                    <Row>
                      <Button
                        color="success"
                        size="sm"
                        style={{
                          marginLeft: "auto",
                          textTransform: "inherit",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                        onClick={this.DownloadPdf}
                      >
                        Export to PDF
                      </Button>

                      <Button
                        color="success"
                        size="sm"
                        style={{
                          textTransform: "inherit",
                          marginRight: "30px",
                          marginBottom: "10px",
                        }}
                        onClick={this.DownloadCsv.bind(this)}
                      >
                        Export to CSV
                      </Button>
                    </Row>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    this._refreshStudents();
  }

  toggleStudentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openModal = !mainState.openModal;
      this.setState(mainState, resolve(true));
    });
  };

  openStudentEditModal = (id) => {
    this.toggleStudentModal();
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.studentId = id;
      this.setState(mainState, resolve(true));
    });
  };

  _refreshStudents() {
    return new Promise((resolve, reject) => {
      axios.get("dashboard/BlockedStudents").then((response) => {
        let mainState = this.state;

        mainState.students = [];
        response.data.data.blockedStudents.map((student, index) =>
          mainState.students.push({
            id: student.id,
            name: student.name,
            i20: student.i20,
            email: student.email,
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  }

  DownloadPdf() {
    axios.get("dashboard/BlockedStudents").then((response) => {
      let students = [];
      response.data.data.blockedStudents.map((student, index) =>
        students.push({
          id: student.id,
          name: student.name,
          i20: student.i20,
          email: student.email,
        })
      );

      SavePdf("BlockedStudents", students, [
        {
          name: "Name",
          i20: "I20",
          email: "Email",
        },
      ]);
    });
  }

  DownloadCsv() {
    axios.get("dashboard/BlockedStudents").then((response) => {
      let students = [];
      response.data.data.blockedStudents.map((student, index) =>
        students.push({
          id: student.id,
          name: student.name,
          i20: student.i20,
          email: student.email,
        })
      );

      let studentCsv = [];

      students.forEach((item) => {
        studentCsv.push({
          Name: item.name,
          I20: item.i20,
          Email: item.email,
        });
      });

      this.downloadCSVFromJson("BlockedStudents.csv", studentCsv);
    });
  }

  downloadCSVFromJson(filename, arrayOfJson) {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default BlockedStudents;
