/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import confirm from "reactstrap-confirm";
import { editMode } from "helpers/Utils.js";

class AssignmentType extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  render() {
    let assignmentTypes = this.state.mainState.assignmentTypes.map(
      (assignmentType) => {
        return (
          <tr key={assignmentType.id}>
            {/* <td>{assignmentType.id}</td> */}

            <td>
              <a
                onClick={this.openModalAssignmentType.bind(
                  this,
                  editMode.EDIT,
                  assignmentType.id
                )}
                href="#"
              >
                {assignmentType.name}
              </a>
            </td>

            {/* <td> */}
            {/* <Button
                color="success"
                size="sm"
                className="mr-2"
                onClick={this.openModalAssignmentType.bind(
                  this,
                  editMode.EDIT,
                  assignmentType.id
                )}
              >
                Edit
              </Button> */}
            {/* <Button
                color="danger"
                size="sm"
                onClick={this.deleteAssignmentType.bind(
                  this,
                  assignmentType.id
                )}
              >
                Delete
              </Button> */}
            {/* </td> */}
          </tr>
        );
      }
    );

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  {/* <CardTitle tag="h4">Campuses</CardTitle> */}
                  <Button
                    className="my-3"
                    color="primary"
                    onClick={this.openModalAssignmentType.bind(
                      this,
                      editMode.INSERT
                    )}
                  >
                    Add assignment type
                  </Button>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th>Name</th>
                        {/* <th>Actions</th> */}
                      </tr>
                    </thead>

                    <tbody>{assignmentTypes}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.mainState.currentPage}
                    totalItemsCount={this.state.mainState.totalResults}
                    onChange={this.nextPage.bind(this)}
                  />

                  <Modal
                    isOpen={this.state.mainState.openModal}
                    toggle={this.toggleNewAssignmentTypeModal.bind(this)}
                    centered={true}
                    autoFocus={true}
                    size={"sm"}
                    backdrop="static"
                    onClosed={this.onCloseModal}
                  >
                    <ModalHeader
                      toggle={this.toggleNewAssignmentTypeModal.bind(this)}
                    >
                      {this.state.modalState.editMode === editMode.INSERT
                        ? "Add"
                        : "Edit"}
                    </ModalHeader>
                    <ModalBody>
                      <Row form>
                        <Col>
                          <FormGroup>
                            <Label for="assignmentTypeName">Name</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.AssignmentType.Name
                                  .ErrorMsg
                              )}`}
                              type="text"
                              name="Name"
                              id="assignmentTypeName"
                              autoComplete="nope"
                              value={
                                this.state.modalState.AssignmentType.Name.Value
                              }
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {
                                this.state.modalState.AssignmentType.Name
                                  .ErrorMsg
                              }
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={
                          this.state.modalState.editMode === editMode.INSERT
                            ? this.addAssignmentType.bind(this)
                            : this.updateAssignmentType.bind(this)
                        }
                        disabled={!this.state.modalState.formValid}
                      >
                        {this.state.modalState.editMode === editMode.INSERT
                          ? "Add"
                          : "Update"}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.toggleNewAssignmentTypeModal.bind(this)}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  getInitialState = () => {
    const initialState = {
      mainState: this.getMainState(),
      modalState: this.getModalState(),
    };

    return initialState;
  };

  getMainState = () => {
    const mainState = {
      assignmentTypes: [],
      openModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      alert: { visible: false, color: "", message: "" },
    };

    return mainState;
  };

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getAssignmentType = () => {
    const assignmentType = {
      Id: this.getEntity(),
      Name: this.getEntity(),
    };
    return assignmentType;
  };

  getModalState = () => {
    const modalState = {
      AssignmentType: this.getAssignmentType(),
      editMode: "",
      cards: [1, 2],
      alert: { visible: false, color: "", message: "" },
      formValid: false,
    };

    return modalState;
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          modalState: this.getModalState(),
        },
        resolve(true)
      );
    });
  };

  validateField(fieldName, value) {
    let { modalState } = this.state;

    switch (fieldName) {
      case "Name":
        modalState.AssignmentType.Name.IsValid = value.length >= 1;
        modalState.AssignmentType.Name.ErrorMsg = modalState.AssignmentType.Name
          .IsValid
          ? ""
          : "Name is empty";
        break;
      default:
        break;
    }

    this.setState(
      {
        modalState: modalState,
      },
      this.validateForm()
    );
  }

  validateForm() {
    let { modalState } = this.state;

    modalState.formValid = modalState.AssignmentType.Name.IsValid === true;

    this.setState({
      modalState: modalState,
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { modalState } = this.state;
    modalState.AssignmentType[name].Value = value;

    this.setState({ modalState: modalState }, () => {
      this.validateField(name, value);
    });
  };

  componentDidMount() {
    this._refreshAssignmentTypes();
  }

  toggleNewAssignmentTypeModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;
      mainState.openModal = !mainState.openModal;
      this.setState(
        {
          mainState: mainState,
        },
        resolve(true)
      );
    });
  };

  addAssignmentType() {
    let assignmentType = {
      Name: this.state.modalState.AssignmentType.Name.Value,
    };

    axios.post("assignmentTypes", assignmentType).then((response) => {
      this.toggleNewAssignmentTypeModal().then(() => {
        this._refreshAssignmentTypes().then(() => {
          this.resetModal();
        });
      });
    });
  }

  updateAssignmentType() {
    let assignmentType = {
      Id: this.state.modalState.AssignmentType.Id.Value,
      Name: this.state.modalState.AssignmentType.Name.Value,
    };

    axios.put("assignmentTypes", assignmentType).then((response) => {
      this.toggleNewAssignmentTypeModal().then(() => {
        this._refreshAssignmentTypes().then(() => {
          this.resetModal();
        });
      });
    });
  }

  openModalAssignmentType(mode, id) {
    let { modalState } = this.state;
    modalState.editMode = mode;

    this.setState({ modalState: modalState });

    if (mode === editMode.INSERT) {
      this.toggleNewAssignmentTypeModal();
    } else {
      axios.get("assignmentTypes/byid?id=" + id).then((response) => {
        modalState.AssignmentType.Id.Value = response.data.data.id;

        modalState.AssignmentType.Name.Value = response.data.data.name;
        modalState.AssignmentType.Name.IsValid = true;

        this.validateForm();

        this.setState(
          {
            modalState: modalState,
          },
          () => {
            this.toggleNewAssignmentTypeModal();
          }
        );
      });
    }
  }

  async deleteAssignmentType(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Are you sure you wish to delete this item?",
    });

    if (result) {
      axios.delete("assignmentTypes/" + id).then(() => {
        this._refreshAssignmentTypes();
      });
    }
  }

  _refreshAssignmentTypes = () => {
    return new Promise((resolve, reject) => {
      let page = this.state.mainState.currentPage;
      let itemsPerPage = this.state.mainState.itemsPerPage;

      axios
        .get("assignmentTypes?page=" + page + "&pageSize=" + itemsPerPage)
        .then((response) => {
          let { mainState } = this.state;

          mainState.assignmentTypes = [];
          response.data.data.items.map((assignmentType, index) =>
            mainState.assignmentTypes.push({
              id: assignmentType.id,
              name: assignmentType.name,
            })
          );

          mainState.totalResults = response.data.data.totalCount;

          this.setState(
            {
              mainState: mainState,
            },
            resolve(true)
          );
        });
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  setCurrentPage(pageNumber) {
    let { mainState } = this.state;
    mainState.currentPage = pageNumber;
    this.setState({ mainState: mainState });
  }

  nextPage(pageNumber) {
    let itemsPerPage = this.state.mainState.itemsPerPage;

    axios
      .get("assignmentTypes?page=" + pageNumber + "&pageSize=" + itemsPerPage)
      .then((response) => {
        let { mainState } = this.state;

        mainState.assignmentTypes = [];
        response.data.data.items.map((assignmentType, index) =>
          mainState.assignmentTypes.push({
            id: assignmentType.id,
            name: assignmentType.name,
          })
        );

        mainState.currentPage = pageNumber;
        mainState.totalResults = response.data.data.totalCount;

        this.setState({
          mainState: mainState,
        });
      });
  }
}

export default AssignmentType;
