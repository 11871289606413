/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  CardHeader,
  FormGroup,
  Label,
  Button,
  CardFooter,
} from "reactstrap";
import axios from "axios";
import * as dateFns from "date-fns";
import Pagination from "components/Pagination/Pagination.js";
import DatePicker from "react-datepicker";
import "react-datasheet/lib/react-datasheet.css";
import { SavePdf } from "components/Report/GeneratePdf.js";

class TransferOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      students: [],
      StartDateSearch: dateFns.startOfYear(new Date()),
      EndDateSearch: dateFns.endOfWeek(new Date()),
    };
  };

  render() {
    let students = this.state.students.map((tr, index) => {
      return (
        <tr key={index}>
          <td>{tr.name}</td>
          <td>{tr.i20}</td>
          <td>{tr.status}</td>
          <td>{tr.description}</td>
          <td>{dateFns.format(tr.date, "MM/dd/yyyy")}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.EndDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "EndDateSearch"
                        )}
                        name="EndDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>I-20</th>
                        <th>Status</th>
                        <th>Description</th>
                        <th>Date</th>
                      </tr>
                    </thead>

                    <tbody>{students}</tbody>
                  </Table>
                </CardBody>

                <CardFooter>
                  <Row form style={{ display: "block" }}>
                    <Row>
                      {students && students.length > 0 && (
                        <h6
                          style={{
                            marginLeft: "25px",
                            marginRight: "auto",
                            marginTop: "15px",
                            textTransform: "inherit",
                          }}
                        >
                          <strong className="text-secondary">
                            {students.length}
                          </strong>{" "}
                          {students.length > 1 ? "records" : "record"}
                        </h6>
                      )}

                      <Button
                        color="success"
                        size="sm"
                        style={{
                          marginLeft: "auto",
                          textTransform: "inherit",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                        onClick={this.DownloadPdf.bind(this)}
                      >
                        Export to PDF
                      </Button>

                      <Button
                        color="success"
                        size="sm"
                        style={{
                          textTransform: "inherit",
                          marginRight: "30px",
                          marginBottom: "10px",
                        }}
                        onClick={this.DownloadCsv.bind(this)}
                      >
                        Export to CSV
                      </Button>
                    </Row>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    this._refreshStudents();
  }

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;

    mainState[name] = date;

    this.setState(mainState, () => {
      this._refreshStudents();
    });
  };

  _refreshStudents() {
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

    return new Promise((resolve, reject) => {
      axios
        .get(
          "dashboard/transfer-out?startDate=" +
            startDate +
            "&endDate=" +
            endDate
        )
        .then((response) => {
          let mainState = this.state;

          mainState.students = [];
          response.data.data.map((tr, index) =>
            mainState.students.push({
              name: tr.name,
              i20: tr.i20,
              status: tr.status,
              description: tr.description,
              date: dateFns.parseISO(tr.date),
            })
          );

          this.setState(mainState, resolve(true));
        });
    });
  }

  DownloadPdf() {
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

    axios
      .get(
        "dashboard/transfer-out?startDate=" + startDate + "&endDate=" + endDate
      )
      .then((response) => {
        let students = [];
        response.data.data.map((tr, index) =>
          students.push({
            name: tr.name,
            i20: tr.i20,
            status: tr.status,
            description: tr.description,
            date: dateFns.format(dateFns.parseISO(tr.date), "MM/dd/yyyy"),
          })
        );

        SavePdf("TransferOut", students, [
          {
            name: "Name",
            i20: "I20",
            status: "Status",
            description: "Description",
            date: "Date",
          },
        ]);
      });
  }

  DownloadCsv() {
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

    axios
      .get(
        "dashboard/transfer-out?startDate=" + startDate + "&endDate=" + endDate
      )
      .then((response) => {
        let students = [];
        response.data.data.map((tr, index) =>
          students.push({
            name: tr.name,
            i20: tr.i20,
            status: tr.status,
            description: tr.description,
            date: dateFns.format(dateFns.parseISO(tr.date), "MM/dd/yyyy"),
          })
        );

        let studentCsv = [];

        students.forEach((item) => {
          studentCsv.push({
            Name: item.name,
            I20: item.i20,
            Status: item.status,
            Description: item.description,
            Date: item.date,
          });
        });

        this.downloadCSVFromJson("TransferOut.csv", studentCsv);
      });
  }

  downloadCSVFromJson(filename, arrayOfJson) {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default TransferOut;
