import { authenticationService } from "services/Authentication.service.js";

export function authHeader() {
  // let user = JSON.parse(localStorage.getItem("user"));
  const user = authenticationService.currentUserValue;

  let obj = { "Content-Type": "application/json" };

  if (user && user.token) {
    obj.Authorization = "Bearer " + user.token;
  }

  let semesterId = JSON.parse(localStorage.getItem("semesterId"));
  let campusId = JSON.parse(localStorage.getItem("campusId"));

  if (semesterId) {
    obj.SemesterId = semesterId;
  }

  if (campusId) {
    obj.CampusId = campusId;
  }

  return obj;
}
