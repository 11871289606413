/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  ListGroupItem,
  CustomInput,
  ListGroup,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import confirm from "reactstrap-confirm";
import { editMode } from "helpers/Utils.js";

class Role extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  render() {
    let roles = this.state.mainState.roles.map((role) => {
      return (
        <tr key={role.id}>
          <td>
            <a
              onClick={this.openModalRole.bind(this, editMode.EDIT, role.id)}
              href="#"
            >
              {role.name}
            </a>
          </td>

          <td>
            <Button
              color="info"
              size="sm"
              className="mr-2"
              onClick={this.openModalRole.bind(this, editMode.CLONE, role.id)}
            >
              Clone
            </Button>

            <Button
              color="danger"
              size="sm"
              className="mr-2"
              onClick={this.deleteRole.bind(this, role.id)}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });

    let features = this.state.modalState.Role.Features.map((feature, index) => {
      return (
        <ListGroupItem key={index}>
          <CustomInput
            type="switch"
            id={feature.id}
            name={feature.id}
            label={feature.fullName}
            checked={feature.checked}
            onChange={this.onSwitchFeatureChange}
          />
        </ListGroupItem>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Button
                    className="my-3"
                    color="primary"
                    onClick={this.openModalRole.bind(this, editMode.INSERT)}
                  >
                    Add role
                  </Button>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>{roles}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.mainState.currentPage}
                    totalItemsCount={this.state.mainState.totalResults}
                    onChange={this._refreshRoles.bind(this)}
                  />

                  <Modal
                    isOpen={this.state.mainState.openModal}
                    toggle={this.toggleNewRoleModal.bind(this)}
                    centered={true}
                    autoFocus={true}
                    size={"lg"}
                    backdrop="static"
                    onClosed={this.onCloseModal}
                    scrollable
                  >
                    <ModalHeader toggle={this.toggleNewRoleModal.bind(this)}>
                      {this.state.modalState.editMode === editMode.INSERT ||
                      this.state.modalState.editMode === editMode.CLONE
                        ? "Add a new role"
                        : "Edit a role"}
                    </ModalHeader>
                    <ModalBody>
                      <Row form>
                        <Col>
                          <FormGroup>
                            <Label for="roleName">Name</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Role.Name.ErrorMsg
                              )}`}
                              type="text"
                              name="Name"
                              id="roleName"
                              autoComplete="nope"
                              value={this.state.modalState.Role.Name.Value}
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Role.Name.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md="12">
                          <FormGroup>
                            <Label for="roleFeature">Features</Label>
                            <ListGroup>{features}</ListGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={
                          this.state.modalState.editMode === editMode.INSERT ||
                          this.state.modalState.editMode === editMode.CLONE
                            ? this.addRole.bind(this)
                            : this.updateRole.bind(this)
                        }
                        disabled={!this.state.modalState.formValid}
                      >
                        {this.state.modalState.editMode === editMode.INSERT ||
                        this.state.modalState.editMode === editMode.CLONE
                          ? "Add"
                          : "Update"}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.toggleNewRoleModal.bind(this)}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  getInitialState = () => {
    const initialState = {
      mainState: this.getMainState(),
      modalState: this.getModalState(),
    };

    return initialState;
  };

  getMainState = () => {
    const mainState = {
      roles: [],
      openModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      alert: { visible: false, color: "", message: "" },
    };

    return mainState;
  };

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getRole = () => {
    const role = {
      Id: this.getEntity(),
      Name: this.getEntity(),
      Features: [],
    };
    return role;
  };

  getModalState = () => {
    const modalState = {
      Role: this.getRole(),
      editMode: "",
      cards: [1, 2],
      alert: { visible: false, color: "", message: "" },
      formValid: false,
    };

    return modalState;
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          modalState: this.getModalState(),
        },
        resolve(true)
      );
    });
  };

  onSwitchFeatureChange = (e) => {
    let { modalState } = this.state;

    modalState.Role.Features.find((x) => x.id === e.target.id).checked =
      e.target.checked;

    this.setState({ modalState: modalState });
  };

  validateField(fieldName, value) {
    let { modalState } = this.state;

    switch (fieldName) {
      case "Name":
        modalState.Role.Name.IsValid = value.length >= 1;
        modalState.Role.Name.ErrorMsg = modalState.Role.Name.IsValid
          ? ""
          : "Name is empty";
        break;
      default:
        break;
    }

    this.setState(
      {
        modalState: modalState,
      },
      this.validateForm()
    );
  }

  validateForm() {
    let { modalState } = this.state;

    modalState.formValid = modalState.Role.Name.IsValid === true;

    this.setState({
      modalState: modalState,
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { modalState } = this.state;
    modalState.Role[name].Value = value;

    this.setState({ modalState: modalState }, () => {
      this.validateField(name, value);
    });
  };

  componentDidMount() {
    this._refreshRoles();
  }

  toggleNewRoleModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;
      mainState.openModal = !mainState.openModal;
      this.setState(
        {
          mainState: mainState,
        },
        resolve(true)
      );
    });
  };

  addRole() {
    let features = [];
    this.state.modalState.Role.Features.filter((x) => x.checked === true).map(
      (feature, index) =>
        features.push({
          Id: feature.id,
        })
    );

    let role = {
      Name: this.state.modalState.Role.Name.Value,
      Features: features,
    };

    axios.post("roles", role).then((response) => {
      this.toggleNewRoleModal().then(() => {
        this._refreshRoles().then(() => {
          this.resetModal();
        });
      });
    });
  }

  updateRole() {
    let features = [];
    this.state.modalState.Role.Features.filter((x) => x.checked === true).map(
      (feature, index) =>
        features.push({
          Id: feature.id,
        })
    );

    let role = {
      Id: this.state.modalState.Role.Id.Value,
      Name: this.state.modalState.Role.Name.Value,
      Features: features,
    };

    axios.put("roles", role).then((response) => {
      this.toggleNewRoleModal().then(() => {
        this._refreshRoles().then(() => {
          this.resetModal();
        });
      });
    });
  }

  openModalRole(mode, id) {
    let { modalState } = this.state;
    modalState.editMode = mode;

    this.setState({ modalState: modalState });

    this.refreshFeatures().then(() => {
      if (mode === editMode.INSERT) {
        this.toggleNewRoleModal();
      } else {
        axios.get("roles/GetById?id=" + id).then((response) => {
          modalState.Role.Id.Value = response.data.data.id;

          modalState.Role.Name.Value = response.data.data.name;
          modalState.Role.Name.IsValid = true;

          if (mode === editMode.CLONE)
            modalState.Role.Name.Value =
              modalState.Role.Name.Value + " - Clone";

          response.data.data.features.forEach((feature) => {
            let featureTable = modalState.Role.Features.find(
              (x) => x.id === feature.id
            );

            if (featureTable) featureTable.checked = true;
          });

          this.validateForm();

          this.setState(
            {
              modalState: modalState,
            },
            () => {
              this.toggleNewRoleModal();
            }
          );
        });
      }
    });
  }

  refreshFeatures = () => {
    return new Promise((resolve, reject) => {
      let { modalState } = this.state;

      this.props.tables.features.map((feature, index) =>
        modalState.Role.Features.push({
          id: feature.id,
          name: feature.name,
          fullName: feature.fullName,
          checked: false,
        })
      );

      this.setState(
        {
          modalState: modalState,
        },
        resolve(true)
      );
    });
  };

  async deleteRole(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Are you sure you wish to delete this item?",
    });

    if (result) {
      axios.delete("roles/" + id).then(() => {
        this._refreshRoles();
      });
    }
  }

  _refreshRoles = (pageNumber) => {
    return new Promise((resolve, reject) => {
      let page = pageNumber || 1;
      let itemsPerPage = this.state.mainState.itemsPerPage;

      axios
        .get("roles/GetAll?page=" + page + "&pageSize=" + itemsPerPage)
        .then((response) => {
          let { mainState } = this.state;

          mainState.roles = [];
          response.data.data.items.map((role, index) =>
            mainState.roles.push({
              id: role.id,
              name: role.name,
            })
          );

          mainState.currentPage = pageNumber;
          mainState.totalResults = response.data.data.totalCount;

          this.setState(
            {
              mainState: mainState,
            },
            resolve(true)
          );
        });
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  setCurrentPage(pageNumber) {
    let { mainState } = this.state;
    mainState.currentPage = pageNumber;
    this.setState({ mainState: mainState });
  }
}

export default Role;
