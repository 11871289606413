/* eslint-disable array-callback-return */
import React from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
} from "reactstrap";
import axios from "axios";

class NoteCompose extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                onClosed={this.onCloseModal}
                onOpened={this.onOpened.bind(this)}
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  {this.props.type === "C"
                    ? "Prob. Letter Sent "
                    : this.props.type === "P"
                    ? "Prob. Letter Signed "
                    : this.props.type === "T"
                    ? "Termination Letter "
                    : ""}
                  Note
                  {this.props.StudentName && " for " + this.props.StudentName}
                </ModalHeader>
                <ModalBody>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Input
                          className={"form-group"}
                          type="textarea"
                          name="Description"
                          id="Description"
                          autoComplete="nope"
                          value={this.state.Note.Description}
                          onChange={this.handleUserInput}
                          placeholder="Type the new note here..."
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={this.saveNote.bind(this)}
                    disabled={!this.validateForm()}
                  >
                    Save
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.props.toggle.bind(this)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  getNote = () => {
    const note = {
      Description: "",
    };

    return note;
  };

  getModalState = () => {
    return {
      Note: this.getNote(),
      formValid: false,
    };
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  validateForm() {
    return this.state.Note.Description.length > 0;
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState.Note[name] = value;

    this.setState(modalState);
  };

  onOpened() {
    this.openModalNoteCompose();
  }

  saveNote() {
    let modalState = this.state;

    let note = {
      Description: modalState.Note.Description,
      Student: { Id: this.props.StudentId },
      Type: this.props.type,
    };

    axios.post("students/savenote", note).then((response) => {
      this.props.toggle().then(() => {
        this.resetModal().then(() => {
          if (
            this.props.callback &&
            typeof this.props.callback === "function"
          ) {
            this.props.callback();
          }
        });
      });
    });
  }

  openModalNoteCompose() {
    let modalState = this.state;

    this.setState(modalState);
  }

  onCloseModal = () => {
    this.resetModal();
  };
}

export default NoteCompose;
