/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import axios from "axios";

class UserPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  }

  onCloseModal = () => {
    this.resetModal();
  };

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { currentPassword: "", newPassword: "", confirmNewPassword: "" },
        resolve(true)
      );
    });
  };

  validateForm() {
    return (
      this.state.currentPassword.length > 0 &&
      this.state.newPassword.length > 0 &&
      this.state.confirmNewPassword.length > 0 &&
      this.state.newPassword === this.state.confirmNewPassword
    );
  }

  handleSubmit = async () => {
    let user = {
      Password: this.state.currentPassword,
      NewPassword: this.state.newPassword,
    };

    await axios.post("users/updatePassword", user).then((response) => {
      this.props.toggle().then(() => {
        this.resetModal();
      });
    });
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                size={"sm"}
                backdrop="static"
                unmountOnClose={true}
                onClosed={this.onCloseModal}
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  Password
                </ModalHeader>
                <ModalBody>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="assignmentName">Current password</Label>
                        <Input
                          type="password"
                          name="currentPassword"
                          autoComplete="nope"
                          value={this.state.currentPassword}
                          onChange={this.handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="assignmentName">New password</Label>
                        <Input
                          type="password"
                          name="newPassword"
                          autoComplete="nope"
                          value={this.state.newPassword}
                          onChange={this.handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="assignmentName">Confirm new password</Label>
                        <Input
                          type="password"
                          name="confirmNewPassword"
                          autoComplete="nope"
                          value={this.state.confirmNewPassword}
                          onChange={this.handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    disabled={!this.validateForm()}
                    onClick={this.handleSubmit}
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={this.props.toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default UserPassword;
