import { Subject } from "rxjs";

const subject = new Subject();

export const messageService = {
  sendMessage: (message) => subject.next({ text: message }),
  clearMessages: () => subject.next(),
  getMessage: () => subject.asObservable(),
};

const swalSubject = new Subject();

export const swalmessageService = {
  sendMessage: (message) => swalSubject.next({ text: message }),
  clearMessages: () => swalSubject.next(),
  getMessage: () => swalSubject.asObservable(),
};
