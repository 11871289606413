/* eslint-disable array-callback-return */
import jsPDF from "jspdf";
import "jspdf-autotable";
import lumos_logo from "lumos_logo.png";
import lumos_address from "lumos_address.png";
import * as dateFns from "date-fns";
import Countries from "Countries.json";

import axios from "axios";

function getHeader() {
  return [
    [
      {
        content: "Intensive English Program",
        colSpan: 26,
        styles: { halign: "center", fillColor: [244, 130, 51] },
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    ],
  ];
}

function getHeaderToefl() {
  return [
    [
      {
        content: "TOEFL Preparation Program",
        colSpan: 5,
        styles: { halign: "center", fillColor: [244, 130, 51] },
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    ],
  ];
}

function getRowLevel() {
  let rowLevel = [
    {
      colSpan: 1,
      content: "",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      colSpan: 1,
      content: "Level",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      colSpan: 3,
      content: "Level Introduction",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
  ];

  for (var i = 1; i <= 6; i++) {
    rowLevel.push({
      colSpan: 3,
      content: "Level " + i,
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    });
  }

  rowLevel.push({
    colSpan: 3,
    content: "Level 6 TOEFL",
    styles: {
      valign: "middle",
      halign: "center",
      cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
    },
  });

  return rowLevel;
}

function getRowLevelToefl() {
  let rowLevel = [
    {
      colSpan: 1,
      content: "",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      colSpan: 1,
      content: "Level",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      colSpan: 3,
      content: "TOEFL",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
  ];

  return rowLevel;
}

function getRowCefr() {
  let rowCefr = [
    {
      colSpan: 1,
      content: "",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      colSpan: 1,
      content: "CEFR",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      colSpan: 3,
      content: "Pre A1",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
  ];

  for (let i = 1; i <= 2; i++) {
    rowCefr.push({
      colSpan: 3,
      content: "A" + i,
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    });
  }

  for (let i = 1; i <= 2; i++) {
    rowCefr.push({
      colSpan: 3,
      content: "B" + i,
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    });
  }

  for (let i = 1; i <= 2; i++) {
    rowCefr.push({
      colSpan: 3,
      content: "C" + i,
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    });
  }

  rowCefr.push({
    colSpan: 3,
    content: "C2",
    styles: {
      valign: "middle",
      halign: "center",
      cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
    },
  });

  return rowCefr;
}

function getRowClass() {
  let rowClass = [
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      content: "Class",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      content: "I101",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      content: "I102",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      content: "I103",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
  ];

  for (let i = 1; i <= 6; i++) {
    for (let j = 1; j <= 3; j++) {
      rowClass.push({
        content: i + "0" + j,
        styles: {
          valign: "middle",
          halign: "center",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      });
    }
  }

  rowClass.push({
    content: "601T",
    styles: {
      valign: "middle",
      halign: "center",
      cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
    },
  });

  rowClass.push({
    content: "602T",
    styles: {
      valign: "middle",
      halign: "center",
      cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
    },
  });

  rowClass.push({
    content: "603T",
    styles: {
      valign: "middle",
      halign: "center",
      cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
    },
  });

  return rowClass;
}

function getRowClassToefl() {
  let rowClass = [
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      content: "Class",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      content: "T101",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      content: "T102",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      content: "T103",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
  ];

  return rowClass;
}

function getRowAttendance() {
  let rowAttendance = [
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      content: "Attendance",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      colSpan: 24,
      content: "",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
  ];

  return rowAttendance;
}

function getRowAttendanceToefl() {
  let rowAttendance = [
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      content: "Attendance",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
    {
      colSpan: 3,
      content: "",
      styles: {
        valign: "middle",
        halign: "center",
        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
      },
    },
  ];

  return rowAttendance;
}

async function getStudent(studentId, semesters) {
  return new Promise((resolve, reject) => {
    let student = {
      studentClasses: [],
      address: {},
      attendance: [],
      eslData: [],
      toeflData: [],
      statusList: [],
    };

    axios.get("students/byid?id=" + studentId).then((response) => {
      student.name = response.data.data.name;
      student.i20 = response.data.data.i20;
      student.birthDate = dateFns.format(
        dateFns.parseISO(response.data.data.birthDate),
        "MM/dd/yyyy"
      );
      student.gender = response.data.data.gender;
      student.status = response.data.data.status;
      student.nationalityId = response.data.data.nationalityId;

      response.data.data.studentClasses.map((studentClass, index) =>
        student.studentClasses.push({
          id: studentClass.id,
          classId: studentClass.classe.id,
          classCode: studentClass.classe.code,
          semesterId: studentClass.classe.semester.id,
          semesterName: studentClass.classe.semester.name.replace(
            "Spring/Summer 2021",
            "Spring/Summer 21"
          ),
          semesterStartDate: dateFns.parseISO(
            studentClass.classe.semester.startDate
          ),
          semesterEndDate: dateFns.parseISO(
            studentClass.classe.semester.endDate
          ),
          maxGrade: studentClass.classe.marks,
          grade: studentClass.marks,
          courseName: studentClass.classe.course.name,
          active: studentClass.active,
          createdDate: dateFns.parseISO(studentClass.createdDate),
        })
      );

      let studentClasses = [...student.studentClasses];

      response.data.data.statusList
        .filter(
          (x) =>
            x.statusType.name.toUpperCase() === "BREAK" ||
            x.statusType.name.toUpperCase() === "MEDICAL LEAVE"
        )
        .map((status, index) => {
          student.statusList.push({
            id: status.id,
            startDate: dateFns.parseISO(status.startDate),
            endDate: dateFns.parseISO(status.endDate),
            name: status.statusType.name,
            createdDate: dateFns.parseISO(status.createdDate),
            semesterName:
              semesters.filter(
                (x) =>
                  dateFns.parseISO(x.startDate) >=
                  dateFns.parseISO(status.startDate)
              ).length > 0 &&
              semesters
                .filter(
                  (x) =>
                    dateFns.parseISO(x.startDate) >=
                    dateFns.parseISO(status.startDate)
                )
                .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                .reverse()[0]
                .name.replace("Spring/Summer 2021", "Spring/Summer 21"),
            isToefl:
              studentClasses.filter(
                (x) => x.semesterEndDate <= dateFns.parseISO(status.endDate)
              ).length > 0 &&
              studentClasses
                .filter(
                  (x) => x.semesterEndDate <= dateFns.parseISO(status.endDate)
                )
                .sort(
                  (a, b) =>
                    new Date(b.semesterEndDate) - new Date(a.semesterEndDate)
                )[0]
                .courseName.toUpperCase()
                .includes("TOEFL"),
          });
        });

      if (response.data.data.address) {
        student.address.street = response.data.data.address.street.replace(
          /(\r\n|\n|\r)/gm,
          " "
        );
        student.address.city = response.data.data.address.city;
        student.address.state = response.data.data.address.state;
        student.address.zip = response.data.data.address.zip;
        student.address.cellPhone = response.data.data.address.cellPhone;
        student.address.email = response.data.data.address.email;
      }

      resolve(student);
    });
  });
}

async function getAttendance(student, studentId) {
  return new Promise((resolve, reject) => {
    axios
      .get("attendances/getallbyStudent?studentId=" + studentId)
      .then((response) => {
        student.attendance = [];
        response.data.data.map((attendance, index) =>
          student.attendance.push({
            id: attendance.id,
            className: attendance.classe.code,
            date: dateFns.format(
              dateFns.parseISO(attendance.date),
              "MM/dd/yyyy"
            ),
            rawDate: dateFns.parseISO(attendance.date),
            status: attendance.attendanceStudentViewModels[0].status,
            semesterId: attendance.classe.semester.id,
            semesterName: attendance.classe.semester.name,
            semesterStartDate: dateFns.format(
              dateFns.parseISO(attendance.classe.semester.startDate),
              "MM/dd/yyyy"
            ),
          })
        );

        resolve(student);
      });
  });
}

async function fillGradesEsl(student, percent) {
  student.studentClasses = student.studentClasses.filter(
    (x) => x.maxGrade > 0 && (x.grade / x.maxGrade) * 100 > percent
  );

  return new Promise((resolve, reject) => {
    let listOfSemesters = [];
    let listToReturn = [];

    student.studentClasses
      .filter((x) => !x.courseName.toUpperCase().includes("TOEFL"))
      .map((studentClass, index) => {
        if (
          !listOfSemesters.find((x) => x.semesterId === studentClass.semesterId)
        )
          listOfSemesters.push({
            semesterId: studentClass.semesterId,
            semesterName: studentClass.semesterName,
            semesterStartDate: studentClass.semesterStartDate,
            isBreak: false,
            createdDate: studentClass.createdDate,
            isActive: student.active,
          });
      });

    student.statusList
      .filter((x) => x.isToefl === false)
      .map((status, index) => {
        listOfSemesters.push({
          semesterId: status.id,
          semesterName: status.semesterName,
          semesterStartDate: status.startDate,
          isBreak: true,
          statusName: status.name,
          createdDate: status.createdDate,
        });
      });

    listOfSemesters = listOfSemesters
      .sort(function (a, b) {
        return (
          new Date(b.semesterStartDate) - new Date(a.semesterStartDate) ||
          new Date(b.createdDate) - new Date(a.createdDate)
        );
      })
      .reverse();

    listOfSemesters.map((semester, index) => {
      let attendanceCount = student.attendance.filter(
        (x) => x.semesterId === semester.semesterId
      ).length;

      let tardyCount = student.attendance.filter(
        (x) =>
          x.semesterId === semester.semesterId &&
          x.status === "T" &&
          x.rawDate <= new Date(2024, 3, 3)
      ).length;

      let tardyNewRuleCount = student.attendance.filter(
        (x) =>
          x.semesterId === semester.semesterId &&
          x.status === "T" &&
          x.rawDate > new Date(2024, 3, 3)
      ).length;

      let absentCount = student.attendance.filter(
        (x) => x.semesterId === semester.semesterId && x.status === "A"
      ).length;

      var quotient = Math.floor(tardyCount / 4);
      var quotientNewRule = Math.floor(tardyNewRuleCount / 4);

      let total = (
        attendanceCount > 0
          ? ((attendanceCount - (absentCount + quotient + quotientNewRule)) /
              attendanceCount) *
            100
          : 0
      ).toFixed(2);

      listToReturn.push([
        {
          content: semester.semesterName,
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: semester.isBreak
            ? semester.statusName
            : semester.active === true
            ? "CE"
            : total + "%",
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "Intro 100A"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "Intro 100B"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "Intro 100C"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "101"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "102"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "103"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "201"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "202"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "203"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content:
            student.name.includes("Yanwen") &&
            semester.semesterName.includes("Fall 2018")
              ? "P"
              : getGradeLetter(
                  student.studentClasses,
                  semester.semesterId,
                  "301"
                ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content:
            student.name.includes("Yanwen") &&
            semester.semesterName.includes("Fall 2018")
              ? "P"
              : getGradeLetter(
                  student.studentClasses,
                  semester.semesterId,
                  "302"
                ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content:
            student.name.includes("Yanwen") &&
            semester.semesterName.includes("Fall 2018")
              ? "P"
              : getGradeLetter(
                  student.studentClasses,
                  semester.semesterId,
                  "303"
                ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "401"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "402"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "403"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "501"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "502"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "503"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "601"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "602"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "603"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },

        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "601+"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "602+"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses,
            semester.semesterId,
            "603+"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
      ]);
    });

    resolve(listToReturn);
  });
}

async function fillGradesToefl(student, percent) {
  student.studentClasses = student.studentClasses.filter(
    (x) => x.maxGrade > 0 && (x.grade / x.maxGrade) * 100 > percent
  );

  return new Promise((resolve, reject) => {
    let listOfSemesters = [];
    let listToReturn = [];

    student.studentClasses
      .filter((x) => x.courseName.toUpperCase().includes("TOEFL"))
      .map((studentClass, index) => {
        if (
          !listOfSemesters.find((x) => x.semesterId === studentClass.semesterId)
        )
          listOfSemesters.push({
            semesterId: studentClass.semesterId,
            semesterName: studentClass.semesterName,
            semesterStartDate: studentClass.semesterStartDate,
            isBreak: false,
            createdDate: studentClass.createdDate,
            active: studentClass.active,
          });
      });

    student.statusList
      .filter((x) => x.isToefl === true)
      .map((status, index) => {
        listOfSemesters.push({
          semesterId: status.id,
          semesterName: status.semesterName,
          semesterStartDate: status.startDate,
          isBreak: true,
          statusName: status.name,
          createdDate: status.createdDate,
        });
      });

    listOfSemesters = listOfSemesters
      .sort(function (a, b) {
        return (
          new Date(b.semesterStartDate) - new Date(a.semesterStartDate) ||
          new Date(b.createdDate) - new Date(a.createdDate)
        );
      })
      .reverse();

    listOfSemesters.map((semester, index) => {
      let attendanceCount = student.attendance.filter(
        (x) => x.semesterId === semester.semesterId
      ).length;

      let tardyCount = student.attendance.filter(
        (x) =>
          x.semesterId === semester.semesterId &&
          x.status === "T" &&
          x.rawDate <= new Date(2024, 3, 3)
      ).length;

      let tardyNewRuleCount = student.attendance.filter(
        (x) =>
          x.semesterId === semester.semesterId &&
          x.status === "T" &&
          x.rawDate > new Date(2024, 3, 3)
      ).length;

      let absentCount = student.attendance.filter(
        (x) => x.semesterId === semester.semesterId && x.status === "A"
      ).length;

      var quotient = Math.floor(tardyCount / 4);
      var quotientNewRule = Math.floor(tardyNewRuleCount / 4);

      let total = (
        attendanceCount > 0
          ? ((attendanceCount - (absentCount + quotient + quotientNewRule)) /
              attendanceCount) *
            100
          : 0
      ).toFixed(2);

      listToReturn.push([
        {
          content: semester.semesterName,
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 0, bottom: 0, left: 0 },
          },
        },
        {
          content: semester.isBreak
            ? semester.statusName
            : semester.active === true
            ? "CE"
            : total + "%",
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses.filter((x) =>
              x.courseName.toUpperCase().includes("TOEFL")
            ),
            semester.semesterId,
            "01"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses.filter((x) =>
              x.courseName.toUpperCase().includes("TOEFL")
            ),
            semester.semesterId,
            "02"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
        {
          content: getGradeLetter(
            student.studentClasses.filter((x) =>
              x.courseName.toUpperCase().includes("TOEFL")
            ),
            semester.semesterId,
            "03"
          ),
          styles: {
            valign: "middle",
            halign: "center",
            cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          },
        },
      ]);
    });

    resolve(listToReturn);
  });
}

function getGradeLetter(studentClasses, semesterId, classCode) {
  let studentClassesFiltered = studentClasses.filter(
    (x) =>
      x.semesterId === semesterId &&
      x.classCode.includes(classCode) &&
      !x.courseName.includes("Plus")
  );

  if (classCode === "502" && studentClassesFiltered.length === 0) {
    studentClassesFiltered = studentClasses.filter(
      (x) => x.semesterId === semesterId && x.classCode.includes("501")
    );
  }

  if (classCode === "503" && studentClassesFiltered.length === 0) {
    studentClassesFiltered = studentClasses.filter(
      (x) => x.semesterId === semesterId && x.classCode.includes("502")
    );
  }

  if (classCode === "503" && studentClassesFiltered.length === 0) {
    studentClassesFiltered = studentClasses.filter(
      (x) => x.semesterId === semesterId && x.classCode.includes("501")
    );
  }

  if (classCode === "Intro 100A" && studentClassesFiltered.length === 0) {
    studentClassesFiltered = studentClasses.filter(
      (x) =>
        x.semesterId === semesterId &&
        (x.classCode.includes("Intro 100A") ||
          x.classCode.includes("Intro 100-A"))
    );
  }

  if (classCode === "Intro 100B" && studentClassesFiltered.length === 0) {
    studentClassesFiltered = studentClasses.filter(
      (x) =>
        x.semesterId === semesterId &&
        (x.classCode.includes("Intro 100B") ||
          x.classCode.includes("Intro 100-B"))
    );
  }

  if (classCode === "Intro 100C" && studentClassesFiltered.length === 0) {
    studentClassesFiltered = studentClasses.filter(
      (x) => x.semesterId === semesterId && x.classCode.includes("Intro 100B")
    );
  }

  if (classCode.includes("+")) {
    studentClassesFiltered = studentClasses.filter(
      (x) =>
        x.semesterId === semesterId &&
        x.classCode.includes(classCode.substring(0, classCode.length - 1)) &&
        x.courseName.includes("Plus")
    );
  }

  if (
    studentClassesFiltered.length > 0 &&
    studentClassesFiltered[0].active === true
  )
    return "CE";

  let gradeMark = "";

  if (
    studentClassesFiltered.length > 0 &&
    studentClassesFiltered[0].maxGrade > 0
  ) {
    gradeMark =
      (studentClassesFiltered[0].grade / studentClassesFiltered[0].maxGrade) *
      100;
  }

  let result = "";

  switch (true) {
    case gradeMark === "":
      result = "";
      break;
    case 0:
      result = "";
      break;
    case gradeMark >= 94:
      result = "A";
      break;
    case gradeMark >= 90 && gradeMark < 94:
      result = "A-";
      break;
    case gradeMark >= 87 && gradeMark < 90:
      result = "B+";
      break;
    case gradeMark >= 83 && gradeMark < 87:
      result = "B";
      break;
    case gradeMark >= 80 && gradeMark < 83:
      result = "B-";
      break;
    case gradeMark >= 77 && gradeMark < 80:
      result = "C+";
      break;
    case gradeMark >= 73 && gradeMark < 77:
      result = "C";
      break;
    case gradeMark >= 70 && gradeMark < 73:
      result = "C-";
      break;
    case gradeMark >= 67 && gradeMark < 70:
      result = "D+";
      break;
    case gradeMark >= 63 && gradeMark < 67:
      result = "D";
      break;
    case gradeMark >= 60 && gradeMark < 63:
      result = "D-";
      break;
    case gradeMark < 60:
      result = "F";
      break;
    default:
      result = "";
      break;
  }

  return result;
}

async function getStudentDetails(studentId, semesters, percent) {
  return new Promise((resolve, reject) => {
    getStudent(studentId, semesters).then((student) => {
      getAttendance(student, studentId).then((student) => {
        fillGradesEsl(student, percent).then((eslData) => {
          fillGradesToefl(student, percent).then((toeflData) => {
            //Fill tested out
            if (eslData[0] !== undefined) {
              let fillTestedOut = true;

              for (let index = 2; index < eslData[0].length; index++) {
                if (fillTestedOut === true) {
                  let item = eslData[0][index].content;

                  if (item === "") {
                    eslData[0][index].content = "TO";
                  } else fillTestedOut = false;
                }
              }

              for (let index = 0; index < eslData.length; index++) {
                const element = eslData[index];

                if (
                  element[1].content.toUpperCase().includes("BREAK") ||
                  element[1].content.toUpperCase().includes("MEDICAL LEAVE")
                ) {
                  for (let index = 2; index < 26; index++) {
                    element[index].content = "-";
                  }
                }
              }
            } else {
              let toList = [];

              for (let index = 0; index < 26; index++) {
                let content = index < 2 ? "" : "TO";
                toList.push({
                  content: content,
                  styles: {
                    valign: "middle",
                    halign: "center",
                    cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
                  },
                });
              }

              eslData.push(toList);
            }

            if (eslData[eslData.length - 1] !== undefined) {
              //Fill not completed
              let indexWithValueEsl = 0;
              for (let index = 25; index > 1; index--) {
                let item = eslData[eslData.length - 1][index].content;

                if (item !== "" && indexWithValueEsl === 0) {
                  indexWithValueEsl = index;
                }
              }

              if (indexWithValueEsl > 0 && indexWithValueEsl < 25) {
                let objToPush = [];

                for (let index = 0; index < 26; index++) {
                  if (index === 0 || index === 1) {
                    objToPush.push({
                      content: "-",
                      styles: {
                        valign: "middle",
                        halign: "center",
                        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
                      },
                    });
                  } else if (index < indexWithValueEsl + 1) {
                    objToPush.push({
                      content: "",
                      styles: {
                        valign: "middle",
                        halign: "center",
                        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
                      },
                    });
                  } else {
                    objToPush.push({
                      content: "NC",
                      styles: {
                        valign: "middle",
                        halign: "center",
                        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
                      },
                    });
                  }
                }

                eslData.push(objToPush);
              }
            }

            if (toeflData[0] !== undefined) {
              //Fill tested out TOEFL
              let fillTestedOut = true;

              for (let index = 2; index < toeflData[0].length; index++) {
                if (fillTestedOut === true) {
                  let item = toeflData[0][index].content;

                  if (item === "") {
                    toeflData[0][index].content = "TO";
                  } else fillTestedOut = false;
                }
              }

              for (let index = 0; index < toeflData.length; index++) {
                const element = toeflData[index];

                if (
                  element[1].content.toUpperCase().includes("BREAK") ||
                  element[1].content.toUpperCase().includes("MEDICAL LEAVE")
                ) {
                  for (let index = 2; index < 5; index++) {
                    element[index].content = "-";
                  }
                }
              }

              //Fill not completed TOEFL
              let indexWithValueToefl = 0;
              for (let index = 4; index > 1; index--) {
                let item = toeflData[toeflData.length - 1][index].content;

                if (item !== "" && indexWithValueToefl === 0) {
                  indexWithValueToefl = index;
                }
              }

              if (indexWithValueToefl > 0 && indexWithValueToefl < 4) {
                let objToPush = [];

                for (let index = 0; index < 5; index++) {
                  if (index === 0 || index === 1) {
                    objToPush.push({
                      content: "-",
                      styles: {
                        valign: "middle",
                        halign: "center",
                        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
                      },
                    });
                  } else if (index < indexWithValueToefl + 1) {
                    objToPush.push({
                      content: "",
                      styles: {
                        valign: "middle",
                        halign: "center",
                        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
                      },
                    });
                  } else {
                    objToPush.push({
                      content: "NC",
                      styles: {
                        valign: "middle",
                        halign: "center",
                        cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
                      },
                    });
                  }
                }

                toeflData.push(objToPush);
              }
            } else {
              let objToPush = [];

              for (let index = 0; index < 5; index++) {
                if (index === 0 || index === 1) {
                  objToPush.push({
                    content: "-",
                    styles: {
                      valign: "middle",
                      halign: "center",
                      cellPadding: {
                        top: 1,
                        right: 1,
                        bottom: 0,
                        left: 1,
                      },
                    },
                  });
                } else {
                  objToPush.push({
                    content: "NC",
                    styles: {
                      valign: "middle",
                      halign: "center",
                      cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
                    },
                  });
                }
              }

              toeflData.push(objToPush);
            }

            student.eslData = eslData;
            student.toeflData = toeflData;
            resolve(student);
          });
        });
      });
    });
  });
}

async function Execute(studentId, note, semesters, percent) {
  var doc = new jsPDF("l", "mm", "letter");

  var img_logo = new Image();
  img_logo.src = lumos_logo;

  var img_address = new Image();
  img_address.src = lumos_address;

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  let header = getHeader();
  let headerToefl = getHeaderToefl();
  let rowLevel = getRowLevel();
  let rowLevelToefl = getRowLevelToefl();
  let rowCefr = getRowCefr();
  let rowClass = getRowClass();
  let rowClassToefl = getRowClassToefl();
  let rowAttendance = getRowAttendance();
  let rowAttendanceToefl = getRowAttendanceToefl();

  await getStudentDetails(studentId, semesters, percent).then((student) => {
    let studentDetails = [];

    let dateIssuedRow = [
      {
        colSpan: 6,
        content: "Date Issued: " + dateFns.format(new Date(), "MMMM dd, yyyy"),
        styles: {
          valign: "middle",
          halign: "left",
          cellWidth: "auto",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
          fillColor: [255, 255, 255],
        },
      },
    ];

    let row0 = [
      {
        colSpan: 6,
        content: "",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    let firstRow = [
      {
        content: "Student: ",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: student.name,
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "Phone: ",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: student.address.cellPhone,
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "Gender: ",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: student.gender === "F" ? "Female" : "Male",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    let secondRow = [
      {
        content: "Street Address: ",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: student.address.street,
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "Date of Birth: ",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: student.birthDate,
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "I-20:",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: student.i20,
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    let country = Countries.find(
      (x) => x.value.toLowerCase() === student.nationalityId.toLowerCase()
    );

    let thirdRow = [
      {
        content: "City/State/Zip: ",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content:
          student.address.city +
          ", " +
          student.address.state +
          " " +
          student.address.zip,
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "Nationality: ",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: country.label,
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "Status: ",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: student.status,
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    studentDetails.push(dateIssuedRow);
    studentDetails.push(row0);
    studentDetails.push(firstRow);
    studentDetails.push(secondRow);
    studentDetails.push(thirdRow);

    doc.autoTable({
      body: studentDetails,
      didDrawPage: function (data) {},
      margin: {
        top: 27,
      },
      columnStyles: {
        0: { cellWidth: "auto" },
        1: { cellWidth: "auto" },
        2: { cellWidth: "auto" },
        3: { cellWidth: "auto" },
        4: { cellWidth: "auto" },
        5: { cellWidth: "auto" },
      },
      styles: { fontSize: 9 },
    });

    let startY = doc.autoTable.previous.finalY + 5;

    let body = [];
    body.push(rowLevel);
    body.push(rowCefr);
    body.push(rowClass);
    body.push(rowAttendance);

    student.eslData.map((studentClass, index) => body.push(studentClass));

    doc.autoTable({
      startY: startY,
      head: header,
      body: body,
      styles: { lineWidth: 0.2, fontSize: 9 },
      headStyles: {
        fillColor: [244, 130, 51],
      },
      didDrawPage: function (data) {
        doc.setTextColor(20);
        doc.setFontStyle("normal");

        if (img_logo) {
          doc.addImage(
            img_logo,
            "JPEG",
            data.settings.margin.left - 1.5,
            10,
            39,
            15
          );
        }

        if (img_address) {
          doc.addImage(
            img_address,
            "JPEG",
            pageWidth - data.settings.margin.left - 28,
            10,
            30,
            15
          );
        }
      },
    });

    startY = doc.autoTable.previous.finalY + 5;

    let bodyToefl = [];
    bodyToefl.push(rowLevelToefl);
    bodyToefl.push(rowClassToefl);
    bodyToefl.push(rowAttendanceToefl);

    student.toeflData.map((studentClass, index) =>
      bodyToefl.push(studentClass)
    );

    doc.autoTable({
      startY: startY,
      head: headerToefl,
      body: bodyToefl,
      styles: { lineWidth: 0.2, fontSize: 9 },
      headStyles: {
        fillColor: [244, 130, 51],
      },
      tableWidth: 80,
      didDrawPage: function (data) {
        doc.setTextColor(40);
        doc.setFontStyle("normal");
        doc.setFontSize(10);

        // Footer
        var strSign =
          "CERTIFIED BY: _____________________________  SIGNATURE: _____________________________  DATE: ___/___/______";

        var strAccredited =
          "Accredited by CEA - The Commission on English Language Program Accreditation";

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();

        doc.text(
          strSign,
          doc.internal.pageSize.getWidth() / 2,
          pageHeight - 17,
          "center"
        );
        doc.text(
          strAccredited,
          doc.internal.pageSize.getWidth() / 2,
          pageHeight - 10,
          "center"
        );
      },
    });

    let headerNotes = [
      [
        {
          content: "Notes",
          styles: { halign: "center", fillColor: [244, 130, 51] },
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      ],
    ];

    let bodyNotes = [];

    bodyNotes.push([
      {
        content: note,
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ]);

    doc.autoTable({
      startY: startY,
      head: headerNotes,
      margin: { left: 98 },
      body: bodyNotes,
      styles: { lineWidth: 0.2, fontSize: 9 },
      headStyles: {
        fillColor: [244, 130, 51],
      },
      tableWidth: 113.5,
    });

    let headerCaption = [
      [
        {
          colSpan: 4,
          content: "Legend",
          styles: { halign: "center", fillColor: [244, 130, 51], fontSize: 9 },
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      ],
    ];

    let bodyCaption = [];

    let bodyCaption1 = [
      {
        content: "TO",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "Tested-out",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "NC",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "Not Completed",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    let bodyCaption2 = [
      {
        content: "A",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "100-94",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "A-",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "93-90",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    let bodyCaption3 = [
      {
        content: "B+",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "89-87",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "B",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "86-83",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    let bodyCaption4 = [
      {
        content: "B-",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "82-80",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "C+",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "79-77",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    let bodyCaption5 = [
      {
        content: "C",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "76-73",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "C-",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "72-70",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    let bodyCaption6 = [
      {
        content: "D+",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "69-67",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "D",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "66-63",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    let bodyCaption7 = [
      {
        content: "D-",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "62-60",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "F",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "59-bellow",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    let bodyCaption8 = [
      {
        content: "P",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "Pass",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "CE",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
      {
        content: "Current Enrolled",
        styles: {
          valign: "middle",
          halign: "left",
          cellPadding: { top: 1, right: 1, bottom: 0, left: 1 },
        },
      },
    ];

    bodyCaption.push(bodyCaption1);
    bodyCaption.push(bodyCaption2);
    bodyCaption.push(bodyCaption3);
    bodyCaption.push(bodyCaption4);
    bodyCaption.push(bodyCaption5);
    bodyCaption.push(bodyCaption6);
    bodyCaption.push(bodyCaption7);
    bodyCaption.push(bodyCaption8);

    doc.autoTable({
      startY: startY,
      head: headerCaption,
      margin: { left: 215.3 },
      body: bodyCaption,
      styles: { lineWidth: 0.2, fontSize: 8 },
      headStyles: {
        fillColor: [244, 130, 51],
      },
      tableWidth: 50,
    });

    doc.save("Complete Record - " + student.name);
  });
}

export function CompleteRecordPdf(studentId, note, semesters, percent) {
  Execute(studentId, note, semesters, percent);
}
