/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  Label,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  FormGroup,
  CardFooter,
  Button,
} from "reactstrap";
import axios from "axios";
import * as dateFns from "date-fns";
import DatePicker from "react-datepicker";
import { SavePdf } from "components/Report/GeneratePdf.js";

class TeachingStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      teachers: [],
      StartDateSearch: this.getStartDateCurrentSemester(),
      EndDateSearch: new Date(),
    };
  };

  getStartDateCurrentSemester = () => {
    let StartDateSearch = dateFns.startOfWeek(new Date());

    let semesterId = JSON.parse(localStorage.getItem("semesterId"));
    if (semesterId) {
      StartDateSearch = dateFns.parseISO(
        this.props.tables.semesters.find((x) => x.id === semesterId).startDate
      );
    }

    return StartDateSearch;
  };

  render() {
    let teachers = [];

    this.state.teachers.map((teacher, index) => {
      teachers.push(
        <tr key={index}>
          <td>{teacher.name}</td>
          <td>{""}</td>
          <td>{""}</td>
          <td>{""}</td>
          <td>{""}</td>
          <td>{""}</td>
          <td>{""}</td>
        </tr>
      );

      teacher.classes.map((classe, i) => {
        teachers.push(
          <tr key={index}>
            <td>{""}</td>
            <td>{classe.code}</td>
            <td style={{ textAlign: "center" }}>{classe.totalStudents}</td>
            <td style={{ textAlign: "center" }}>
              {classe.totalAssignmentPendencies}
            </td>
            <td style={{ textAlign: "center" }}>
              {classe.totalAttendancePendencies}
            </td>
            <td style={{ textAlign: "center" }}>{classe.totalLessonPlan}</td>
            <td style={{ textAlign: "center" }}>{classe.totalDaysOfClass}</td>
          </tr>
        );
      });
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.EndDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "EndDateSearch"
                        )}
                        name="EndDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>&nbsp;</Label>

                      <Button
                        style={{ marginLeft: "5px", marginTop: "0" }}
                        color="primary"
                        onClick={this._refreshTeachers.bind(this)}
                      >
                        Search
                      </Button>
                    </FormGroup>
                  </Row>
                </CardHeader>

                {teachers.length > 0 && (
                  <React.Fragment>
                    <CardBody>
                      <Table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Subject</th>
                            <th style={{ textAlign: "center" }}>
                              Total Students
                            </th>
                            <th style={{ textAlign: "center" }}>
                              Grades Pending
                            </th>
                            <th style={{ textAlign: "center" }}>
                              Attendance Pending
                            </th>
                            <th style={{ textAlign: "center" }}>
                              Submitted Lesson Plans
                            </th>
                            <th style={{ textAlign: "center" }}>
                              Total Class Days
                            </th>
                          </tr>
                        </thead>

                        <tbody>{teachers}</tbody>
                      </Table>
                    </CardBody>

                    <CardFooter>
                      <Row form style={{ display: "block" }}>
                        <Row>
                          <Button
                            color="success"
                            size="sm"
                            style={{
                              marginLeft: "auto",
                              textTransform: "inherit",
                              marginRight: "30px",
                              marginBottom: "10px",
                            }}
                            onClick={this.DownloadPdf.bind(this)}
                          >
                            Export to PDF
                          </Button>
                        </Row>
                      </Row>
                    </CardFooter>
                  </React.Fragment>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    // this._refreshTeachers();
  }

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;

    mainState[name] = date;

    this.setState(mainState);
  };

  _refreshTeachers() {
    return new Promise((resolve, reject) => {
      let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
      let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

      axios
        .get(
          "teachers/teacherstatus?" +
            "startDate=" +
            startDate +
            "&endDate=" +
            endDate
        )
        .then((response) => {
          let mainState = this.state;

          mainState.teachers = [];
          response.data.data.map((teacher, index) =>
            mainState.teachers.push({
              id: teacher.id,
              name: teacher.name,
              classes: teacher.classes,
            })
          );

          this.setState(mainState, resolve(true));
        });
    });
  }

  DownloadPdf() {
    let teachers = [];
    this.state.teachers.map((teacher, index) => {
      teachers.push({
        name: teacher.name,
        code: "",
        totalStudents: "",
        totalAssignmentPendencies: "",
        totalAttendancePendencies: "",
        totalLessonPlan: "",
        totalDaysOfClass: "",
      });

      teacher.classes.map((classe, index) =>
        teachers.push({
          name: "",
          code: classe.code,
          totalStudents: classe.totalStudents,
          totalAssignmentPendencies: classe.totalAssignmentPendencies,
          totalAttendancePendencies: classe.totalAttendancePendencies,
          totalLessonPlan: classe.totalLessonPlan,
          totalDaysOfClass: classe.totalDaysOfClass,
        })
      );
    });

    SavePdf(
      "Teaching Status " +
        dateFns.format(this.state.StartDateSearch, "MM/dd/yyyy") +
        " - " +
        dateFns.format(this.state.EndDateSearch, "MM/dd/yyyy"),
      teachers,
      [
        {
          name: "Name",
          code: "Subject",
          totalStudents: "Total Students",
          totalAssignmentPendencies: "Grades Pending",
          totalAttendancePendencies: "Attendance Pending",
          totalLessonPlan: "Submitted Lesson Plans",
          totalDaysOfClass: "Total Class Days",
        },
      ]
    );
  }
}

export default TeachingStatus;
