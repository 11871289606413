/* eslint-disable array-callback-return */
import React from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  ListGroup,
  CustomInput,
  ListGroupItem,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import Autosuggest from "react-autosuggest";
import { editMode } from "helpers/Utils.js";
const daysString = "0123456";

class ClasseEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();

    this.timeout = 0;
  }

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
    };

    let daysDb = [];
    if (this.state.Classe.Days.Value)
      daysDb = this.state.Classe.Days.Value.split("");

    let daysOfWeek = daysString.split("").map((day) => {
      return (
        <CustomInput
          key={day}
          type="checkbox"
          id={day}
          inline
          name={day}
          label={this.getDayName(day)}
          checked={daysDb.includes(day)}
          onChange={this.onSwitchDaysChange}
        />
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggleClasseModal.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                onClosed={this.onCloseModal}
                onOpened={this.onOpened.bind(this)}
              >
                <ModalHeader toggle={this.props.toggleClasseModal.bind(this)}>
                  {this.props.editMode === editMode.INSERT
                    ? "Add a new subject"
                    : "Edit a subject"}
                </ModalHeader>
                <ModalBody>
                  <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="classeCode">Code</Label>
                        <Input
                          className={`form-group ${this.errorClass(
                            this.state.Classe.Code.ErrorMsg
                          )}`}
                          type="text"
                          name="Code"
                          id="classeCode"
                          autoComplete="nope"
                          value={this.state.Classe.Code.Value}
                          onChange={this.handleUserInput}
                        />
                        <span className="text-danger">
                          {this.state.Classe.Code.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="classeName">Name</Label>
                        <Input
                          className={`form-group ${this.errorClass(
                            this.state.Classe.Name.ErrorMsg
                          )}`}
                          type="text"
                          name="Name"
                          id="classeName"
                          autoComplete="nope"
                          value={this.state.Classe.Name.Value}
                          onChange={this.handleUserInput}
                        />
                        <span className="text-danger">
                          {this.state.Classe.Name.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="classeCampus">Campus</Label>
                        <Select
                          className={`form-group ${this.errorClass(
                            this.state.Classe.CampusId.ErrorMsg
                          )}`}
                          value={
                            this.state.Classe.CampusId.Value &&
                            this.state.campuses.find(
                              (x) =>
                                x.value === this.state.Classe.CampusId.Value
                            )
                          }
                          onChange={this.handleCampusChange}
                          options={this.state.campuses}
                        />
                        <span className="text-danger">
                          {this.state.Classe.CampusId.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="classTeacher">Teacher</Label>
                        <Autosuggest
                          className={`form-group ${this.errorClass(
                            this.state.Classe.TeacherId.ErrorMsg
                          )}`}
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequested
                          }
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          placeholder="Enter teacher name"
                          inputProps={inputProps}
                          onSuggestionSelected={this.onSuggestionSelected}
                        />
                        <span className="text-danger">
                          {this.state.Classe.TeacherId.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="classeSemester">Semester</Label>
                        <Select
                          className={`form-group ${this.errorClass(
                            this.state.Classe.SemesterId.ErrorMsg
                          )}`}
                          value={
                            this.state.Classe.SemesterId.Value &&
                            this.state.semesters.find(
                              (x) =>
                                x.value === this.state.Classe.SemesterId.Value
                            )
                          }
                          onChange={this.handleSemesterChange}
                          options={this.state.semesters}
                        />
                        <span className="text-danger">
                          {this.state.Classe.SemesterId.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="classeCourse">Level</Label>
                        <Select
                          className={`form-group ${this.errorClass(
                            this.state.Classe.CourseId.ErrorMsg
                          )}`}
                          value={
                            this.state.Classe.CourseId.Value &&
                            this.state.courses.find(
                              (x) =>
                                x.value === this.state.Classe.CourseId.Value
                            )
                          }
                          onChange={this.handleCourseChange}
                          options={this.state.courses}
                        />
                        <span className="text-danger">
                          {this.state.Classe.CourseId.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="classePeriod">Period</Label>
                        <Select
                          className={`form-group ${this.errorClass(
                            this.state.Classe.Period.ErrorMsg
                          )}`}
                          value={
                            this.state.Classe.Period.Value &&
                            this.periodOptions.find(
                              (x) => x.value === this.state.Classe.Period.Value
                            )
                          }
                          onChange={this.handlePeriodChange}
                          options={this.periodOptions}
                        />
                        <span className="text-danger">
                          {this.state.Classe.Period.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="classeBlock">Block</Label>
                        <Select
                          className={`form-group ${this.errorClass(
                            this.state.Classe.Block.ErrorMsg
                          )}`}
                          value={
                            this.state.Classe.Block.Value &&
                            this.blockOptions.find(
                              (x) => x.value === this.state.Classe.Block.Value
                            )
                          }
                          onChange={this.handleBlockChange}
                          options={this.blockOptions}
                        />
                        <span className="text-danger">
                          {this.state.Classe.Block.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="classroomTotalSeats">Total Points</Label>
                        <Input
                          className={`form-group ${this.errorClass(
                            this.state.Classe.TotalPoints.ErrorMsg
                          )}`}
                          type="number"
                          name="TotalPoints"
                          id="classroomTotalPoints"
                          autoComplete="nope"
                          value={this.state.Classe.TotalPoints.Value}
                          onChange={this.handleUserInput}
                        />
                        <span className="text-danger">
                          {this.state.Classe.TotalPoints.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="classroomTotalSeats">Total Seats</Label>
                        <Input
                          className={`form-group ${this.errorClass(
                            this.state.Classe.TotalSeats.ErrorMsg
                          )}`}
                          type="number"
                          name="TotalSeats"
                          id="classroomTotalSeats"
                          autoComplete="nope"
                          value={this.state.Classe.TotalSeats.Value}
                          onChange={this.handleUserInput}
                        />
                        <span className="text-danger">
                          {this.state.Classe.TotalSeats.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <Label for="classeSubjectTime">Time</Label>
                        <Select
                          className={`form-group ${this.errorClass(
                            this.state.Classe.SubjectTimeId.ErrorMsg
                          )}`}
                          value={
                            this.state.Classe.SubjectTimeId.Value &&
                            this.state.subjectTimes.find(
                              (x) =>
                                x.value ===
                                this.state.Classe.SubjectTimeId.Value
                            )
                          }
                          onChange={this.handleSubjectTimeChange}
                          options={this.state.subjectTimes}
                        />
                        <span className="text-danger">
                          {this.state.Classe.SubjectTimeId.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup>
                        <Label for="classeGroupClass">Class</Label>
                        <Select
                          className={`form-group ${this.errorClass(
                            this.state.Classe.GroupClassId.ErrorMsg
                          )}`}
                          value={
                            this.state.Classe.GroupClassId.Value &&
                            this.state.groupClasses.find(
                              (x) =>
                                x.value === this.state.Classe.GroupClassId.Value
                            )
                          }
                          onChange={this.handleGroupClassChange}
                          options={this.state.groupClasses}
                        />
                        <span className="text-danger">
                          {this.state.Classe.GroupClassId.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="userRole">Days</Label>
                        <ListGroup>
                          <ListGroupItem>{daysOfWeek}</ListGroupItem>
                        </ListGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={
                      this.props.editMode === editMode.INSERT
                        ? this.addClasse.bind(this)
                        : this.updateClasse.bind(this)
                    }
                    disabled={!this.state.formValid}
                  >
                    {this.props.editMode === editMode.INSERT ? "Add" : "Update"}
                  </Button>{" "}
                  <Button
                    color="secondary"
                    onClick={this.props.toggleClasseModal.bind(this)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  onChange = (event, { newValue }) => {
    let modalState = this.state;

    modalState.value = newValue;
    modalState.Classe.TeacherId.Value = "";

    this.setState(modalState);
  };

  getDayName(initial) {
    switch (initial) {
      case "0":
        return "Sun";
      case "1":
        return "Mon";
      case "2":
        return "Tue";
      case "3":
        return "Wed";
      case "4":
        return "Thu";
      case "5":
        return "Fri";
      case "6":
        return "Sat";
      default:
        break;
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      let modalState = this.state;

      axios
        .get(`teachers/byname/?name=${value}&page=1&pageSize=999999999`)
        .then((response) => {
          modalState.suggestions = [];
          response.data.data.items.map((teacher, index) =>
            modalState.suggestions.push({
              id: teacher.id,
              name: teacher.name,
            })
          );

          this.setState(modalState);
        });
    }, 500);
  };

  onSuggestionsClearRequested = () => {
    let modalState = this.state;
    modalState.suggestions = [];

    this.setState(modalState);
  };

  onSuggestionSelected = (event, { suggestion }) => {
    let modalState = this.state;
    modalState.Classe.TeacherId.Value = suggestion.id;

    this.setState(modalState, () => {
      this.validateField("TeacherId", suggestion.id);
    });
  };

  onBlur = (event) => {
    let modalState = this.state;
    let value = modalState.Classe.TeacherId.Value;

    this.validateField("TeacherId", value);
  };

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getClasse = () => {
    const classe = {
      Id: this.getEntity(),
      Name: this.getEntity(),
      Code: this.getEntity(),
      CampusId: this.getEntity(),
      SemesterId: this.getEntity(),
      CourseId: this.getEntity(),
      TeacherId: this.getEntity(),
      SubjectTimeId: this.getEntity(),
      GroupClassId: this.getEntity(),
      Period: this.getEntity(),
      Block: this.getEntity(),
      Days: this.getEntity(),
      TotalPoints: this.getEntity(),
      TotalSeats: this.getEntity(),
    };
    return classe;
  };

  getModalState = () => {
    return {
      Classe: this.getClasse(),
      cards: [1, 2],
      alert: { visible: false, color: "", message: "" },
      formValid: false,
      value: "",
      campuses: [],
      semesters: [],
      courses: [],
      suggestions: [],
      subjectTimes: [],
      groupClasses: [],
    };
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  onSwitchDaysChange = (e) => {
    let modalState = this.state;

    let daysInState = [];

    if (modalState.Classe.Days.Value)
      daysInState = modalState.Classe.Days.Value.split("");

    let daysToRecord = "";
    let daysString2 = "0123456";

    daysString2.split("").map((day) => {
      if (daysInState.includes(day) || e.target.name === day) {
        if (daysInState.includes(day) && e.target.name !== day) {
          daysToRecord += day;
        } else if (!daysInState.includes(day) && e.target.name === day) {
          daysToRecord += day;
        }
      } else if (!daysInState.includes(day) && e.target.name === day) {
        daysToRecord += day;
      }
    });

    modalState.Classe.Days.Value = daysToRecord;

    this.setState(modalState);
  };

  validateField(fieldName, value) {
    let modalState = this.state;

    switch (fieldName) {
      case "Code":
        modalState.Classe.Code.IsValid = value.length >= 1;
        modalState.Classe.Code.ErrorMsg = modalState.Classe.Code.IsValid
          ? ""
          : "Code is empty";
        break;

      case "Name":
        modalState.Classe.Name.IsValid = value.length >= 1;
        modalState.Classe.Name.ErrorMsg = modalState.Classe.Name.IsValid
          ? ""
          : "Name is empty";
        break;

      case "CampusId":
        modalState.Classe.CampusId.IsValid = value.length >= 1;
        modalState.Classe.CampusId.ErrorMsg = modalState.Classe.CampusId.IsValid
          ? ""
          : "CampusId is empty";
        break;

      case "SemesterId":
        modalState.Classe.SemesterId.IsValid = value.length >= 1;
        modalState.Classe.SemesterId.ErrorMsg = modalState.Classe.SemesterId
          .IsValid
          ? ""
          : "SemesterId is empty";
        break;

      case "CourseId":
        modalState.Classe.CourseId.IsValid = value.length >= 1;
        modalState.Classe.CourseId.ErrorMsg = modalState.Classe.CourseId.IsValid
          ? ""
          : "LevelId is empty";
        break;

      case "TeacherId":
        modalState.Classe.TeacherId.IsValid = value.length >= 1;
        modalState.Classe.TeacherId.ErrorMsg = modalState.Classe.TeacherId
          .IsValid
          ? ""
          : "TeacherId is empty";
        break;

      case "Period":
        modalState.Classe.Period.IsValid = value.length >= 1;
        modalState.Classe.Period.ErrorMsg = modalState.Classe.Period.IsValid
          ? ""
          : "Period is empty";
        break;

      case "Block":
        modalState.Classe.Block.IsValid = value.length >= 1;
        modalState.Classe.Block.ErrorMsg = modalState.Classe.Block.IsValid
          ? ""
          : "Block is empty";
        break;

      case "TotalPoints":
        modalState.Classe.TotalPoints.IsValid = value.length >= 1;
        modalState.Classe.TotalPoints.ErrorMsg = modalState.Classe.TotalPoints
          .IsValid
          ? ""
          : "TotalPoints is empty";
        break;

      case "TotalSeats":
        modalState.Classe.TotalSeats.IsValid = value.length >= 1;
        modalState.Classe.TotalSeats.ErrorMsg = modalState.Classe.TotalSeats
          .IsValid
          ? ""
          : "TotalSeats is empty";
        break;

      case "SubjectTimeId":
        modalState.Classe.SubjectTimeId.IsValid = value.length >= 1;
        modalState.Classe.SubjectTimeId.ErrorMsg = modalState.Classe
          .SubjectTimeId.IsValid
          ? ""
          : "SubjectTimeId is empty";
        break;

      case "GroupClassId":
        modalState.Classe.GroupClassId.IsValid = value.length >= 1;
        modalState.Classe.GroupClassId.ErrorMsg = modalState.Classe.GroupClassId
          .IsValid
          ? ""
          : "GroupClassId is empty";
        break;

      default:
        break;
    }

    this.setState(modalState, this.validateForm());
  }

  validateForm() {
    let modalState = this.state;

    modalState.formValid =
      modalState.Classe.Code.IsValid === true &&
      modalState.Classe.Name.IsValid === true &&
      modalState.Classe.CampusId.IsValid === true &&
      modalState.Classe.SemesterId.IsValid === true &&
      modalState.Classe.CourseId.IsValid === true &&
      modalState.Classe.Period.IsValid === true &&
      modalState.Classe.Block.IsValid === true &&
      modalState.Classe.TotalPoints.IsValid === true &&
      modalState.Classe.TotalSeats.IsValid === true &&
      modalState.Classe.SubjectTimeId.IsValid === true &&
      modalState.Classe.GroupClassId.IsValid === true &&
      modalState.Classe.TeacherId.IsValid === true;

    this.setState(modalState);
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState.Classe[name].Value = value;

    this.setState(modalState, () => {
      this.validateField(name, value);
    });
  };

  onOpened() {
    this.openModalClasse();
  }

  addClasse() {
    let modalState = this.state;

    let classe = {
      Code: modalState.Classe.Code.Value,
      Name: modalState.Classe.Name.Value,
      Period: modalState.Classe.Period.Value,
      Block: modalState.Classe.Block.Value,
      Days: modalState.Classe.Days.Value,
      TotalPoints: modalState.Classe.TotalPoints.Value,
      TotalSeats: modalState.Classe.TotalSeats.Value,
      Campus: { Id: modalState.Classe.CampusId.Value },
      Semester: { Id: modalState.Classe.SemesterId.Value },
      Course: { Id: modalState.Classe.CourseId.Value },
      Teacher: { Id: modalState.Classe.TeacherId.Value },
      SubjectTime: { Id: modalState.Classe.SubjectTimeId.Value },
      GroupClass: { Id: modalState.Classe.GroupClassId.Value },
    };

    axios.post("classes", classe).then((response) => {
      this.props.toggleClasseModal().then(() => {
        this.resetModal().then(() => {
          if (
            this.props.callback &&
            typeof this.props.callback === "function"
          ) {
            this.props.callback();
          }
        });
      });
    });
  }

  updateClasse() {
    let modalState = this.state;

    let classe = {
      Id: modalState.Classe.Id.Value,
      Code: modalState.Classe.Code.Value,
      Name: modalState.Classe.Name.Value,
      Period: modalState.Classe.Period.Value,
      Block: modalState.Classe.Block.Value,
      Days: modalState.Classe.Days.Value,
      TotalPoints: modalState.Classe.TotalPoints.Value,
      TotalSeats: modalState.Classe.TotalSeats.Value,
      Campus: { Id: modalState.Classe.CampusId.Value },
      Semester: { Id: modalState.Classe.SemesterId.Value },
      Course: { Id: modalState.Classe.CourseId.Value },
      Teacher: { Id: modalState.Classe.TeacherId.Value },
      SubjectTime: { Id: modalState.Classe.SubjectTimeId.Value },
      GroupClass: { Id: modalState.Classe.GroupClassId.Value },
    };

    axios.put("classes", classe).then((response) => {
      this.props.toggleClasseModal().then(() => {
        this.resetModal().then(() => {
          if (
            this.props.callback &&
            typeof this.props.callback === "function"
          ) {
            this.props.callback();
          }
        });
      });
    });
  }

  refreshCampuses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.campuses.map((campus, index) =>
        modalState.campuses.push({
          value: campus.id,
          label: campus.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshSubjectTimes = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.subjectTimes.map((st, index) =>
        modalState.subjectTimes.push({
          value: st.id,
          label: st.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshGroupClasses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.groupClasses.map((gc, index) =>
        modalState.groupClasses.push({
          value: gc.id,
          label: gc.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshSemesters = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.semesters.map((semester, index) =>
        modalState.semesters.push({
          value: semester.id,
          label: semester.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.courses.map((course, index) =>
        modalState.courses.push({
          value: course.id,
          label: course.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  // copyPoints = () => {
  //   if (this.state.Classe.Code) {
  //     axios
  //       .get("classes/copyPoints?code=" + this.state.Classe.Code.Value)
  //       .then((response) => {
  //         let mainState = this.state;

  //         mainState.Classe.TotalPoints.Value = response.data.data;

  //         this.setState(
  //           mainState,
  //           this.validateField("TotalPoints", response.data.data)
  //         );
  //       });
  //   }
  // };

  openModalClasse() {
    let modalState = this.state;

    this.refreshCampuses().then(() => {
      this.refreshSubjectTimes().then(() => {
        this.refreshGroupClasses().then(() => {
          this.refreshSemesters().then(() => {
            this.refreshCourses().then(() => {
              if (this.props.editMode === editMode.INSERT) {
                this.setState(modalState);
              } else {
                axios
                  .get(
                    "classes/byid?id=" + this.props.id + "&fillStudents=false"
                  )
                  .then((response) => {
                    modalState.Classe.Id.Value = response.data.data.id;

                    modalState.Classe.Code.Value = response.data.data.code;
                    modalState.Classe.Code.IsValid = true;

                    modalState.Classe.Name.Value = response.data.data.name;
                    modalState.Classe.Name.IsValid = true;

                    modalState.Classe.CampusId.Value =
                      response.data.data.campus.id;
                    modalState.Classe.CampusId.IsValid = true;

                    modalState.Classe.SemesterId.Value =
                      response.data.data.semester.id;
                    modalState.Classe.SemesterId.IsValid = true;

                    modalState.Classe.CourseId.Value =
                      response.data.data.course.id;
                    modalState.Classe.CourseId.IsValid = true;

                    if (response.data.data.teacher.id) {
                      modalState.Classe.TeacherId.Value =
                        response.data.data.teacher.id;
                      modalState.Classe.TeacherId.IsValid = true;
                    }

                    if (response.data.data.teacher.name) {
                      modalState.value = response.data.data.teacher.name;
                    }

                    modalState.Classe.Period.Value = response.data.data.period;
                    modalState.Classe.Period.IsValid = true;

                    modalState.Classe.Block.Value = response.data.data.block;
                    modalState.Classe.Block.IsValid = true;

                    modalState.Classe.Days.Value = response.data.data.days;
                    modalState.Classe.Days.IsValid = true;

                    modalState.Classe.TotalPoints.Value =
                      response.data.data.totalPoints;
                    modalState.Classe.TotalPoints.IsValid = true;

                    modalState.Classe.TotalSeats.Value =
                      response.data.data.totalSeats;
                    modalState.Classe.TotalSeats.IsValid = true;

                    if (response.data.data.subjectTime) {
                      modalState.Classe.SubjectTimeId.Value =
                        response.data.data.subjectTime.id;
                      modalState.Classe.SubjectTimeId.IsValid = true;
                    }

                    if (response.data.data.groupClass) {
                      modalState.Classe.GroupClassId.Value =
                        response.data.data.groupClass.id;
                      modalState.Classe.GroupClassId.IsValid = true;
                    }

                    this.setState(modalState);
                    this.validateForm();
                  });
              }
            });
          });
        });
      });
    });
  }

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  getSuggestionValue(suggestion) {
    return suggestion.name;
  }

  renderSuggestion(suggestion) {
    return <span>{suggestion.name}</span>;
  }

  handleCampusChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Classe.CampusId.Value = selectedOption.value;

    this.setState(modalState, () => {
      this.validateField("CampusId", selectedOption.value);
    });
  };

  handleSubjectTimeChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Classe.SubjectTimeId.Value = selectedOption.value;

    this.setState(modalState, () => {
      this.validateField("SubjectTimeId", selectedOption.value);
    });
  };

  handleGroupClassChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Classe.GroupClassId.Value = selectedOption.value;

    this.setState(modalState, () => {
      this.validateField("GroupClassId", selectedOption.value);
    });
  };

  handleSemesterChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Classe.SemesterId.Value = selectedOption.value;

    this.setState(modalState, () => {
      this.validateField("SemesterId", selectedOption.value);
    });
  };

  handleCourseChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Classe.CourseId.Value = selectedOption.value;

    this.setState(modalState, () => {
      this.validateField("CourseId", selectedOption.value);
    });
  };

  handlePeriodChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Classe.Period.Value = selectedOption.value;

    this.setState(modalState, () => {
      this.validateField("Period", selectedOption.value);
    });
  };

  handleBlockChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Classe.Block.Value = selectedOption.value;

    this.setState(modalState, () => {
      this.validateField("Block", selectedOption.value);
    });
  };

  periodOptions = [
    { value: "M", label: "Morning" },
    { value: "A", label: "Afternoon" },
    { value: "E", label: "Evening" },
    { value: "MT", label: "Mon/Tue" },
    { value: "WT", label: "Wed/Thu" },
  ];

  blockOptions = [
    { value: "1", label: "First" },
    { value: "2", label: "Second" },
  ];
}

export default ClasseEdit;
