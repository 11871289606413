/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import "assets/css/Login.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Label,
  Col,
  FormGroup,
} from "reactstrap";
import SignaturePad from "react-signature-canvas";
import axios from "axios";
import confirm from "reactstrap-confirm";

class Signature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      token: "",
      isOpen: false,
      trimmedDataURL: null,
      trimmedDataURLInitials: null,
      description: "",
    };
  }

  sigPad = {};
  initialsPad = {};

  handleSubmit(e) {
    e.preventDefault();

    return new Promise((resolve, reject) => {
      axios
        .get("users/ValidateSignatureToken?token=" + this.state.token)
        .then((response) => {
          if (response.data.data === false) {
            this.setState({
              error: "The given token is expired or not found",
            });
          }

          this.setState({ isOpen: response.data.data }, resolve(true));
        });
    });
  }

  toggleModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          isOpen: !this.state.isOpen,
        },
        resolve(true)
      );
    });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, error: "" });
  }

  clear() {
    this.setState({ trimmedDataURL: null }, () => this.sigPad.clear());
  }

  clearInitials() {
    this.setState({ trimmedDataURLInitials: null }, () =>
      this.initialsPad.clear()
    );
  }

  async closeModal() {
    let result = await confirm({
      title: <>Warning</>,
      message: "Do you want to cancel this operation?",
    });

    if (result) {
      this.toggleModal();
    }
  }

  trim = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          trimmedDataURL: this.sigPad.getCanvas().toDataURL("image/png"),
        },
        resolve(true)
      );
    });
  };

  trimInitials = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          trimmedDataURLInitials: this.initialsPad
            .getCanvas()
            .toDataURL("image/png"),
        },
        resolve(true)
      );
    });
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { error: "", token: "", isOpen: false, trimmedDataURL: null },
        resolve(true)
      );
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  async saveSignature() {
    await this.trim().then(() => {
      let signature = {
        Token: this.state.token,
        Signature: this.state.trimmedDataURL,
        Initials: this.state.trimmedDataURLInitials,
      };

      axios.post("users/SaveSignaturePicture", signature).then((response) => {
        this.toggleModal().then(() => {
          this.resetModal().then(() => {
            if (
              this.props.callback &&
              typeof this.props.callback === "function"
            ) {
              this.props.callback();
            }
          });
        });
      });
    });
  }

  validForm() {
    return (
      this.state.trimmedDataURL !== null &&
      this.state.trimmedDataURLInitials !== null
    );
  }

  onOpened() {
    return new Promise((resolve, reject) => {
      axios
        .get("users/getTokenDetails?token=" + this.state.token)
        .then((response) => {
          this.setState({ description: response.data.data }, resolve(true));
        });
    });
  }

  render() {
    const { error } = this.state;

    let description = this.state.description.split("\n").map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });

    return (
      <div className="centerLogin text-center">
        <div className="card">
          <form className="form-signin">
            <h1 className="h3 mb-3 font-weight-normal">
              Please enter the token generated by the LMS.
            </h1>

            <input
              type="text"
              id="token"
              className="form-control"
              placeholder="Token"
              name="token"
              required
              value={this.state.token}
              onChange={this.handleChange.bind(this)}
            />
            <button
              className="btn btn-lg btn-primary btn-block"
              type="button"
              onClick={this.handleSubmit.bind(this)}
              disabled={this.state.token.length === 0}
            >
              Ok
            </button>

            {error && <div className={"loginAlert"}>{error}</div>}
          </form>
        </div>

        <Modal
          isOpen={this.state.isOpen}
          centered={true}
          autoFocus={true}
          size={"lg"}
          backdrop="static"
          scrollable
          onClosed={this.onCloseModal}
          onOpened={this.onOpened.bind(this)}
        >
          <ModalHeader>Registration Financial Contract</ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                    Tuition Payment:
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    Tuition must be paid in full by the tuition payment deadline
                    as agreed in the Student Financial Contract (Form 103). All
                    other fees for application, placement test, and books must
                    be paid prior to classes starting and are non-refundable.
                    Failure to pay the full amount of tuition or arrange other
                    financial support prior to the due date may result in a late
                    flat fee of $50.00.
                  </p>

                  <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                    Tuition Refund:
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    Students requesting a refund must send a written request to
                    the Student Financial Services by email (finance@lumos.edu).
                    The refund will be made to the student within 30 days in the
                    form of a check. The student will be notified by email when
                    the check is ready to be picked up. Student’s failure to
                    notify the appropriate parties in writing of withdrawal may
                    delay the tuition re- fund. The school will consider the
                    Date of Enrollment (DE) whatever comes first of the
                    following: 1) The day the student signs an enrollment
                    agreement; 2) the day the student pays the institution an
                    initial deposit or first payment toward tuition and fees; 3)
                    the day that the student first visits the institution if the
                    program lasts more than 30 consecutive calendar days.
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    The percentage of tuition refund is based on the full amount
                    of the semester tuition, and not based on the amount paid.
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    Refund Schedule based on Date of Enrollment. The student is
                    entitled to refund as follow:
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    • Until 3 Days within Date of Enrollment = 100%
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    • Until 4 Days within Date of Enrollment = 50%
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    • Until 5 Days within Date of Enrollment = 25%
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    • After the 5th day of Date of Enrollment = 0%
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    Requests sent after the 5th day of the DE will not qualify
                    for any refund.
                  </p>

                  <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                    By signing this Financial Agreement, I acknowledge that I
                    have read and understand the applicable fees and fi- nancial
                    responsibilities of Lumos Language School, as stated on the
                    back of this contract, and agree to abide by all policies
                    and pay all fees accordingly. I understand that all
                    registration fees are nonrefundable nor transferable and
                    that the Financial Agreement is a commitment for the period
                    of time contracted.
                  </p>

                  <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                    Amendments to Policies
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    Lumos Language School hereby reserves the right to modify
                    its policies at any given time. Such modifications will be
                    promptly communicated to all enrolled students and will be
                    available in the Student Handbook.
                  </p>

                  <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                    Program/Class Cancellation:
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    If our institution cancels a program after a student’s
                    registration, we will refund all fees paid by the student.
                    The institution holds the right to make class closures if
                    the number of students enrolled in each class does not reach
                    minimum capacity. In such cases, the institution will
                    provide other options when available for students to make
                    changes on their schedule due to class closures.
                  </p>

                  <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                    Attendance:
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    International F-1 students have legal obligations they must
                    fulfill in order to maintain status. The U.S. Government,
                    through federal laws, has determined that a full course of
                    study for an F-1 student consists of at least eighteen clock
                    hours of attendance a week if the dominant part of the
                    course of study consists of classroom instruction. (C.F.R.
                    214.2(f)(6)(i)(F)). Also, the U.S. Government allows each
                    English program to determine its own attendance policy that
                    will ensure that students fulfill their legal obligations
                    and complete a full course of study while enrolled.
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    Lumos Language School policy expects regular and punctual
                    attendance in all courses. Students are required to maintain
                    a minimum of 80% attendance of enrolled programs.
                  </p>

                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginBottom: "7px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            minWidth: "100px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Absences
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            minWidth: "100px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          School Action
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            minWidth: "100px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          School Standing
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            minWidth: "100px",
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Sevis Standing
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          0-15
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          None
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          In Status
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          In Status
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          16-23
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          Warning Email
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          In Status
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          In Status
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          24 and up
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          Termination Letter
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          Terminated
                        </td>
                        <td
                          style={{
                            border: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          Terminated*
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <p style={{ marginTop: "1rem", fontWeight: "bold" }}>
                    Zero Tolerance of Disrespect Towards Faculty, Staff, and
                    Students:
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    Any perspective or enrolled student at Lumos, who shows
                    disrespect to Lumos students, any member of staff or faculty
                    can be subject to disciplinary action such as loss of
                    attendance and/or removal from the pro- gram. Disrespect in
                    this Handbook is defined by (but not excluded to) the
                    following:
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    Constant class disruption, rudeness, unnecessary and
                    excessive argumentation with other students or staff and
                    faculty, harassment, excessive complaining, yelling or
                    threatening speech, physical violence, verbal threats of
                    violence, disregard or refusal of faculty direction, refusal
                    to participate in classroom activities, overall disrespect
                    and disregard for Staff and Faculty efforts and direction.
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    Any student or prospective student may be removed from the
                    program and not be allowed to register and attend the
                    programs offered at Lumos due to violating the
                    Zero-Tolerance of Disrespect policy.
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    Lumos students are required to maintain certain standards of
                    behavior while they attend our program which encourages an
                    atmosphere of learning and mutual respect
                  </p>

                  <p style={{ fontWeight: "bold", textAlign: "justify" }}>
                    I understand and agree with the school policies above
                    described and I have been given ample opportunity to review
                    and understand the terms and conditions of enrollment,
                    including the institution’s refund policy, prior to signing
                    the enrollment documents
                  </p>

                  <p
                    style={{
                      textAlign: "justify",
                      fontWeight: "bold",
                      marginTop: "1rem",
                    }}
                  >
                    Payment Plan
                  </p>

                  {description}

                  <br />
                  <p style={{ textAlign: "justify", fontWeight: "bold" }}>
                    If you choose to make any tuition payments in cash, please
                    go to the school in person and the credit/debit card fee for
                    that payment will be removed. By signing this Financial
                    Agreement, I acknowledge that I have read and understand the
                    applicable fees and financial responsibilities of Lumos
                    Language School, as stated on the back of this contract, and
                    agree to abide by all policies and pay all fees accordingly.
                    I understand that all registration fees are nonrefundable
                    nor transferable and that the Financial Agreement is a
                    commitment for the period of time contracted.
                  </p>
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Label>Student Signature</Label>
                  <div className="sigContainer">
                    <SignaturePad
                      canvasProps={{
                        className: "sigPad",
                      }}
                      ref={(ref) => {
                        this.sigPad = ref;
                      }}
                      onEnd={this.trim.bind(this)}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>Student Initials</Label>
                  <div className="sigContainer">
                    <SignaturePad
                      canvasProps={{
                        className: "sigPad",
                      }}
                      ref={(ref) => {
                        this.initialsPad = ref;
                      }}
                      onEnd={this.trimInitials.bind(this)}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              style={{
                textTransform: "inherit",
                marginRight: "auto",
                marginBottom: "10px",
              }}
              onClick={this.clear.bind(this)}
              disabled={this.state.trimmedDataURL == null}
            >
              Clear signature
            </Button>

            <Button
              color="success"
              style={{
                textTransform: "inherit",
                marginRight: "auto",
                marginBottom: "10px",
              }}
              onClick={this.clearInitials.bind(this)}
              disabled={this.state.trimmedDataURLInitials == null}
            >
              Clear initials
            </Button>
            <Button
              color="primary"
              onClick={this.saveSignature.bind(this)}
              disabled={!this.validForm()}
            >
              I Agree
            </Button>
            <Button color="secondary" onClick={this.closeModal.bind(this)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Signature;
