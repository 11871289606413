/* eslint-disable no-useless-constructor */
/* eslint-disable array-callback-return */
import React from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";

class ApplicationOwnerModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getModalState();
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                onClosed={this.onCloseModal}
                size={"sm"}
                backdrop="static"
                onOpened={this.onOpened.bind(this)}
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  Select the owner
                </ModalHeader>
                <ModalBody>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Select
                          className={`form-group`}
                          value={
                            this.state.nextOwnerId &&
                            this.state.userList.find(
                              (x) => x.value === this.state.nextOwnerId
                            )
                          }
                          onChange={this.handleUserChange}
                          options={this.state.userList}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={this.save.bind(this)}
                    disabled={!this.validateForm()}
                  >
                    Save
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.props.toggle.bind(this)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  refreshUserList = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      axios.get(`users/getAllowedApplicationOwners`).then((response) => {
        modalState.userList = [];

        response.data.data.map((user, index) =>
          modalState.userList.push({
            value: user.id,
            label: user.fullName,
          })
        );

        this.setState(modalState, resolve(true));
      });
    });
  };

  onOpened() {
    this.refreshUserList();
  }

  handleUserChange = (selectedOption) => {
    return new Promise((resolve, reject) => {
      this.setState({ nextOwnerId: selectedOption.value }, resolve(true));
    });
  };

  getModalState = () => {
    return {
      nextOwnerId: "",
      userList: [],
    };
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  validateForm() {
    return this.state.nextOwnerId !== "";
  }

  save() {
    axios
      .get(
        "apply/assignApply?applyId=" +
          this.props.applyId +
          "&ownerId=" +
          this.state.nextOwnerId
      )
      .then((response) => {
        this.props.toggle().then(() => {
          this.resetModal().then(() => {
            if (
              this.props.callback &&
              typeof this.props.callback === "function"
            ) {
              this.props.callback();
            }
          });
        });
      });
  }
}

export default ApplicationOwnerModal;
