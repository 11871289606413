/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Card,
  Label,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import {
  editMode,
  FormatFullDateList,
  FormatMonthYearDateList,
} from "helpers/Utils.js";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";
import Select from "react-select";
import * as dateFns from "date-fns";
import { authenticationService } from "services/Authentication.service.js";
import Can from "../components/Can/Can";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown";
import ColumnChooser from "../components/Report/ColumnChooser.component";
import StudentEnroll from "../components/Student/Student.Enroll";

class Student extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      students: [],
      openModal: false,
      modalStudentEnrollIsOpen: false,
      editMode: "",
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      alert: { visible: false, color: "", message: "" },
      courseId: "",
      classId: "",
      periodId: "all",
      courses: [],
      classes: [],
      statusTypes: [],
      selectedStatusTypes: [],
      DateSearch: dateFns.startOfWeek(new Date()),
      currentUser: null,
      campusId: "",
      campuses: [],
      sevisId: "",
      sevisCampuses: [],
      columnChooserIsOpen: false,
    };
  };

  render() {
    const { currentUser } = this.state;

    let students = this.state.students.map((student) => {
      return (
        <tr key={student.id}>
          <td>
            {" "}
            <a
              onClick={this.openStudentModal.bind(
                this,
                editMode.EDIT,
                student.id
              )}
              href="#"
            >
              {student.name}
            </a>
          </td>

          {/* <td>{student.campusName}</td> */}
          <td>{student.sevisName}</td>
          <td>{student.status}</td>
          <td className="hideColumn">{student.i20}</td>
          <td className="hideColumn">{student.enrollmentDate}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup>
                      <Label>&nbsp;</Label>

                      {currentUser !== null && (
                        <Can
                          feature="Student_Add"
                          yes={() => (
                            <Button
                              style={{ margin: "0" }}
                              color="primary"
                              onClick={this.openStudentModal.bind(
                                this,
                                editMode.INSERT
                              )}
                            >
                              Add student
                            </Button>
                          )}
                        ></Can>
                      )}
                    </FormGroup>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Level</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.courseId &&
                            this.state.courses.find(
                              (x) => x.value === this.state.courseId
                            )
                          }
                          onChange={this.handleCourseChange}
                          options={this.state.courses}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Period</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.periodId &&
                            this.periodOptions.find(
                              (x) => x.value === this.state.periodId
                            )
                          }
                          onChange={this.handlePeriodChange}
                          options={this.periodOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Subject</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.classId &&
                            this.state.classes.find(
                              (x) => x.value === this.state.classId
                            )
                          }
                          onChange={this.handleClassChange}
                          options={this.state.classes}
                        />
                      </FormGroup>
                    </Col>

                    {/* <Col md={2}>
                      <FormGroup>
                        <Label>Status Date</Label>

                        <DatePicker
                          selected={this.state.DateSearch}
                          onChange={this.handleSearchDateInput.bind(
                            this,
                            "StartDateSearch"
                          )}
                          name="StartDateSearch"
                          dateFormat="MM/dd/yyyy"
                        />
                      </FormGroup>
                    </Col> */}
                  </Row>

                  <Row form>
                    <Col md={3}>
                      <Label>Status</Label>
                      <Multiselect
                        options={this.state.statusTypes}
                        onSelect={this.onSelect.bind(this)}
                        onRemove={this.onRemove.bind(this)}
                        displayValue="value"
                      />
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Campus</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.campusId &&
                            this.state.campuses.find(
                              (x) => x.value === this.state.campusId
                            )
                          }
                          onChange={this.handleCampusChange}
                          options={this.state.campuses}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">SEVIS Origin</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.sevisId &&
                            this.state.sevisCampuses.find(
                              (x) => x.value === this.state.sevisId
                            )
                          }
                          onChange={this.handleSevisChange}
                          options={this.state.sevisCampuses}
                        />
                      </FormGroup>
                    </Col>

                    <FormGroup>
                      <Label>&nbsp;</Label>

                      {currentUser !== null && (
                        <Can
                          feature="Student_Add"
                          yes={() => (
                            <Button
                              style={{ margin: "0" }}
                              color="primary"
                              onClick={this.toggleModalStudentEnroll.bind(this)}
                            >
                              Enroll
                            </Button>
                          )}
                        ></Can>
                      )}
                    </FormGroup>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        {/* <th>Campus</th> */}
                        <th>SEVIS</th>
                        <th>Status</th>
                        <th className="hideColumn">I20</th>
                        <th className="hideColumn">Enrollment Date</th>
                      </tr>
                    </thead>

                    <tbody>{students}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.currentPage}
                    totalItemsCount={this.state.totalResults}
                    onChange={this._refreshStudents.bind(this)}
                    csv={this.columnChooserToggle.bind(this)}
                    buttonName="Report"
                  />

                  <StudentEdit2
                    isOpen={this.state.openModal}
                    toggleStudentModal={this.toggleStudentModal.bind(this)}
                    editMode={this.state.editMode}
                    id={this.state.id}
                    callback={this._refreshStudents.bind(this)}
                    tables={this.props.tables}
                  />

                  <ColumnChooser
                    isOpen={this.state.columnChooserIsOpen}
                    toggle={this.columnChooserToggle.bind(this)}
                    courseId={this.state.courseId}
                    classId={this.state.classId}
                    periodId={this.state.periodId}
                    dateSearch={this.state.DateSearch}
                    campusId={this.state.campusId}
                    sevisId={this.state.sevisId}
                    selectedStatusTypes={this.state.selectedStatusTypes}
                  />

                  <StudentEnroll
                    isOpen={this.state.modalStudentEnrollIsOpen}
                    toggle={this.toggleModalStudentEnroll.bind(this)}
                    courseId={this.state.courseId}
                    classId={this.state.classId}
                    periodId={this.state.periodId}
                    dateSearch={this.state.DateSearch}
                    campusId={this.state.campusId}
                    sevisId={this.state.sevisId}
                    selectedStatusTypes={this.state.selectedStatusTypes}
                    tables={this.props.tables}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.refreshStatusTypes().then(() => {
      this.refreshCourses().then(() => {
        this.refreshCampuses().then(() => {
          this.refreshSevisCampuses().then(() => {
            this._refreshStudents().then(() => {
              this.refreshClasses();
            });
          });
        });
      });
    });

    authenticationService.currentUser.subscribe((x) => {
      this.setState({ currentUser: x });
    });
  }

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.courses = [];

      mainState.courses.push({
        value: "all",
        label: "All levels",
      });

      mainState.courseId = "all";

      this.props.tables.courses.map((course, index) =>
        mainState.courses.push({
          value: course.id,
          label: course.name,
        })
      );

      this.setState(mainState, resolve(true));
    });
  };

  refreshCampuses = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.campuses = [];

      mainState.campuses.push({
        value: "all",
        label: "All campuses",
      });

      mainState.campusId = "all";

      this.props.tables.campuses.map((campus, index) =>
        mainState.campuses.push({
          value: campus.id,
          label: campus.name,
        })
      );

      this.setState(mainState, resolve(true));
    });
  };

  refreshSevisCampuses = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.sevisCampuses = [];

      mainState.sevisCampuses.push({
        value: "all",
        label: "All",
      });

      mainState.sevisId = "all";

      this.props.tables.campuses
        .filter(
          (x) => x.name == "Salt Lake in Person" || x.name == "Orem In Person"
        )
        .map((campus, index) =>
          mainState.sevisCampuses.push({
            value: campus.id,
            label: campus.name
              .replace("Salt Lake in Person", "SLC")
              .replace("Orem In Person", "OREM"),
          })
        );

      this.setState(mainState, resolve(true));
    });
  };

  refreshStatusTypes = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.statusTypes = [];
      this.props.tables.statusTypes.map((statusType, index) =>
        mainState.statusTypes.push({
          id: statusType.id,
          value: statusType.name,
          hasDate: statusType.hasDate,
        })
      );

      this.setState(mainState, resolve(true));
    });
  };

  handleCourseChange = (selectedOption) => {
    let mainState = this.state;

    mainState.courseId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState(mainState);

    this._refreshStudents();
  };

  handleCampusChange = (selectedOption) => {
    this.setState({ campusId: selectedOption.value, currentPage: 1 }, () =>
      this._refreshStudents()
    );
  };

  handleSevisChange = (selectedOption) => {
    this.setState({ sevisId: selectedOption.value, currentPage: 1 }, () =>
      this._refreshStudents()
    );
  };

  handlePeriodChange = (selectedOption) => {
    this.setState({ periodId: selectedOption.value, currentPage: 1 }, () =>
      this._refreshStudents()
    );
  };

  refreshClasses = () => {
    return new Promise((resolve, reject) => {
      let courseId = this.state.courseId;
      let periodId = this.state.periodId;

      if (periodId === "all") periodId = "";

      axios
        .get(
          "classes?courseId=" +
            courseId +
            "&period=" +
            periodId +
            "&page=1&pageSize=999999"
        )
        .then((response) => {
          let mainState = this.state;

          mainState.classes = [];

          mainState.classes.push({
            value: "all",
            label: "All subjects",
          });

          mainState.classId = "all";

          response.data.data.items.map((classe, index) =>
            mainState.classes.push({
              value: classe.id,
              label: classe.code,
            })
          );

          this.setState(mainState, resolve(true));
        });
    });
  };

  handleClassChange = (selectedOption) => {
    let mainState = this.state;

    mainState.classId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState(mainState);

    this._refreshStudents();
  };

  onSelect(selectedList, selectedItem) {
    let mainState = this.state;
    mainState.selectedStatusTypes.push(selectedItem);
    this.setState(mainState);
    this._refreshStudents();
  }

  onRemove(selectedList, removedItem) {
    let mainState = this.state;
    let newSelectedStatusTypes = mainState.selectedStatusTypes.filter(
      (x) => x.id !== removedItem.id
    );
    mainState.selectedStatusTypes = newSelectedStatusTypes;
    this.setState(mainState);
    this._refreshStudents();
  }

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    this.setState({ DateSearch: date, currentPage: 1 }, () => {
      this._refreshStudents();
    });
  };

  toggleStudentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openModal = !mainState.openModal;
      this.setState(mainState, resolve(true));
    });
  };

  toggleModalStudentEnroll = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { modalStudentEnrollIsOpen: !this.state.modalStudentEnrollIsOpen },
        resolve(true)
      );
    });
  };

  columnChooserToggle = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { columnChooserIsOpen: !this.state.columnChooserIsOpen },
        resolve(true)
      );
    });
  };

  openStudentModal = (mode, id) => {
    this.toggleStudentModal();
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.editMode = mode;
      mainState.id = id;
      this.setState(mainState, resolve(true));
    });
  };

  _refreshStudents(pageNumber) {
    return new Promise((resolve, reject) => {
      let page = pageNumber || 1;
      let itemsPerPage = this.state.itemsPerPage;
      let courseId = this.state.courseId;
      let classId = this.state.classId;
      let periodId = this.state.periodId;
      let statusDate = dateFns.format(this.state.DateSearch, "yyyy-MM-dd");
      let campusId = this.state.campusId;
      let sevisId = this.state.sevisId;

      if (periodId === "all") periodId = "";
      if (courseId === "all") courseId = "";
      if (classId === "all") classId = "";
      if (campusId === "all") campusId = "";
      if (sevisId === "all") sevisId = "";

      let statusTypeList = [];
      this.state.selectedStatusTypes.map((statusType, index) =>
        statusTypeList.push({
          Id: statusType.id,
        })
      );

      let getAllParam = {
        courseId: courseId,
        classId: classId,
        period: periodId,
        statusTypeList: statusTypeList,
        statusDate: statusDate,
        campusId: campusId,
        sevisId: sevisId,
        page: page,
        pageSize: itemsPerPage,
      };

      axios.post("students/getall", getAllParam).then((response) => {
        let mainState = this.state;

        mainState.students = [];
        response.data.data.items.map((student, index) =>
          mainState.students.push({
            id: student.id,
            name: student.name,
            nickName: student.nickName,
            birthDate: FormatMonthYearDateList(student.birthDate),
            gender: student.gender,
            nationalityId: student.nationalityId,
            i20: student.i20,
            i20EndDate: FormatFullDateList(student.i20EndDate),
            enrollmentDate: FormatFullDateList(student.enrollmentDate),
            status: student.status,
            campusName: student.campusName,
            sevisName:
              student.sevisName &&
              student.sevisName
                .replace("Salt Lake in Person", "SLC")
                .replace("Orem In Person", "OREM"),
          })
        );

        mainState.currentPage = pageNumber;
        mainState.totalResults = response.data.data.totalCount;

        this.setState(mainState, resolve(true));
      });
    });
  }

  setCurrentPage(pageNumber) {
    let mainState = this.state;
    mainState.currentPage = pageNumber;
    this.setState(mainState);
  }

  periodOptions = [
    { value: "all", label: "All periods" },
    { value: "M", label: "Morning" },
    { value: "A", label: "Afternoon" },
    { value: "E", label: "Evening" },
    { value: "MT", label: "Mon/Tue" },
    { value: "WT", label: "Wed/Thu" },
  ];
}

export default Student;
