/* eslint-disable array-callback-return */
import jsPDF from "jspdf";
import "jspdf-autotable";
import lumos_selo from "lumos_logo.png";
import tabela_sevis from "tabela_sevis.png";
import * as dateFns from "date-fns";
import axios from "axios";

function loadImage(url) {
  return new Promise((resolve) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.src = url;
  });
}

async function Execute(student, options) {
  const img_signature = await loadImage(student.signatureImg);

  const img_initials = await loadImage(student.initialsImg);

  var doc = new jsPDF("p", "mm", "letter");
  doc.setLineHeightFactor(1.15);

  var img_selo = new Image();
  img_selo.src = lumos_selo;

  var img_sevis = new Image();
  img_sevis.src = tabela_sevis;

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.text(
    "Lumos Language School",
    doc.internal.pageSize.getWidth() / 2 + 50,
    12,
    "center"
  );

  doc.text(
    "Student Financial Contract",
    doc.internal.pageSize.getWidth() / 2 + 50,
    20,
    "center"
  );

  doc.text(
    "Semester: " + options.currentSemesterName,
    doc.internal.pageSize.getWidth() / 2 + 50,
    28,
    "center"
  );

  let rowPersonalInformation = [
    {
      colSpan: 3,
      content: "Personal Information",
      styles: {
        valign: "middle",
        halign: "left",
        fontStyle: "bold",
        cellPadding: { top: 1, right: 3, bottom: 3, left: 1 },
        fontSize: "10",
      },
    },
  ];

  let firstRow = [
    {
      content: "Full name: " + student.name,
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "10",
      },
    },
    {
      content:
        "DOB: " +
        dateFns.format(dateFns.parseISO(student.dateOfBirth), "MM/dd/yyyy"),
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "10",
      },
    },
    {
      content: "I-20: " + student.i20,
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "10",
      },
    },
  ];

  let row3 = [
    {
      content: "Address: " + student.street,
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "10",
      },
    },
    {
      content:
        "City/State/Zip: " +
        student.city +
        ", " +
        student.state +
        " " +
        student.zip,
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "10",
      },
    },
  ];

  let row4 = [
    {
      content: "Email: " + student.email,
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "10",
      },
    },
    {
      content: "Cell Number: " + student.cellPhone,
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "10",
      },
    },
    {
      content: "Status: " + student.visaType,
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 0, bottom: 1, left: 1 },
        fontSize: "10",
      },
    },
  ];

  let studentDetails = [];

  studentDetails.push(rowPersonalInformation);
  studentDetails.push(firstRow);
  studentDetails.push(row3);
  studentDetails.push(row4);

  doc.autoTable({
    startY: 35,
    body: studentDetails,
    didDrawPage: function (data) {
      if (img_selo) {
        doc.addImage(
          img_selo,
          "JPEG",
          data.settings.margin.left + 9,
          6,
          65,
          25
        );
      }
      doc.setFontSize(10);

      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

      if (img_signature) {
        doc.addImage(
          img_signature,
          "JPEG",
          data.settings.margin.left,
          pageHeight - 33,
          85,
          17
        );
      }

      doc.text(options.currentUser.fullName, 105, pageHeight - 16, "left");

      doc.text(
        "Student Signature                                                               School Registrar",
        data.settings.margin.left + 1,
        pageHeight - 10,
        "left"
      );

      // doc.text(
      //   "Form 103 - " +
      //     (options.isSelfEnrollment
      //       ? ""
      //       : "Token Authentication: " + student.signatureToken + " - ") +
      //     dateFns.format(new Date(), "MM/dd/yyyy HH:mm:ss") +
      //     " - Copyright Lumos @ 2024 revised on March 2023",
      //   doc.internal.pageSize.getWidth() / 2,
      //   pageHeight - 10,
      //   "center"
      // );
    },
    columnStyles: {
      0: { cellWidth: "wrap" },
      1: { cellWidth: "wrap" },
      2: { cellWidth: "wrap" },
    },
    styles: {
      fontSize: 10,
      cellWidth: "nowrap",
    },
    theme: "plain",
  });

  let row8 = [
    {
      content: "Tuition Information",
      styles: {
        valign: "middle",
        halign: "left",
        fontStyle: "bold",
        cellPadding: { top: 1, right: 3, bottom: 3, left: 1 },
      },
    },
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "left",
        fontStyle: "bold",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
      },
    },
    {
      content: "Payment Plan",
      styles: {
        valign: "middle",
        halign: "left",
        fontStyle: "bold",
        cellPadding: { top: 1, right: 3, bottom: 3, left: 1 },
      },
    },
  ];

  let row_tuition = [
    {
      content: "Tuition:",
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
    {
      content: "$" + options.tuitionCost.toFixed(2),
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
    {
      content: options.isSelfEnrollment
        ? "Initial Payment: $ 0.00"
        : "Initial Payment: $" +
          (options.downPaymentCost + options.downPaymentFee).toFixed(2) +
          " " +
          options.downPaymentType +
          (options.downPaymentType2 &&
          options.downPaymentType2.length > 0 &&
          options.downPaymentType2 !== "N/A"
            ? " $" +
              (options.downPaymentCost2 + options.downPaymentFee2).toFixed(2) +
              " " +
              options.downPaymentType2
            : "") +
          " on " +
          dateFns.format(options.date, "MM/dd/yyyy"),
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
  ];

  let row_extra_fees = [
    {
      content: "Extra Fees:",
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
    {
      content: "$ 0.00",
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
    {
      content:
        "Balance to be paid in " +
        (options.balanceCost > 0 ? options.balanceMonths : "0") +
        (options.balanceCost > 0 ? " payments. " : ""),
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
  ];

  let row_books = [
    {
      content: "Credit Card Processing Fees:",
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
    {
      content: "$" + options.cardFee.toFixed(2),
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
    {
      content: options.firstPaymentDate
        ? "1st Payment " +
          dateFns.format(options.firstPaymentDate, "MM/dd/yyyy") +
          " of $" +
          (options.firstPaymentCost + options.firstPaymentFee).toFixed(2) +
          (" (Tuition: $" +
            options.firstPaymentCost.toFixed(2) +
            " + Credit/Debit Card Fee: $" +
            options.firstPaymentFee.toFixed(2) +
            ")")
        : "1st Payment - ",

      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
  ];

  let row_total = [
    {
      content: "Total:",
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
    {
      content: "$" + options.totalCost.toFixed(2),
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
    {
      content: options.secondPaymentDate
        ? "2nd Payment " +
          dateFns.format(options.secondPaymentDate, "MM/dd/yyyy") +
          " of $" +
          (options.secondPaymentCost + options.secondPaymentFee).toFixed(2) +
          (" (Tuition: $" +
            options.secondPaymentCost.toFixed(2) +
            " + Credit/Debit Card Fee: $" +
            options.secondPaymentFee.toFixed(2) +
            ")")
        : "2nd Payment - ",
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
  ];

  let row_thirdPayment = [
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
    {
      content: options.thirdPaymentDate
        ? "3rd Payment " +
          dateFns.format(options.thirdPaymentDate, "MM/dd/yyyy") +
          " of $" +
          (options.thirdPaymentCost + options.thirdPaymentFee).toFixed(2) +
          (" (Tuition: $" +
            options.thirdPaymentCost.toFixed(2) +
            " + Credit/Debit Card Fee: $" +
            options.thirdPaymentFee.toFixed(2) +
            ")")
        : "3rd Payment - ",
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 1, bottom: 1, left: 1 },
        fontSize: "9",
      },
    },
  ];

  let costDetails = [];
  costDetails.push(row8);
  costDetails.push(row_tuition);
  costDetails.push(row_extra_fees);
  costDetails.push(row_books);
  costDetails.push(row_total);
  costDetails.push(row_thirdPayment);

  doc.autoTable({
    startY: 65,
    body: costDetails,
    didDrawPage: function (data) {
      doc.setFontSize(10);
    },
    columnStyles: {
      0: { cellWidth: 45 },
      1: { cellWidth: 18 },
      2: { cellWidth: "wrap" },
    },
    styles: {
      fontSize: 10,
    },
    theme: "plain",
  });

  //personal information
  doc.setDrawColor(0, 0, 0);
  doc.roundedRect(12, 42, doc.internal.pageSize.getWidth() - 24, 22, 3, 3, "S");

  //tuition information
  doc.setDrawColor(0, 0, 0);
  doc.roundedRect(12, 71, 63, 32, 3, 3, "S");

  //payment plan
  doc.setDrawColor(0, 0, 0);
  doc.roundedRect(76, 71, 128, 32, 3, 3, "S");

  let row11_2 = [
    {
      content: "Tuition Payment",
      styles: {
        valign: "middle",
        halign: "left",
        fontStyle: "bold",
        fontSize: "8",
      },
    },
  ];

  let row11_3 = [
    {
      content:
        "Tuition must be paid in full by the tuition payment deadline as agreed in the Student Financial Contract (Form 103). All other fees for application, placement test, and books must be paid prior to classes starting and are non-refundable. Failure to pay the full amount of tuition or arrange other financial support prior to the due date may result in a late flat fee of $50.00.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let row11_4 = [
    {
      content:
        "Students with a balance of more than 3 days past due are failing to fulfill their financial obligations with the school and will not be permitted to attend classes since they are not paying for this service. The student will then be marked as “absent”, which will impact his/her attendance and could affect his/her ability to maintain the F1 student status.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let row11_5 = [
    {
      content:
        "Upon signing the contract with our school, you committed to a legal obligation to pay the full amount specified, regardless of your continuation or discontinuation of studies. It’s important to understand that if this obligation is not met, the school will pursue legal avenues to collect the full amount of the signed contract. Students who are 18 or older are required to coordinate their own financial arrangements, while students under 18 must have their guardian coordinate with us.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let paymentPlanDetails = [];
  paymentPlanDetails.push(row11_2);
  paymentPlanDetails.push(row11_3);
  paymentPlanDetails.push(row11_4);
  paymentPlanDetails.push(row11_5);

  doc.autoTable({
    startY: 105,
    body: paymentPlanDetails,
    didDrawPage: function (data) {
      doc.setFontSize(10);
    },
    columnStyles: {
      0: { cellWidth: "auto" },
    },
    styles: {
      fontSize: 10,
    },
    theme: "plain",
  });

  //tuition payment
  doc.setDrawColor(0, 0, 0);
  doc.roundedRect(
    12,
    105,
    doc.internal.pageSize.getWidth() - 24,
    47,
    3,
    3,
    "S"
  );

  let row_tuition_refund_1 = [
    {
      content: "Tuition Refund",
      styles: {
        valign: "middle",
        halign: "left",
        fontStyle: "bold",
        fontSize: "8",
      },
    },
  ];

  let row_tuition_refund_2 = [
    {
      content:
        "Students requesting a refund must send a written request to the Student Financial Services by email (finance@lumos.edu). The refund will be made to the student within 30 days in the form of a check. The student will be notified by email when the check is ready to be picked up. Student’s failure to notify the appropriate parties in writing of withdrawal may delay the tuition re- fund. The school will consider the Date of Enrollment (DE) whatever comes first of the following: 1) The day the student signs an enrollment agreement; 2) the day the student pays the institution an initial deposit or first payment toward tuition and fees; 3) the day that the student first visits the institution if the program lasts more than 30 consecutive calendar days.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let row_tuition_refund_3 = [
    {
      content:
        "The percentage of tuition refund is based on the full amount of the semester tuition, and not based on the amount paid.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let row_tuition_refund_4 = [
    {
      content:
        "Refund Schedule based on Date of Enrollment. The student is entitled to refund as follow:",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontStyle: "bold",
        fontSize: "8",
      },
    },
  ];

  let row_tuition_refund_5 = [
    {
      content: "•	Until 3 Days within Date of Enrollment = 100%",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let row_tuition_refund_6 = [
    {
      content: "•	Until 4 Days within Date of Enrollment = 50%",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let row_tuition_refund_7 = [
    {
      content: "•	Until 5 Days within Date of Enrollment = 25%",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let row_tuition_refund_8 = [
    {
      content: "•	After the 5th day of Date of Enrollment = 0%",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let row_tuition_refund_9 = [
    {
      content:
        "Requests sent after the 5th day of the DE will not qualify for any refund.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let row_tuition_refund_10 = [
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let row_tuition_refund_11 = [
    {
      content:
        "By signing this Financial Agreement, I acknowledge that I have read and understand the applicable fees and financial responsibilities of Lumos Language School, as stated on the back of this contract, and agree to abide by all policies and pay all fees accordingly. I understand that all registration fees are nonrefundable nor transferable and that the Financial Agreement is a commitment for the period of time contracted.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "10",
      },
    },
  ];

  let signingDetails = [];
  signingDetails.push(row_tuition_refund_1);
  signingDetails.push(row_tuition_refund_2);
  signingDetails.push(row_tuition_refund_3);
  signingDetails.push(row_tuition_refund_4);
  signingDetails.push(row_tuition_refund_5);
  signingDetails.push(row_tuition_refund_6);
  signingDetails.push(row_tuition_refund_7);
  signingDetails.push(row_tuition_refund_8);
  signingDetails.push(row_tuition_refund_9);
  signingDetails.push(row_tuition_refund_10);
  signingDetails.push(row_tuition_refund_11);

  doc.autoTable({
    startY: 154,
    body: signingDetails,
    didDrawPage: function (data) {
      doc.setFontSize(10);
    },
    columnStyles: {
      0: { cellWidth: "auto" },
    },
    styles: {
      fontSize: 10,
    },
    theme: "plain",
  });

  //tuition refund
  doc.setDrawColor(0, 0, 0);
  doc.roundedRect(
    12,
    154,
    doc.internal.pageSize.getWidth() - 24,
    68,
    3,
    3,
    "S"
  );

  doc.addPage("p", "mm", "letter");

  doc.text(
    "Policies and Student Responsibilities",
    doc.internal.pageSize.getWidth() / 2,
    13,
    "center"
  );

  let secondPageDetails = [];

  let secondPageDetails_1 = [
    {
      content: "Amendments to Policies",
      styles: {
        valign: "middle",
        halign: "left",
        fontStyle: "bold",
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_1_space = [
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "left",
        fontStyle: "bold",
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_2 = [
    {
      content:
        "Lumos Language School hereby reserves the right to modify its policies at any given time. Such modifications will be promptly communicated to all enrolled students and will be available in the Student Handbook.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_3 = [
    {
      content: "Program/Class Cancellation:",
      styles: {
        valign: "middle",
        halign: "left",
        fontStyle: "bold",
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_4 = [
    {
      content:
        "If our institution cancels a program after a student’s registration, we will refund all fees paid by the student. The institution holds the right to make class closures if the number of students enrolled in each class does not reach minimum capacity. In such cases, the institution will provide other options when available for students to make changes on their schedule due to class closures.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_5 = [
    {
      content: "Attendance:",
      styles: {
        valign: "middle",
        halign: "left",
        fontStyle: "bold",
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_6 = [
    {
      content:
        "International F-1 students have legal obligations they must fulfill in order to maintain status.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_7 = [
    {
      content:
        "The U.S. Government, through federal laws, has determined that a full course of study for an F-1 student consists of at least eighteen clock hours of attendance a week if the dominant part of the course of study consists of classroom instruction. (C.F.R. 214.2(f)(6)(i)(F)). Also, the U.S. Government allows each English program to determine its own attendance policy that will ensure that students fulfill their legal obligations and complete a full course of study while enrolled.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_8 = [
    {
      content:
        "Lumos Language School policy expects regular and punctual attendance in all courses. Students are required to maintain a minimum of 80% attendance of enrolled programs.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_9 = [
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_10 = [
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_11 = [
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_12 = [
    {
      content: "",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_13 = [
    {
      content: "* Terminated for “Failing to Mantain Status”",
      styles: {
        valign: "middle",
        halign: "left",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_14 = [
    {
      content:
        "Zero Tolerance of Disrespect Towards Faculty, Staff, and Students",
      styles: {
        valign: "middle",
        halign: "justify",
        fontStyle: "bold",
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_15 = [
    {
      content:
        "Any perspective or enrolled student at Lumos, who shows disrespect to Lumos students, any member of staff or faculty can be subject to disciplinary action such as loss of attendance and/or removal from the pro- gram. Disrespect in this Handbook is defined by (but not excluded to) the following: Constant class disruption, rudeness, unnecessary and excessive argumentation with other students or staff and faculty, harassment, excessive complaining, yelling or threatening speech, physical violence, verbal threats of violence, disregard or refusal of faculty direction, refusal to participate in classroom activities, overall disrespect and disregard for Staff and Faculty efforts and direction. Any student or prospective student may be removed from the program and not be allowed to register and attend the programs offered at Lumos due to violating the Zero-Tolerance of Disrespect policy.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  let secondPageDetails_16 = [
    {
      content:
        "Lumos students are required to maintain certain standards of behavior while they attend our program which encourages an atmosphere of learning and mutual respect.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "8",
      },
    },
  ];

  secondPageDetails.push(secondPageDetails_1);
  secondPageDetails.push(secondPageDetails_2);
  secondPageDetails.push(secondPageDetails_1_space);
  secondPageDetails.push(secondPageDetails_3);
  secondPageDetails.push(secondPageDetails_4);
  secondPageDetails.push(secondPageDetails_1_space);
  secondPageDetails.push(secondPageDetails_5);
  secondPageDetails.push(secondPageDetails_6);
  secondPageDetails.push(secondPageDetails_7);
  secondPageDetails.push(secondPageDetails_8);
  secondPageDetails.push(secondPageDetails_9);
  secondPageDetails.push(secondPageDetails_10);
  secondPageDetails.push(secondPageDetails_11);
  secondPageDetails.push(secondPageDetails_12);
  secondPageDetails.push(secondPageDetails_13);
  secondPageDetails.push(secondPageDetails_1_space);
  secondPageDetails.push(secondPageDetails_14);
  secondPageDetails.push(secondPageDetails_15);
  secondPageDetails.push(secondPageDetails_16);

  doc.autoTable({
    startY: 20,
    body: secondPageDetails,
    didDrawPage: function (data) {
      if (img_sevis) {
        doc.addImage(
          img_sevis,
          "JPEG",
          data.settings.margin.left + 1,
          109,
          92,
          15
        );
      }

      doc.setFontSize(10);

      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

      if (img_signature) {
        doc.addImage(
          img_signature,
          "JPEG",
          data.settings.margin.left + 25,
          pageHeight - 50,
          85,
          17
        );
      }

      doc.text(
        "Student Signature",
        doc.internal.pageSize.getWidth() / 2,
        pageHeight - 25,
        "center"
      );

      doc.text(
        "Form 103 - " +
          (options.isSelfEnrollment
            ? ""
            : "Token Authentication: " + student.signatureToken + " - ") +
          dateFns.format(new Date(), "MM/dd/yyyy HH:mm:ss") +
          " - Copyright Lumos @ 2024 revised on July 2024",
        doc.internal.pageSize.getWidth() / 2,
        pageHeight - 10,
        "center"
      );
    },
    columnStyles: {
      0: { cellWidth: "auto" },
    },
    margin: {
      left: 40,
    },
    styles: {
      fontSize: 7,
    },
    theme: "plain",
  });

  //Amendments to Policies
  doc.setDrawColor(0, 0, 0);
  doc.roundedRect(38, 20, doc.internal.pageSize.getWidth() - 50, 17, 3, 3, "S");

  if (img_initials) {
    doc.addImage(img_initials, "JPEG", 10, 23, 20, 10);
  }

  doc.setFontSize(8);
  doc.text("Initials", 16, 36, "left");

  //Program/Class Cancellation:
  doc.setDrawColor(0, 0, 0);
  doc.roundedRect(38, 42, doc.internal.pageSize.getWidth() - 50, 23, 3, 3, "S");

  if (img_initials) {
    doc.addImage(img_initials, "JPEG", 10, 49, 20, 10);
  }

  doc.text("Initials", 16, 62, "left");

  //Attendance
  doc.setDrawColor(0, 0, 0);
  doc.roundedRect(38, 70, doc.internal.pageSize.getWidth() - 50, 63, 3, 3, "S");

  if (img_initials) {
    doc.addImage(img_initials, "JPEG", 10, 98, 20, 10);
  }

  doc.text("Initials", 16, 111, "left");

  //Zero Tolerance of Disrespect Towards Faculty, Staff, and Students
  doc.setDrawColor(0, 0, 0);
  doc.roundedRect(
    38,
    138,
    doc.internal.pageSize.getWidth() - 50,
    47,
    3,
    3,
    "S"
  );

  if (img_initials) {
    doc.addImage(img_initials, "JPEG", 10, 157, 20, 10);
  }

  doc.text("Initials", 16, 170, "left");

  let agreeSentence = [];

  let agreeSentence_1 = [
    {
      content:
        "I understand and agree with the school policies above described and I have been given ample opportunity to review and understand the terms and conditions of enrollment, including the institution’s refund policy, prior to signing the enrollment documents.",
      styles: {
        valign: "middle",
        halign: "justify",
        cellPadding: { top: 1, right: 3, bottom: 1, left: 1 },
        fontSize: "10",
      },
    },
  ];

  agreeSentence.push(agreeSentence_1);

  doc.autoTable({
    startY: 190,
    body: agreeSentence,
    didDrawPage: function (data) {
      doc.setFontSize(10);

      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    },
    columnStyles: {
      0: { cellWidth: "auto" },
    },

    styles: {
      fontSize: 7,
    },
    theme: "plain",
  });

  var base64 = doc.output("datauristring");

  let note = {
    Type: "N",
    Description: "Lumos Financial Contract",
    Document: {
      FileContentBase64: base64,
      FileName:
        "Financial Contract - " +
        student.name +
        " - " +
        options.currentSemesterName +
        ".pdf",
    },
    Student: {
      Id: student.id,
      Address: {
        Email: student.email,
      },
    },
    Semester: {
      Name: options.currentSemesterName,
    },
  };

  axios.post("students/SaveNoteSendEmail", note);
}

export function FinancialContractPdf(student, options) {
  Execute(student, options);
}
