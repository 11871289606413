/* eslint-disable no-useless-constructor */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { CompleteRecordPdf } from "components/Report/CompleteRecordPdf.js";

class ModalInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();
  }

  getModalState = () => {
    return {
      note: "1 semester is equal to 15 weeks, with a total of 257 hours. \n",
      percent: 5,
    };
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          centered
          onClosed={this.onCloseModal}
          onOpened={this.onOpened.bind(this)}
        >
          <ModalHeader toggle={this.props.toggle}>
            Notes for the complete record
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Input
                    id="inputNote"
                    className={"form-group"}
                    type="textarea"
                    placeholder="Enter any additional notes to be printed on the student's complete record."
                    rows={5}
                    value={this.state.note}
                    onChange={this.handleNoteInput.bind(this)}
                  />
                </FormGroup>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label
                    for="classroomTotalSeats"
                    style={{ display: "inline" }}
                  >
                    Disregard grades below (%)
                  </Label>
                  <Input
                    style={{
                      display: "inline",
                      maxWidth: "7rem",
                      marginLeft: "1rem",
                    }}
                    className={`form-group`}
                    type="number"
                    id="percent"
                    autoComplete="nope"
                    value={this.state.percent}
                    onChange={this.handlePercentInput.bind(this)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.openCompleteRecord.bind(this)}
            >
              Ok
            </Button>{" "}
            <Button color="secondary" onClick={this.props.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  onCloseModal = () => {
    this.resetModal();
  };

  onOpened() {
    let element = document.getElementById("inputNote");
    element.focus();
    element.setSelectionRange(element.value.length, element.value.length);
  }

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  handleNoteInput = (e) => {
    const value = e.target.value;
    this.setState({ note: value });
  };

  handlePercentInput = (e) => {
    const value = e.target.value;
    this.setState({ percent: value });
  };

  openCompleteRecord = () => {
    return new Promise((resolve, reject) => {
      this.props.toggle();

      CompleteRecordPdf(
        this.props.studentId,
        this.state.note,
        this.props.semesters,
        this.state.percent
      );
    });
  };
}

export default ModalInput;
