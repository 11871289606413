/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  CardHeader,
  FormGroup,
  Label,
  Button,
  Badge,
} from "reactstrap";
import axios from "axios";
import * as dateFns from "date-fns";
import Pagination from "components/Pagination/Pagination.js";
import DatePicker from "react-datepicker";
import ReferralModal from "../Referral/Referral.Modal.Component";
import { Multiselect } from "multiselect-react-dropdown";
import SearchInput from "components/Search/Search.input.component";
import Select from "react-select";

class ReferralReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      referrals: [],
      StartDateSearch: dateFns.addMonths(new Date(), -6),
      EndDateSearch: dateFns.endOfWeek(new Date()),
      referralId: "",
      referralName: "",
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      logModalIsOpen: false,
      StatusTypeList: [],
      selectedStatusTypes: [],
      applicantName: "",
      studentName: "",
      hasBalance: "all",
      paymentTypeId: "all",
    };
  };

  toggleLogModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { logModalIsOpen: !this.state.logModalIsOpen },
        resolve(true)
      );
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState({ referralId: "", referralName: "" }, resolve(true));
    });
  };

  setReferralIdAndToggleLog = (id, name) => {
    return new Promise((resolve, reject) => {
      this.setState({ referralId: id, referralName: name }, () =>
        this.toggleLogModal()
      );
    });
  };

  renderStatusButton = (referral) => {
    let color = "";

    switch (referral.lastStatusName) {
      case "New":
        color = "#f9a02b";
        break;
      case "Pending":
        color = "#5b5b5b";
        break;
      case "Resolved":
        color = "#7ac947";
        break;
      default:
        break;
    }

    return (
      <Badge style={{ fontSize: "small", backgroundColor: color }}>
        {referral.lastStatusName}
      </Badge>
    );
  };

  renderPaymentButton = (referral) => {
    let color = "";

    switch (referral.paymentTypeId) {
      case "Pending":
        color = "#f9a02b";
        break;
      case "Check":
        color = "#7ac947";
        break;
      case "Credit":
        color = "#10b8f9";
        break;
      default:
        break;
    }

    return (
      <Badge style={{ fontSize: "small", backgroundColor: color }}>
        {referral.paymentTypeId}
      </Badge>
    );
  };

  renderBalanceButton = (referral) => {
    let color = referral.hasBalance ? "#dc3545" : "#7ac947";
    let msg = referral.hasBalance ? "Has balance" : "No balance";

    return (
      <Badge style={{ fontSize: "small", backgroundColor: color }}>{msg}</Badge>
    );
  };

  render() {
    let referrals = this.state.referrals.map((referral, index) => {
      return (
        <tr
          key={index}
          style={{ backgroundColor: referral.isCampaign ? "#befcbe" : "" }}
        >
          <td>{dateFns.format(referral.createdDate, "MM/dd/yyyy")}</td>
          <td>{referral.personalFullName}</td>
          <td>{referral.type}</td>
          <td>{referral.campus}</td>
          <td>{referral.studentName}</td>
          <td>{referral.studentI20}</td>
          <td>{referral.studentEmail}</td>
          <td>{this.renderBalanceButton(referral)}</td>
          <td>{this.renderStatusButton(referral)}</td>
          <td>{this.renderPaymentButton(referral)}</td>
          <td>
            <Button
              color="warning"
              size="sm"
              className="mr-2 buttonApplicationTable"
              onClick={this.setReferralIdAndToggleLog.bind(
                this,
                referral.id,
                referral.personalFullName
              )}
            >
              Log
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.EndDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "EndDateSearch"
                        )}
                        name="EndDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <SearchInput
                      searchInput={this.state.applicantName}
                      placeholder="Search by Applicant"
                      handleSearchInput={this.handleSearchInput.bind(
                        this,
                        "applicantName"
                      )}
                    />

                    <SearchInput
                      searchInput={this.state.studentName}
                      placeholder="Search by Student"
                      handleSearchInput={this.handleSearchInput.bind(
                        this,
                        "studentName"
                      )}
                    />
                  </Row>

                  <Row form>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Has Balance</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.hasBalance &&
                            this.balanceOptions.find(
                              (x) => x.value === this.state.hasBalance
                            )
                          }
                          onChange={this.handleBalanceChange}
                          options={this.balanceOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Payment Type</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.paymentTypeId &&
                            this.paymentOptions.find(
                              (x) => x.value === this.state.paymentTypeId
                            )
                          }
                          onChange={this.handlePaymentChange}
                          options={this.paymentOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <Label>Status</Label>
                      <Multiselect
                        options={this.state.StatusTypeList}
                        onSelect={this.onSelect.bind(this)}
                        onRemove={this.onRemove.bind(this)}
                        displayValue="name"
                      />
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Applicant</th>
                        <th style={{ minWidth: "70px" }}>Type</th>
                        <th>Campus</th>
                        <th>Student Name</th>
                        <th style={{ minWidth: "120px" }}>Student I-20</th>
                        <th>Student Email</th>
                        <th>Balance</th>
                        <th>Status</th>
                        <th style={{ minWidth: "95px" }}>Pmt. Type</th>
                      </tr>
                    </thead>

                    <tbody>{referrals}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.currentPage}
                    totalItemsCount={this.state.totalResults}
                    onChange={this._refreshReferrals.bind(this)}
                    csv={this.downloadReferrals.bind(this)}
                    buttonName="Export to CSV"
                  />

                  <ReferralModal
                    isOpen={this.state.logModalIsOpen}
                    toggle={this.toggleLogModal.bind(this)}
                    id={this.state.referralId}
                    callback={this._refreshReferrals.bind(this)}
                    tables={this.props.tables}
                    title={this.state.referralName}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  handleSearchInput = (name, e) => {
    const value = e.target.value;

    this.setState({ [name]: value, currentPage: 1 }, () => {
      this._refreshReferrals();
    });
  };

  handleBalanceChange = (selectedOption) => {
    let mainState = this.state;

    mainState.hasBalance = selectedOption.value;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshReferrals();
    });
  };

  handlePaymentChange = (selectedOption) => {
    let mainState = this.state;

    mainState.paymentTypeId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshReferrals();
    });
  };

  componentDidMount() {
    this._refreshReferrals().then(() => {
      this.refreshStatusTypeList();
    });
  }

  onSelect(selectedList, selectedItem) {
    let mainState = this.state;
    mainState.selectedStatusTypes.push(selectedItem);
    this.setState(mainState, () => this._refreshReferrals());
  }

  onRemove(selectedList, removedItem) {
    let mainState = this.state;
    let newSelectedStatusTypes = mainState.selectedStatusTypes.filter(
      (x) => x.id !== removedItem.id
    );
    mainState.selectedStatusTypes = newSelectedStatusTypes;
    this.setState(mainState, () => this._refreshReferrals());
  }

  refreshStatusTypeList = () => {
    return new Promise((resolve, reject) => {
      axios.get("referralstatustypes").then((response) => {
        let mainState = this.state;

        mainState.StatusTypeList = [];

        response.data.data.map((statusType, index) =>
          mainState.StatusTypeList.push({
            id: statusType.id,
            name: statusType.name,
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  };

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;

    mainState[name] = date;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshReferrals();
    });
  };

  _refreshReferrals(pageNumber) {
    let page = pageNumber || 1;
    let itemsPerPage = this.state.itemsPerPage;
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

    let paymentTypeId =
      this.state.paymentTypeId === "all" ? "" : this.state.paymentTypeId;

    let hasBalance =
      this.state.hasBalance == "all"
        ? null
        : this.state.hasBalance == "1"
        ? true
        : false;

    let statusTypeList = [];
    this.state.selectedStatusTypes.map((statusType, index) =>
      statusTypeList.push({
        Id: statusType.id,
      })
    );

    let getAllParam = {
      page: page,
      pageSize: itemsPerPage,
      startDate: startDate,
      endDate: endDate,
      statusTypeList: statusTypeList,
      applicantName: this.state.applicantName,
      studentName: this.state.studentName,
      hasBalance: hasBalance,
      paymentTypeId: paymentTypeId,
    };

    return new Promise((resolve, reject) => {
      axios.post("referral/getall?", getAllParam).then((response) => {
        let mainState = this.state;
        mainState.referrals = [];

        response.data.data.items.map((referral, index) => {
          mainState.referrals.push({
            id: referral.id,
            createdDate: dateFns.parseISO(referral.apply.createdDate),
            paymentTypeId:
              referral.paymentTypeId === "P"
                ? "Pending"
                : referral.paymentTypeId === "H"
                ? "Check"
                : referral.paymentTypeId === "R"
                ? "Credit"
                : "",
            lastStatusName: referral.lastStatusName,
            personalFullName: referral.apply.personalFullName,
            studentName: referral.student.name,
            studentI20: referral.student.i20,
            studentEmail: referral.student.address.email,
            hasBalance: referral.student.hasBalance,
            isCampaign: referral.isCampaign,
            type:
              referral.apply.applicationChoice == "Initial Attendance"
                ? "In.At."
                : referral.apply.applicationChoice == "Change of Status"
                ? "Ch.St."
                : referral.apply.applicationChoice == "Transfer In"
                ? "Tr.In"
                : referral.apply.applicationChoice,
            campus:
              referral.apply.campus == "Salt Lake City"
                ? "SLC"
                : referral.apply.campus,
          });
        });

        mainState.currentPage = pageNumber;
        mainState.totalResults = response.data.data.totalCount;

        this.setState(mainState, resolve(true));
      });
    });
  }

  downloadReferrals() {
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

    let statusTypeList = [];
    this.state.selectedStatusTypes.map((statusType, index) =>
      statusTypeList.push({
        Id: statusType.id,
      })
    );

    let paymentTypeId =
      this.state.paymentTypeId === "all" ? "" : this.state.paymentTypeId;

    let hasBalance =
      this.state.hasBalance == "all"
        ? null
        : this.state.hasBalance == "1"
        ? true
        : false;

    let getAllParam = {
      page: 1,
      pageSize: 999999,
      startDate: startDate,
      endDate: endDate,
      statusTypeList: statusTypeList,
      applicantName: this.state.applicantName,
      studentName: this.state.studentName,
      hasBalance: hasBalance,
      paymentTypeId: paymentTypeId,
    };

    return new Promise((resolve, reject) => {
      axios.post("referral/getall?", getAllParam).then((response) => {
        let referrals = [];

        response.data.data.items.map((referral, index) => {
          referrals.push({
            CreatedDate: dateFns.format(
              dateFns.parseISO(referral.apply.createdDate),
              "MM/dd/yyyy"
            ),
            ApplicantName: referral.apply.personalFullName,
            StudentName: referral.student.name,
            studentI20: referral.student.i20,
            studentEmail: referral.student.address.email,
            HasBalance: referral.student.hasBalance,
            Status: referral.lastStatusName,
            PaymentType:
              referral.paymentTypeId === "P"
                ? "Pending"
                : referral.paymentTypeId === "H"
                ? "Check"
                : referral.paymentTypeId === "R"
                ? "Credit"
                : "",
            Type:
              referral.apply.applicationChoice == "Initial Attendance"
                ? "In.At."
                : referral.apply.applicationChoice == "Change of Status"
                ? "Ch.St."
                : referral.apply.applicationChoice == "Transfer In"
                ? "Tr.In"
                : referral.apply.applicationChoice,
            Campus:
              referral.apply.campus == "Salt Lake City"
                ? "SLC"
                : referral.apply.campus,
          });
        });

        this.downloadCSVFromJson("Referrals.csv", referrals);
      });
    });
  }

  downloadCSVFromJson(filename, arrayOfJson) {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  balanceOptions = [
    { value: "all", label: "All" },
    { value: "1", label: "Has balance" },
    { value: "0", label: "No balance" },
  ];

  paymentOptions = [
    { value: "all", label: "All" },
    { value: "P", label: "Pending" },
    { value: "H", label: "Check" },
    { value: "R", label: "Credit" },
  ];
}

export default ReferralReport;
