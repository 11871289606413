/* eslint-disable no-useless-constructor */
/* eslint-disable array-callback-return */
import React from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Label,
  Alert,
} from "reactstrap";
import axios from "axios";
import { authService } from "services/Permission.service.js";

class AuthenticateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.getModalState();
  }

  render() {
    let messages = this.props.message.split("\n").map((item, key) => {
      return (
        <span key={key}>
          {key + 1 + ") " + item}
          <br />
        </span>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                onClosed={this.onCloseModal}
                size={"lg"}
                backdrop="static"
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  Authentication required
                </ModalHeader>
                <ModalBody>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Alert color="danger">{messages}</Alert>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="messagePeriod">E-mail</Label>
                        <Input
                          className={`form-group`}
                          type="text"
                          name="login"
                          id="login"
                          autoComplete="nope"
                          value={this.state.login}
                          onChange={this.handleAuthenticationInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="messagePeriod">Password</Label>
                        <Input
                          className={`form-group`}
                          type="password"
                          name="password"
                          id="password"
                          autoComplete="nope"
                          value={this.state.password}
                          onChange={this.handleAuthenticationInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="messagePeriod">Note</Label>
                        <Input
                          className={"form-group"}
                          type="textarea"
                          name="modalAuthenticateNote"
                          id="note"
                          autoComplete="nope"
                          value={this.props.note}
                          onChange={this.props.onChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={this.authenticate.bind(this)}
                    disabled={!this.validateForm()}
                  >
                    Authenticate
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.props.toggle.bind(this)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  getModalState = () => {
    return {
      login: "",
      password: "",
    };
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  validateForm() {
    return this.props.note.length > 0;
  }

  setIsAuthenticated = (token) => {
    return new Promise((resolve, reject) => {
      authService.setAuth(token);
      resolve(true);
    });
  };

  handleAuthenticationInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState[name] = value;

    this.setState(modalState);
  };

  authenticate() {
    let user = {
      Name: this.state.login,
      Password: this.state.password,
    };

    axios.post("users/authenticateadmin", user).then((response) => {
      let token = response.data.data.token;

      this.setIsAuthenticated(token).then(() => {
        this.props.toggle().then(() => {
          this.resetModal().then(() => {
            if (
              this.props.callback &&
              typeof this.props.callback === "function"
            ) {
              this.props.callback();
            }
          });
        });
      });
    });
  }
}

export default AuthenticateModal;
