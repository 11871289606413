/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import ReportModal from "./ReportModal.component";
import { Row, Col, Button, Label } from "reactstrap";
import * as dateFns from "date-fns";
import axios from "axios";
import DatePicker from "react-datepicker";

class ReportClassAttendance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      students: [],
      currentMonth: new Date(),
      monthToGenerate: new Date(),
    };
  }

  render() {
    let originalDate = this.state.monthToGenerate;
    let date = originalDate;

    let lastDayOfMonth = dateFns.lastDayOfMonth(date);
    let daysToGenerate = [];
    let semesterStartDate = dateFns.parseISO(
      this.props.classe.semesterStartDate
    );
    let semesterEndDate = dateFns.parseISO(this.props.classe.semesterEndDate);

    if (this.props.classe.days) {
      while (date <= lastDayOfMonth) {
        let dayIndex = dateFns.getDay(date);

        let formatedDate = dateFns.format(date, "yyyy-MM-dd") + "T00:00:00";

        if (
          this.props.classe.days.indexOf(dayIndex) > -1 &&
          date >= semesterStartDate &&
          date <= semesterEndDate &&
          !this.props.tables.holidays.find((x) => x.date === formatedDate)
        )
          daysToGenerate.push(dateFns.format(date, "dd"));

        date = dateFns.addDays(date, 1);
      }
    }

    let students = this.state.students.map((student) => {
      return (
        <tr key={student.id}>
          <td className="fitwidth">{student.name}</td>

          {daysToGenerate.map((day, index) => {
            return <td className="reportTd"></td>;
          })}
        </tr>
      );
    });

    let table = (
      <React.Fragment>
        <Row form style={{ padding: "10px" }}>
          <Col md={"4"} style={{ paddingLeft: "0", paddingBottom: "15px" }}>
            <Label for="studentBirthDate">Select the month</Label>

            {/* <Input
              type="month"
              name="BirthDate"
              id="studentBirthDate"
              value={this.state.currentMonth}
              onChange={this.handleDateInput.bind(this)}
            /> */}

            <DatePicker
              selected={this.state.currentMonth}
              onChange={this.handleDateInput.bind(this)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </Col>
          <Col md={3} style={{ paddingLeft: "0", paddingBottom: "15px" }}>
            <Label>&nbsp;</Label>
            <Button
              style={{ margin: "0" }}
              color="primary"
              onClick={this.refresh.bind(this)}
            >
              Generate
            </Button>
          </Col>
        </Row>

        {students.length > 0 && daysToGenerate.length > 0 && (
          <Row form id="tableReport" style={{ padding: "10px" }}>
            <table
              border="1"
              cellPadding="5"
              cellSpacing="2"
              className="tableReport"
            >
              <tbody>
                <tr>
                  <th
                    colSpan="3"
                    scope="colgroup"
                    style={{ textAlign: "left", borderRight: "0" }}
                  >
                    Lumos Language School - {this.props.classe.code}
                  </th>
                  <th
                    colSpan={daysToGenerate.length - 4}
                    scope="colgroup"
                    style={{ textAlign: "right", borderLeft: "0" }}
                  >
                    {this.props.classe.teacherName}
                  </th>
                </tr>
                <tr style={{ height: "20px" }}></tr>
                <tr>
                  <th
                    colSpan={daysToGenerate.length + 1}
                    scope="colgroup"
                    style={{ textAlign: "left" }}
                  >
                    {dateFns.format(originalDate, "MMMM yyyy")}
                  </th>
                </tr>
                <tr style={{ height: "20px" }}></tr>
              </tbody>
            </table>

            <table
              border="1"
              cellPadding="5"
              cellSpacing="2"
              className="tableReport"
            >
              <tbody>
                <tr>
                  <th scope="col">Student Name</th>

                  {daysToGenerate.map((day, index) => {
                    return <th>{day}</th>;
                  })}
                </tr>
                {students}
              </tbody>
            </table>
          </Row>
        )}
      </React.Fragment>
    );

    return (
      <ReportModal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        title={this.props.classe.code}
        table={table}
        onClosed={this.onClosed.bind(this)}
        buttonPrintDisabled={this.state.students.length === 0}
      ></ReportModal>
    );
  }

  handleDateInput = (date) => {
    let modalState = this.state;

    modalState.currentMonth = date;
    modalState.students = [];

    this.setState(modalState);
  };

  onClosed = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          students: [],
          currentMonth: new Date(),
          monthToGenerate: new Date(),
        },
        resolve(true)
      );
    });
  };

  refresh = () => {
    let currentMonth = this.state.currentMonth;
    this.setState({ monthToGenerate: currentMonth });

    if (this.state.students.length === 0) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            "students/byClass?classId=" +
              this.props.classe.id +
              "&page=1&pageSize=10000"
          )
          .then((response) => {
            let modalState = this.state;

            modalState.students = [];
            response.data.data.items.map((student, index) =>
              modalState.students.push({
                id: student.id,
                name: student.name,
              })
            );

            this.setState(modalState, resolve(true));
          });
      });
    }
  };
}

export default ReportClassAttendance;
