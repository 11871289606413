/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Collapse,
  Navbar,
  Input,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Col,
  Popover,
  PopoverHeader,
  Button,
  PopoverBody,
  Row,
  FormGroup,
  Label,
  Table,
  Form,
} from "reactstrap";

import routes from "routes.js";
import { authenticationService } from "services/Authentication.service.js";
import axios from "axios";
import Select from "react-select";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";
import { editMode } from "helpers/Utils.js";
import Can from "../Can/Can";
import UserPassword from "../User/User.password.component";
import Pagination from "components/Pagination/Pagination.js";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
      suggestions: [],
      value: "",
      semesters: [],
      semesterId: "",
      campuses: [],
      campusId: "",
      openStudentModal: false,
      studentId: "",
      currentUser: authenticationService.currentUserValue,
      isPasswordModalOpen: false,
      popoverOpen: false,
      popoverName: "",
      popoverEmail: "",
      popoverI20: "",
      popoverStudents: [],
      itemsPerPage: 10,
      currentPage: 1,
      totalResults: 0,
    };

    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.sidebarToggle = React.createRef();

    this.timeout = 0;
  }

  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "dark",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  getBrand() {
    let brandName = "Default Brand";

    let windowLocation = window.location.href;

    routes.map((prop, key) => {
      if (prop.children && prop.children.length > 0) {
        prop.children.forEach((child) => {
          if (windowLocation.indexOf(child.layout + child.path) !== -1)
            brandName = child.name;
        });
      } else {
        if (windowLocation.indexOf(prop.layout + prop.path) !== -1) {
          brandName = prop.name;
        }
      }

      return null;
    });
    return brandName;
  }

  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  }

  logout() {
    authenticationService.logout();
    this.props.history.push("/login");
    //history.push("/login");
  }

  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));

    this.setState(
      {
        semesterId: JSON.parse(localStorage.getItem("semesterId")),
        campusId: JSON.parse(localStorage.getItem("campusId")),
      },
      () => {
        this.refreshSemesters().then(() => {
          this.refreshCampuses();
        });
      }
    );
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }
  }

  render() {
    const { currentUser } = this.state;

    let popoverStudents = this.state.popoverStudents.map((student) => {
      return (
        <tr key={student.id}>
          <td>
            <a
              onClick={this.setStudentIdAndOpenModal.bind(this, student.id)}
              href="#"
            >
              {student.name}
            </a>
          </td>

          <td>{student.courseName}</td>
          <td>{student.campusName}</td>
          <td>{student.email}</td>
          <td>{student.i20}</td>
        </tr>
      );
    });

    return (
      <Navbar
        color={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
              (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={this.sidebarToggle}
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="/">{this.getBrand()}</NavbarBrand>
          </div>

          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >
            <div className="navbar-wrapper">
              <NavbarBrand>
                Hi, {currentUser.name.substr(0, currentUser.name.indexOf("@"))}
              </NavbarBrand>
            </div>

            <Can
              feature="NavBar_SearchStudent"
              yes={() => (
                <React.Fragment>
                  <i
                    id="Popover1"
                    className="fas fa-search"
                    title="Student search"
                    style={{
                      color: "#51cbce",
                      cursor: "pointer",
                      marginRight: "10px",
                      fontSize: "x-large",
                    }}
                  />
                  <Popover
                    placement="bottom-end"
                    isOpen={this.state.popoverOpen}
                    target="Popover1"
                    className="popover-container"
                    toggle={this.togglePopover}
                    modifiers={{ flip: { behavior: ["bottom-end"] } }}
                  >
                    <PopoverHeader>
                      Student search (Provide at least one field)
                    </PopoverHeader>
                    <PopoverBody>
                      <Form onSubmit={this.onFormSubmit}>
                        <Row form>
                          <Col md={5}>
                            <FormGroup>
                              <Label for="address">Name</Label>
                              <Input
                                className={`form-group`}
                                type="text"
                                id="popoverName"
                                autoComplete="nope"
                                value={this.state.popoverName}
                                onChange={this.handleInput}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label for="address">E-mail</Label>
                              <Input
                                className={`form-group`}
                                type="text"
                                id="popoverEmail"
                                autoComplete="nope"
                                value={this.state.popoverEmail}
                                onChange={this.handleInput}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Label for="address">I-20</Label>
                              <Input
                                className={`form-group`}
                                type="text"
                                id="popoverI20"
                                autoComplete="nope"
                                value={this.state.popoverI20}
                                onChange={this.handleInput}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <Button
                                type="submit"
                                color="info"
                                className="mr-2"
                                disabled={
                                  this.state.popoverName === "" &&
                                  this.state.popoverEmail === "" &&
                                  this.state.popoverI20 === ""
                                }
                              >
                                Search
                              </Button>
                              <Button
                                color="secondary"
                                className="mr-2"
                                onClick={this.clearSearch.bind(
                                  this,
                                  this.state.currentPage
                                )}
                                disabled={
                                  this.state.popoverName === "" &&
                                  this.state.popoverEmail === "" &&
                                  this.state.popoverI20 === ""
                                }
                              >
                                Clear
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                        {popoverStudents.length > 0 && (
                          <Row>
                            <Col md={12}>
                              <FormGroup>
                                <Table>
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Level</th>
                                      <th>Campus</th>
                                      <th>Email</th>
                                      <th>I20</th>
                                    </tr>
                                  </thead>

                                  <tbody>{popoverStudents}</tbody>
                                </Table>

                                <Pagination
                                  activePage={this.state.currentPage}
                                  totalItemsCount={this.state.totalResults}
                                  onChange={this.studentSearch.bind(this)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </PopoverBody>
                  </Popover>
                </React.Fragment>
              )}
            ></Can>

            <Can
              feature="NavBar_Campuses"
              yes={() =>
                this.state.campuses.length > 2 && (
                  <Col md={2} style={{ paddingLeft: "0", paddingRight: "5px" }}>
                    <Select
                      value={
                        this.state.campusId &&
                        this.state.campuses.find(
                          (x) => x.value === this.state.campusId
                        )
                      }
                      onChange={this.handleCampusChange}
                      options={this.state.campuses}
                    />
                  </Col>
                )
              }
            ></Can>
            <Col md={2} style={{ paddingLeft: "0", paddingRight: "0" }}>
              <Select
                value={
                  this.state.semesterId &&
                  this.state.semesters.find(
                    (x) => x.value === this.state.semesterId
                  )
                }
                onChange={this.handleSemesterChange}
                options={this.state.semesters}
              />
            </Col>
            <Nav navbar>
              <Dropdown
                nav
                isOpen={this.state.dropdownOpen}
                toggle={(e) => this.dropdownToggle(e)}
              >
                <DropdownToggle caret nav>
                  <i className="nc-icon nc-settings-gear-65" />
                  <p>
                    <span className="d-lg-none d-md-block">Some Actions</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    tag="a"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.toggleisPasswordModalOpen()}
                  >
                    Settings
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={() => this.logout()}
                    style={{ cursor: "pointer" }}
                  >
                    Log out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Container>
        <StudentEdit2
          isOpen={this.state.openStudentModal}
          toggleStudentModal={this.toggleStudentModal.bind(this)}
          editMode={editMode.EDIT}
          id={this.state.studentId}
          tables={this.props.tables}
        />
        <UserPassword
          isOpen={this.state.isPasswordModalOpen}
          toggle={this.toggleisPasswordModalOpen.bind(this)}
        />
      </Navbar>
    );
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    this.studentSearch();
  };

  handleInput = (e) => {
    const name = e.target.id;
    const value = e.target.value;

    this.setState({ [name]: value });
  };

  toggleStudentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openStudentModal = !mainState.openStudentModal;
      this.setState(mainState, resolve(true));
    });
  };

  setStudentIdAndOpenModal = (id) => {
    let mainState = this.state;

    mainState.studentId = id;
    mainState.popoverOpen = false;
    mainState.popoverName = "";
    mainState.popoverEmail = "";
    mainState.popoverI20 = "";
    mainState.popoverStudents = [];

    this.setState(mainState, () => {
      this.toggleStudentModal();
    });
  };

  toggleisPasswordModalOpen = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.isPasswordModalOpen = !mainState.isPasswordModalOpen;
      this.setState(mainState, resolve(true));
    });
  };

  togglePopover = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen }, () => {
      if (this.state.popoverOpen)
        document.getElementById("popoverName").focus();
    });
  };

  clearSearch = () => {
    this.setState(
      {
        popoverName: "",
        popoverEmail: "",
        popoverI20: "",
        popoverStudents: [],
      },
      () => {
        if (this.state.popoverOpen)
          document.getElementById("popoverName").focus();
      }
    );
  };

  studentSearch(pageNumber) {
    return new Promise((resolve, reject) => {
      let page = pageNumber || 1;
      let itemsPerPage = this.state.itemsPerPage;
      let popoverName = this.state.popoverName;
      let popoverEmail = this.state.popoverEmail;
      let popoverI20 = this.state.popoverI20;

      axios
        .get(
          "students/bynameemaili20?" +
            "name=" +
            popoverName +
            "&email=" +
            popoverEmail +
            "&i20=" +
            popoverI20 +
            "&page=" +
            page +
            "&pageSize=" +
            itemsPerPage
        )
        .then((response) => {
          let mainState = this.state;

          mainState.popoverStudents = [];
          response.data.data.items.map((student, index) =>
            mainState.popoverStudents.push({
              id: student.id,
              name: student.name,
              email: student.address.email,
              i20: student.i20,
              courseName: student.courseName,
              campusName: student.campusName,
            })
          );

          mainState.currentPage = pageNumber;
          mainState.totalResults = response.data.data.totalCount;

          this.setState(mainState, resolve(true));
        });
    });
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  refreshSemesters = () => {
    return new Promise((resolve, reject) => {
      let { semesters, semesterId } = this.state;

      semesters = [];
      this.props.tables.semesters.map((semester, index) =>
        semesters.push({
          value: semester.id,
          label: semester.name,
          current: semester.current,
        })
      );

      if (!semesterId) {
        var currentSemesterId = semesters.find((x) => x.current === true).value;
        this.setState({ semesterId: currentSemesterId });
        localStorage.setItem("semesterId", JSON.stringify(currentSemesterId));
      }

      this.setState(
        {
          semesters: semesters,
        },
        resolve(true)
      );
    });
  };

  refreshCampuses = async () => {
    return await new Promise((resolve, reject) => {
      let { campuses, campusId } = this.state;

      campuses = [];

      campuses.push({
        value: "all",
        label: "All campuses",
      });

      this.props.tables.campuses.map((campus, index) =>
        campuses.push({
          value: campus.id,
          label: campus.name,
        })
      );

      if (!campusId) {
        this.setState({ campusId: "all" });
        localStorage.setItem("campusId", JSON.stringify("all"));
      }

      this.setState(
        {
          campuses: campuses,
        },
        resolve(true)
      );
    });
  };

  handleSemesterChange = (selectedOption) => {
    localStorage.setItem("semesterId", JSON.stringify(selectedOption.value));

    this.setState({ semesterId: selectedOption.value }, () => {
      window.location.reload(false);
    });
  };

  handleCampusChange = (selectedOption) => {
    this.setState({ campusId: selectedOption.value });
    localStorage.setItem("campusId", JSON.stringify(selectedOption.value));
    window.location.reload(false);
  };
}

export default Header;
