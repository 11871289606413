/* eslint-disable array-callback-return */
/* eslint-disable no-useless-constructor */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  CustomInput,
} from "reactstrap";
import { SavePdf } from "components/Report/GeneratePdf.js";
import axios from "axios";
import * as dateFns from "date-fns";

class ColumnChooser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: this.getColumns(),
    };
  }

  getColumns = () => {
    const columns = [];

    columns.push(
      { Name: "FullName", IsVisible: true },
      { Name: "FirstName", IsVisible: false },
      { Name: "LastName", IsVisible: false },
      { Name: "Campus", IsVisible: true },
      { Name: "Sevis", IsVisible: true },
      { Name: "Level", IsVisible: true },
      { Name: "Class", IsVisible: true },
      { Name: "Status", IsVisible: true },
      { Name: "I20", IsVisible: false },
      { Name: "EnrollmentDate", IsVisible: false },
      { Name: "LastModifiedDate", IsVisible: false },
      { Name: "I20StartDate", IsVisible: false },
      { Name: "I20EndDate", IsVisible: false },
      { Name: "PassportExpDate", IsVisible: false },
      { Name: "VisaType", IsVisible: false },
      { Name: "StudentOrigin", IsVisible: false },
      { Name: "BirthDate", IsVisible: false },
      { Name: "Gender", IsVisible: false },
      { Name: "NationalityId", IsVisible: false },
      { Name: "Street", IsVisible: false },
      { Name: "City", IsVisible: false },
      { Name: "State", IsVisible: false },
      { Name: "Zip", IsVisible: false },
      { Name: "CountryId", IsVisible: false },
      { Name: "CellPhone", IsVisible: false },
      { Name: "Email", IsVisible: false },
      { Name: "Attendance", IsVisible: false },
      { Name: "Grade", IsVisible: false },
      { Name: "MoodleCode", IsVisible: false }
    );

    return columns;
  };

  isColumnChecked = (name) => {
    let column = this.state.columns.find((x) => x.Name === name);
    return column && column.IsVisible === true;
  };

  isColumnVisible = (name) => {
    return this.isColumnChecked(name) === true ? "" : "none";
  };

  onColumnCheckboxChange = (e) => {
    let columns = this.state.columns;
    let column = columns.find((x) => x.Name === e.target.name);
    if (column) column.IsVisible = e.target.checked;
    this.setState({ columns });
  };

  render() {
    let columns = this.state.columns.map((column, index) => {
      return (
        <Col md={3} key={index}>
          <FormGroup>
            <CustomInput
              className="defaultCheckBox"
              type="checkbox"
              id={column.Name}
              name={column.Name}
              label={column.Name}
              checked={column.IsVisible}
              onChange={this.onColumnCheckboxChange}
            />
          </FormGroup>
        </Col>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                unmountOnClose={true}
                onClosed={this.onCloseModal}
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  Student Report
                </ModalHeader>
                <ModalBody>
                  <Row form>{columns}</Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="success"
                    onClick={this.download.bind(this, "pdf")}
                  >
                    Export to PDF
                  </Button>
                  <Button
                    color="success"
                    onClick={this.download.bind(this, "csv")}
                  >
                    Export to CSV
                  </Button>
                  <Button color="secondary" onClick={this.props.toggle}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState({ columns: this.getColumns() }, resolve(true));
    });
  };

  download(type) {
    let courseId = this.props.courseId;
    let classId = this.props.classId;
    let periodId = this.props.periodId;
    let statusDate = dateFns.format(this.props.dateSearch, "yyyy-MM-dd");
    let campusId = this.props.campusId;
    let sevisId = this.props.sevisId;

    if (periodId === "all") periodId = "";
    if (courseId === "all") courseId = "";
    if (classId === "all") classId = "";
    if (campusId === "all") campusId = "";
    if (sevisId === "all") sevisId = "";

    let statusTypeList = [];
    this.props.selectedStatusTypes.map((statusType, index) =>
      statusTypeList.push({
        Id: statusType.id,
      })
    );

    let getAllParam = {
      courseId: courseId,
      classId: classId,
      period: periodId,
      statusTypeList: statusTypeList,
      statusDate: statusDate,
      campusId: campusId,
      sevisId: sevisId,
      includeAttendance: this.isColumnChecked("Attendance"),
      includeGrade: this.isColumnChecked("Grade"),
    };

    axios
      .post("students/StudentColumnChooser", getAllParam)
      .then((response) => {
        let students = [];

        response.data.data.map((student, index) => {
          let obj = {};

          if (this.isColumnChecked("FullName")) obj.FullName = student.name;

          let fullName = student.name.trim();
          let splitName = fullName
            .replace(new RegExp(" ", "g"), " ")
            .split(" ");

          if (this.isColumnChecked("FirstName")) obj.FirstName = splitName[0];
          if (this.isColumnChecked("LastName"))
            obj.LastName = splitName[splitName.length - 1];

          if (this.isColumnChecked("Campus")) obj.Campus = student.campusName;
          if (this.isColumnChecked("Sevis"))
            obj.Sevis =
              student.sevisName &&
              student.sevisName
                .replace("Salt Lake in Person", "SLC")
                .replace("Orem In Person", "OREM");
          if (this.isColumnChecked("Level")) obj.Level = student.courseName;
          if (this.isColumnChecked("Class")) obj.Class = student.classGroup;
          if (this.isColumnChecked("I20")) obj.I20 = student.i20;
          if (this.isColumnChecked("Status")) obj.Status = student.status;
          if (this.isColumnChecked("Email")) obj.Email = student.address.email;

          if (this.isColumnChecked("EnrollmentDate"))
            obj.EnrollmentDate = dateFns.format(
              dateFns.parseISO(student.enrollmentDate),
              "MM/dd/yyyy"
            );

          if (this.isColumnChecked("LastModifiedDate"))
            obj.LastModifiedDate = dateFns.format(
              dateFns.parseISO(student.lastModifiedDate),
              "MM/dd/yyyy HH:mm:ss"
            );

          if (this.isColumnChecked("I20StartDate"))
            obj.I20StartDate = dateFns.format(
              dateFns.parseISO(student.i20StartDate),
              "MM/dd/yyyy"
            );

          if (this.isColumnChecked("I20EndDate"))
            obj.I20EndDate = dateFns.format(
              dateFns.parseISO(student.i20EndDate),
              "MM/dd/yyyy"
            );

          if (this.isColumnChecked("PassportExpDate"))
            obj.PassportExpDate = dateFns.format(
              dateFns.parseISO(student.passportExpDate),
              "MM/dd/yyyy"
            );

          if (this.isColumnChecked("VisaType"))
            obj.VisaType = student.visaType.name;

          if (this.isColumnChecked("StudentOrigin"))
            obj.StudentOrigin = student.studentOrigin.name;

          if (this.isColumnChecked("BirthDate"))
            obj.BirthDate = dateFns.format(
              dateFns.parseISO(student.birthDate),
              "MM/dd/yyyy"
            );

          if (this.isColumnChecked("Gender")) obj.Gender = student.gender;
          if (this.isColumnChecked("NationalityId"))
            obj.NationalityId = student.nationalityId;
          if (this.isColumnChecked("Street"))
            obj.Street = student.address.street;
          if (this.isColumnChecked("City")) obj.City = student.address.city;
          if (this.isColumnChecked("State")) obj.State = student.address.state;
          if (this.isColumnChecked("Zip")) obj.Zip = student.address.zip;

          if (this.isColumnChecked("CountryId"))
            obj.CountryId = student.address.countryId;

          if (this.isColumnChecked("CellPhone"))
            obj.CellPhone = student.address.cellPhone;

          if (this.isColumnChecked("Attendance")) {
            obj.Present = student.dashboardDto.totalPresent;
            obj.Tardy = student.dashboardDto.totalTardy;
            obj.Absent = student.dashboardDto.totalAbsent;
          }

          if (this.isColumnChecked("Grade")) {
            let subject1 = student.studentClasses.find((x) =>
              x.classe.code.includes("01")
            );
            if (subject1 && subject1.marks > 0)
              obj.Subject1 = (
                (subject1.marks / subject1.classe.marks) *
                1000
              ).toFixed(2);

            let subject2 = student.studentClasses.find((x) =>
              x.classe.code.includes("02")
            );

            if (subject2 && subject2.marks > 0)
              obj.Subject2 = (
                (subject2.marks / subject2.classe.marks) *
                1000
              ).toFixed(2);

            let subject3 = student.studentClasses.find((x) =>
              x.classe.code.includes("03")
            );

            if (subject3 && subject3.marks > 0)
              obj.Subject3 = (
                (subject3.marks / subject3.classe.marks) *
                1000
              ).toFixed(2);
          }

          if (this.isColumnChecked("MoodleCode")) {
            let subject1 = student.moodleCodeList.find((x) => x.includes("01"));
            if (subject1) obj.MoodleCode01 = subject1;

            let subject2 = student.moodleCodeList.find((x) => x.includes("02"));
            if (subject2) obj.MoodleCode02 = subject2;

            let subject3 = student.moodleCodeList.find((x) => x.includes("03"));
            if (subject3) obj.MoodleCode03 = subject3;
            else obj.MoodleCode03 = "";
          }

          students.push(obj);
        });

        if (type === "pdf") {
          let header = {};

          this.state.columns
            .filter(
              (x) =>
                x.IsVisible === true &&
                x.Name !== "Attendance" &&
                x.Name !== "Grade"
            )
            .map((column, index) => {
              header[column.Name] = column.Name;
            });

          if (this.isColumnChecked("Attendance")) {
            header["Present"] = "Present";
            header["Tardy"] = "Tardy";
            header["Absent"] = "Absent";
          }

          if (this.isColumnChecked("Grade")) {
            header["Subject1"] = "Subject1";
            header["Subject2"] = "Subject2";
            header["Subject3"] = "Subject3";
          }

          if (this.isColumnChecked("MoodleCode")) {
            header["MoodleCode01"] = "MoodleCode01";
            header["MoodleCode02"] = "MoodleCode02";
            header["MoodleCode03"] = "MoodleCode03";
          }

          SavePdf("Students", students, [header]);
        } else if (type === "csv") {
          this.downloadCSVFromJson("Students_Report.csv", students);
        }
      });
  }

  downloadCSVFromJson = (filename, arrayOfJson) => {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}

export default ColumnChooser;
