import jsPDF from "jspdf";
import "jspdf-autotable";
import logoreport from "logo_header_template_letter.jpg";
import * as dateFns from "date-fns";
import axios from "axios";

export function SaveTemplateLetterPdf(
  studentId,
  studentName,
  description,
  documentTypeId,
  save
) {
  studentName = studentName.trim();

  var doc = new jsPDF();
  var totalPagesExp = "{total_pages_count_string}";

  var img = new Image();
  img.src = logoreport;

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  let pageDetails = [];

  let row1 = [
    {
      content: description,
      styles: {
        valign: "middle",
        halign: "left",
        fontStyle: "normal",
        fontSize: "11",
        fillColor: [0, 0, 0],
      },
    },
  ];

  pageDetails.push(row1);

  doc.autoTable({
    body: pageDetails,
    didDrawPage: function (data) {
      doc.setFontStyle("normal");

      if (img) {
        doc.addImage(img, "JPEG", pageWidth / 2 - 25, 15, 50, 26);
      }

      doc.setFontSize(10);

      var str = "Page " + doc.internal.getNumberOfPages();

      if (typeof doc.putTotalPages === "function") {
        str = str + " of " + totalPagesExp;
      }

      doc.setFontSize(10);

      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

      doc.text(str, data.settings.margin.left, pageHeight - 10);

      doc.text(
        dateFns.format(new Date(), "MM/dd/yyyy"),
        pageWidth - data.settings.margin.right,
        pageHeight - 10,
        "right"
      );

      doc.setFontSize(10);

      doc.text(
        "220 EAST 3900 SHOUTH SUITE 1 \n SALT LAKE CITY – UT 84107 \n (801) 265-2345 \n LUMOS.EDU",
        pageWidth / 2,
        pageHeight - 40,
        "center"
      );
    },

    didParseCell: function (HookData) {
      HookData.cell.styles.fillColor = [255, 255, 255];
      HookData.cell.styles.textColor = "#000000";
    },

    margin: { top: 50 },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  var base64 = doc.output("datauristring");

  let note = {
    Type: "S",
    Description: "HR SSN Letter",
    Document: {
      FileContentBase64: base64,
      FileName: "HR SSN Letter - " + studentName + ".pdf",
      DocumentType: { Id: documentTypeId },
    },
    Student: {
      Id: studentId,
    },
  };

  if (save) {
    axios.post("students/SaveNote", note).then(() => {
      doc.save("HR SSN Letter - " + studentName + ".pdf");
    });
  } else {
    doc.save("HR SSN Letter - " + studentName + ".pdf");
  }
}
