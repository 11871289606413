/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import confirm from "reactstrap-confirm";
import { editMode } from "helpers/Utils.js";
import * as dateFns from "date-fns";

class Holiday extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  render() {
    let holidays = this.state.mainState.holidays.map((holiday) => {
      return (
        <tr key={holiday.id}>
          <td>
            <a
              onClick={this.openModalHoliday.bind(
                this,
                editMode.EDIT,
                holiday.id
              )}
              href="#"
            >
              {holiday.name}
            </a>
          </td>

          <td>{holiday.date}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  {/* <CardTitle tag="h4">Campuses</CardTitle> */}
                  <Button
                    className="my-3"
                    color="primary"
                    onClick={this.openModalHoliday.bind(this, editMode.INSERT)}
                  >
                    Add holiday
                  </Button>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Date</th>
                      </tr>
                    </thead>

                    <tbody>{holidays}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.mainState.currentPage}
                    totalItemsCount={this.state.mainState.totalResults}
                    onChange={this._refreshHolidays.bind(this)}
                  />

                  <Modal
                    isOpen={this.state.mainState.openModal}
                    toggle={this.toggleNewHolidayModal.bind(this)}
                    centered={true}
                    autoFocus={true}
                    backdrop="static"
                    onClosed={this.onCloseModal}
                    style={{
                      maxWidth: "500px",
                      width: "100%",
                    }}
                  >
                    <ModalHeader toggle={this.toggleNewHolidayModal.bind(this)}>
                      {this.state.modalState.editMode === editMode.INSERT
                        ? "Add a new holiday"
                        : "Edit a holiday"}
                    </ModalHeader>
                    <ModalBody>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="holidayName">Name</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Holiday.Name.ErrorMsg
                              )}`}
                              type="text"
                              name="Name"
                              id="holidayName"
                              autoComplete="nope"
                              value={this.state.modalState.Holiday.Name.Value}
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Holiday.Name.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="holidayDate">Date</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Holiday.Date.ErrorMsg
                              )}`}
                              type="date"
                              name="Date"
                              id="holidayDate"
                              autoComplete="nope"
                              value={this.state.modalState.Holiday.Date.Value}
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Holiday.Date.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={
                          this.state.modalState.editMode === editMode.INSERT
                            ? this.addHoliday.bind(this)
                            : this.updateHoliday.bind(this)
                        }
                        disabled={!this.state.modalState.formValid}
                      >
                        {this.state.modalState.editMode === editMode.INSERT
                          ? "Add"
                          : "Update"}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.toggleNewHolidayModal.bind(this)}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  getInitialState = () => {
    const initialState = {
      mainState: this.getMainState(),
      modalState: this.getModalState(),
    };

    return initialState;
  };

  getMainState = () => {
    const mainState = {
      holidays: [],
      openModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
    };

    return mainState;
  };

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getHoliday = () => {
    const holiday = {
      Id: this.getEntity(),
      Name: this.getEntity(),
      Date: this.getEntity(),
    };
    return holiday;
  };

  getModalState = () => {
    const modalState = {
      Holiday: this.getHoliday(),
      editMode: "",
      cards: [1, 2],
      formValid: false,
    };

    return modalState;
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          modalState: this.getModalState(),
        },
        resolve(true)
      );
    });
  };

  validateField(fieldName, value) {
    let { modalState } = this.state;

    switch (fieldName) {
      case "Name":
        modalState.Holiday.Name.IsValid = value.length >= 1;
        modalState.Holiday.Name.ErrorMsg = modalState.Holiday.Name.IsValid
          ? ""
          : "Name is empty";
        break;
      case "Date":
        modalState.Holiday.Date.IsValid = value.length >= 1;
        modalState.Holiday.Date.ErrorMsg = modalState.Holiday.Date.IsValid
          ? ""
          : "Date is empty";
        break;
      default:
        break;
    }

    this.setState(
      {
        modalState: modalState,
      },
      this.validateForm()
    );
  }

  validateForm() {
    let { modalState } = this.state;

    modalState.formValid =
      modalState.Holiday.Name.IsValid === true &&
      modalState.Holiday.Date.IsValid === true;

    this.setState({
      modalState: modalState,
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { modalState } = this.state;
    modalState.Holiday[name].Value = value;

    this.setState({ modalState: modalState }, () => {
      this.validateField(name, value);
    });
  };

  componentDidMount() {
    this._refreshHolidays();
  }

  toggleNewHolidayModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;
      mainState.openModal = !mainState.openModal;
      this.setState(
        {
          mainState: mainState,
        },
        resolve(true)
      );
    });
  };

  addHoliday() {
    let holiday = {
      Name: this.state.modalState.Holiday.Name.Value,
      Date: this.state.modalState.Holiday.Date.Value,
    };

    axios.post("holidays", holiday).then((response) => {
      this.toggleNewHolidayModal().then(() => {
        this._refreshHolidays().then(() => {
          this.resetModal();
        });
      });
    });
  }

  updateHoliday() {
    let holiday = {
      Id: this.state.modalState.Holiday.Id.Value,
      Name: this.state.modalState.Holiday.Name.Value,
      Date: this.state.modalState.Holiday.Date.Value,
    };

    axios.put("holidays", holiday).then((response) => {
      this.toggleNewHolidayModal().then(() => {
        this._refreshHolidays().then(() => {
          this.resetModal();
        });
      });
    });
  }

  openModalHoliday(mode, id) {
    let { modalState } = this.state;
    modalState.editMode = mode;

    this.setState({ modalState: modalState });

    if (mode === editMode.INSERT) {
      this.toggleNewHolidayModal();
    } else {
      axios.get("holidays/byid?id=" + id).then((response) => {
        modalState.Holiday.Id.Value = response.data.data.id;

        modalState.Holiday.Name.Value = response.data.data.name;
        modalState.Holiday.Name.IsValid = true;

        modalState.Holiday.Date.Value = dateFns.format(
          dateFns.parseISO(response.data.data.date),
          "yyyy-MM-dd"
        );
        modalState.Holiday.Date.IsValid = true;

        this.validateForm();

        this.setState(
          {
            modalState: modalState,
          },
          () => {
            this.toggleNewHolidayModal();
          }
        );
      });
    }
  }

  async deleteHoliday(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Are you sure you wish to delete this item?",
    });

    if (result) {
      axios.delete("holidays/" + id).then(() => {
        this._refreshHolidays();
      });
    }
  }

  _refreshHolidays = (pageNumber) => {
    return new Promise((resolve, reject) => {
      let page = pageNumber || 1;
      let itemsPerPage = this.state.mainState.itemsPerPage;

      axios
        .get("holidays?page=" + page + "&pageSize=" + itemsPerPage)
        .then((response) => {
          let { mainState } = this.state;

          mainState.holidays = [];
          response.data.data.items.map((holiday, index) =>
            mainState.holidays.push({
              id: holiday.id,
              name: holiday.name,
              date: dateFns.format(
                dateFns.parseISO(holiday.date),
                "MM/dd/yyyy"
              ),
            })
          );

          mainState.currentPage = pageNumber;
          mainState.totalResults = response.data.data.totalCount;

          this.setState(
            {
              mainState: mainState,
            },
            resolve(true)
          );
        });
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  setCurrentPage(pageNumber) {
    let { mainState } = this.state;
    mainState.currentPage = pageNumber;
    this.setState({ mainState: mainState });
  }
}

export default Holiday;
