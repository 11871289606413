/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import { SavePdf } from "components/Report/GeneratePdf.js";

class AvailableSeats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      classes: [],
      openAvailableSeatsPanel: false,
    };
  }

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          classes: [],
          openAvailableSeatsPanel: false,
        },
        resolve(true)
      );
    });
  };

  refresh() {
    return new Promise((resolve, reject) => {
      axios.get("dashboard/AvailableSeats").then((response) => {
        let classes = [];
        response.data.data.availableSeats.map((classe, index) =>
          classes.push({
            code: classe.name,
            campusName: classe.campusName,
            total: classe.total,
            totalAvailable: classe.totalAvailable,
          })
        );

        this.setState(
          {
            classes: classes,
          },
          resolve(true)
        );
      });
    });
  }

  onOpened() {
    this.refresh();
  }

  render() {
    let classes = this.state.classes.map((classe, index) => {
      return (
        <tr key={index}>
          <td>{classe.code}</td>
          <td>{classe.campusName}</td>
          <td>{classe.total}</td>
          <td>
            <strong>{classe.totalAvailable}</strong>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggleAvailableSeatsModal.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                unmountOnClose={true}
                onOpened={this.onOpened.bind(this)}
                scrollable={true}
                onClosed={this.onCloseModal}
              >
                <ModalHeader
                  toggle={this.props.toggleAvailableSeatsModal.bind(this)}
                >
                  Available seats
                </ModalHeader>
                <ModalBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Class</th>
                        <th>Campus</th>
                        <th>Total seats</th>
                        <th>Available seats</th>
                      </tr>
                    </thead>

                    <tbody>{classes}</tbody>
                  </Table>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="success"
                    onClick={SavePdf.bind(this, "Classes", this.state.classes, [
                      {
                        code: "Class",
                        campusName: "Campus",
                        total: "Total Seats",
                        totalAvailable: "Available Seats",
                      },
                    ])}
                  >
                    Report
                  </Button>

                  <Button
                    color="primary"
                    onClick={this.props.toggleAvailableSeatsModal.bind(this)}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AvailableSeats;
