/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  Label,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  FormGroup,
  Badge,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import { editMode } from "helpers/Utils.js";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";
import Select from "react-select";
import * as dateFns from "date-fns";
import DatePicker from "react-datepicker";
import { SavePdf } from "components/Report/GeneratePdf.js";

class LevelChangeReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      students: [],
      openModal: false,
      editMode: "",
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      StartDateSearch: dateFns.startOfWeek(new Date()),
      EndDateSearch: dateFns.endOfWeek(new Date()),
      enrollmentTypeId: "all",
    };
  };

  render() {
    let students = this.state.students.map((student, index) => {
      return (
        <tr key={index}>
          <td>
            {" "}
            <a
              onClick={this.openStudentModal.bind(
                this,
                editMode.EDIT,
                student.id
              )}
              href="#"
            >
              {student.name}
            </a>
          </td>

          <td>{student.classCode}</td>
          <td>
            {student.active === true
              ? student.createdDate
              : student.updatedDate}
          </td>
          <td>
            <Badge
              style={{ fontSize: "medium" }}
              color={student.active === true ? "success" : "danger"}
            >
              {student.active === true ? "Enrollment" : "Unenrollment"}
            </Badge>
          </td>
          <td>{student.userName}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.EndDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "EndDateSearch"
                        )}
                        name="EndDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Type</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.enrollmentTypeId &&
                            this.enrollmentOptions.find(
                              (x) => x.value === this.state.enrollmentTypeId
                            )
                          }
                          onChange={this.handleEnrollmentTypeChange}
                          options={this.enrollmentOptions}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Subject</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>User</th>
                      </tr>
                    </thead>

                    <tbody>{students}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.currentPage}
                    totalItemsCount={this.state.totalResults}
                    onChange={this._refreshStudents.bind(this)}
                    pdf={this.DownloadPdf.bind(this, "pdf")}
                    csv={this.DownloadPdf.bind(this, "csv")}
                    buttonName="Export to CSV"
                    pdfButtonName="Export to PDF"
                  />

                  <StudentEdit2
                    isOpen={this.state.openModal}
                    toggleStudentModal={this.toggleStudentModal.bind(this)}
                    editMode={this.state.editMode}
                    id={this.state.id}
                    callback={this._refreshStudents.bind(this)}
                    tables={this.props.tables}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    this._refreshStudents();
  }

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;

    mainState[name] = date;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshStudents();
    });
  };

  handleEnrollmentTypeChange = (selectedOption) => {
    let mainState = this.state;

    mainState.enrollmentTypeId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshStudents();
    });
  };

  toggleStudentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openModal = !mainState.openModal;
      this.setState(mainState, resolve(true));
    });
  };

  openStudentModal = (mode, id) => {
    this.toggleStudentModal();
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.editMode = mode;
      mainState.id = id;
      this.setState(mainState, resolve(true));
    });
  };

  _refreshStudents(pageNumber) {
    return new Promise((resolve, reject) => {
      let page = pageNumber || 1;
      let itemsPerPage = this.state.itemsPerPage;
      let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
      let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");
      let enrollmentTypeId = this.state.enrollmentTypeId;

      if (enrollmentTypeId === "all") enrollmentTypeId = "";

      axios
        .get(
          "students/levelchangereport?" +
            "startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&enrollmentTypeId=" +
            enrollmentTypeId +
            "&page=" +
            page +
            "&pageSize=" +
            itemsPerPage
        )
        .then((response) => {
          let mainState = this.state;

          mainState.students = [];
          response.data.data.items.map((student, index) =>
            mainState.students.push({
              id: student.id,
              name: student.name,
              classCode: student.studentClasses[0].classe.code,
              createdDate: dateFns.format(
                dateFns.parseISO(student.studentClasses[0].createdDate),
                "MM/dd/yyyy"
              ),
              updatedDate: dateFns.format(
                dateFns.parseISO(student.studentClasses[0].updatedDate),
                "MM/dd/yyyy"
              ),
              userName:
                student.studentClasses[0].user.name &&
                student.studentClasses[0].user.name.slice(
                  0,
                  student.studentClasses[0].user.name.lastIndexOf("@")
                ),
              active: student.studentClasses[0].active,
            })
          );

          mainState.currentPage = pageNumber;
          mainState.totalResults = response.data.data.totalCount;

          this.setState(mainState, resolve(true));
        });
    });
  }

  DownloadPdf(type) {
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");
    let enrollmentTypeId = this.state.enrollmentTypeId;

    if (enrollmentTypeId === "all") enrollmentTypeId = "";

    axios
      .get(
        "students/levelchangereport?" +
          "startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&enrollmentTypeId=" +
          enrollmentTypeId +
          "&page=1&pageSize=9999999"
      )
      .then((response) => {
        let students = [];
        response.data.data.items.map((student, index) =>
          students.push({
            id: student.id,
            name: student.name,
            classCode: student.studentClasses[0].classe.code,
            date:
              student.studentClasses[0].active === true
                ? dateFns.format(
                    dateFns.parseISO(student.studentClasses[0].createdDate),
                    "MM/dd/yyyy"
                  )
                : dateFns.format(
                    dateFns.parseISO(student.studentClasses[0].updatedDate),
                    "MM/dd/yyyy"
                  ),
            userName:
              student.studentClasses[0].user.name &&
              student.studentClasses[0].user.name.slice(
                0,
                student.studentClasses[0].user.name.lastIndexOf("@")
              ),
            active: student.studentClasses[0].active,
            type:
              student.studentClasses[0].active === true
                ? "Enrollment"
                : "Unenrollment",
          })
        );

        if (type === "pdf") {
          SavePdf("Students", students, [
            {
              name: "Name",
              classCode: "Subject",
              date: "Date",
              type: "Type",
              userName: "User",
            },
          ]);
        } else {
          let studentCsv = [];

          students.forEach((item) => {
            studentCsv.push({
              Name: item.name,
              ClassCode: item.classCode,
              Date: item.date,
              Type: item.type,
              UserName: item.userName,
            });
          });

          this.downloadCSVFromJson("LevelChange.csv", studentCsv);
        }
      });
  }

  downloadCSVFromJson = (filename, arrayOfJson) => {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  enrollmentOptions = [
    { value: "all", label: "All types" },
    { value: "e", label: "Enrollment" },
    { value: "u", label: "Unenrollment" },
  ];
}

export default LevelChangeReport;
