/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import { FormatFullDateList, editMode } from "helpers/Utils.js";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";
import Can from "components/Can/Can";

class StudentClassView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      students: [],
      openStudentModal: false,
      studentId: "",
    };
  }

  toggleStudentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openStudentModal = !mainState.openStudentModal;
      this.setState(mainState, resolve(true));
    });
  };

  openStudentEditModal = (id) => {
    this.toggleStudentModal();
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.studentId = id;
      this.setState(mainState, resolve(true));
    });
  };

  refresh() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.props.url + this.props.searchId + "&page=1&pageSize=999999999"
        )
        .then((response) => {
          let modalState = this.state;

          modalState.students = [];
          response.data.data.items.map((student, index) =>
            modalState.students.push({
              id: student.id,
              name: student.name,
              i20EndDate: FormatFullDateList(student.i20EndDate),
              i20: student.i20,
            })
          );

          modalState.totalStudents = response.data.data.totalCount;

          this.setState({ modalState }, resolve(true));
        });
    });
  }

  onOpened() {
    this.refresh();
  }

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          students: [],
          openStudentModal: false,
          studentId: "",
        },
        resolve(true)
      );
    });
  };

  render() {
    let students = this.state.students.map((student) => {
      return (
        <tr key={student.id}>
          <Can
            feature="/admin/student"
            yes={() => (
              <td>
                <a
                  onClick={this.openStudentEditModal.bind(this, student.id)}
                  href="#"
                >
                  {student.name}
                </a>
              </td>
            )}
            no={() => <td>{student.name}</td>}
          ></Can>

          <td>{student.i20}</td>
          <td>
            <strong>{student.i20EndDate}</strong>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggleStudentModal.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                unmountOnClose={true}
                onOpened={this.onOpened.bind(this)}
                onClosed={this.onCloseModal}
                scrollable
              >
                <ModalHeader toggle={this.props.toggleStudentModal.bind(this)}>
                  {this.props.headerName}
                </ModalHeader>
                <ModalBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>I20</th>
                        <th>I20 End Date</th>
                      </tr>
                    </thead>

                    <tbody>{students}</tbody>
                  </Table>
                </ModalBody>
                <ModalFooter>
                  <h6
                    style={{
                      marginLeft: "15px",
                      marginRight: "auto",
                      marginTop: "15px",
                      textTransform: "inherit",
                    }}
                  >
                    <strong className="text-secondary">
                      {students.length}
                    </strong>{" "}
                    {students.length > 1 ? "records" : "record"}
                  </h6>

                  <Button
                    color="primary"
                    onClick={this.props.toggleStudentModal.bind(this)}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
            <StudentEdit2
              isOpen={this.state.openStudentModal}
              toggleStudentModal={this.toggleStudentModal.bind(this)}
              editMode={editMode.EDIT}
              id={this.state.studentId}
              tables={this.props.tables}
            />
          </Row>
        </div>
      </>
    );
  }
}

export default StudentClassView;
