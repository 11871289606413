/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import confirm from "reactstrap-confirm";

class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taskId: "",
      fromSemesterId: "",
      toSemesterId: "",
      logArea: "",
      semesters: [],
    };
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Task</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.taskId &&
                            this.taskOptions.find(
                              (x) => x.value === this.state.taskId
                            )
                          }
                          onChange={this.handleTaskChange}
                          options={this.taskOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="studentClass">From Semester</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.fromSemesterId &&
                            this.state.semesters.find(
                              (x) => x.value === this.state.fromSemesterId
                            )
                          }
                          onChange={this.handleFromSemesterChange}
                          options={this.state.semesters}
                          isDisabled={this.state.taskId === ""}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup>
                        <Label for="studentClass">To Semester</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.toSemesterId &&
                            this.state.semesters.find(
                              (x) => x.value === this.state.toSemesterId
                            )
                          }
                          onChange={this.handleToSemesterChange}
                          options={this.state.semesters}
                          isDisabled={
                            this.state.taskId === "" ||
                            this.state.fromSemesterId === ""
                          }
                        />
                      </FormGroup>
                    </Col>

                    <FormGroup>
                      <Label>&nbsp;</Label>
                      <Button
                        style={{ margin: "0" }}
                        color="primary"
                        disabled={
                          this.state.taskId === "" ||
                          this.state.fromSemesterId === "" ||
                          this.state.toSemesterId === ""
                        }
                        onClick={this.execute}
                      >
                        Execute
                      </Button>
                    </FormGroup>
                  </Row>
                </CardHeader>

                <CardBody></CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Result</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody
                  style={{
                    minHeight: "240px",
                  }}
                >
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Input
                          type="textarea"
                          value={this.state.logArea}
                          style={{ minHeight: "240px" }}
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </div>
      </>
    );
  }

  componentDidMount() {
    this.refreshSemester();
  }

  handleFromSemesterChange = (selectedOption) => {
    this.setState({ fromSemesterId: selectedOption.value });
  };

  handleToSemesterChange = (selectedOption) => {
    this.setState({ toSemesterId: selectedOption.value });
  };

  refreshSemester = () => {
    return new Promise((resolve, reject) => {
      let semesters = [];

      this.props.tables.semesters.map((semester, index) =>
        semesters.push({
          value: semester.id,
          label: semester.name,
        })
      );

      this.setState({ semesters: semesters }, resolve(true));
    });
  };

  handleTaskChange = (selectedOption) => {
    this.setState({ taskId: selectedOption.value });
  };

  setLog = (log) => {
    let logArea = this.state.logArea;
    logArea += "\n" + log;
    this.setState({ logArea: logArea });
  };

  execute = async () => {
    let taskName = this.taskOptions.find(
      (x) => x.value === this.state.taskId
    ).label;

    let result = await confirm({
      title: <>Warning</>,
      message:
        "Do you really want to perform task '" +
        taskName +
        "'?\n If you confirm, the operation will not be undone. " +
        "\n(Confirm that you have selected the correct semester)",
    });

    if (result) {
      let { taskId, fromSemesterId, toSemesterId } = this.state;

      axios.post(
        "tasks/execute?taskId=" +
          taskId +
          "&fromSemesterId=" +
          fromSemesterId +
          "&toSemesterId=" +
          toSemesterId
      );
    }
  };

  taskOptions = [
    { value: "CC", label: "Copy class" },
    //{ value: "CS", label: "Close semester" },
    // { value: "ST", label: "Start semester" },
    // { value: "HP", label: "Insert homework and participation" },
  ];
}

export default Tasks;
