import * as dateFns from "date-fns";

export const editMode = {
  INSERT: "insert",
  EDIT: "edit",
  CLONE: "clone",
  VIEW: "view",
};

export function ErrorMessage(error) {
  var message = "";

  if (error.response) {
    if (error.response.data) {
      if (error.response.data.messages) {
        message = error.response.data.messages.map((error) => error).join("\n");
      } else {
        message = error.response.data;
      }
    } else {
      message = error.message;
    }
  } else if (error.message) {
    message = error.message;
  }

  return message;
}

export function FormatDate(iDate) {
  if (iDate && iDate !== "0001-01-01T00:00:00") {
    var inputDate = dateFns.format(dateFns.parseISO(iDate), "yyyy-MM-dd");

    return inputDate;
  } else return null;
}

export function FormatMonthYearDateList(iDate) {
  if (iDate && iDate !== "0001-01-01T00:00:00") {
    var inputDate = dateFns.format(dateFns.parseISO(iDate), "MM/dd");

    return inputDate;
  } else return null;
}

export function FormatFullDateList(iDate) {
  if (iDate && iDate !== "0001-01-01T00:00:00") {
    var inputDate = dateFns.format(dateFns.parseISO(iDate), "MM/dd/yyyy");

    return inputDate;
  } else return null;
}
