/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  CustomInput,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import confirm from "reactstrap-confirm";
import { editMode } from "helpers/Utils.js";
import * as dateFns from "date-fns";
import Select from "react-select";
import DatePicker from "react-datepicker";
import SearchInput from "components/Search/Search.input.component";
import { isBefore } from "date-fns";

class Partner extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  render() {
    let partners = this.state.mainState.partners.map((partner) => {
      return (
        <tr
          key={partner.id}
          style={{ backgroundColor: partner.inactive ? "#fceded" : "" }}
        >
          <td>
            <a
              onClick={this.openModalPartner.bind(
                this,
                editMode.EDIT,
                partner.id,
                partner.inactive
              )}
              href="#"
            >
              {partner.name}
            </a>
          </td>

          <td>{partner.code}</td>
          <td>{partner.discountPercentage}</td>
          <td>{partner.singleUse}</td>
          <td>{partner.type}</td>
          <td>
            {dateFns.format(partner.expirationDate, "MM/dd/yyyy hh:mm:ss aa")}
          </td>
          <td>{partner.userName}</td>
          <td>{partner.createdDate}</td>
          <td>
            {partner.deletedAt &&
              dateFns.format(partner.deletedAt, "MM/dd/yyyy hh:mm:ss aa")}
          </td>

          {(!partner.inactive && (
            <td>
              <Button
                color="danger"
                size="sm"
                onClick={this.deletePartner.bind(this, partner.id)}
              >
                Inactivate
              </Button>
            </td>
          )) || <td></td>}
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    {/* <CardTitle tag="h4">Campuses</CardTitle> */}
                    <Button
                      className="my-3"
                      color="primary"
                      onClick={this.openModalPartner.bind(
                        this,
                        editMode.INSERT
                      )}
                    >
                      Add partner
                    </Button>

                    <SearchInput
                      searchInput={this.state.mainState.codeSearch}
                      placeholder="Search by code"
                      handleSearchInput={this.handleSearchInput.bind(
                        this,
                        "codeSearch"
                      )}
                    />

                    <SearchInput
                      searchInput={this.state.mainState.nameSearch}
                      placeholder="Search by name"
                      handleSearchInput={this.handleSearchInput.bind(
                        this,
                        "nameSearch"
                      )}
                    />

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Status</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.mainState.statusSearch &&
                            this.statusOptions.find(
                              (x) =>
                                x.value === this.state.mainState.statusSearch
                            )
                          }
                          onChange={this.handleStatusChange}
                          options={this.statusOptions}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Discount %</th>
                        <th>Single Use?</th>
                        <th>Type</th>
                        <th>Expiration Date</th>
                        <th>User</th>
                        <th>Created Date</th>
                        <th>Inactive Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>{partners}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.mainState.currentPage}
                    totalItemsCount={this.state.mainState.totalResults}
                    onChange={this._refreshPartners.bind(this)}
                  />

                  <Modal
                    isOpen={this.state.mainState.openModal}
                    toggle={this.toggleNewPartnerModal.bind(this)}
                    centered={true}
                    autoFocus={true}
                    size={"lg"}
                    backdrop="static"
                    onClosed={this.onCloseModal}
                  >
                    <ModalHeader toggle={this.toggleNewPartnerModal.bind(this)}>
                      {this.state.modalState.editMode === editMode.INSERT
                        ? "Add a new partner"
                        : "Edit a partner"}
                    </ModalHeader>
                    <ModalBody>
                      <Row form>
                        <Col md={8}>
                          <FormGroup>
                            <Label for="partnerName">Company</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Partner.Name.ErrorMsg
                              )}`}
                              type="text"
                              name="Name"
                              id="partnerName"
                              autoComplete="nope"
                              value={this.state.modalState.Partner.Name.Value}
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Partner.Name.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>

                        <Col md={4}>
                          <FormGroup>
                            <Label for="assignmentMaxGrade">Discount%</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Partner.DiscountPercentage
                                  .ErrorMsg
                              )}`}
                              type="number"
                              name="DiscountPercentage"
                              id="assignmentMaxGrade"
                              autoComplete="nope"
                              value={
                                this.state.modalState.Partner.DiscountPercentage
                                  .Value
                              }
                              onChange={this.handleUserInput}
                              onBlur={this.maxGradeOnBlur}
                              onFocus={this.handleFocus}
                            />
                            <span className="text-danger">
                              {
                                this.state.modalState.Partner.DiscountPercentage
                                  .ErrorMsg
                              }
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="partnerName">Code</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Partner.Code.ErrorMsg
                              )}`}
                              type="text"
                              name="Code"
                              id="partnerName"
                              autoComplete="nope"
                              value={this.state.modalState.Partner.Code.Value}
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Partner.Code.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <Label for="classeCampus">Type</Label>
                            <Select
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Partner.Type.ErrorMsg
                              )}`}
                              value={
                                this.state.modalState.Partner.Type.Value &&
                                this.typeOptions.find(
                                  (x) =>
                                    x.value ===
                                    this.state.modalState.Partner.Type.Value
                                )
                              }
                              onChange={this.handleTypeChange}
                              options={this.typeOptions}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Partner.Type.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="partnerName">Expiration Date</Label>

                            <DatePicker
                              selected={
                                this.state.modalState.Partner.ExpirationDate
                                  .Value
                              }
                              onChange={this.handleDateTimeInput.bind(
                                this,
                                "ExpirationDate"
                              )}
                              name="ExpirationDate"
                              showTimeSelect
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                            <span className="text-danger">
                              {
                                this.state.modalState.Partner.ExpirationDate
                                  .ErrorMsg
                              }
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="partnerName">Contact Name</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Partner.ContactName
                                  .ErrorMsg
                              )}`}
                              type="text"
                              name="ContactName"
                              id="partnerName"
                              autoComplete="nope"
                              value={
                                this.state.modalState.Partner.ContactName.Value
                              }
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {
                                this.state.modalState.Partner.ContactName
                                  .ErrorMsg
                              }
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="partnerName">Email</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Partner.Email.ErrorMsg
                              )}`}
                              type="text"
                              name="Email"
                              id="partnerName"
                              autoComplete="nope"
                              value={this.state.modalState.Partner.Email.Value}
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Partner.Email.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="partnerName">Country</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.Partner.Country.ErrorMsg
                              )}`}
                              type="text"
                              name="Country"
                              id="partnerName"
                              autoComplete="nope"
                              value={
                                this.state.modalState.Partner.Country.Value
                              }
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.Partner.Country.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <div class="span3">
                              <label for="checkbox" class="checkbox">
                                <CustomInput
                                  style={{ marginTop: "15px" }}
                                  className="activeCheckBox"
                                  type="checkbox"
                                  id="activeCheckBox"
                                  name="activeCheckBox"
                                  label="Single use? (By checking this option, this code will expire after the first use)"
                                  checked={
                                    this.state.modalState.Partner.SingleUse
                                      .Value
                                  }
                                  onChange={this.onCheckActiveChange.bind(this)}
                                />
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="success"
                        onClick={this.generateCode.bind(this, 6)}
                        style={{ marginLeft: "5px", marginRight: "auto" }}
                      >
                        Generate Code
                      </Button>
                      <Button
                        color="primary"
                        onClick={
                          this.state.modalState.editMode === editMode.INSERT
                            ? this.addPartner.bind(this)
                            : this.updatePartner.bind(this)
                        }
                        disabled={!this.state.modalState.formValid}
                      >
                        {this.state.modalState.editMode === editMode.INSERT
                          ? "Add"
                          : "Update"}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.toggleNewPartnerModal.bind(this)}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  getInitialState = () => {
    const initialState = {
      mainState: this.getMainState(),
      modalState: this.getModalState(),
    };

    return initialState;
  };

  getMainState = () => {
    const mainState = {
      partners: [],
      openModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      codeSearch: "",
      nameSearch: "",
      statusSearch: "Active",
    };

    return mainState;
  };

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getPartner = () => {
    const partner = {
      Id: this.getEntity(),
      Name: this.getEntity(),
      Code: this.getEntity(),
      DiscountPercentage: this.getEntity(),
      SingleUse: this.getEntity(),
      Type: this.getEntity(),
      ContactName: this.getEntity(),
      Email: this.getEntity(),
      Country: this.getEntity(),
      ExpirationDate: {
        Value: new Date(),
        IsValid: false,
        ErrorMsg: "",
      },
    };

    return partner;
  };

  getModalState = () => {
    const modalState = {
      Partner: this.getPartner(),
      editMode: "",
      formValid: false,
    };

    return modalState;
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          modalState: this.getModalState(),
        },
        resolve(true)
      );
    });
  };

  validateField(fieldName, value) {
    let { modalState } = this.state;

    switch (fieldName) {
      case "Name":
        modalState.Partner.Name.IsValid = value.length >= 1;
        modalState.Partner.Name.ErrorMsg = modalState.Partner.Name.IsValid
          ? ""
          : "Name is empty";
        break;
      case "Code":
        modalState.Partner.Code.IsValid = value.length >= 1;
        modalState.Partner.Code.ErrorMsg = modalState.Partner.Code.IsValid
          ? ""
          : "Code is empty";
        break;
      case "DiscountPercentage":
        modalState.Partner.DiscountPercentage.IsValid = value.length >= 1;
        modalState.Partner.DiscountPercentage.ErrorMsg = modalState.Partner
          .DiscountPercentage.IsValid
          ? ""
          : "Discount is empty";
        break;
      case "Type":
        modalState.Partner.Type.IsValid = value >= 1;
        modalState.Partner.Type.ErrorMsg = modalState.Partner.Type.IsValid
          ? ""
          : "Type is empty";
        break;

      case "ContactName":
        modalState.Partner.ContactName.IsValid = value.length >= 1;
        modalState.Partner.ContactName.ErrorMsg = modalState.Partner.ContactName
          .IsValid
          ? ""
          : "Contact Name is empty";
        break;

      case "Email":
        modalState.Partner.Email.IsValid = value.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        )
          ? true
          : false;
        modalState.Partner.Email.ErrorMsg = modalState.Partner.Email.IsValid
          ? ""
          : "E-mail is invalid";
        break;

      case "Country":
        modalState.Partner.Country.IsValid = value.length >= 1;
        modalState.Partner.Country.ErrorMsg = modalState.Partner.Country.IsValid
          ? ""
          : "Country is empty";
        break;

      default:
        break;
    }

    this.setState({ modalState: modalState }, this.validateForm());
  }

  onCheckActiveChange = (e) => {
    let { modalState } = this.state;

    modalState.Partner.SingleUse.Value = e.target.checked;

    this.setState({ modalState: modalState });
  };

  validateForm() {
    let { modalState } = this.state;

    modalState.formValid =
      modalState.Partner.Name.IsValid === true &&
      modalState.Partner.Code.IsValid === true &&
      modalState.Partner.ContactName.IsValid === true &&
      modalState.Partner.Email.IsValid === true &&
      modalState.Partner.Country.IsValid === true &&
      modalState.Partner.DiscountPercentage.IsValid === true &&
      modalState.Partner.Type.IsValid === true;

    this.setState({
      modalState: modalState,
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { modalState } = this.state;
    modalState.Partner[name].Value = value;

    this.setState({ modalState: modalState }, () => {
      this.validateField(name, value);
    });
  };

  componentDidMount() {
    this._refreshPartners();
  }

  toggleNewPartnerModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;
      mainState.openModal = !mainState.openModal;
      this.setState(
        {
          mainState: mainState,
        },
        resolve(true)
      );
    });
  };

  addPartner() {
    let partner = {
      Name: this.state.modalState.Partner.Name.Value,
      Code: this.state.modalState.Partner.Code.Value,
      DiscountPercentage:
        this.state.modalState.Partner.DiscountPercentage.Value,
      SingleUse: this.state.modalState.Partner.SingleUse.Value,
      Type: this.state.modalState.Partner.Type.Value,
      ContactName: this.state.modalState.Partner.ContactName.Value,
      Email: this.state.modalState.Partner.Email.Value,
      Country: this.state.modalState.Partner.Country.Value,
      ExpirationDate:
        this.state.modalState.Partner.ExpirationDate.Value.toLocaleString(
          "en-US",
          { timeZone: "America/Denver" }
        ),
    };

    axios.post("partners", partner).then((response) => {
      this.toggleNewPartnerModal().then(() => {
        this._refreshPartners().then(() => {
          this.resetModal();
        });
      });
    });
  }

  updatePartner() {
    let partner = {
      Id: this.state.modalState.Partner.Id.Value,
      Name: this.state.modalState.Partner.Name.Value,
      Code: this.state.modalState.Partner.Code.Value,
      DiscountPercentage:
        this.state.modalState.Partner.DiscountPercentage.Value,
      SingleUse: this.state.modalState.Partner.SingleUse.Value,
      Type: this.state.modalState.Partner.Type.Value,
      ContactName: this.state.modalState.Partner.ContactName.Value,
      Email: this.state.modalState.Partner.Email.Value,
      Country: this.state.modalState.Partner.Country.Value,
      ExpirationDate:
        this.state.modalState.Partner.ExpirationDate.Value.toLocaleString(
          "en-US",
          { timeZone: "America/Denver" }
        ),
    };

    axios.put("partners", partner).then((response) => {
      this.toggleNewPartnerModal().then(() => {
        this._refreshPartners().then(() => {
          this.resetModal();
        });
      });
    });
  }

  async openModalPartner(mode, id, inactive) {
    if (inactive) {
      await confirm({
        title: <>Warning</>,
        message: "This parter has already been used and cannot be edited.",
        cancelText: null,
      });
    } else {
      let { modalState } = this.state;
      modalState.editMode = mode;

      this.setState({ modalState: modalState });

      if (mode === editMode.INSERT) {
        this.toggleNewPartnerModal();
      } else {
        axios.get("partners/byid?id=" + id).then((response) => {
          modalState.Partner.Id.Value = response.data.data.id;

          modalState.Partner.Name.Value = response.data.data.name;
          modalState.Partner.Name.IsValid = true;

          modalState.Partner.Code.Value = response.data.data.code;
          modalState.Partner.Code.IsValid = true;

          modalState.Partner.DiscountPercentage.Value =
            response.data.data.discountPercentage;
          modalState.Partner.DiscountPercentage.IsValid = true;

          modalState.Partner.ExpirationDate.Value = dateFns.parseISO(
            response.data.data.expirationDate
          );

          modalState.Partner.ExpirationDate.IsValid = true;

          modalState.Partner.SingleUse.Value = response.data.data.singleUse;
          modalState.Partner.SingleUse.IsValid = true;

          modalState.Partner.Type.Value = response.data.data.type;

          modalState.Partner.ContactName.Value = response.data.data.contactName;
          modalState.Partner.ContactName.IsValid = true;

          modalState.Partner.Email.Value = response.data.data.email;
          modalState.Partner.Email.IsValid = true;

          modalState.Partner.Country.Value = response.data.data.country;
          modalState.Partner.Country.IsValid = true;

          if (response.data.data.type > 0)
            modalState.Partner.Type.IsValid = true;
          else this.validateField("Type");

          this.validateForm();

          this.setState(
            {
              modalState: modalState,
            },
            () => {
              this.toggleNewPartnerModal();
            }
          );
        });
      }
    }
  }

  async deletePartner(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Do you want to inactivate this item?",
    });

    if (result) {
      axios.delete("partners/" + id).then(() => {
        this._refreshPartners();
      });
    }
  }

  _refreshPartners = (pageNumber) => {
    return new Promise((resolve, reject) => {
      let page = pageNumber || 1;
      let itemsPerPage = this.state.mainState.itemsPerPage;

      let statusSearch =
        this.state.mainState.statusSearch == "All"
          ? null
          : this.state.mainState.statusSearch == "Active"
          ? true
          : false;

      axios
        .get(
          "partners/get-all?code=" +
            this.state.mainState.codeSearch +
            "&name=" +
            this.state.mainState.nameSearch +
            "&active=" +
            statusSearch +
            "&page=" +
            page +
            "&pageSize=" +
            itemsPerPage
        )
        .then((response) => {
          let { mainState } = this.state;

          mainState.partners = [];
          response.data.data.items.map((partner, index) =>
            mainState.partners.push({
              id: partner.id,
              name: partner.name,
              discountPercentage: partner.discountPercentage,
              code: partner.code,
              singleUse: partner.singleUse ? "Yes" : "No",
              userName:
                partner.user.name &&
                partner.user.name.slice(0, partner.user.name.lastIndexOf("@")),
              type: partner.type,
              expirationDate:
                partner.expirationDate !== undefined
                  ? dateFns.parseISO(partner.expirationDate)
                  : "",
              createdDate: dateFns.format(
                dateFns.parseISO(partner.createdDate),
                "MM/dd/yyyy hh:mm:ss aa"
              ),
              deletedAt:
                partner.deletedAt !== undefined
                  ? dateFns.parseISO(partner.expirationDate)
                  : "",
              inactive:
                (partner.expirationDate !== undefined &&
                  dateFns.parseISO(partner.expirationDate) < new Date()) ||
                partner.deletedAt !== undefined,
            })
          );

          mainState.currentPage = pageNumber;
          mainState.totalResults = response.data.data.totalCount;

          this.setState(
            {
              mainState: mainState,
            },
            resolve(true)
          );
        });
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  setCurrentPage(pageNumber) {
    let { mainState } = this.state;
    mainState.currentPage = pageNumber;
    this.setState({ mainState: mainState });
  }

  generateCode(length) {
    return new Promise((resolve, reject) => {
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var charactersLength = characters.length;

      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      let { modalState } = this.state;
      modalState.Partner.Code.Value = result;
      modalState.Partner.Code.IsValid = true;

      this.setState(
        {
          modalState: modalState,
        },
        resolve(true)
      );
    });
  }

  handleTypeChange = (selectedOption) => {
    let { modalState } = this.state;

    modalState.Partner.Type.Value = selectedOption.value;

    this.setState(
      {
        modalState: modalState,
      },
      () => {
        this.validateField("Type", selectedOption.value);
      }
    );
  };

  handleDateTimeInput = (name, date) => {
    if (date === null) date = new Date();

    let { modalState } = this.state;
    modalState.Partner.ExpirationDate.Value = date;

    this.setState(
      {
        modalState: modalState,
      },
      () => {
        this.validateField("ExpirationDate", date);
      }
    );
  };

  handleSearchInput = (name, e) => {
    const value = e.target.value;

    let mainState = this.state.mainState;

    mainState[name] = value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshPartners();
    });
  };

  handleStatusChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.statusSearch = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshPartners();
    });
  };

  typeOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
  ];

  statusOptions = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active only" },
    { value: "Inactive", label: "Inactive only" },
  ];
}

export default Partner;
