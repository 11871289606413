/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import Select from "react-select";
import confirm from "reactstrap-confirm";
import * as dateFns from "date-fns";
import DatePicker from "react-datepicker";
import Can from "components/Can/Can";
import { saveAs } from "file-saver";

class LessonPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  render() {
    let lessonPlans = this.state.mainState.lessonPlans.map((lessonPlan) => {
      return (
        <tr key={lessonPlan.id}>
          <td>{lessonPlan.date}</td>
          <td> {lessonPlan.classCode} </td>
          <td>{lessonPlan.teacherName}</td>

          <td>
            {lessonPlan.documentId && (
              <Button
                color="info"
                size="sm"
                onClick={this.downloadDocumentFile.bind(
                  this,
                  lessonPlan.documentId,
                  lessonPlan.documentName
                )}
              >
                {lessonPlan.documentName}
              </Button>
            )}

            {!lessonPlan.documentId && (
              <Can
                feature="Lesson_Plan_Add"
                yes={() => (
                  <Button
                    color="danger"
                    size="sm"
                    onClick={this.uploadLessonPlan.bind(
                      this,
                      lessonPlan.id,
                      lessonPlan.classCode,
                      lessonPlan.date
                    )}
                  >
                    Upload Lesson Plan
                  </Button>
                )}
              ></Can>
            )}
          </td>

          <Can
            feature="Lesson_Plan_Add"
            yes={() => (
              <td>
                {lessonPlan.documentId && (
                  <React.Fragment>
                    <Button
                      color="success"
                      size="sm"
                      onClick={this.uploadLessonPlan.bind(
                        this,
                        lessonPlan.id,
                        lessonPlan.classCode,
                        lessonPlan.date
                      )}
                    >
                      Replace Lesson Plan
                    </Button>
                  </React.Fragment>
                )}
              </td>
            )}
          ></Can>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.mainState.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.mainState.EndDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "EndDateSearch"
                        )}
                        name="EndDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <input
                      type="file"
                      id="selectPdf"
                      hidden
                      onChange={this.handlePdfChange.bind(this)}
                      onClick={this.onInputClick}
                    />

                    <Can
                      feature="Lesson_Plan_Filter"
                      yes={() => (
                        <React.Fragment>
                          <Col md={2}>
                            <FormGroup>
                              <Label for="studentClass">Level</Label>
                              <Select
                                className={"form-group"}
                                value={
                                  this.state.mainState.courseId &&
                                  this.state.mainState.courses.find(
                                    (x) =>
                                      x.value === this.state.mainState.courseId
                                  )
                                }
                                onChange={this.handleCourseChange}
                                options={this.state.mainState.courses}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={2}>
                            <FormGroup>
                              <Label for="studentClass">Period</Label>
                              <Select
                                className={"form-group"}
                                value={
                                  this.state.mainState.periodId &&
                                  this.periodOptions.find(
                                    (x) =>
                                      x.value === this.state.mainState.periodId
                                  )
                                }
                                onChange={this.handlePeriodChange}
                                options={this.periodOptions}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={2}>
                            <FormGroup>
                              <Label for="studentClass">Subject</Label>
                              <Select
                                className={"form-group"}
                                value={
                                  this.state.mainState.classId &&
                                  this.state.mainState.classes.find(
                                    (x) =>
                                      x.value === this.state.mainState.classId
                                  )
                                }
                                onChange={this.handleClassMainStateChange}
                                options={this.state.mainState.classes}
                              />
                            </FormGroup>
                          </Col>

                          <Col md={2}>
                            <FormGroup>
                              <Label for="studentClass">Teacher</Label>
                              <Select
                                className={"form-group"}
                                value={
                                  this.state.mainState.teacherId &&
                                  this.state.mainState.teachers.find(
                                    (x) =>
                                      x.value === this.state.mainState.teacherId
                                  )
                                }
                                onChange={this.handleTeacherChange}
                                options={this.state.mainState.teachers}
                              />
                            </FormGroup>
                          </Col>
                        </React.Fragment>
                      )}
                    ></Can>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Subject</th>
                        <th>Teacher</th>
                        <th>Lesson Plan File</th>

                        <Can
                          feature="Lesson_Plan_Add"
                          yes={() => <th>Actions</th>}
                        ></Can>
                      </tr>
                    </thead>

                    <tbody>{lessonPlans}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.mainState.currentPage}
                    totalItemsCount={this.state.mainState.totalResults}
                    onChange={this._refreshLessonPlans.bind(this)}
                    csv={this.downloadLessonPlans.bind(this)}
                    buttonName="Download Lesson Plans"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  onInputClick = (event) => {
    event.target.value = "";
  };

  getInitialState = () => {
    const initialState = {
      mainState: this.getMainState(),
    };

    return initialState;
  };

  getMainState = () => {
    const mainState = {
      lessonPlans: [],
      openModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      StartDateSearch: dateFns.startOfWeek(new Date()),
      EndDateSearch: dateFns.endOfWeek(new Date()),
      courses: [],
      classes: [],
      teachers: [],
      courseId: "",
      periodId: "all",
      classId: "",
      teacherId: "",
      lessonPlanId: "",
      subjectCode: "",
      selectedDate: null,
    };

    return mainState;
  };

  downloadDocumentFile(id, fileName) {
    axios
      .get("documents/getFileByDate?id=" + id, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click();
      });
  }

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let { mainState } = this.state;

    mainState[name] = date;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshLessonPlans();
    });
  };

  handleCourseChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.courseId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState });

    this._refreshLessonPlans();
  };

  handleTeacherChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.teacherId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState });

    this._refreshLessonPlans();
  };

  handlePeriodChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.periodId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState });

    this._refreshLessonPlans();
  };

  componentDidMount() {
    this._refreshLessonPlans().then(() => {
      this.refreshClassesMainState().then(() => {
        this.refreshTeachers().then(() => {
          this.refreshCourses();
        });
      });
    });
  }

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;

      mainState.courses = [];

      mainState.courses.push({
        value: "all",
        label: "All levels",
      });

      mainState.courseId = "all";

      this.props.tables.courses.map((course, index) =>
        mainState.courses.push({
          value: course.id,
          label: course.name,
        })
      );

      this.setState({ mainState: mainState }, resolve(true));
    });
  };

  refreshTeachers = () => {
    return new Promise((resolve, reject) => {
      axios.get("teachers/getWithSubjects").then((response) => {
        let mainState = this.state.mainState;

        mainState.teachers = [];

        mainState.teachers.push({
          value: "all",
          label: "All teachers",
        });

        mainState.teacherId = "all";

        response.data.data.map((teacher, index) =>
          mainState.teachers.push({
            value: teacher.id,
            label: teacher.name,
          })
        );

        this.setState({ mainState: mainState }, resolve(true));
      });
    });
  };

  refreshClassesMainState = () => {
    return new Promise((resolve, reject) => {
      axios.get("classes?page=1&pageSize=999999").then((response) => {
        let mainState = this.state.mainState;

        mainState.classes = [];

        mainState.classes.push({
          value: "all",
          label: "All subjects",
        });

        mainState.classId = "all";

        response.data.data.items.map((classe, index) =>
          mainState.classes.push({
            value: classe.id,
            label: classe.code,
          })
        );

        this.setState({ mainState: mainState }, resolve(true));
      });
    });
  };

  handleClassMainStateChange = (selectedOption) => {
    let mainState = this.state.mainState;

    mainState.classId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshLessonPlans();
    });
  };

  toggleNewLessonPlanModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;

      mainState.openModal = !mainState.openModal;

      this.setState(
        {
          mainState: mainState,
        },
        resolve(true)
      );
    });
  };

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  }

  cleanFileContent = () => {
    return new Promise((resolve, reject) => {
      let { mainState } = this.state;

      mainState.lessonPlanId = "";
      mainState.subjectCode = "";
      mainState.selectedDate = null;

      this.setState({ mainState: mainState }, resolve(true));
    });
  };

  async deleteLessonPlan(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Are you sure you wish to delete this item?",
    });

    if (result) {
      axios.delete("lessonPlans/" + id).then(() => {
        this._refreshLessonPlans();
      });
    }
  }

  _refreshLessonPlans = (pageNumber) => {
    return new Promise((resolve, reject) => {
      let page = pageNumber || 1;
      let itemsPerPage = this.state.mainState.itemsPerPage;
      let startDate = dateFns.format(
        this.state.mainState.StartDateSearch,
        "yyyy-MM-dd"
      );
      let endDate = dateFns.format(
        this.state.mainState.EndDateSearch,
        "yyyy-MM-dd"
      );
      let courseId = this.state.mainState.courseId;
      let periodId = this.state.mainState.periodId;
      let classId = this.state.mainState.classId;
      let teacherId = this.state.mainState.teacherId;

      if (periodId === "all") periodId = "";

      axios
        .get(
          "lessonplans?" +
            "startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&courseId=" +
            courseId +
            "&period=" +
            periodId +
            "&classId=" +
            classId +
            "&teacherId=" +
            teacherId +
            "&page=" +
            page +
            "&pageSize=" +
            itemsPerPage
        )
        .then((response) => {
          let { mainState } = this.state;

          mainState.currentPage = pageNumber;
          mainState.totalResults = response.data.data.totalCount;

          mainState.lessonPlans = [];
          response.data.data.items.map((lessonPlan, index) =>
            mainState.lessonPlans.push({
              id: lessonPlan.id,
              date: dateFns.format(
                dateFns.parseISO(lessonPlan.date),
                "MM/dd/yyyy"
              ),
              classId: lessonPlan.classe.id,
              classCode: lessonPlan.classe.code,
              teacherName: lessonPlan.classe.teacher.name,
              documentId: lessonPlan.document && lessonPlan.document.id,
              documentName: lessonPlan.document && lessonPlan.document.fileName,
            })
          );

          this.setState(
            {
              mainState: mainState,
            },
            resolve(true)
          );
        });
    });
  };

  async downloadLessonPlans() {
    let result = await confirm({
      title: <>Warning</>,
      message:
        "This will generate a single zip file containing all PDFs with the selected filter. Do you want to proceed?",
    });

    if (result) {
      let startDate = dateFns.format(
        this.state.mainState.StartDateSearch,
        "yyyy-MM-dd"
      );
      let endDate = dateFns.format(
        this.state.mainState.EndDateSearch,
        "yyyy-MM-dd"
      );
      let courseId = this.state.mainState.courseId;
      let periodId = this.state.mainState.periodId;
      let classId = this.state.mainState.classId;
      let teacherId = this.state.mainState.teacherId;

      if (periodId === "all") periodId = "";

      axios
        .get(
          "lessonplans?" +
            "startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&courseId=" +
            courseId +
            "&period=" +
            periodId +
            "&classId=" +
            classId +
            "&teacherId=" +
            teacherId +
            "&page=1" +
            "&pageSize=999999999"
        )
        .then((response) => {
          const zip = require("jszip")();

          let items = response.data.data.items.filter(
            (x) => x.document !== null && x.document !== undefined
          );

          let plans = items.map((lessonPlan, index) =>
            axios
              .get("documents/getFileByDate?id=" + lessonPlan.document.id, {
                responseType: "blob",
              })
              .then((response) => {
                zip.file(
                  index +
                    "_" +
                    lessonPlan.classe.code +
                    "_" +
                    lessonPlan.classe.teacher.name +
                    "_" +
                    lessonPlan.document.fileName,
                  new Blob([response.data], { type: response.data.type })
                );
              })
          );

          Promise.all(plans).then(() => {
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(
                content,
                "Lesson_Plan_" +
                  dateFns.format(new Date(), "MMddyyyy_hhmmss") +
                  ".zip"
              );
            });
          });
        });
    }
  }

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  setCurrentPage(pageNumber) {
    let { mainState } = this.state;
    mainState.currentPage = pageNumber;
    this.setState({ mainState: mainState });
  }

  uploadLessonPlan(lessonPlanId, subjectCode, selectedDate) {
    let { mainState } = this.state;

    mainState.lessonPlanId = lessonPlanId;
    mainState.subjectCode = subjectCode;
    mainState.selectedDate = selectedDate;

    this.setState({ mainState: mainState }, () => {
      document.getElementById("selectPdf").click();
    });
  }

  async fillDocumentFileContent(lessonPlan) {
    if (lessonPlan.File !== null) {
      let base64File = await this.getBase64(lessonPlan.File);
      let fileName = lessonPlan.File.name;

      lessonPlan.Document = {
        FileContentBase64: base64File,
        FileName: fileName,
      };
      lessonPlan.File = null;
    }
  }

  async handlePdfChange(event) {
    let file = event.target.files[0];

    if (file !== undefined) {
      let filteredLessonPlans = this.state.mainState.lessonPlans.filter(
        (x) =>
          x.classCode.substring(0, 3) ===
            this.state.mainState.subjectCode.substring(0, 3) &&
          x.date === this.state.mainState.selectedDate
      );

      let updateSimilarSubjects = false;

      if (filteredLessonPlans.length === 1) {
        updateSimilarSubjects = false;
      } else {
        let result = await confirm({
          title: <>Warning</>,
          message:
            "Do you want to submit this document for all subjects with the code '" +
            this.state.mainState.subjectCode.substring(0, 3) +
            "' on '" +
            this.state.mainState.selectedDate +
            "'? \n\n" +
            "* Choosing 'No' the system will only submit the document for the subject '" +
            this.state.mainState.subjectCode +
            "'.",
          confirmText: "Yes",
          cancelText: "No",
        });

        updateSimilarSubjects = result;
      }

      let lessonPlan = {
        Id: this.state.mainState.lessonPlanId,
        File: file,
        UpdateSimilarSubjects: updateSimilarSubjects,
      };

      await this.fillDocumentFileContent(lessonPlan).then(() => {
        axios.put("lessonPlans", lessonPlan).then((response) => {
          this._refreshLessonPlans(this.state.mainState.currentPage).then(
            () => {
              this.cleanFileContent();
            }
          );
        });
      });
    }
  }

  periodOptions = [
    { value: "all", label: "All periods" },
    { value: "M", label: "Morning" },
    { value: "E", label: "Evening" },
    { value: "MT", label: "Mon/Tue" },
    { value: "WT", label: "Wed/Thu" },
  ];
}

export default LessonPlan;
