import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/App.css";
import "assets/css/flags.css";

import { App } from "App/App.jsx";

ReactDOM.render(<App />, document.getElementById("root"));
