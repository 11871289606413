/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Tooltip,
  Badge,
  UncontrolledTooltip,
  CustomInput,
} from "reactstrap";
import axios from "axios";
import Countries from "Countries.json";
import States from "States.json";
import Select from "react-select";
import Autosuggest from "react-autosuggest";
import classnames from "classnames";
import confirm from "reactstrap-confirm";
import { editMode, FormatDate, FormatFullDateList } from "helpers/Utils.js";
import AssignmentView from "../Assignment/Assignment.component.view";
import uuid from "uuid";
import Address from "components/Address/Address.component";
import CustomCalendar from "../Calendar/Calendar.component";
import * as dateFns from "date-fns";
import { authenticationService } from "services/Authentication.service.js";
import MessageEdit from "components/Message/Message.component.edit.jsx";
import profilePicture from "assets/img/default-avatar.png";
import Can from "components/Can/Can";
import { check } from "components/Can/Can";
import DatePicker from "react-datepicker";
import ModalInput from "../Modal/Modal.component.input";
import ModalFinancialContract from "../Modal/Modal.FinancialContract.component";
import ModalAttendance from "../Modal/Modal.Attendance.component";
import { SavePdf } from "components/Report/GeneratePdf.js";
import AuthenticateModal from "../Authenticate/Authenticate.modal.component";
import { authService } from "services/Permission.service.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

class StudentEdit2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();
  }

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getAddressEntity = () => {
    const address = {
      Id: this.getEntity(),
      Street: this.getEntity(),
      CountryId: this.getEntity(),
      City: this.getEntity(),
      Zip: this.getEntity(),
      HomePhone: this.getEntity(),
      CellPhone: this.getEntity(),
      Email: this.getEntity(),
      State: this.getEntity(),
      States: [],
      CountryName: "",
    };

    return address;
  };

  getStatusEntity = () => {
    const status = {
      openStatusChange: false,
      selectedStatusTypeId: "",
      selectedSemesterId: "",
      startDate: "",
      endDate: "",
      comment: "",
      hasDate: false,
      allowClassEnroll: false,
      fileContent: null,
      fileName: "",
    };

    return status;
  };

  getNoteEntity = () => {
    const note = {
      openNotePanel: false,
      description: "",
    };

    return note;
  };

  getDocumentNoteEntity = () => {
    const note = {
      openNotePanel: false,
      selectedDocumentTypeId: "",
      fileContent: null,
      fileName: "",
      description: "",
    };

    return note;
  };

  getBookPurchaseEntity = () => {
    const bookpurchase = {
      openBookPurchasePanel: false,
      selectedBookId: "",
      ticketNumber: "",
    };

    return bookpurchase;
  };

  getModalState = () => {
    return {
      Student: {
        Id: this.getEntity(),
        Name: this.getEntity(),
        NickName: this.getEntity(),
        I20: this.getEntity(),
        I20StartDate: this.getEntity(),
        I20EndDate: this.getEntity(),
        EnrollmentDate: this.getEntity(),
        PassportExpDate: this.getEntity(),
        BirthDate: this.getEntity(),
        Gender: this.getEntity(),
        VisaTypeId: this.getEntity(),
        SevisId: this.getEntity(),
        StudentOriginId: this.getEntity(),
        NationalityId: this.getEntity(),
        status: this.getEntity(),
        pictureUrl: this.getEntity(),
        pinNumber: this.getEntity(),
        pictureFileContent: null,
        classes: [],
        notes: [],
        bookPurchases: [],
        statusList: [],
        messageList: [],
        blockHistory: [],
        invoiceDetailList: [],
        attendance: [],
        totalAttendance: 0,
        currentAttendancePage: 1,
        attendancePerPage: 5,
        address: this.getAddressEntity(),
        studiedSemesters: [],
        SelfEnroll: null,
        Blocked: false,
        LastSemester: "",
        CanScheduleAnyTest: false,
      },
      cards: [1, 2],
      formValid: false,
      value: "",
      suggestions: [],
      classes: [],
      courses: [],
      periods: [],
      statusTypes: [],
      documentTypes: [],
      books: [],
      visaTypes: [],
      campuses: [],
      studentOrigins: [],
      semesters: [],
      selectedSemesterId: "",
      calendarSelectedSemesterId: "",
      selectedClassId: "",
      selectedCourseId: "",
      selectedPeriod: "",
      firstDayOfClass: null,
      tooltipFirstDayOfClassIsOpen: false,
      selectClassIdForAssignmentModal: "",
      activeTab: "1",
      status: this.getStatusEntity(),
      openAssignmentModal: false,
      currentNote: this.getNoteEntity(),
      currentDocumentNote: this.getDocumentNoteEntity(),
      currentBookPurchase: this.getBookPurchaseEntity(),
      currentUser: authenticationService.currentUserValue,
      openModalMessage: false,
      modalInputIsOpen: false,
      modalFinancialContractIsOpen: false,

      modalAuthenticateIsOpen: false,
      modalAuthenticateMessage: "",
      modalAuthenticateNote: "",

      modalAttendanceIsOpen: false,
      modalAttendanceHasChanged: new Date(),
      modalAttendanceStatus: "",
      modalAttendanceDate: new Date(),
      modalAttendanceComment: "",
      modalAttendanceSubject: "",
      modalAttendanceId: "",

      modalHTMLMessageIsOpen: false,
      modalHTMLMessageDescription: "",
      modalHTMLMessageTitle: "",
    };
  };

  renderSwitch(param) {
    switch (param) {
      case "P":
        return (
          <Button color="success" size="sm">
            Present
          </Button>
        );
      case "T":
        return (
          <Button color="warning" size="sm">
            Tardy
          </Button>
        );
      case "A":
        return (
          <Button color="danger" size="sm">
            Absent
          </Button>
        );
      default:
        return (
          <Button color="success" size="sm">
            Present
          </Button>
        );
    }
  }

  toggleStatusChange = () => {
    let newStatus = this.state.Student.statusList.find((x) => x.isNew === true);

    if (newStatus !== undefined) {
      confirm({
        title: <>Warning</>,
        message: "A new status has already been registered for this student.",
        cancelText: null,
      });
    } else {
      return new Promise((resolve, reject) => {
        this.setState((prevState) => {
          let status = Object.assign({}, prevState.status);
          status.openStatusChange = true;
          return { status };
        }, resolve(true));
      });
    }
  };

  toggleNotePanel = () => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let currentNote = Object.assign({}, prevState.currentNote);
        currentNote.openNotePanel = true;
        return { currentNote };
      }, resolve(true));
    });
  };

  toggleDocumentNotePanel = () => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let currentDocumentNote = Object.assign(
          {},
          prevState.currentDocumentNote
        );
        currentDocumentNote.openNotePanel = true;
        return { currentDocumentNote };
      }, resolve(true));
    });
  };

  toggleBookPurchasePanel = () => {
    return new Promise((resolve, reject) => {
      this.setState((prevState) => {
        let currentBookPurchase = Object.assign(
          {},
          prevState.currentBookPurchase
        );
        currentBookPurchase.openBookPurchasePanel = true;
        return { currentBookPurchase };
      }, resolve(true));
    });
  };

  toggleAssignmentModal = (classId) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.openAssignmentModal = !mainState.openAssignmentModal;
      mainState.selectClassIdForAssignmentModal = classId;

      this.setState(mainState, resolve(true));
    });
  };

  toggleModalInput = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.modalInputIsOpen = !mainState.modalInputIsOpen;
      this.setState(mainState, resolve(true));
    });
  };

  toggleModalFinancialContractInput = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.modalFinancialContractIsOpen =
        !mainState.modalFinancialContractIsOpen;
      this.setState(mainState, resolve(true));
    });
  };

  async financialContract() {
    let hasActiveSubjects =
      this.state.Student.classes.filter((x) => x.active === true).length > 0;

    let result =
      hasActiveSubjects ||
      (await confirm({
        title: <>Warning</>,
        message:
          "The student is not enrolled in any subject. Do you want to proceed with the financial contract?",
      }));

    if (result) {
      this.toggleModalFinancialContractInput();
    }
  }

  toggleModalAuthenticate = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          modalAuthenticateIsOpen: !this.state.modalAuthenticateIsOpen,
        },
        resolve(true)
      );
    });
  };

  setToken = (token) => {
    return new Promise((resolve, reject) => {
      authService.setAuth(token);
      resolve(true);
    });
  };

  setModalAuthenticateMessageAndOpen = (message) => {
    this.setState({ modalAuthenticateMessage: message }, () =>
      this.toggleModalAuthenticate().then(() => this.setToken(""))
    );
  };

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  monthDiff(startDate, endDate) {
    var result = [];

    while (dateFns.isBefore(startDate, endDate)) {
      result.push(startDate);
      startDate = dateFns.addMonths(startDate, 1);
    }

    return result;
  }

  render() {
    const { value, suggestions, activeTab } = this.state;
    let currentSemester = null;

    let semesters = [];

    if (this.state.calendarSelectedSemesterId !== "") {
      currentSemester = this.props.tables.semesters.find(
        (x) => x.id === this.state.calendarSelectedSemesterId
      );

      let startDate = dateFns.startOfMonth(
        dateFns.parseISO(currentSemester.startDate)
      );

      let endDate = dateFns.endOfMonth(
        dateFns.parseISO(currentSemester.endDate)
      );

      let totalMonths = this.monthDiff(startDate, endDate);

      semesters = totalMonths.map((month, index) => {
        return (
          <Col md={6} key={index}>
            {" "}
            <CustomCalendar
              attendance={this.filterAttendance(month)}
              currentMonth={month}
              currentSemester={currentSemester}
              tables={this.props.tables}
              onClick={this.setModalAttendanceProperties}
              modalAttendanceHasChanged={this.state.modalAttendanceHasChanged}
            />
          </Col>
        );
      });
    }

    const { currentUser } = this.state;

    const inputProps = {
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
    };

    let studentClassActive = this.state.Student.classes.filter(
      (x) => x.toBeDeleted !== true
    );

    let studentClasses = studentClassActive.map((classe, index) => {
      return (
        <React.Fragment>
          {index > 1 &&
            studentClassActive.length > 1 &&
            classe.semesterName !==
              studentClassActive[index - 1].semesterName && (
              <tr>
                <br />
                <br />
              </tr>
            )}

          <tr key={classe.id} style={{ backgroundColor: "#f7f4f4" }}>
            <td>{classe.label}</td>
            <td>{classe.teacherName}</td>

            <Can
              feature="Student_SubjectShowGrades"
              yes={() =>
                this.props.editMode === editMode.EDIT && (
                  <React.Fragment>
                    <td>
                      <strong>
                        {" "}
                        {classe.grade && classe.maxGrade && classe.maxGrade > 0
                          ? ((classe.grade / classe.maxGrade) * 100).toFixed(
                              2
                            ) + "%"
                          : "0%"}
                      </strong>
                    </td>
                  </React.Fragment>
                )
              }
            ></Can>

            <td>{classe.campusName.replace("Salt Lake", "SLC")}</td>
            <td>{classe.semesterName}</td>
            <td>
              <React.Fragment>
                {classe.editMode === false &&
                  dateFns.format(classe.startDate, "MM/dd/yyyy")}

                <Can
                  feature="Student_Edit_Start_Date"
                  yes={() =>
                    classe.editMode === true && (
                      <DatePicker
                        selected={classe.newStartDate}
                        onChange={this.changeStartDate.bind(this, classe.id)}
                        name="StartDate"
                        id="StartDate"
                        dateFormat="MM/dd/yyyy"
                        className="react-datepicker-wrapper-7rem"
                      />
                    )
                  }
                ></Can>
              </React.Fragment>
            </td>
            <td>{dateFns.format(classe.createdDate, "MM/dd/yyyy")}</td>

            <td>
              {classe.active && !classe.new && (
                <Button
                  color="danger"
                  size="sm"
                  className="mr-2"
                  onClick={this.classUnenroll.bind(this, classe.id)}
                >
                  Unenroll
                </Button>
              )}

              {!classe.new && classe.hasData && (
                <React.Fragment>
                  <Button
                    color="info"
                    size="sm"
                    className="mr-2"
                    onClick={this.toggleAssignmentModal.bind(
                      this,
                      classe.value
                    )}
                  >
                    Grade
                  </Button>

                  <Button
                    color="success"
                    size="sm"
                    onClick={this.downloadPdfSubject.bind(
                      this,
                      classe.value,
                      classe.label,
                      classe.semesterName,
                      classe.grade ? classe.grade : 0,
                      classe.maxGrade
                    )}
                  >
                    PDF
                  </Button>
                </React.Fragment>
              )}

              {currentUser !== null && (
                <Can
                  feature="Student_Delete_Subject_Other_Semester"
                  yes={() =>
                    (classe.new || !classe.hasData) && (
                      <Button
                        color="danger"
                        size="sm"
                        className="mr-2"
                        onClick={this.classDelete.bind(this, classe.id)}
                      >
                        Delete
                      </Button>
                    )
                  }
                  no={() =>
                    (classe.new ||
                      (!classe.hasData && classe.isCurrentSemester)) && (
                      <Button
                        color="danger"
                        size="sm"
                        className="mr-2"
                        onClick={this.classDelete.bind(this, classe.id)}
                      >
                        Delete
                      </Button>
                    )
                  }
                ></Can>
              )}

              <Can
                feature="Student_Edit_Start_Date"
                yes={() => (
                  <React.Fragment>
                    {classe.editMode === true && (
                      <React.Fragment>
                        <Button
                          color="success"
                          size="sm"
                          className="ml-2"
                          onClick={this.saveStartDate.bind(this, classe.id)}
                        >
                          Save
                        </Button>
                        <Button
                          color="warning"
                          size="sm"
                          className="ml-2"
                          onClick={this.cancelEditStartDate.bind(
                            this,
                            classe.id
                          )}
                        >
                          Cancel
                        </Button>
                      </React.Fragment>
                    )}

                    {classe.editMode === false && (
                      <Button
                        color="info"
                        size="sm"
                        className="ml-2"
                        onClick={this.editStartDate.bind(this, classe.id)}
                      >
                        Edit
                      </Button>
                    )}
                  </React.Fragment>
                )}
              ></Can>
            </td>
          </tr>
        </React.Fragment>
      );
    });

    let studentNotes = this.state.Student.notes
      .filter((x) => x.toBeDeleted !== true && !x.documentId && !x.fileContent)
      .map((note, index) => {
        return (
          <tr key={index}>
            <td>
              {dateFns.format(note.createdDate, "MM/dd/yyyy hh:mm:ss aa")}
            </td>
            <td>{note.typeName}</td>
            <td>{note.description}</td>
            <td>
              {currentUser !== null && (
                <Can
                  feature="Student_Delete_Note"
                  yes={() =>
                    note.type === "N" && (
                      <Button
                        color="danger"
                        size="sm"
                        className="mr-2"
                        onClick={this.deleteNote.bind(this, note.id)}
                      >
                        Delete
                      </Button>
                    )
                  }
                  no={() =>
                    (note.isNew && note.type === "N" && (
                      <Button
                        color="danger"
                        size="sm"
                        className="mr-2"
                        onClick={this.deleteNote.bind(this, note.id)}
                      >
                        Delete
                      </Button>
                    )) ||
                    !note.isNew
                  }
                ></Can>
              )}
            </td>
            <td>{note.userName}</td>
          </tr>
        );
      });

    let studentDocumentNotes = this.state.Student.notes
      .filter((x) => x.toBeDeleted !== true && (x.documentId || x.fileContent))
      .map((note, index) => {
        return (
          <tr key={index}>
            <td>
              {dateFns.format(note.createdDate, "MM/dd/yyyy hh:mm:ss aa")}
            </td>
            <td>{note.documentTypeName}</td>
            <td>{note.description}</td>
            <td>
              {currentUser !== null && (
                <Can
                  feature="Student_Delete_Note"
                  yes={() => (
                    <Button
                      color="danger"
                      size="sm"
                      className="mr-2"
                      onClick={this.deleteNote.bind(this, note.id)}
                    >
                      Delete
                    </Button>
                  )}
                  no={() =>
                    (note.isNew && (
                      <Button
                        color="danger"
                        size="sm"
                        className="mr-2"
                        onClick={this.deleteNote.bind(this, note.id)}
                      >
                        Delete
                      </Button>
                    )) ||
                    !note.isNew
                  }
                ></Can>
              )}

              {note.documentId && (
                <Button
                  color="success"
                  size="sm"
                  className="mr-2"
                  onClick={this.SendDocumentByEmail.bind(this, note.id)}
                >
                  Send by email
                </Button>
              )}

              {(note.documentId || note.fileContent) && (
                <Button
                  color="info"
                  size="sm"
                  className="mr-2"
                  onClick={this.DownloadNoteFile.bind(this, note.id)}
                >
                  {note.fileName}
                </Button>
              )}
            </td>
            <td>{note.userName}</td>
          </tr>
        );
      });

    let bookPurchases = this.state.Student.bookPurchases.map((bp, index) => {
      return (
        <tr key={index}>
          <td>{bp.ticketNumber}</td>
          <td>{this.renderBookPurchaseButton(bp.status, bp.statusName)}</td>
          <td>{dateFns.format(bp.createdDate, "MM/dd/yyyy")}</td>

          <td style={{ width: "15rem" }}>
            <React.Fragment>
              {bp.editMode === false && bp.bookName}

              <Can
                feature="Student_Deliver_Book"
                yes={() =>
                  bp.editMode === true && (
                    <Select
                      value={
                        bp.newSelectedBookId &&
                        this.state.books.find(
                          (x) => x.value === bp.newSelectedBookId
                        )
                      }
                      onChange={this.handleBookDeliverChange.bind(this, bp.id)}
                      options={this.state.books}
                    />
                  )
                }
              ></Can>
            </React.Fragment>
          </td>

          <td>{bp.semesterName}</td>
          <td>{bp.createdByUserName}</td>
          <td>
            {bp.deliveredDate && dateFns.format(bp.deliveredDate, "MM/dd/yyyy")}
          </td>
          <td>{bp.deliveredByUserName}</td>
          <td>
            {bp.canceledDate && dateFns.format(bp.canceledDate, "MM/dd/yyyy")}
          </td>
          <td>{bp.canceledByUserName}</td>
          <td>
            {currentUser !== null && (
              <React.Fragment>
                {bp.status === "P" && (
                  <React.Fragment>
                    <Can
                      feature="Student_Cancel_Book"
                      yes={() => (
                        <Button
                          color="danger"
                          size="sm"
                          className="mr-2"
                          onClick={this.cancelBookTicket.bind(this, bp.id)}
                        >
                          Cancel Ticket
                        </Button>
                      )}
                    ></Can>

                    <Can
                      feature="Student_Deliver_Book"
                      yes={() => (
                        <React.Fragment>
                          <Button
                            color="success"
                            size="sm"
                            className="mr-2"
                            onClick={this.deliverBook.bind(this, bp.id)}
                          >
                            Deliver Book
                          </Button>
                        </React.Fragment>
                      )}
                    ></Can>
                  </React.Fragment>
                )}

                {bp.status !== "C" && (
                  <Can
                    feature="Student_Deliver_Book"
                    yes={() => (
                      <React.Fragment>
                        {bp.editMode === true && (
                          <React.Fragment>
                            <Button
                              color="success"
                              size="sm"
                              className="ml-2"
                              onClick={this.saveBookDeliverChange.bind(
                                this,
                                bp.id
                              )}
                            >
                              Save
                            </Button>
                            <Button
                              color="warning"
                              size="sm"
                              className="ml-2"
                              onClick={this.cancelBookDeliverChange.bind(
                                this,
                                bp.id
                              )}
                            >
                              Cancel
                            </Button>
                          </React.Fragment>
                        )}

                        {bp.editMode === false && (
                          <Button
                            color="info"
                            size="sm"
                            className="ml-2"
                            onClick={this.editBookDeliver.bind(this, bp.id)}
                          >
                            Change book
                          </Button>
                        )}
                      </React.Fragment>
                    )}
                  ></Can>
                )}
              </React.Fragment>
            )}
          </td>
        </tr>
      );
    });

    let statusList = this.state.Student.statusList
      .filter((x) => x.toBeDeleted !== true)
      .map((status, index) => {
        return (
          <tr key={index}>
            <td>
              {dateFns.format(status.createdDate, "MM/dd/yyyy hh:mm:ss aa")}
            </td>
            <td>{status.statusTypeName}</td>
            <td>{status.comment}</td>
            <td>
              {status.startDate &&
                dateFns.format(
                  dateFns.parseISO(status.startDate),
                  "MM/dd/yyyy"
                )}
            </td>
            <td>
              {status.endDate &&
                dateFns.format(dateFns.parseISO(status.endDate), "MM/dd/yyyy")}
            </td>
            <td>
              {currentUser !== null && (
                <Can
                  feature="Student_Delete_Status"
                  yes={() =>
                    ((status.isNew || status.canBeDeleted) && (
                      <Button
                        color="danger"
                        size="sm"
                        className="mr-2"
                        onClick={this.deleteStatus.bind(this, status.id)}
                      >
                        Delete
                      </Button>
                    )) ||
                    !status.canBeDeleted
                  }
                  no={() =>
                    (status.isNew && (
                      <Button
                        color="danger"
                        size="sm"
                        className="mr-2"
                        onClick={this.deleteStatus.bind(this, status.id)}
                      >
                        Delete
                      </Button>
                    )) ||
                    !status.isNew
                  }
                ></Can>
              )}

              {(status.documentId || status.fileContent) && (
                <Button
                  color="info"
                  size="sm"
                  className="mr-2"
                  onClick={this.DownloadStatusFile.bind(this, status.id)}
                >
                  {status.fileName}
                </Button>
              )}
            </td>
            <td>{status.userName}</td>
          </tr>
        );
      });

    let messageList = this.state.Student.messageList.map((message, index) => {
      return (
        <tr key={index}>
          <td>
            {dateFns.format(message.createdDate, "MM/dd/yyyy hh:mm:ss aa")}
          </td>
          <td>{message.title}</td>

          <td>
            {(message.description.includes("<!DOCTYPE html>") && (
              <Button
                color="info"
                size="sm"
                className="mr-2"
                onClick={this.setHTMLMessageDescriptionAndToggle.bind(
                  this,
                  message.title,
                  message.description
                )}
              >
                View HTML Message
              </Button>
            )) || (
              <Button
                color="info"
                size="sm"
                className="mr-2"
                onClick={this.setHTMLMessageDescriptionAndToggle.bind(
                  this,
                  message.title,
                  message.description
                )}
              >
                View Message
              </Button>
            )}
          </td>

          <td>{message.userName}</td>
        </tr>
      );
    });

    let blockHistory = this.state.Student.blockHistory.map((bh, index) => {
      return (
        <tr key={index}>
          <td>{bh.blocked === true ? "True" : "False"}</td>
          <td>{bh.userName}</td>
          <td>{dateFns.format(bh.createdDate, "MM/dd/yyyy hh:mm:ss aa")}</td>
        </tr>
      );
    });

    let invoiceDetailList = this.state.Student.invoiceDetailList.map(
      (id, index) => {
        return (
          <tr key={index}>
            <td>{"$" + id.balance.toFixed(2)}</td>
            <td>{dateFns.format(id.dueDate, "MM/dd/yyyy")}</td>
            <td>{id.docNumber}</td>
            <td>{id.invoiceId}</td>
          </tr>
        );
      }
    );

    let viewOnly = check("Student_ViewOnly");
    let subjectViewOnly = check("Student_SubjectViewOnly");
    let canEditPicture = check("Student_Edit_Picture");

    return (
      <>
        <div className="content">
          <Row>
            <Modal
              isOpen={this.props.isOpen}
              toggle={this.props.toggleStudentModal.bind(this)}
              centered={true}
              autoFocus={true}
              size={"lg"}
              backdrop="static"
              onClosed={this.onCloseModal}
              onOpened={this.onOpened.bind(this)}
              style={{
                maxWidth: "1600px",
                width: "80%",
              }}
              scrollable={true}
            >
              <ModalHeader toggle={this.props.toggleStudentModal.bind(this)}>
                {this.props.editMode === editMode.INSERT
                  ? "New student: "
                  : "Update: "}
                {this.state.Student.Name.Value}
              </ModalHeader>
              <ModalBody
                style={{ backgroundColor: "#f4f3ef", paddingBottom: "0" }}
              >
                <div>
                  <Row form>
                    <Col md="3"></Col>
                    <Col md="3">
                      <Card
                        className="card-user"
                        style={{
                          backgroundColor:
                            this.state.Student.Blocked === true
                              ? "#ffbaba"
                              : "white",
                        }}
                      >
                        <CardBody
                          style={{
                            minHeight:
                              this.props.editMode === editMode.INSERT
                                ? "150px"
                                : "225px",
                          }}
                        >
                          <div className="author">
                            <div className="pictureDiv">
                              <img
                                alt="..."
                                className="avatar border-gray pictureDiv"
                                src={
                                  this.state.Student.pictureUrl.Value !== ""
                                    ? this.state.Student.pictureUrl.Value
                                    : profilePicture
                                }
                                onClick={canEditPicture && this.upload}
                              />

                              {currentUser !== null && (
                                <Can
                                  feature="Student_Edit_Picture"
                                  yes={() => (
                                    <a
                                      className="far fa-edit changePictureLink"
                                      onClick={this.upload}
                                      href="#"
                                      title="Click to select photo"
                                    ></a>
                                  )}
                                ></Can>
                              )}
                            </div>

                            <input
                              type="file"
                              id="selectImage"
                              hidden
                              onChange={this.handlePictureChange.bind(this)}
                            />
                          </div>
                          {this.props.editMode === editMode.EDIT && (
                            <CardFooter style={{ padding: "0" }}>
                              <hr />
                              <div className="button-container">
                                <Row>
                                  <Col
                                    className="ml-auto"
                                    lg="2"
                                    style={{ padding: "0" }}
                                  >
                                    <h5
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.sumAttendance("P")} <br />
                                      <small>P</small>
                                    </h5>
                                  </Col>
                                  <Col
                                    className="ml-auto"
                                    lg="2"
                                    style={{ padding: "0" }}
                                  >
                                    <h5
                                      style={{
                                        color: "goldenrod",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.sumAttendance("T")} <br />
                                      <small>T</small>
                                    </h5>
                                  </Col>
                                  <Col
                                    className="ml-auto"
                                    lg="2"
                                    style={{ padding: "0" }}
                                  >
                                    <h5
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.sumAttendance("A")} <br />
                                      <small>A</small>
                                    </h5>
                                  </Col>
                                  <Col
                                    className="ml-auto"
                                    lg="2"
                                    style={{ padding: "0" }}
                                  >
                                    <h5
                                      style={{
                                        color: "#2ba9cd",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.sumAttendance("E")} <br />
                                      <small>E</small>
                                    </h5>
                                  </Col>
                                  <Col
                                    className="ml-auto"
                                    lg="2"
                                    style={{ padding: "0" }}
                                  >
                                    <h5
                                      style={{
                                        color: "#2ba9cd",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.sumAttendance("L")} <br />
                                      <small>L</small>
                                    </h5>
                                  </Col>
                                </Row>
                              </div>
                            </CardFooter>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3">
                      <Card className="card-user">
                        <CardBody
                          style={{
                            minHeight:
                              this.props.editMode === editMode.INSERT
                                ? "150px"
                                : "225px",
                          }}
                        >
                          {this.props.editMode === editMode.EDIT && (
                            <React.Fragment>
                              <Row form>
                                <Input
                                  className={`form-group`}
                                  type="text"
                                  value={this.state.Student.status.Value}
                                  disabled={true}
                                  style={{ fontWeight: "bold" }}
                                />
                              </Row>
                              <Row form>
                                <Input
                                  className={`form-group `}
                                  type="text"
                                  value={this.getCourseName()}
                                  disabled={true}
                                  style={{ fontWeight: "bold" }}
                                />
                              </Row>
                              <Row form>
                                <Input
                                  className={`form-group `}
                                  type="text"
                                  value={this.state.Student.LastSemester}
                                  disabled={true}
                                  style={{ fontWeight: "bold" }}
                                />
                              </Row>
                            </React.Fragment>
                          )}
                          <Row form>
                            <FormGroup style={{ width: "100%" }}>
                              <Select
                                className={`form-group ${this.errorClass(
                                  this.state.Student.SevisId.ErrorMsg
                                )}`}
                                value={
                                  this.state.Student.SevisId.Value &&
                                  this.state.campuses.find(
                                    (x) =>
                                      x.value ===
                                      this.state.Student.SevisId.Value
                                  )
                                }
                                onChange={this.handleSevisChange}
                                options={this.state.campuses}
                                placeholder="SEVIS Origin"
                              />
                            </FormGroup>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="3"></Col>
                  </Row>

                  <Nav card tabs style={{ marginLeft: "5px" }}>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggleTabs("1");
                        }}
                      >
                        Basic data
                      </NavLink>
                    </NavItem>

                    {currentUser !== null && (
                      <Can
                        feature="Student_Subjects"
                        yes={() => (
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "2",
                              })}
                              onClick={() => {
                                this.toggleTabs("2");
                              }}
                            >
                              Subjects
                            </NavLink>
                          </NavItem>
                        )}
                      ></Can>
                    )}

                    {this.props.editMode === editMode.EDIT &&
                      currentUser !== null && (
                        <Can
                          feature="Student_Attendance"
                          yes={() => (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "3",
                                })}
                                onClick={() => {
                                  this.toggleTabs("3");
                                }}
                              >
                                Attendance
                              </NavLink>
                            </NavItem>
                          )}
                        ></Can>
                      )}

                    {this.props.editMode === editMode.EDIT &&
                      currentUser !== null && (
                        <Can
                          feature="Student_Notes"
                          yes={() => (
                            <React.Fragment>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTab === "4",
                                  })}
                                  onClick={() => {
                                    this.toggleTabs("4");
                                  }}
                                >
                                  Notes
                                </NavLink>
                              </NavItem>

                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTab === "5",
                                  })}
                                  onClick={() => {
                                    this.toggleTabs("5");
                                  }}
                                >
                                  Documents
                                </NavLink>
                              </NavItem>
                            </React.Fragment>
                          )}
                        ></Can>
                      )}

                    {this.props.editMode === editMode.EDIT &&
                      currentUser !== null && (
                        <Can
                          feature="Student_Messages"
                          yes={() => (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "6",
                                })}
                                onClick={() => {
                                  this.toggleTabs("6");
                                }}
                              >
                                Messages
                              </NavLink>
                            </NavItem>
                          )}
                        ></Can>
                      )}

                    {this.props.editMode === editMode.EDIT &&
                      currentUser !== null && (
                        <Can
                          feature="Student_Status"
                          yes={() => (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "7",
                                })}
                                onClick={() => {
                                  this.toggleTabs("7");
                                }}
                              >
                                Status
                              </NavLink>
                            </NavItem>
                          )}
                        ></Can>
                      )}

                    {this.props.editMode === editMode.EDIT &&
                      currentUser !== null && (
                        <Can
                          feature="Student_Finance"
                          yes={() => (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "8",
                                })}
                                onClick={() => {
                                  this.toggleTabs("8");
                                }}
                              >
                                Block
                              </NavLink>
                            </NavItem>
                          )}
                        ></Can>
                      )}

                    {this.props.editMode === editMode.EDIT &&
                      currentUser !== null && (
                        <Can
                          feature="Student_Invoices"
                          yes={() => (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "9",
                                })}
                                onClick={() => {
                                  this.toggleTabs("9");
                                }}
                              >
                                Invoices
                              </NavLink>
                            </NavItem>
                          )}
                        ></Can>
                      )}

                    {this.props.editMode === editMode.EDIT &&
                      currentUser !== null && (
                        <Can
                          feature="Student_Books"
                          yes={() => (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === "10",
                                })}
                                onClick={() => {
                                  this.toggleTabs("10");
                                }}
                              >
                                Books
                              </NavLink>
                            </NavItem>
                          )}
                        ></Can>
                      )}
                  </Nav>

                  <TabContent
                    activeTab={activeTab}
                    style={{ margin: "1rem 0" }}
                  >
                    <TabPane tabId="1">
                      {" "}
                      <Row
                        form
                        style={
                          viewOnly
                            ? { pointerEvents: "none", opacity: "0.7" }
                            : {}
                        }
                      >
                        <Col md="7">
                          <Card className="card-user">
                            <CardHeader>
                              <CardTitle tag="h5">Profile</CardTitle>
                            </CardHeader>
                            <CardBody>
                              <Row form>
                                <Col md={8}>
                                  <FormGroup>
                                    <Label for="studentName">Full name</Label>
                                    <Input
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.Name.ErrorMsg
                                      )}`}
                                      type="text"
                                      name="Name"
                                      id="studentName"
                                      autoComplete="nope"
                                      value={this.state.Student.Name.Value}
                                      onChange={this.handleUserInput}
                                    />
                                    <span className="text-danger">
                                      {this.state.Student.Name.ErrorMsg}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label for="studentNickName">
                                      Nick name
                                    </Label>
                                    <Input
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.NickName.ErrorMsg
                                      )}`}
                                      type="text"
                                      name="NickName"
                                      id="studentNickName"
                                      autoComplete="nope"
                                      value={this.state.Student.NickName.Value}
                                      onChange={this.handleUserInput}
                                    />
                                    <span className="text-danger">
                                      {this.state.Student.NickName.ErrorMsg}
                                    </span>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row form>
                                <Col md={3}>
                                  <FormGroup>
                                    <Label for="studentI20">I-20</Label>
                                    <Input
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.I20.ErrorMsg
                                      )}`}
                                      type="text"
                                      name="I20"
                                      id="studentI20"
                                      autoComplete="nope"
                                      value={this.state.Student.I20.Value}
                                      onChange={this.handleUserInput}
                                    />
                                    <span className="text-danger">
                                      {this.state.Student.I20.ErrorMsg}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col md={3}>
                                  <FormGroup>
                                    <Label for="studentI20StartDate">
                                      I-20 Start Date
                                    </Label>
                                    <Input
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.I20StartDate.ErrorMsg
                                      )}`}
                                      type="date"
                                      name="I20StartDate"
                                      id="studentStartDate"
                                      value={
                                        this.state.Student.I20StartDate.Value
                                      }
                                      onChange={this.handleUserInput}
                                    />
                                    <span className="text-danger">
                                      {this.state.Student.I20StartDate.ErrorMsg}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col md={3}>
                                  <FormGroup>
                                    <Label for="studentI20EndDate">
                                      I-20 End Date
                                    </Label>
                                    <Input
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.I20EndDate.ErrorMsg
                                      )}`}
                                      type="date"
                                      name="I20EndDate"
                                      id="studentEndDate"
                                      value={
                                        this.state.Student.I20EndDate.Value
                                      }
                                      onChange={this.handleUserInput}
                                      style={{
                                        backgroundColor:
                                          this.state.Student.I20EndDate.Value &&
                                          dateFns.parseISO(
                                            this.state.Student.I20EndDate
                                              .Value + "T00:00:00"
                                          ) < dateFns.addMonths(new Date(), 1)
                                            ? "#ffc4c4"
                                            : "",
                                      }}
                                    />
                                    <span className="text-danger">
                                      {this.state.Student.I20EndDate.ErrorMsg}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col md={3}>
                                  <FormGroup>
                                    <Label for="studentPassportExpDate">
                                      Passport Exp. Date
                                    </Label>
                                    <Input
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.PassportExpDate
                                          .ErrorMsg
                                      )}`}
                                      type="date"
                                      name="PassportExpDate"
                                      id="studentPassportExpDate"
                                      value={
                                        this.state.Student.PassportExpDate.Value
                                      }
                                      onChange={this.handleUserInput}
                                      style={{
                                        backgroundColor:
                                          this.state.Student.PassportExpDate
                                            .Value &&
                                          dateFns.parseISO(
                                            this.state.Student.PassportExpDate
                                              .Value + "T00:00:00"
                                          ) < dateFns.addMonths(new Date(), 1)
                                            ? "#ffc4c4"
                                            : "",
                                      }}
                                    />
                                    <span className="text-danger">
                                      {
                                        this.state.Student.PassportExpDate
                                          .ErrorMsg
                                      }
                                    </span>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row form>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label for="studentGender">Gender</Label>
                                    <Select
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.Gender.ErrorMsg
                                      )}`}
                                      value={
                                        this.state.Student.Gender.Value &&
                                        this.options.find(
                                          (x) =>
                                            x.value ===
                                            this.state.Student.Gender.Value
                                        )
                                      }
                                      onChange={this.handleGenderChange}
                                      options={this.options}
                                    />
                                    <span className="text-danger">
                                      {this.state.Student.Gender.ErrorMsg}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label for="studentNationality">
                                      Nationality
                                    </Label>
                                    <Autosuggest
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.NationalityId
                                          .ErrorMsg
                                      )}`}
                                      suggestions={suggestions}
                                      onSuggestionsFetchRequested={
                                        this.onSuggestionsFetchRequested
                                      }
                                      onSuggestionsClearRequested={
                                        this.onSuggestionsClearRequested
                                      }
                                      getSuggestionValue={
                                        this.getSuggestionValue
                                      }
                                      renderSuggestion={this.renderSuggestion}
                                      placeholder="Enter country name"
                                      inputProps={inputProps}
                                      onSuggestionSelected={
                                        this.onSuggestionSelected
                                      }
                                    />
                                    <span className="text-danger">
                                      {
                                        this.state.Student.NationalityId
                                          .ErrorMsg
                                      }
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label for="studentEnrollmentDate">
                                      Enrollment date
                                    </Label>
                                    <Input
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.EnrollmentDate
                                          .ErrorMsg
                                      )}`}
                                      type="date"
                                      name="EnrollmentDate"
                                      id="studentEnrollmentDate"
                                      value={
                                        this.state.Student.EnrollmentDate.Value
                                      }
                                      onChange={this.handleUserInput}
                                    />
                                    <span className="text-danger">
                                      {
                                        this.state.Student.EnrollmentDate
                                          .ErrorMsg
                                      }
                                    </span>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row form>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label for="studentBirthDate">
                                      Birth date
                                    </Label>
                                    <Input
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.BirthDate.ErrorMsg
                                      )}`}
                                      type="date"
                                      name="BirthDate"
                                      id="studentBirthDate"
                                      value={this.state.Student.BirthDate.Value}
                                      onChange={this.handleUserInput}
                                    />
                                    <span className="text-danger">
                                      {this.state.Student.BirthDate.ErrorMsg}
                                    </span>
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label for="studentVisaType">
                                      Visa Type
                                    </Label>
                                    <Select
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.VisaTypeId.ErrorMsg
                                      )}`}
                                      value={
                                        this.state.Student.VisaTypeId.Value &&
                                        this.state.visaTypes.find(
                                          (x) =>
                                            x.value ===
                                            this.state.Student.VisaTypeId.Value
                                        )
                                      }
                                      onChange={this.handleVisaTypeChange}
                                      options={this.state.visaTypes}
                                    />
                                    <span className="text-danger">
                                      {this.state.Student.VisaTypeId.ErrorMsg}
                                    </span>
                                  </FormGroup>
                                </Col>

                                <Col md={4}>
                                  <FormGroup>
                                    <Label for="studentStudentOrigin">
                                      Origin
                                    </Label>
                                    <Select
                                      className={`form-group ${this.errorClass(
                                        this.state.Student.StudentOriginId
                                          .ErrorMsg
                                      )}`}
                                      value={
                                        this.state.Student.StudentOriginId
                                          .Value &&
                                        this.state.studentOrigins.find(
                                          (x) =>
                                            x.value ===
                                            this.state.Student.StudentOriginId
                                              .Value
                                        )
                                      }
                                      onChange={this.handleStudentOriginChange}
                                      options={this.state.studentOrigins}
                                    />
                                    <span className="text-danger">
                                      {
                                        this.state.Student.StudentOriginId
                                          .ErrorMsg
                                      }
                                    </span>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="5">
                          <Card className="card-user">
                            <CardHeader>
                              <CardTitle tag="h5">Address</CardTitle>
                            </CardHeader>
                            <CardBody>
                              <Address
                                Address={this.state.Student.address}
                                handleAddressInput={this.handleAddressInput}
                                OnCountryChange={this.onCountryChange}
                                OnCountrySuggestionSelected={
                                  this.onCountrySuggestionSelected
                                }
                                HandleStateChange={this.handleStateChange}
                                OnBlur={this.onCountryBlur}
                                pinNumber={this.state.Student.pinNumber.Value}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      {" "}
                      <Row
                        form
                        style={
                          viewOnly || subjectViewOnly
                            ? { pointerEvents: "none", opacity: "0.7" }
                            : {}
                        }
                      >
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h4">Subjects</CardTitle>
                            </CardHeader>
                            <CardBody>
                              <Row form>
                                <Col md={2}>
                                  <FormGroup>
                                    <Label for="studentClass">Semester</Label>
                                    <Select
                                      value={
                                        this.state.selectedSemesterId &&
                                        this.state.semesters.find(
                                          (x) =>
                                            x.value ===
                                            this.state.selectedSemesterId
                                        )
                                      }
                                      onChange={this.handleSemesterEnrollChange}
                                      options={this.state.semesters.filter(
                                        (x) =>
                                          dateFns.parseISO(x.endDate) >=
                                          new Date()
                                      )}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col md={2}>
                                  <FormGroup>
                                    <Label for="studentClass">Level</Label>
                                    <Select
                                      value={
                                        this.state.selectedCourseId &&
                                        this.state.courses.find(
                                          (x) =>
                                            x.value ===
                                            this.state.selectedCourseId
                                        )
                                      }
                                      onChange={this.handleCourseChange}
                                      options={this.state.courses}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={2}>
                                  <FormGroup>
                                    <Label for="studentClass">Period</Label>
                                    <Select
                                      isDisabled={
                                        this.state.selectedCourseId === "" ||
                                        this.state.selectedSemesterId === ""
                                      }
                                      value={
                                        this.state.selectedPeriod &&
                                        this.periodOptions.find(
                                          (x) =>
                                            x.value ===
                                            this.state.selectedPeriod
                                        )
                                      }
                                      onChange={this.handlePeriodChange}
                                      options={this.periodOptions}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={2}>
                                  <FormGroup>
                                    <Label for="studentClass">Subject</Label>
                                    <Select
                                      isDisabled={
                                        this.state.selectedCourseId === "" ||
                                        this.state.selectedPeriod === "" ||
                                        this.state.selectedSemesterId === ""
                                      }
                                      value={
                                        this.state.selectedClassId &&
                                        this.state.classes.find(
                                          (x) =>
                                            x.value ===
                                            this.state.selectedClassId
                                        )
                                      }
                                      onChange={this.handleClassChange}
                                      options={this.state.classes}
                                    />
                                  </FormGroup>
                                </Col>

                                <Col md={2}>
                                  <FormGroup>
                                    <Label for="studentClass">
                                      First day of class
                                    </Label>

                                    <DatePicker
                                      selected={this.state.firstDayOfClass}
                                      onChange={this.handleFirstDayOfClassChange.bind(
                                        this
                                      )}
                                      name="FirstDayOfClass"
                                      id="FirstDayOfClass"
                                      dateFormat="MM/dd/yyyy"
                                    />
                                    <Tooltip
                                      placement="top-end"
                                      isOpen={
                                        this.state.tooltipFirstDayOfClassIsOpen
                                      }
                                      target={"FirstDayOfClass"}
                                      toggle={this.toggleToolTip.bind(this)}
                                    >
                                      {
                                        "Select the student's first day of class.\n\nThe student will be absent in all attendance after the informed date and before today."
                                      }
                                    </Tooltip>
                                  </FormGroup>
                                </Col>

                                <Col md={2}>
                                  <FormGroup>
                                    <Label>&nbsp;</Label>
                                    <Button
                                      color="primary"
                                      style={{ margin: "0" }}
                                      onClick={this.classEnroll.bind(this)}
                                      disabled={
                                        this.state.selectedClassId === "" ||
                                        !dateFns.isValid(
                                          this.state.firstDayOfClass
                                        )
                                      }
                                    >
                                      Enroll
                                    </Button>
                                  </FormGroup>
                                </Col>
                              </Row>
                              {studentClasses.length > 0 && (
                                <Row form>
                                  <Table className="table">
                                    <thead>
                                      <tr>
                                        {/* <th>#</th> */}
                                        <th>Subject</th>
                                        <th>Teacher</th>

                                        <Can
                                          feature="Student_SubjectShowGrades"
                                          yes={() =>
                                            this.props.editMode ===
                                              editMode.EDIT && (
                                              <React.Fragment>
                                                <th>Grade</th>
                                              </React.Fragment>
                                            )
                                          }
                                        ></Can>

                                        <th>Campus</th>
                                        <th>Semester</th>
                                        <th>Start Date</th>
                                        <th>Created Date</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <br />
                                      </tr>
                                      {studentClasses}
                                    </tbody>
                                  </Table>
                                  <AssignmentView
                                    studentId={this.props.id}
                                    classId={
                                      this.state.selectClassIdForAssignmentModal
                                    }
                                    isOpen={this.state.openAssignmentModal}
                                    toggleAssignmentModal={this.toggleAssignmentModal.bind(
                                      this,
                                      this.state.selectClassIdForAssignmentModal
                                    )}
                                    callback={this.refreshStudentClasses}
                                  />
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Can
                        feature="Student_CanScheduleAnyTest"
                        yes={() =>
                          this.props.editMode === editMode.EDIT && (
                            <React.Fragment>
                              <Row form>
                                <Col md={12} style={{ marginBottom: "1rem" }}>
                                  <Card>
                                    <CardBody>
                                      <CustomInput
                                        type="switch"
                                        id={"CanScheduleAnyTest"}
                                        name={"CanScheduleAnyTest"}
                                        label={
                                          "Student can schedule any missed test (Mid Term and Test). This option will be automatically unchecked when the student pays for the missed test."
                                        }
                                        checked={
                                          this.state.Student.CanScheduleAnyTest
                                        }
                                        onChange={
                                          this.onSwitchCanScheduleAnyTest
                                        }
                                      />
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                        }
                      ></Can>
                    </TabPane>
                    <TabPane tabId="3">
                      {" "}
                      <Row
                        form
                        style={
                          viewOnly
                            ? { pointerEvents: "none", opacity: "0.7" }
                            : {}
                        }
                      >
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5">Attendance</CardTitle>
                            </CardHeader>
                            {currentSemester && (
                              <CardBody>
                                <Row form>
                                  <Col md={2}>
                                    <FormGroup>
                                      <Label for="studentClass">Semester</Label>
                                      <Select
                                        value={
                                          this.state
                                            .calendarSelectedSemesterId &&
                                          this.state.Student.studiedSemesters.find(
                                            (x) =>
                                              x.value ===
                                              this.state
                                                .calendarSelectedSemesterId
                                          )
                                        }
                                        onChange={
                                          this.handleCalendarSemesterChange
                                        }
                                        options={
                                          this.state.Student.studiedSemesters
                                        }
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md={10}>
                                    <FormGroup>
                                      <Label>&nbsp;</Label>
                                      <Label
                                        for="studentClass"
                                        style={{
                                          textAlign: "right",
                                          float: "right",
                                          marginTop: "20px",
                                          color: "red",
                                        }}
                                      >
                                        * Days with a lower red line indicate
                                        that the teacher made a note.
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>{semesters}</Row>
                                <br />
                              </CardBody>
                            )}
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row
                        form
                        style={
                          viewOnly
                            ? { pointerEvents: "none", opacity: "0.7" }
                            : {}
                        }
                      >
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5">Notes</CardTitle>
                            </CardHeader>
                            <CardBody>
                              {!this.state.currentNote.openNotePanel && (
                                <Row form>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Button
                                        color="primary"
                                        style={{ margin: "0" }}
                                        onClick={this.toggleNotePanel.bind(
                                          this
                                        )}
                                      >
                                        Add note
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              )}

                              {this.state.currentNote.openNotePanel && (
                                <React.Fragment>
                                  <Row form>
                                    <Col md={12}>
                                      <FormGroup>
                                        <Input
                                          className={`form-group`}
                                          type="textarea"
                                          name="studentNote"
                                          id="studentNote"
                                          autoComplete="nope"
                                          value={
                                            this.state.currentNote.description
                                          }
                                          onChange={this.handleNoteInput}
                                          placeholder={
                                            "Type the new note here..."
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <Row form>
                                    <Col md={12}>
                                      <FormGroup>
                                        <Button
                                          color="primary"
                                          className="mr-2"
                                          style={{ margin: "0" }}
                                          onClick={this.saveNote.bind(this)}
                                          disabled={
                                            this.state.currentNote
                                              .description === ""
                                          }
                                        >
                                          Save note
                                        </Button>
                                        <Button
                                          color="danger"
                                          style={{ margin: "0" }}
                                          onClick={this.cancelNote.bind(this)}
                                        >
                                          Cancel
                                        </Button>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}

                              {studentNotes.length > 0 && (
                                <Row form>
                                  <Table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Note</th>
                                        <th>Actions</th>
                                        <th>User</th>
                                      </tr>
                                    </thead>
                                    <tbody>{studentNotes}</tbody>
                                  </Table>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="5">
                      <Row
                        form
                        style={
                          viewOnly
                            ? { pointerEvents: "none", opacity: "0.7" }
                            : {}
                        }
                      >
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5">Documents</CardTitle>
                            </CardHeader>
                            <CardBody>
                              {!this.state.currentDocumentNote
                                .openNotePanel && (
                                <Row form>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Button
                                        color="primary"
                                        style={{ margin: "0" }}
                                        onClick={this.toggleDocumentNotePanel.bind(
                                          this
                                        )}
                                      >
                                        Add document
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              )}

                              {this.state.currentDocumentNote.openNotePanel && (
                                <React.Fragment>
                                  <Row form>
                                    <Col md={2}>
                                      <FormGroup>
                                        <Label for="studentClass">
                                          Document Type
                                        </Label>
                                        <Select
                                          value={
                                            this.state.currentDocumentNote
                                              .selectedDocumentTypeId &&
                                            this.state.documentTypes.find(
                                              (x) =>
                                                x.value ===
                                                this.state.currentDocumentNote
                                                  .selectedDocumentTypeId
                                            )
                                          }
                                          onChange={
                                            this.handleDocumentTypeChange
                                          }
                                          options={this.state.documentTypes}
                                        />
                                      </FormGroup>
                                    </Col>

                                    {this.state.currentDocumentNote
                                      .selectedDocumentTypeId !== "" && (
                                      <React.Fragment>
                                        <Col md={4}>
                                          <FormGroup>
                                            <Label for="exampleFile">
                                              File
                                            </Label>
                                            <Input
                                              type="file"
                                              accept="application/pdf"
                                              name="file"
                                              id="exampleFile"
                                              onChange={this.onNoteFileChange}
                                            />
                                          </FormGroup>
                                        </Col>
                                      </React.Fragment>
                                    )}
                                  </Row>

                                  {this.state.status.selectedDocumentTypeId !==
                                    "" && (
                                    <React.Fragment>
                                      <Row form>
                                        <Col md={12}>
                                          <FormGroup>
                                            <Label for="statusEndDate">
                                              Description ( optional )
                                            </Label>
                                            <Input
                                              className={`form-group`}
                                              type="textarea"
                                              name="comment"
                                              id="studentNote"
                                              autoComplete="nope"
                                              value={
                                                this.state.currentDocumentNote
                                                  .description
                                              }
                                              onChange={
                                                this.handleDocumentNoteInput
                                              }
                                              placeholder="Type the description here..."
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}

                                  <Row form>
                                    <Col md={12}>
                                      <FormGroup>
                                        <Button
                                          color="primary"
                                          className="mr-2"
                                          style={{ margin: "0" }}
                                          onClick={this.saveDocumentNote.bind(
                                            this
                                          )}
                                          disabled={
                                            this.state.currentDocumentNote
                                              .fileName === ""
                                          }
                                        >
                                          Save document
                                        </Button>
                                        <Button
                                          color="danger"
                                          style={{ margin: "0" }}
                                          onClick={this.cancelDocumentNote.bind(
                                            this
                                          )}
                                        >
                                          Cancel
                                        </Button>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}

                              {studentDocumentNotes.length > 0 && (
                                <Row form>
                                  <Table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>Date</th>
                                        <th>Document</th>
                                        <th>Description</th>
                                        <th>Actions</th>
                                        <th>User</th>
                                      </tr>
                                    </thead>
                                    <tbody>{studentDocumentNotes}</tbody>
                                  </Table>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="6">
                      <Row
                        form
                        style={
                          viewOnly
                            ? { pointerEvents: "none", opacity: "0.7" }
                            : {}
                        }
                      >
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5">Messages</CardTitle>
                            </CardHeader>
                            <CardBody>
                              {messageList.length > 0 && (
                                <Row form>
                                  <Table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>Date</th>
                                        <th>Title</th>
                                        <th>Message</th>
                                        <th>Sender</th>
                                      </tr>
                                    </thead>
                                    <tbody>{messageList}</tbody>
                                  </Table>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="7">
                      <Row
                        form
                        style={
                          viewOnly
                            ? { pointerEvents: "none", opacity: "0.7" }
                            : {}
                        }
                      >
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5">Status</CardTitle>
                            </CardHeader>
                            <CardBody>
                              {!this.state.status.openStatusChange &&
                                !this.state.Student.statusList.find(
                                  (x) => x.isNew === true
                                ) && (
                                  <Row form>
                                    <Col md={4}>
                                      <FormGroup>
                                        <Button
                                          color="primary"
                                          style={{ margin: "0" }}
                                          onClick={this.toggleStatusChange.bind(
                                            this
                                          )}
                                        >
                                          Change status
                                        </Button>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                )}

                              {this.state.status.openStatusChange && (
                                <React.Fragment>
                                  <Row form>
                                    <Col md={2}>
                                      <FormGroup>
                                        <Label for="studentClass">Type</Label>
                                        <Select
                                          value={
                                            this.state.status
                                              .selectedStatusTypeId &&
                                            this.state.statusTypes.find(
                                              (x) =>
                                                x.value ===
                                                this.state.status
                                                  .selectedStatusTypeId
                                            )
                                          }
                                          onChange={this.handleStatusTypeChange}
                                          options={this.state.statusTypes}
                                        />
                                      </FormGroup>
                                    </Col>

                                    {this.state.status.hasDate && (
                                      <React.Fragment>
                                        <Col md={2}>
                                          <FormGroup>
                                            <Label for="studentClass">
                                              Semester
                                            </Label>
                                            <Select
                                              value={
                                                this.state.status
                                                  .selectedSemesterId &&
                                                this.state.semesters.find(
                                                  (x) =>
                                                    x.value ===
                                                    this.state.status
                                                      .selectedSemesterId
                                                )
                                              }
                                              onChange={
                                                this.handleSemesterChange
                                              }
                                              options={this.state.semesters}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                          <FormGroup>
                                            <Label for="statusStartDate">
                                              Start Date
                                            </Label>
                                            <Input
                                              type="date"
                                              name="startDate"
                                              id="statusStartDate"
                                              value={
                                                this.state.status.startDate
                                              }
                                              onChange={
                                                this.handleStatusDateInput
                                              }
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                          <FormGroup>
                                            <Label for="statusEndDate">
                                              End Date
                                            </Label>
                                            <Input
                                              type="date"
                                              name="endDate"
                                              id="statusEndDate"
                                              value={this.state.status.endDate}
                                              onChange={
                                                this.handleStatusDateInput
                                              }
                                            />
                                          </FormGroup>
                                        </Col>
                                      </React.Fragment>
                                    )}

                                    {this.state.status.selectedStatusTypeId !==
                                      "" && (
                                      <React.Fragment>
                                        <Col md={4}>
                                          <FormGroup>
                                            <Label for="exampleFile">
                                              File
                                            </Label>
                                            <Input
                                              type="file"
                                              name="file"
                                              accept="application/pdf"
                                              id="exampleFile"
                                              onChange={this.onFileChange}
                                            />
                                          </FormGroup>
                                        </Col>
                                      </React.Fragment>
                                    )}
                                  </Row>

                                  {this.state.status.selectedStatusTypeId !==
                                    "" && (
                                    <React.Fragment>
                                      <Row form>
                                        <Col md={12}>
                                          <FormGroup>
                                            <Label for="statusEndDate">
                                              Comment ( optional )
                                            </Label>
                                            <Input
                                              className={`form-group`}
                                              type="textarea"
                                              name="comment"
                                              id="studentNote"
                                              autoComplete="nope"
                                              value={this.state.status.comment}
                                              onChange={
                                                this.handleStatusDateInput
                                              }
                                              placeholder="Enter the status comment here..."
                                            />
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}

                                  <Row form>
                                    <Col md={12}>
                                      <FormGroup>
                                        <Button
                                          color="primary"
                                          className="mr-2"
                                          style={{ margin: "0" }}
                                          onClick={this.addStatus.bind(this)}
                                          disabled={
                                            this.state.status
                                              .selectedStatusTypeId === ""
                                          }
                                        >
                                          Save status
                                        </Button>
                                        <Button
                                          color="danger"
                                          style={{ margin: "0" }}
                                          onClick={this.cancelStatus.bind(this)}
                                        >
                                          Cancel
                                        </Button>
                                      </FormGroup>
                                    </Col>
                                  </Row>

                                  <br />
                                </React.Fragment>
                              )}

                              <Row form>
                                <Table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>Date</th>
                                      <th>Status</th>
                                      <th>Comment</th>
                                      <th>Start date</th>
                                      <th>End date</th>
                                      <th>Actions</th>
                                      <th>User</th>
                                    </tr>
                                  </thead>
                                  <tbody>{statusList}</tbody>
                                </Table>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="8">
                      <Row form>
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5">Block</CardTitle>
                            </CardHeader>
                            <CardBody>
                              <Row form>
                                <Col md={4} style={{ marginBottom: "1rem" }}>
                                  <CustomInput
                                    type="switch"
                                    id={"Blocked"}
                                    name={"Blocked"}
                                    label={"Blocked"}
                                    checked={this.state.Student.Blocked}
                                    onChange={this.onSwitchBlocked}
                                  />
                                </Col>
                              </Row>

                              <Row form>
                                <Table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>Blocked</th>
                                      <th>User</th>
                                      <th>Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>{blockHistory}</tbody>
                                </Table>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="9">
                      <Row form>
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5">Invoice</CardTitle>
                            </CardHeader>
                            <CardBody>
                              <Row form>
                                <Table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>Balance</th>
                                      <th>Due Date</th>
                                      <th>DocNumber</th>
                                      <th>InvoiceId</th>
                                    </tr>
                                  </thead>
                                  <tbody>{invoiceDetailList}</tbody>
                                </Table>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="10">
                      <Row
                        form
                        style={
                          viewOnly
                            ? { pointerEvents: "none", opacity: "0.7" }
                            : {}
                        }
                      >
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5">Books</CardTitle>
                            </CardHeader>
                            <CardBody>
                              {!this.state.currentBookPurchase
                                .openBookPurchasePanel && (
                                <Row form>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Button
                                        color="primary"
                                        style={{ margin: "0" }}
                                        onClick={this.toggleBookPurchasePanel.bind(
                                          this
                                        )}
                                      >
                                        Add Book Purchase
                                      </Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              )}

                              {this.state.currentBookPurchase
                                .openBookPurchasePanel && (
                                <React.Fragment>
                                  <Row form>
                                    <Col md={2}>
                                      <FormGroup>
                                        <Label for="studentClass">
                                          Book Name
                                        </Label>
                                        <Select
                                          value={
                                            this.state.currentBookPurchase
                                              .selectedBookId &&
                                            this.state.books.find(
                                              (x) =>
                                                x.value ===
                                                this.state.currentBookPurchase
                                                  .selectedBookId
                                            )
                                          }
                                          onChange={this.handleBookChange}
                                          options={this.state.books}
                                        />
                                      </FormGroup>
                                    </Col>

                                    {this.state.currentBookPurchase
                                      .selectedBookId !== "" && (
                                      <Col md={2}>
                                        <FormGroup>
                                          <Label for="statusEndDate">
                                            Ticket Number
                                          </Label>
                                          <Input
                                            className={`form-group`}
                                            type="text"
                                            name="comment"
                                            id="ticketNumber"
                                            autoComplete="nope"
                                            value={
                                              this.state.currentBookPurchase
                                                .ticketNumber
                                            }
                                            onChange={
                                              this.handleTicketNumberInput
                                            }
                                            placeholder="Ticket number"
                                          />
                                        </FormGroup>
                                      </Col>
                                    )}
                                  </Row>

                                  <Row form>
                                    <Col md={12}>
                                      <FormGroup>
                                        <Button
                                          color="primary"
                                          className="mr-2"
                                          style={{ margin: "0" }}
                                          onClick={this.addBookPurchase.bind(
                                            this
                                          )}
                                          disabled={
                                            this.state.currentBookPurchase
                                              .ticketNumber === ""
                                          }
                                        >
                                          Save book purchase
                                        </Button>
                                        <Button
                                          color="danger"
                                          style={{ margin: "0" }}
                                          onClick={this.cancelBookPurchaseInsert.bind(
                                            this
                                          )}
                                        >
                                          Cancel
                                        </Button>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}

                              {bookPurchases.length > 0 && (
                                <Row form>
                                  <Table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th>Ticket Number</th>
                                        <th>Status</th>
                                        <th>Purchase Date</th>
                                        <th>Book</th>
                                        <th>Semester</th>
                                        <th>Purchase User</th>
                                        <th>Delivered Date</th>
                                        <th>Delivered By</th>
                                        <th>Canceled Date</th>
                                        <th>Canceled By</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>{bookPurchases}</tbody>
                                  </Table>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
                <br />
              </ModalBody>
              <ModalFooter>
                {this.props.editMode === editMode.EDIT &&
                  currentUser !== null && (
                    <Can
                      feature="Student_Complete_Record"
                      yes={() => (
                        <Button
                          color="info"
                          onClick={this.toggleModalInput.bind(this)}
                          style={{ marginLeft: "10px" }}
                        >
                          Complete record
                        </Button>
                      )}
                    ></Can>
                  )}

                {this.props.editMode === editMode.EDIT &&
                  currentUser !== null && (
                    <Can
                      feature="Student_Financial_Contract"
                      yes={() => (
                        <Button
                          color="success"
                          onClick={this.financialContract.bind(this)}
                          style={{ marginLeft: "10px", marginRight: "auto" }}
                        >
                          Financial Contract
                        </Button>
                      )}
                    ></Can>
                  )}

                {currentUser !== null && (
                  <Can
                    feature="Student_Send_Message"
                    yes={() =>
                      this.props.editMode === editMode.EDIT && (
                        <Button
                          color="primary"
                          onClick={this.toggleMessageModal.bind(this)}
                        >
                          Send message
                        </Button>
                      )
                    }
                  ></Can>
                )}

                {currentUser !== null && (
                  <Can
                    feature="Student_Add"
                    yes={() => (
                      <Button
                        color="primary"
                        onClick={
                          this.props.editMode === editMode.INSERT
                            ? this.addStudent.bind(this)
                            : this.updateStudent.bind(this)
                        }
                        disabled={!this.state.formValid}
                      >
                        {this.props.editMode === editMode.INSERT
                          ? "Add"
                          : "Update"}
                      </Button>
                    )}
                  ></Can>
                )}

                <Button
                  color="secondary"
                  onClick={this.props.toggleStudentModal.bind(this)}
                >
                  Cancel
                </Button>

                <MessageEdit
                  isOpen={this.state.openModalMessage}
                  toggleMessageModal={this.toggleMessageModal.bind(this)}
                  editMode={editMode.INSERT}
                  tables={this.props.tables}
                  StudentId={this.props.id}
                  StudentName={this.state.Student.Name.Value}
                />
                <ModalInput
                  isOpen={this.state.modalInputIsOpen}
                  toggle={this.toggleModalInput}
                  studentId={this.props.id}
                  semesters={this.props.tables.semesters}
                />
                <ModalFinancialContract
                  isOpen={this.state.modalFinancialContractIsOpen}
                  toggle={this.toggleModalFinancialContractInput}
                  student={this.state.Student}
                  visaTypes={this.state.visaTypes}
                  semesters={this.props.tables.semesters}
                  tables={this.props.tables}
                />

                <AuthenticateModal
                  isOpen={this.state.modalAuthenticateIsOpen}
                  toggle={this.toggleModalAuthenticate.bind(this)}
                  message={this.state.modalAuthenticateMessage}
                  note={this.state.modalAuthenticateNote}
                  callback={this.updateStudent.bind(this)}
                  onChange={this.handleAuthenticationInput}
                />

                <Can
                  feature="Student_Edit_Attendance"
                  yes={() => (
                    <React.Fragment>
                      {this.state.modalAttendanceIsOpen && (
                        <ModalAttendance
                          isOpen={this.state.modalAttendanceIsOpen}
                          toggle={this.toggleModalAttendance}
                          date={this.state.modalAttendanceDate}
                          status={this.state.modalAttendanceStatus}
                          comment={this.state.modalAttendanceComment}
                          subject={this.state.modalAttendanceSubject}
                          callback={this.refreshAttendance.bind(this)}
                          id={this.state.modalAttendanceId}
                        />
                      )}
                    </React.Fragment>
                  )}
                ></Can>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={this.state.modalHTMLMessageIsOpen}
              toggle={this.toggleModalHTMLMessage.bind(this)}
              centered={true}
              autoFocus={true}
              backdrop="static"
              //onClosed={this.onCloseModal}
              size={"lg"}
              scrollable
            >
              <ModalHeader toggle={this.toggleModalHTMLMessage.bind(this)}>
                {this.state.modalHTMLMessageTitle}
              </ModalHeader>
              <ModalBody>
                {(this.state.modalHTMLMessageDescription.includes(
                  "<!DOCTYPE html>"
                ) && (
                  <div
                    id="htmlContainer"
                    dangerouslySetInnerHTML={{
                      __html: this.state.modalHTMLMessageDescription,
                    }}
                  ></div>
                )) || (
                  <div id="htmlContainer">
                    {this.state.modalHTMLMessageDescription}
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                {/* <Button color="primary" onClick={this.print.bind(this)}>
                  Print
                </Button> */}
                <Button
                  color="secondary"
                  onClick={this.toggleModalHTMLMessage.bind(this)}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Row>
        </div>
      </>
    );
  }

  upload() {
    document.getElementById("selectImage").click();
  }

  handlePictureChange = (event) => {
    let modalState = this.state;

    modalState.Student.pictureUrl.Value = URL.createObjectURL(
      event.target.files[0]
    );

    modalState.Student.pictureFileContent = event.target.files[0];

    this.setState(modalState);
  };

  handleFirstDayOfClassChange = (date) => {
    let modalState = this.state;

    modalState.firstDayOfClass = date;

    this.setState(modalState);
  };

  toggleToolTip = () => {
    let modalState = this.state;

    modalState.tooltipFirstDayOfClassIsOpen =
      !modalState.tooltipFirstDayOfClassIsOpen;

    this.setState(modalState);
  };

  onFileChange = (event) => {
    let file = event.target.files[0];

    return new Promise((resolve, reject) => {
      let modalState = this.state;

      if (file !== undefined) {
        modalState.status.fileContent = file;
        modalState.status.fileName = file.name;
      } else {
        modalState.status.fileContent = null;
        modalState.status.fileName = "";
      }

      this.setState(modalState, resolve(true));
    });
  };

  onNoteFileChange = (event) => {
    let file = event.target.files[0];

    return new Promise((resolve, reject) => {
      let modalState = this.state;

      if (file !== undefined) {
        modalState.currentDocumentNote.fileContent = file;
        modalState.currentDocumentNote.fileName = file.name;
      } else {
        modalState.currentDocumentNote.fileContent = null;
        modalState.currentDocumentNote.fileName = "";
      }

      this.setState(modalState, resolve(true));
    });
  };

  toggleMessageModal = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;
      modalState.openModalMessage = !modalState.openModalMessage;
      this.setState(modalState, resolve(true));
    });
  };

  toggleModalHTMLMessage = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;
      modalState.modalHTMLMessageIsOpen = !modalState.modalHTMLMessageIsOpen;
      this.setState(modalState, resolve(true));
    });
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  handleGenderChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Student.Gender.Value = selectedOption.value;

    this.setState(modalState, () => {
      this.validateField("Gender", selectedOption.value);
    });
  };

  handleVisaTypeChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Student.VisaTypeId.Value = selectedOption.value;

    this.setState(modalState, () => {
      this.validateField("VisaType", selectedOption.value);
    });
  };

  handleSevisChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Student.SevisId.Value = selectedOption.value;

    this.setState(modalState, () => {
      this.validateField("Sevis", selectedOption.value);
    });
  };

  handleStudentOriginChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Student.StudentOriginId.Value = selectedOption.value;

    this.setState(modalState, () => {
      this.validateField("StudentOrigin", selectedOption.value);
    });
  };

  validateField(fieldName, value) {
    let modalState = this.state;

    switch (fieldName) {
      case "Name":
        modalState.Student.Name.IsValid = value.length >= 1;
        modalState.Student.Name.ErrorMsg = modalState.Student.Name.IsValid
          ? ""
          : "Name is empty";
        break;
      case "NickName":
        modalState.Student.NickName.IsValid = value.length >= 1;
        modalState.Student.NickName.ErrorMsg = modalState.Student.NickName
          .IsValid
          ? ""
          : "NickName is empty";
        break;
      case "I20":
        modalState.Student.I20.IsValid = value.match(/^[T|R|N|J][0-9]{10}$/i)
          ? true
          : false;
        modalState.Student.I20.ErrorMsg = modalState.Student.I20.IsValid
          ? ""
          : "Pattern for I-20: J or N or R or T + 10 numbers";
        break;
      case "I20StartDate":
        modalState.Student.I20StartDate.IsValid = value.length >= 1;
        modalState.Student.I20StartDate.ErrorMsg = modalState.Student
          .I20StartDate.IsValid
          ? ""
          : "I20 Start Date is empty";
        break;
      case "I20EndDate":
        modalState.Student.I20EndDate.IsValid = value.length >= 1;
        modalState.Student.I20EndDate.ErrorMsg = modalState.Student.I20EndDate
          .IsValid
          ? ""
          : "I20 End Date is empty";
        break;
      case "EnrollmentDate":
        modalState.Student.EnrollmentDate.IsValid = value.length >= 1;
        modalState.Student.EnrollmentDate.ErrorMsg = modalState.Student
          .EnrollmentDate.IsValid
          ? ""
          : "EnrollmentDate is empty";
        break;
      case "PassportExpDate":
        modalState.Student.PassportExpDate.IsValid = value.length >= 1;
        modalState.Student.PassportExpDate.ErrorMsg = modalState.Student
          .PassportExpDate.IsValid
          ? ""
          : "PassportExpDate is empty";
        break;
      case "BirthDate":
        modalState.Student.BirthDate.IsValid = value.length >= 1;
        modalState.Student.BirthDate.ErrorMsg = modalState.Student.BirthDate
          .IsValid
          ? ""
          : "BirthDate is empty";
        break;
      case "Gender":
        modalState.Student.Gender.IsValid = value.length >= 1;
        modalState.Student.Gender.ErrorMsg = modalState.Student.Gender.IsValid
          ? ""
          : "Gender is empty";
        break;
      case "NationalityId":
        modalState.Student.NationalityId.IsValid = value.length >= 1;
        modalState.Student.NationalityId.ErrorMsg = modalState.Student
          .NationalityId.IsValid
          ? ""
          : "NationalityId is empty";
        break;
      case "VisaType":
        modalState.Student.VisaTypeId.IsValid = value.length >= 1;
        modalState.Student.VisaTypeId.ErrorMsg = modalState.Student.VisaTypeId
          .IsValid
          ? ""
          : "Visa Type is empty";
        break;
      case "Sevis":
        modalState.Student.SevisId.IsValid = value.length >= 1;
        modalState.Student.SevisId.ErrorMsg = modalState.Student.SevisId.IsValid
          ? ""
          : "Sevis is empty";
        break;
      case "StudentOrigin":
        modalState.Student.StudentOriginId.IsValid = value.length >= 1;
        modalState.Student.StudentOriginId.ErrorMsg = modalState.Student
          .StudentOriginId.IsValid
          ? ""
          : "Origin is empty";
        break;
      case "Street":
        modalState.Student.address.Street.IsValid = value.length >= 1;
        modalState.Student.address.Street.ErrorMsg = modalState.Student.address
          .Street.IsValid
          ? ""
          : "Street is empty";
        break;
      case "CountryId":
        modalState.Student.address.CountryId.IsValid = value.length >= 1;
        modalState.Student.address.CountryId.ErrorMsg = modalState.Student
          .address.CountryId.IsValid
          ? ""
          : "CountryId is empty";
        break;
      case "City":
        modalState.Student.address.City.IsValid = value.length >= 1;
        modalState.Student.address.City.ErrorMsg = modalState.Student.address
          .City.IsValid
          ? ""
          : "City is empty";
        break;
      case "State":
        modalState.Student.address.State.IsValid = value.length >= 1;
        modalState.Student.address.State.ErrorMsg = modalState.Student.address
          .State.IsValid
          ? ""
          : "State is empty";
        break;
      case "Zip":
        modalState.Student.address.Zip.IsValid = value.length >= 1;
        modalState.Student.address.Zip.ErrorMsg = modalState.Student.address.Zip
          .IsValid
          ? ""
          : "Zip code is empty";
        break;
      case "CellPhone":
        modalState.Student.address.CellPhone.IsValid = value.length >= 1;
        modalState.Student.address.CellPhone.ErrorMsg = modalState.Student
          .address.CellPhone.IsValid
          ? ""
          : "Cell phone is empty";
        break;

      case "Email":
        modalState.Student.address.Email.IsValid = value.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        )
          ? true
          : false;
        modalState.Student.address.Email.ErrorMsg = modalState.Student.address
          .Email.IsValid
          ? ""
          : "E-mail is invalid";
        break;

      default:
        break;
    }

    this.setState(modalState, this.validateForm());
  }

  validateForm() {
    let modalState = this.state;

    modalState.formValid =
      modalState.Student.Name.IsValid === true &&
      modalState.Student.I20.IsValid === true &&
      modalState.Student.I20StartDate.IsValid === true &&
      modalState.Student.I20EndDate.IsValid === true &&
      modalState.Student.EnrollmentDate.IsValid === true &&
      modalState.Student.PassportExpDate.IsValid === true &&
      modalState.Student.Gender.IsValid === true &&
      modalState.Student.VisaTypeId.IsValid === true &&
      modalState.Student.SevisId.IsValid === true &&
      modalState.Student.StudentOriginId.IsValid === true &&
      modalState.Student.NationalityId.IsValid === true &&
      modalState.Student.BirthDate.IsValid === true &&
      modalState.Student.address.Street.IsValid === true &&
      modalState.Student.address.CountryId.IsValid === true &&
      modalState.Student.address.City.IsValid === true &&
      modalState.Student.address.State.IsValid === true &&
      modalState.Student.address.Zip.IsValid === true &&
      modalState.Student.address.CellPhone.IsValid === true &&
      modalState.Student.address.Email.IsValid === true;

    this.setState(modalState);
  }

  filterAttendance(date) {
    return this.state.Student.attendance.filter(
      (x) => x.date.substring(0, 2) === dateFns.format(date, "MM")
    );
  }

  handleAuthenticationInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState[name] = value;

    this.setState(modalState);
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState.Student[name].Value = value;

    this.setState(modalState, () => {
      this.validateField(name, value);
    });
  };

  handleAddressInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState.Student.address[name].Value = value;

    this.setState(modalState, () => {
      this.validateField(name, value);
    });
  };

  handleStatusDateInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => {
      let status = Object.assign({}, prevState.status);
      status[name] = value;
      return { status };
    });
  };

  handleNoteInput = (e) => {
    const value = e.target.value;

    let modalState = this.state;

    modalState.currentNote.description = value;

    this.setState(modalState);
  };

  handleDocumentNoteInput = (e) => {
    const value = e.target.value;

    let modalState = this.state;

    modalState.currentDocumentNote.description = value;

    this.setState(modalState);
  };

  handleTicketNumberInput = (e) => {
    const value = e.target.value;

    let modalState = this.state;

    modalState.currentBookPurchase.ticketNumber = value;

    this.setState(modalState);
  };

  onBlur = (event) => {
    let modalState = this.state;
    let value = modalState.Student.NationalityId.Value;

    this.validateField("NationalityId", value);
  };

  onCountryBlur = (event) => {
    let modalState = this.state;

    this.validateField("CountryId", modalState.Student.address.CountryId.Value);
    this.validateField("State", modalState.Student.address.State.Value);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    let modalState = this.state;
    modalState.suggestions = this.getSuggestions(value);
    this.setState(modalState);
  };

  onSuggestionSelected = (event, { suggestion }) => {
    let modalState = this.state;
    modalState.Student.NationalityId.Value = suggestion.value;

    this.setState(modalState, () => {
      this.validateField("NationalityId", suggestion.value);
    });
  };

  onSuggestionsClearRequested = () => {
    let modalState = this.state;
    modalState.suggestions = [];

    this.setState(modalState);
  };

  onOpened() {
    this.openModalStudent();
  }

  async addStudent() {
    let studentClasses = [];
    this.state.Student.classes.map((classe, index) =>
      studentClasses.push({
        Id: classe.id,
        Classe: { Id: classe.value },
        Active: classe.active,
        StartDate: classe.startDate,
      })
    );

    let notes = [];
    this.state.Student.notes
      .filter((x) => x.isNew)
      .map((note, index) =>
        notes.push({
          Description: note.description,
          File: note.fileContent,
          DocumentTypeId: note.documentTypeId,
        })
      );

    let address = {
      Street: this.state.Student.address.Street.Value,
      CountryId: this.state.Student.address.CountryId.Value,
      City: this.state.Student.address.City.Value,
      Zip: this.state.Student.address.Zip.Value,
      HomePhone: this.state.Student.address.HomePhone.Value,
      CellPhone: this.state.Student.address.CellPhone.Value,
      Email: this.state.Student.address.Email.Value,
      State: this.state.Student.address.State.Value,
    };

    let student = {
      Name: this.state.Student.Name.Value,
      NickName: this.state.Student.NickName.Value,
      I20: this.state.Student.I20.Value,
      I20StartDate: this.state.Student.I20StartDate.Value,
      I20EndDate: this.state.Student.I20EndDate.Value,
      EnrollmentDate: this.state.Student.EnrollmentDate.Value,
      PassportExpDate: this.state.Student.PassportExpDate.Value,
      BirthDate: this.state.Student.BirthDate.Value,
      Gender: this.state.Student.Gender.Value,
      VisaType: { Id: this.state.Student.VisaTypeId.Value },
      Sevis: { Id: this.state.Student.SevisId.Value },
      StudentOrigin: { Id: this.state.Student.StudentOriginId.Value },
      NationalityId: this.state.Student.NationalityId.Value,
      StudentClasses: studentClasses,
      Address: address,
      PictureFileContent: this.state.Student.pictureFileContent,
      Notes: notes,
    };

    await this.fillStudentNotesFiles(student).then(() => {
      this.fillStudentPictureBase64File(student).then(() => {
        axios.post("students", student).then((response) => {
          this.props.toggleStudentModal().then(() => {
            this.resetModal().then(() => {
              if (
                this.props.callback &&
                typeof this.props.callback === "function"
              ) {
                this.props.callback();
              }
            });
          });
        });
      });
    });
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  }

  async fillStudentStatusFiles(student) {
    for (const status of student.StatusList) {
      if (status.File !== null && status.File !== undefined) {
        let base64File = await this.getBase64(status.File);
        let fileName = status.File.name;

        status.Document = { FileContentBase64: base64File, FileName: fileName };
        status.File = null;
      }
    }
  }

  async fillStudentNotesFiles(student) {
    for (const note of student.Notes) {
      if (note.File !== null && note.File !== undefined) {
        let base64File = await this.getBase64(note.File);
        let fileName = note.File.name;

        note.Document = {
          FileContentBase64: base64File,
          FileName: fileName,
          DocumentType: { Id: note.DocumentTypeId },
        };

        note.File = null;
      }
    }
  }

  async fillStudentPictureBase64File(student) {
    if (student.PictureFileContent !== null) {
      let base64File = await this.getBase64(student.PictureFileContent);
      student.PictureFileContent = base64File;
    }
  }

  async updateStudent() {
    let studentClasses = [];
    this.state.Student.classes.map((classe, index) =>
      studentClasses.push({
        Id: classe.id,
        Classe: { Id: classe.value },
        Active: classe.active,
        StartDate: classe.startDate,
        ToBeDeleted: classe.toBeDeleted,
      })
    );

    let notes = [];
    this.state.Student.notes
      .filter((x) => x.isNew || x.toBeDeleted)
      .map((note, index) =>
        notes.push({
          Id: note.id,
          Description: note.description,
          File: note.fileContent,
          DocumentTypeId: note.documentTypeId,
          ToBeDeleted: note.toBeDeleted,
        })
      );

    let address = {
      Id: this.state.Student.address.Id.Value,
      Street: this.state.Student.address.Street.Value,
      CountryId: this.state.Student.address.CountryId.Value,
      City: this.state.Student.address.City.Value,
      Zip: this.state.Student.address.Zip.Value,
      HomePhone: this.state.Student.address.HomePhone.Value,
      CellPhone: this.state.Student.address.CellPhone.Value,
      Email: this.state.Student.address.Email.Value,
      State: this.state.Student.address.State.Value,
    };

    let statusList = [];
    this.state.Student.statusList
      .filter((x) => x.isNew || x.toBeDeleted)
      .map((status, index) =>
        statusList.push({
          Id: status.id,
          Comment: status.comment,
          StartDate: status.startDate !== undefined ? status.startDate : null,
          EndDate: status.endDate,
          StatusType: { Id: status.statusTypeId },
          File: status.fileContent,
          ToBeDeleted: status.toBeDeleted,
        })
      );

    let student = {
      Id: this.state.Student.Id.Value,
      Name: this.state.Student.Name.Value,
      NickName: this.state.Student.NickName.Value,
      I20: this.state.Student.I20.Value,
      I20StartDate: this.state.Student.I20StartDate.Value,
      I20EndDate: this.state.Student.I20EndDate.Value,
      EnrollmentDate: this.state.Student.EnrollmentDate.Value,
      PassportExpDate: this.state.Student.PassportExpDate.Value,
      BirthDate: this.state.Student.BirthDate.Value,
      Gender: this.state.Student.Gender.Value,
      VisaType: { Id: this.state.Student.VisaTypeId.Value },
      Sevis: { Id: this.state.Student.SevisId.Value },
      StudentOrigin: { Id: this.state.Student.StudentOriginId.Value },
      NationalityId: this.state.Student.NationalityId.Value,
      StudentClasses: studentClasses,
      Address: address,
      PictureFileContent: this.state.Student.pictureFileContent,
      Notes: notes,
      StatusList: statusList,
      Blocked: this.state.Student.Blocked,
      CanScheduleAnyTest: this.state.Student.CanScheduleAnyTest,
    };

    if (this.state.modalAuthenticateNote.length > 0) {
      notes.push({
        Description: this.state.modalAuthenticateNote,
      });
    }

    await this.fillStudentStatusFiles(student).then(() => {
      this.fillStudentNotesFiles(student).then(() => {
        this.fillStudentPictureBase64File(student).then(() => {
          axios.put("students", student).then((response) => {
            if (
              response.data.success === true &&
              response.data.needAuthorization === true
            ) {
              let message = response.data.messages
                .map((message) => message)
                .join("\n");

              this.setModalAuthenticateMessageAndOpen(message);
            } else {
              this.props.toggleStudentModal().then(() => {
                this.setToken("").then(() => {
                  this.resetModal().then(() => {
                    if (
                      this.props.callback &&
                      typeof this.props.callback === "function"
                    ) {
                      this.props.callback();
                    }
                  });
                });
              });
            }
          });
        });
      });
    });
  }

  DownloadStatusFile(id) {
    let status = this.state.Student.statusList.find((x) => x.id === id);

    if (status.isNew) {
      const url = window.URL.createObjectURL(
        new Blob([status.fileContent], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.click();
    } else {
      axios
        .get("documents/getFile?id=" + status.documentId, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.click();
        });
    }
  }

  DownloadNoteFile(id) {
    let note = this.state.Student.notes.find((x) => x.id === id);

    if (note.isNew) {
      const url = window.URL.createObjectURL(
        new Blob([note.fileContent], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.click();
    } else {
      axios
        .get("documents/getFile?id=" + note.documentId, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.click();
        });
    }
  }

  async SendDocumentByEmail(id) {
    let result = await confirm({
      title: <>Warning</>,
      message:
        "The document will be sent to the email: " +
        this.state.Student.address.Email.Value +
        ". Do you want to proceed?",
    });

    if (result) {
      return new Promise((resolve, reject) => {
        axios.get("documents/sendbyemail?id=" + id);
      });
    }
  }

  openModalStudent() {
    let modalState = this.state;

    this.refreshCourses().then(() => {
      this.refreshStatusTypes().then(() => {
        this.refreshDocumentTypes().then(() => {
          this.refreshBooks().then(() => {
            this.refreshVisaTypes().then(() => {
              this.refreshCampuses().then(() => {
                this.refreshStudentOrigins().then(() => {
                  this.refreshSemesters().then(() => {
                    if (this.props.editMode === editMode.INSERT) {
                      this.setState(modalState);
                    } else {
                      if (this.props.editMode === editMode.EDIT) {
                        axios
                          .get("students/getbasicdata?id=" + this.props.id)
                          .then((response) => {
                            modalState.Student.Id.Value = response.data.data.id;

                            modalState.Student.Name.Value =
                              response.data.data.name;
                            modalState.Student.Name.IsValid = true;

                            modalState.Student.NickName.Value =
                              response.data.data.nickName;
                            modalState.Student.NickName.IsValid = true;

                            modalState.Student.I20.Value =
                              response.data.data.i20;
                            let validI20 = response.data.data.i20.match(
                              /^[T|R|N|J][0-9]{10}$/i
                            )
                              ? true
                              : false;
                            modalState.Student.I20.IsValid = validI20;
                            modalState.Student.I20.ErrorMsg = validI20
                              ? ""
                              : "Pattern for I-20: J or N or R or T + 10 numbers";

                            modalState.Student.I20StartDate.Value = FormatDate(
                              response.data.data.i20StartDate
                            );
                            modalState.Student.I20StartDate.IsValid = true;

                            modalState.Student.I20EndDate.Value = FormatDate(
                              response.data.data.i20EndDate
                            );
                            modalState.Student.I20EndDate.IsValid = true;

                            modalState.Student.BirthDate.Value = FormatDate(
                              response.data.data.birthDate
                            );
                            modalState.Student.BirthDate.IsValid = true;

                            modalState.Student.Gender.Value =
                              response.data.data.gender;
                            modalState.Student.Gender.IsValid = true;

                            if (response.data.data.pictureUrl) {
                              modalState.Student.pictureUrl.Value =
                                response.data.data.pictureUrl;
                              modalState.Student.pictureUrl.IsValid = true;
                            }

                            modalState.Student.VisaTypeId.Value =
                              response.data.data.visaType.id;
                            modalState.Student.VisaTypeId.IsValid = true;

                            if (response.data.data.sevis) {
                              modalState.Student.SevisId.Value =
                                response.data.data.sevis.id;
                              modalState.Student.SevisId.IsValid = true;
                            } else modalState.Student.SevisId.IsValid = false;

                            modalState.Student.StudentOriginId.Value =
                              response.data.data.studentOrigin.id;
                            modalState.Student.StudentOriginId.IsValid = true;

                            if (response.data.data.nationalityId) {
                              modalState.Student.NationalityId.Value =
                                response.data.data.nationalityId;
                              modalState.Student.NationalityId.IsValid = true;
                            }

                            modalState.value =
                              modalState.Student.NationalityId.Value &&
                              Countries.find(
                                (x) =>
                                  x.value ===
                                  modalState.Student.NationalityId.Value
                              ).label;

                            modalState.Student.EnrollmentDate.Value =
                              FormatDate(response.data.data.enrollmentDate);
                            modalState.Student.EnrollmentDate.IsValid = true;

                            modalState.Student.PassportExpDate.Value =
                              FormatDate(response.data.data.passportExpDate);
                            modalState.Student.PassportExpDate.IsValid = true;

                            modalState.Student.Blocked =
                              response.data.data.blocked;

                            modalState.Student.CanScheduleAnyTest =
                              response.data.data.canScheduleAnyTest;

                            if (response.data.data.address) {
                              modalState.Student.address.Id.Value =
                                response.data.data.address.id;
                              modalState.Student.address.Id.IsValid = true;

                              modalState.Student.address.Street.Value =
                                response.data.data.address.street;
                              modalState.Student.address.Street.IsValid = true;

                              modalState.Student.address.City.Value =
                                response.data.data.address.city;
                              modalState.Student.address.City.IsValid = true;

                              modalState.Student.address.State.Value =
                                response.data.data.address.state;
                              modalState.Student.address.State.IsValid = true;

                              modalState.Student.address.Zip.Value =
                                response.data.data.address.zip;
                              modalState.Student.address.Zip.IsValid = true;

                              modalState.Student.address.CountryId.Value =
                                response.data.data.address.countryId;
                              modalState.Student.address.CountryId.IsValid = true;

                              modalState.Student.address.CountryName =
                                modalState.Student.address.CountryId.Value &&
                                Countries.find(
                                  (x) =>
                                    x.value ===
                                    modalState.Student.address.CountryId.Value
                                ).label;

                              modalState.Student.address.HomePhone.Value =
                                response.data.data.address.homePhone;
                              modalState.Student.address.HomePhone.IsValid = true;

                              modalState.Student.address.CellPhone.Value =
                                response.data.data.address.cellPhone;
                              modalState.Student.address.CellPhone.IsValid = true;

                              modalState.Student.address.Email.Value =
                                response.data.data.address.email;
                              let validEmail =
                                response.data.data.address.email.match(
                                  /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
                                )
                                  ? true
                                  : false;
                              modalState.Student.address.Email.IsValid =
                                validEmail;
                              modalState.Student.address.Email.ErrorMsg =
                                validEmail ? "" : "E-mail is invalid";

                              modalState.Student.status.Value =
                                response.data.data.status;

                              States.filter(
                                (e) =>
                                  e.value ===
                                  response.data.data.address.countryId
                              ).map((state, index) =>
                                modalState.Student.address.States.push({
                                  label: state.label,
                                  value: state.label,
                                })
                              );
                            }

                            modalState.Student.pinNumber.Value =
                              response.data.data.pinNumber;
                            modalState.Student.pinNumber.IsValid = true;

                            let studiedSemesters = [];
                            modalState.Student.classes = [];

                            response.data.data.studentClasses.map(
                              (studentClass, index) => {
                                modalState.Student.classes.push({
                                  id: studentClass.id,
                                  hasData: studentClass.hasData,
                                  value: studentClass.classe.id,
                                  label: studentClass.classe.code,
                                  campusName: studentClass.classe.campus.name,
                                  semesterName:
                                    studentClass.classe.semester.name,
                                  isCurrentSemester:
                                    studentClass.classe.semester.current,
                                  active: studentClass.active,
                                  createdDate: dateFns.parseISO(
                                    studentClass.createdDate
                                  ),

                                  updatedDate: dateFns.parseISO(
                                    studentClass.updatedDate
                                  ),

                                  maxGrade: studentClass.classe.marks,
                                  grade: studentClass.marks,

                                  courseName: studentClass.classe.course.name,
                                  startDate: dateFns.parseISO(
                                    studentClass.startDate
                                  ),
                                  newStartDate: dateFns.parseISO(
                                    studentClass.startDate
                                  ),
                                  teacherName: studentClass.classe.teacher.name,
                                  editMode: false,
                                });

                                if (
                                  !studiedSemesters.find(
                                    (x) =>
                                      x.value ===
                                      studentClass.classe.semester.id
                                  )
                                )
                                  studiedSemesters.push({
                                    value: studentClass.classe.semester.id,
                                    label: studentClass.classe.semester.name,
                                    startDate: dateFns.parseISO(
                                      studentClass.classe.semester.startDate
                                    ),
                                    endDate: dateFns.parseISO(
                                      studentClass.classe.semester.endDate
                                    ),
                                  });
                              }
                            );

                            studiedSemesters.sort(function (a, b) {
                              return (
                                new Date(b.startDate) - new Date(a.startDate)
                              );
                            });

                            modalState.Student.studiedSemesters =
                              studiedSemesters;

                            if (
                              modalState.calendarSelectedSemesterId === "" &&
                              studiedSemesters.filter(
                                (x) => x.startDate <= new Date()
                              ).length > 0
                            )
                              modalState.calendarSelectedSemesterId =
                                studiedSemesters.filter(
                                  (x) => x.startDate <= new Date()
                                )[0].value;

                            this.setState(modalState);
                            this.validateForm();
                          })
                          .then(() => {
                            this.refreshAttendance().then(() =>
                              this.refreshAdditionalInformation()
                            );
                          });
                      }
                    }
                  });
                });
              });
            });
          });
        });
      });
    });
  }

  async classEnroll(e) {
    let modalState = this.state;

    if (modalState.selectedClassId) {
      if (
        modalState.Student.classes.filter(
          (e) => e.active === true && e.toBeDeleted !== true
        ).length >= 4
      ) {
        confirm({
          title: <>Warning</>,
          message: "The student is already enrolled in four subjects.",
          cancelText: null,
        });
      } else if (
        modalState.Student.classes.filter(
          (e) =>
            e.value === modalState.selectedClassId &&
            e.active === true &&
            e.toBeDeleted !== true
        ).length > 0
      ) {
        confirm({
          title: <>Warning</>,
          message:
            "The selected subject is already associated with the student",
          cancelText: null,
        });
      } else {
        let semester = this.getSelectedSemester();
        let startSemester = dateFns.parseISO(semester.startDate);
        let endSemester = dateFns.parseISO(semester.endDate);

        let maxStartDate;
        if (
          modalState.Student.classes.filter((e) => e.hasData === true).length >
          0
        )
          maxStartDate = new Date(
            Math.max(
              ...modalState.Student.classes
                .filter((e) => e.hasData === true)
                .map((e) => new Date(e.startDate))
            )
          );

        if (
          modalState.firstDayOfClass < startSemester ||
          modalState.firstDayOfClass > endSemester
        ) {
          confirm({
            title: <>Warning</>,
            message:
              "The first selected class day ( " +
              dateFns.format(modalState.firstDayOfClass, "MM/dd/yyyy") +
              " ) is outside the selected semester day range ( " +
              semester.label +
              " - " +
              dateFns.format(startSemester, "MM/dd/yyyy") +
              " - " +
              dateFns.format(endSemester, "MM/dd/yyyy") +
              " ). Change the selected semester to proceed.",
            cancelText: null,
          });
        } else {
          let result =
            !maxStartDate ||
            modalState.firstDayOfClass > maxStartDate ||
            (await confirm({
              title: <>Warning</>,
              message:
                "The first day of class selected ( " +
                dateFns.format(modalState.firstDayOfClass, "MM/dd/yyyy") +
                " ) is less than or equal to the last start date ( " +
                dateFns.format(maxStartDate, "MM/dd/yyyy") +
                " ) of the previous subject. Do you want to proceed?",
            }));

          if (result) {
            let classeFound = modalState.classes.find(
              (x) => x.value === modalState.selectedClassId
            );

            let classe = Object.assign({}, classeFound);

            if (
              modalState.Student.classes.filter(
                (e) => e.active === true && e.toBeDeleted !== true
              ).length === 0
            ) {
              let result = await confirm({
                title: <>Warning</>,
                message:
                  "Do you want to enroll the student in all subjects related to the selected subject?",
              });

              if (result) {
                let similarClasses = modalState.classes.filter(
                  (x) => x.groupClass == classe.groupClass
                );

                similarClasses.forEach((item) => {
                  let newItem = Object.assign({}, item);

                  newItem.active = true;
                  newItem.new = true;
                  newItem.id = uuid.v4();
                  newItem.startDate = modalState.firstDayOfClass;
                  newItem.newStartDate = modalState.firstDayOfClass;
                  newItem.createdDate = new Date();
                  newItem.editMode = false;

                  modalState.Student.classes.unshift(newItem);
                });
              } else {
                classe.active = true;
                classe.new = true;
                classe.id = uuid.v4();
                classe.startDate = modalState.firstDayOfClass;
                classe.newStartDate = modalState.firstDayOfClass;
                classe.createdDate = new Date();
                classe.editMode = false;

                modalState.Student.classes.unshift(classe);
              }
            } else {
              classe.active = true;
              classe.new = true;
              classe.id = uuid.v4();
              classe.startDate = modalState.firstDayOfClass;
              classe.newStartDate = modalState.firstDayOfClass;
              classe.createdDate = new Date();
              classe.editMode = false;

              modalState.Student.classes.unshift(classe);
            }

            modalState.selectedClassId = "";

            this.setState(modalState);
          }
        }
      }
    }
  }

  getSelectedSemester = () => {
    let semesterId = this.state.selectedSemesterId;
    return this.state.semesters.find((x) => x.value === semesterId);
  };

  async saveNote(e) {
    let note = {
      id: uuid.v4(),
      description: this.state.currentNote.description,
      Student: { Id: this.props.id },
      Type: "N",
    };

    axios.post("students/savenote", note).then((response) => {
      let modalState = this.state;
      note.createdDate = new Date();
      note.userName = this.state.currentUser.name;
      modalState.Student.notes.unshift(note);
      modalState.currentNote = this.getNoteEntity();
      this.setState(modalState);
    });
  }

  async fillNoteDocument(note) {
    let documentType = this.state.documentTypes.find(
      (x) => x.value === this.state.currentDocumentNote.selectedDocumentTypeId
    );

    let base64File = await this.getBase64(
      this.state.currentDocumentNote.fileContent
    );

    let documentId = uuid.v4();

    note.Document = {
      Id: documentId,
      FileContentBase64: base64File,
      FileName: this.state.currentDocumentNote.fileName,
      DocumentType: { Id: documentType.value, Name: documentType.label },
    };
  }

  async saveDocumentNote(e) {
    let note = {
      id: uuid.v4(),
      description: this.state.currentDocumentNote.description,
      Student: { Id: this.props.id },
      Type: "N",
    };

    await this.fillNoteDocument(note).then(() => {
      axios.post("students/savenote", note).then((response) => {
        let modalState = this.state;

        note.createdDate = new Date();
        note.userName = this.state.currentUser.name;

        note.documentTypeId = note.Document.DocumentType.Id;
        note.documentTypeName = note.Document.DocumentType.Name;
        note.documentId = note.Document.Id;
        note.fileName = note.Document.FileName;

        modalState.Student.notes.unshift(note);
        modalState.currentDocumentNote = this.getDocumentNoteEntity();
        this.setState(modalState);
      });
    });
  }

  async addBookPurchase(e) {
    let result = await confirm({
      title: <>Warning</>,
      message:
        "Would you like to save the operation using voucher " +
        this.state.currentBookPurchase.ticketNumber +
        "?",
    });

    if (result) {
      let book = this.state.books.find(
        (x) => x.value === this.state.currentBookPurchase.selectedBookId
      );

      let bookPurchase = {
        id: uuid.v4(),
        Student: { Id: this.props.id },
        Book: { Id: this.state.currentBookPurchase.selectedBookId },
        TicketNumber: this.state.currentBookPurchase.ticketNumber,
      };

      await axios
        .post("students/addbookpurchase", bookPurchase)
        .then((response) => {
          let modalState = this.state;

          bookPurchase.createdDate = new Date();
          bookPurchase.createdByUserName = this.state.currentUser.name.slice(
            0,
            this.state.currentUser.name.lastIndexOf("@")
          );
          bookPurchase.status = "P";
          bookPurchase.bookName = book.label;
          bookPurchase.ticketNumber =
            this.state.currentBookPurchase.ticketNumber;
          bookPurchase.semesterName =
            this.props.tables.selfEnrollmentSemester.name;
          bookPurchase.statusName = "Pending";
          bookPurchase.selectedBookId = book.value;
          bookPurchase.newSelectedBookId = book.value;
          bookPurchase.editMode = false;

          modalState.Student.bookPurchases.unshift(bookPurchase);
          modalState.currentBookPurchase = this.getBookPurchaseEntity();

          this.setState(modalState);
        });
    }
  }

  async cancelBookTicket(id) {
    let modalState = this.state;

    const index = modalState.Student.bookPurchases.findIndex(
      (x) => x.id === id
    );
    const bookPurchases = [...modalState.Student.bookPurchases];
    const bookPurchase = bookPurchases[index];

    let result = await confirm({
      title: <>Warning</>,
      message:
        "Would you like to cancel voucher " + bookPurchase.ticketNumber + "?",
    });

    if (result) {
      bookPurchase.status = "C";
      bookPurchase.statusName = "Canceled";
      bookPurchase.canceledDate = new Date();
      bookPurchase.canceledByUserName = this.state.currentUser.name.slice(
        0,
        this.state.currentUser.name.lastIndexOf("@")
      );

      this.setState(modalState, () => {
        let bookPurchaseObj = {
          Id: id,
        };

        axios.post("students/cancelbookpurchase", bookPurchaseObj);
      });
    }
  }

  async deliverBook(id) {
    let modalState = this.state;

    const index = modalState.Student.bookPurchases.findIndex(
      (x) => x.id === id
    );
    const bookPurchases = [...modalState.Student.bookPurchases];
    const bookPurchase = bookPurchases[index];

    let result = await confirm({
      title: <>Warning</>,
      message:
        "Would you like to save the deliver using voucher " +
        bookPurchase.ticketNumber +
        "?",
    });

    if (result) {
      bookPurchase.status = "D";
      bookPurchase.statusName = "Delivered";
      bookPurchase.deliveredDate = new Date();
      bookPurchase.deliveredByUserName = this.state.currentUser.name.slice(
        0,
        this.state.currentUser.name.lastIndexOf("@")
      );

      this.setState(modalState, () => {
        let bookPurchaseObj = {
          Id: id,
        };

        axios.post("students/deliverBook", bookPurchaseObj);
      });
    }
  }

  async classUnenroll(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Do you want to unenroll the student from this subject?",
    });

    if (result) {
      let modalState = this.state;

      modalState.Student.classes.find(
        (classe) => classe.id === id
      ).active = false;

      this.setState(modalState);
    }
  }

  async editStartDate(id) {
    let modalState = this.state;

    modalState.Student.classes.find(
      (classe) => classe.id === id
    ).editMode = true;

    this.setState(modalState);
  }

  async cancelEditStartDate(id) {
    let modalState = this.state;

    let oldStartDate = modalState.Student.classes.find(
      (classe) => classe.id === id
    ).startDate;

    modalState.Student.classes.find((classe) => classe.id === id).newStartDate =
      oldStartDate;

    modalState.Student.classes.find(
      (classe) => classe.id === id
    ).editMode = false;

    this.setState(modalState);
  }

  async changeStartDate(id, date) {
    let modalState = this.state;

    modalState.Student.classes.find((classe) => classe.id === id).newStartDate =
      date;

    this.setState(modalState);
  }

  saveStartDate = (id) => {
    let modalState = this.state;

    let classe = modalState.Student.classes.find((classe) => classe.id === id);

    let newStartDate = classe.newStartDate;
    if (newStartDate === null || newStartDate === undefined)
      newStartDate = classe.startDate;

    modalState.Student.classes.find((classe) => classe.id === id).startDate =
      newStartDate;

    modalState.Student.classes.find(
      (classe) => classe.id === id
    ).editMode = false;

    if (classe.new) {
      this.setState(modalState);
    } else {
      this.setState(modalState, () => {
        let studentClass = {
          Id: id,
          StartDate: newStartDate,
        };

        axios.put("students/UpdateStartDate", studentClass);
      });
    }
  };

  gradeSave = (id) => {
    let modalState = this.state;

    const index = modalState.assignments.findIndex(
      (x) => x.assignmentStudentId === id
    );
    const assignments = [...modalState.assignments];
    const assignment = assignments[index];
    assignment.editMode = false;

    this.setState(modalState, () => {
      let assignmentStudent = {
        Id: id,
        Grade: assignment.grade,
      };

      axios.put("assignments/UpdateAssignmentStudent", assignmentStudent);
    });
  };

  async classDelete(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Do you want to delete this subject?",
    });

    if (result) {
      let modalState = this.state;

      let studentClass = modalState.Student.classes.find((x) => x.id === id);

      if (studentClass != undefined) {
        if (studentClass.new) {
          const newClasses = modalState.Student.classes.filter(
            (classe) => classe.id !== id
          );

          modalState.Student.classes = newClasses;
        } else {
          studentClass.toBeDeleted = true;
        }
      }

      this.setState(modalState);
    }
  }

  async deleteStatus(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Do you want to delete this status?",
    });

    if (result) {
      let modalState = this.state;

      let status = modalState.Student.statusList.find((x) => x.id === id);

      if (status !== undefined) {
        if (status.isNew) {
          const newStatusList = modalState.Student.statusList.filter(
            (status) => status.id !== id
          );

          modalState.Student.statusList = newStatusList;
        } else {
          status.toBeDeleted = true;
        }
      }

      this.setState(modalState);
    }
  }

  async deleteNote(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Do you want to delete this note?",
    });

    if (result) {
      let modalState = this.state;

      let note = modalState.Student.notes.find((x) => x.id === id);

      if (note !== undefined) {
        if (note.isNew) {
          const newNotes = modalState.Student.notes.filter(
            (note) => note.id !== id
          );

          modalState.Student.notes = newNotes;
        } else {
          note.toBeDeleted = true;
        }
      }

      this.setState(modalState);
    }
  }

  async addStatus(e) {
    let modalState = this.state;

    let result = await confirm({
      title: <>Warning</>,
      message: "Do you want to change the student's status?",
    });

    if (result) {
      let statusType = modalState.statusTypes.find(
        (x) => x.value === modalState.status.selectedStatusTypeId
      );

      let activeStudentClasses = modalState.Student.classes.filter(
        (x) => x.active
      );

      if (activeStudentClasses.length > 0 && !statusType.allowClassEnroll) {
        confirm({
          title: <>Warning</>,
          message:
            "The student is enrolled in " +
            activeStudentClasses.length +
            " subjects. In order for the status to be changed to '" +
            statusType.label +
            "', it is necessary to unenroll the student from all subjects.",
          cancelText: null,
        });
      } else {
        let status = {
          id: uuid.v4(),
          createdDate: new Date(),
          statusTypeId: statusType.value,
          statusTypeName: statusType.label,
          startDate: modalState.status.startDate,
          endDate: modalState.status.endDate,
          comment: modalState.status.comment,
          isNew: true,
          fileContent: modalState.status.fileContent,
          fileName: modalState.status.fileName,
        };

        modalState.Student.statusList.unshift(status);

        modalState.status = this.getStatusEntity();

        this.setState(modalState);
      }
    }
  }

  async cancelStatus(e) {
    let modalState = this.state;

    modalState.status = this.getStatusEntity();

    this.setState(modalState);
  }

  async cancelNote(e) {
    let modalState = this.state;

    modalState.currentNote = this.getNoteEntity();

    this.setState(modalState);
  }

  async cancelDocumentNote(e) {
    let modalState = this.state;

    modalState.currentDocumentNote = this.getDocumentNoteEntity();

    this.setState(modalState);
  }

  async cancelBookPurchaseInsert(e) {
    let modalState = this.state;

    modalState.currentBookPurchase = this.getBookPurchaseEntity();

    this.setState(modalState);
  }

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.courses.map((classe, index) =>
        modalState.courses.push({
          value: classe.id,
          label: classe.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshStatusTypes = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.statusTypes
        .filter((x) => x.enrolled === false && x.unenrolled === false)
        .map((statusType, index) =>
          modalState.statusTypes.push({
            value: statusType.id,
            label: statusType.name,
            allowClassEnroll: statusType.allowClassEnroll,
            hasDate: statusType.hasDate,
          })
        );

      this.setState(modalState, resolve(true));
    });
  };

  refreshDocumentTypes = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.documentTypes.map((documentType, index) =>
        modalState.documentTypes.push({
          value: documentType.id,
          label: documentType.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshBooks = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.books.map((book, index) =>
        modalState.books.push({
          value: book.id,
          label: book.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshVisaTypes = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.visaTypes.map((visaType, index) =>
        modalState.visaTypes.push({
          value: visaType.id,
          label: visaType.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshCampuses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.campuses
        .filter(
          (x) => x.name == "Salt Lake in Person" || x.name == "Orem In Person"
        )
        .map((campus, index) =>
          modalState.campuses.push({
            value: campus.id,
            label: campus.name
              .replace("Salt Lake in Person", "SLC")
              .replace("Orem In Person", "OREM"),
          })
        );

      this.setState(modalState, resolve(true));
    });
  };

  refreshStudentOrigins = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.studentOrigins.map((studentOrigin, index) =>
        modalState.studentOrigins.push({
          value: studentOrigin.id,
          label: studentOrigin.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshSemesters = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.semesters.map((semester, index) =>
        modalState.semesters.push({
          value: semester.id,
          label: semester.name,
          startDate: semester.startDate,
          endDate: semester.endDate,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshClasses = () => {
    return new Promise((resolve, reject) => {
      let courseId = this.state.selectedCourseId;
      let periodId = this.state.selectedPeriod;
      let semesterId = this.state.selectedSemesterId;

      axios
        .get(
          "classes?courseId=" +
            courseId +
            "&period=" +
            periodId +
            "&semesterId=" +
            semesterId +
            "&page=1&pageSize=999999"
        )
        .then((response) => {
          let modalState = this.state;

          modalState.classes = [];
          response.data.data.items.map((classe, index) =>
            modalState.classes.push({
              value: classe.id,
              label: classe.code,
              campusName: classe.campus.name,
              semesterName: classe.semester.name,
              courseName: classe.course.name,
              isCurrentSemester: classe.semester.current,
              semesterStartDate: classe.semester.startDate,
              period: classe.period,
              groupClass: classe.groupClass.name,
            })
          );

          this.setState(modalState, resolve(true));
        });
    });
  };

  refreshAttendance = async () => {
    let page = this.state.Student.currentAttendancePage;

    await axios
      .get(
        "attendances/GetByStudentBasic?" +
          "studentId=" +
          this.props.id +
          "&semesterId=" +
          this.state.calendarSelectedSemesterId +
          "&page=" +
          page +
          "&pageSize=1000"
      )
      .then((response) => {
        let attendanceList = [];

        response.data.data.items.map((attendance, index) =>
          attendanceList.push({
            id: attendance.id,
            className: attendance.classe.code,
            campusName: attendance.classe.campus.name,
            date: FormatFullDateList(attendance.date),
            status: attendance.attendanceStudentViewModels[0].status,
            comment: attendance.attendanceStudentViewModels[0].comment,
            classBlock: attendance.classe.block,
            recordedBy:
              attendance.user.name &&
              attendance.user.name.slice(
                0,
                attendance.user.name.lastIndexOf("@")
              ),
            createdDate: FormatFullDateList(attendance.createdDate),
            attendanceStudentId: attendance.attendanceStudentViewModels[0].id,
            dateISO: attendance.date,
            Start: attendance.classe.subjectTime.start,
            subjectTimeEnd: attendance.classe.subjectTime.end,
          })
        );

        return new Promise((resolve, reject) => {
          this.setState((prevState) => {
            let state = Object.assign({}, prevState);

            state.Student.totalAttendance = response.data.data.totalCount;
            state.Student.attendance = attendanceList;

            return { state };
          }, resolve(true));
        });
      })
      .then(() => {
        this.setState({ modalAttendanceHasChanged: new Date() });
      });
  };

  refreshAdditionalInformation = async () => {
    await axios
      .get("students/getadditionalbasicdata?id=" + this.props.id)
      .then((response) => {
        let notes = [];

        response.data.data.notes.map((note, index) =>
          notes.push({
            id: note.id,
            documentTypeId:
              note.document && note.document.documentType
                ? note.document.documentType.id
                : "",
            documentTypeName:
              note.document && note.document.documentType
                ? note.document.documentType.name
                : "",
            description: note.description,
            createdDate: dateFns.parseISO(note.createdDate),
            userName:
              note.user.name &&
              note.user.name.slice(0, note.user.name.lastIndexOf("@")),
            documentId: note.document && note.document.id,
            fileName: note.document && note.document.fileName,
            type: note.type,
            typeName: note.type
              ? note.type === "N"
                ? "Note"
                : note.type === "SE"
                ? "Self-enrollment"
                : note.type === "AP"
                ? "Application"
                : ""
              : "",
          })
        );

        let bookPurchases = [];

        response.data.data.bookPurchaseList.map((bp, index) =>
          bookPurchases.push({
            id: bp.id,
            selectedBookId: bp.book.id,
            newSelectedBookId: bp.book.id,
            bookName: bp.book.name,
            ticketNumber: bp.ticketNumber,
            semesterName: bp.semester.name,
            editMode: false,
            status: bp.status,
            createdDate: dateFns.parseISO(bp.createdDate),
            createdByUserName:
              bp.createdByUserName &&
              bp.createdByUserName.slice(
                0,
                bp.createdByUserName.lastIndexOf("@")
              ),
            statusName: bp.status
              ? bp.status === "P"
                ? "Pending"
                : bp.status === "C"
                ? "Canceled"
                : bp.status === "D"
                ? "Delivered"
                : ""
              : "",
            deliveredDate:
              bp.bookDelivery && dateFns.parseISO(bp.bookDelivery.createdDate),
            canceledDate: bp.canceledDate && dateFns.parseISO(bp.canceledDate),
            deliveredByUserName:
              bp.deliveredByUserName &&
              bp.deliveredByUserName.slice(
                0,
                bp.deliveredByUserName.lastIndexOf("@")
              ),
            canceledByUserName:
              bp.canceledByUserName &&
              bp.canceledByUserName.slice(
                0,
                bp.canceledByUserName.lastIndexOf("@")
              ),
          })
        );

        let statusList = [];

        response.data.data.statusList.map((status, index) =>
          statusList.push({
            id: status.id,
            statusTypeId: status.statusType.id,
            statusTypeName: status.statusType.name,
            startDate: status.startDate,
            endDate: status.endDate,
            createdDate: dateFns.parseISO(status.createdDate),
            comment: status.comment,
            documentId: status.document && status.document.id,
            fileName: status.document && status.document.fileName,
            userName:
              status.user.name &&
              status.user.name.slice(0, status.user.name.lastIndexOf("@")),
          })
        );

        var lastStatus = statusList[0];
        var lastSemester = "";

        if (
          lastStatus.statusTypeName.toUpperCase() == "BREAK" &&
          lastStatus.startDate &&
          dateFns.isValid(dateFns.parseISO(lastStatus.startDate))
        ) {
          let lastSemesterFiltered = this.props.tables.semesters.filter(
            (x) =>
              dateFns.parseISO(lastStatus.startDate) >=
                dateFns.parseISO(x.startDate) &&
              dateFns.parseISO(lastStatus.endDate) <=
                dateFns.parseISO(x.endDate)
          )[0];

          if (lastSemesterFiltered) lastSemester = lastSemesterFiltered.name;
        } else {
          var lastSemesterFiltered = this.state.Student.studiedSemesters[0];

          if (lastSemesterFiltered) lastSemester = lastSemesterFiltered.label;
        }

        if (
          statusList.length > 0 &&
          statusList[0].statusTypeName.toUpperCase() !== "ENROLLED" &&
          statusList[0].statusTypeName.toUpperCase() !== "UNENROLLED"
        )
          statusList[0].canBeDeleted = true;

        let messageList = [];

        response.data.data.messageList.map((message, index) =>
          messageList.push({
            id: message.id,
            title: message.title,
            description: message.description,
            createdDate: dateFns.parseISO(message.createdDate),
            userName:
              message.user.name &&
              message.user.name.slice(0, message.user.name.lastIndexOf("@")),
          })
        );

        let selfEnroll = response.data.data.selfEnroll;

        let blockHistoryList = [];

        response.data.data.blockHistoryList.map((bh, index) =>
          blockHistoryList.push({
            id: bh.id,
            blocked: bh.blocked,
            createdDate: dateFns.parseISO(bh.createdDate),
            userName:
              bh.user.name &&
              bh.user.name.slice(0, bh.user.name.lastIndexOf("@")),
          })
        );

        let invoiceDetailList = [];

        response.data.data.invoiceDetailList.map((id, index) =>
          invoiceDetailList.push({
            id: id.id,
            balance: id.balance,
            dueDate: dateFns.parseISO(id.dueDate),
            docNumber: id.docNumber,
            invoiceId: id.invoiceId,
          })
        );

        this.setState((prevState) => {
          let state = Object.assign({}, prevState);

          state.Student.notes = notes;
          state.Student.statusList = statusList;
          state.Student.messageList = messageList;
          state.Student.SelfEnroll = selfEnroll;
          state.Student.blockHistory = blockHistoryList;
          state.Student.invoiceDetailList = invoiceDetailList;
          state.Student.LastSemester = lastSemester;
          state.Student.bookPurchases = bookPurchases;

          return { state };
        });
      });
  };

  refreshStudentClasses = () => {
    return new Promise((resolve, reject) => {
      axios
        .get("students/GetStudentClasses?id=" + this.props.id)
        .then((response) => {
          let modalState = this.state;

          let studiedSemesters = [];
          modalState.Student.classes = [];

          response.data.data.map((studentClass, index) => {
            modalState.Student.classes.push({
              id: studentClass.id,
              hasData: studentClass.hasData,
              value: studentClass.classe.id,
              label: studentClass.classe.code,
              campusName: studentClass.classe.campus.name,
              semesterName: studentClass.classe.semester.name,
              isCurrentSemester: studentClass.classe.semester.current,
              active: studentClass.active,
              createdDate: dateFns.parseISO(studentClass.createdDate),

              updatedDate: dateFns.parseISO(studentClass.updatedDate),

              maxGrade: studentClass.classe.marks,
              grade: studentClass.marks,

              courseName: studentClass.classe.course.name,
              startDate: dateFns.parseISO(studentClass.startDate),
              newStartDate: dateFns.parseISO(studentClass.startDate),
              teacherName: studentClass.classe.teacher.name,
              editMode: false,
            });

            if (
              !studiedSemesters.find(
                (x) => x.value === studentClass.classe.semester.id
              )
            )
              studiedSemesters.push({
                value: studentClass.classe.semester.id,
                label: studentClass.classe.semester.name,
                startDate: dateFns.parseISO(
                  studentClass.classe.semester.startDate
                ),
                endDate: dateFns.parseISO(studentClass.classe.semester.endDate),
              });
          });

          studiedSemesters.sort(function (a, b) {
            return new Date(b.startDate) - new Date(a.startDate);
          });

          modalState.Student.studiedSemesters = studiedSemesters;

          if (
            modalState.calendarSelectedSemesterId === "" &&
            studiedSemesters.filter((x) => x.startDate <= new Date()).length > 0
          )
            modalState.calendarSelectedSemesterId = studiedSemesters.filter(
              (x) => x.startDate <= new Date()
            )[0].value;

          this.setState(modalState, resolve(true));
        });
    });
  };

  options = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
  ];

  toggleTabs(tab) {
    let modalState = this.state;

    if (modalState.activeTab !== tab) modalState.activeTab = tab;

    this.setState(modalState);
  }

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  sumAttendance(status) {
    return this.state.Student.attendance.filter((x) => x.status === status)
      .length;
  }

  getCourseName() {
    let firstClass = this.state.Student.classes[0];
    return firstClass ? firstClass.courseName : "";
  }

  getSelectedCalendarSemesterName() {
    if (
      this.state.Student.studiedSemesters.find(
        (x) => x.value === this.state.calendarSelectedSemesterId
      )
    )
      return this.state.Student.studiedSemesters.find(
        (x) => x.value === this.state.calendarSelectedSemesterId
      ).label;

    return "";
  }

  handleClassChange = (selectedOption) => {
    let modalState = this.state;

    modalState.selectedClassId = selectedOption.value;

    if (modalState.firstDayOfClass === null)
      modalState.tooltipFirstDayOfClassIsOpen = true;

    this.setState(modalState);
  };

  handlePeriodChange = (selectedOption) => {
    let modalState = this.state;

    modalState.selectedPeriod = selectedOption.value;
    modalState.selectedClassId = "";

    this.setState(modalState, () => {
      this.refreshClasses();
    });
  };

  handleCourseChange = (selectedOption) => {
    let modalState = this.state;

    modalState.selectedCourseId = selectedOption.value;
    modalState.selectedClassId = "";

    this.setState(modalState, () => {
      this.refreshClasses();
    });
  };

  handleSemesterEnrollChange = (selectedOption) => {
    let modalState = this.state;

    modalState.selectedSemesterId = selectedOption.value;
    modalState.selectedClassId = "";

    this.setState(modalState, () => {
      this.refreshClasses();
    });
  };

  handleCalendarSemesterChange = (selectedOption) => {
    let modalState = this.state;
    modalState.calendarSelectedSemesterId = selectedOption.value;
    this.setState(modalState, () => {
      this.refreshAttendance();
    });
  };

  handleStatusTypeChange = (selectedOption) => {
    let statusType = this.state.statusTypes.find(
      (x) => x.value === selectedOption.value
    );

    this.setState((prevState) => {
      let status = Object.assign({}, prevState.status);

      status.selectedStatusTypeId = selectedOption.value;
      status.hasDate = statusType.hasDate;
      status.allowClassEnroll = statusType.allowClassEnroll;

      return { status };
    });
  };

  handleDocumentTypeChange = (selectedOption) => {
    this.setState((prevState) => {
      let currentDocumentNote = Object.assign(
        {},
        prevState.currentDocumentNote
      );
      currentDocumentNote.selectedDocumentTypeId = selectedOption.value;
      return { currentDocumentNote };
    });
  };

  handleBookChange = (selectedOption) => {
    this.setState((prevState) => {
      let currentBookPurchase = Object.assign(
        {},
        prevState.currentBookPurchase
      );
      currentBookPurchase.selectedBookId = selectedOption.value;
      return { currentBookPurchase };
    });
  };

  async editBookDeliver(id) {
    let modalState = this.state;

    modalState.Student.bookPurchases.find((bp) => bp.id === id).editMode = true;

    this.setState(modalState);
  }

  async handleBookDeliverChange(id, selectedOption) {
    let modalState = this.state;
    modalState.Student.bookPurchases.find(
      (bp) => bp.id === id
    ).newSelectedBookId = selectedOption.value;
    this.setState(modalState);
  }

  async cancelBookDeliverChange(id) {
    let modalState = this.state;

    let oldSelectedBookId = modalState.Student.bookPurchases.find(
      (bp) => bp.id === id
    ).selectedBookId;

    modalState.Student.bookPurchases.find(
      (bp) => bp.id === id
    ).newSelectedBookId = oldSelectedBookId;

    modalState.Student.bookPurchases.find(
      (bp) => bp.id === id
    ).editMode = false;

    this.setState(modalState);
  }

  async saveBookDeliverChange(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Would you like to save the book exchange?",
    });

    if (result) {
      let modalState = this.state;
      let bp = modalState.Student.bookPurchases.find((bp) => bp.id === id);
      let oldBookId = bp.selectedBookId;
      let newBookId = bp.newSelectedBookId;

      if (oldBookId !== newBookId) {
        let bookPurchase = {
          Id: id,
          Book: { Id: bp.newSelectedBookId },
        };

        axios.post("students/UpdateBookPurchaseBook", bookPurchase).then(() => {
          modalState.Student.bookPurchases.find(
            (bp) => bp.id === id
          ).selectedBookId = bp.newSelectedBookId;

          modalState.Student.bookPurchases.find(
            (bp) => bp.id === id
          ).editMode = false;

          this.setState(modalState, () => {
            this.refreshAdditionalInformation();
          });
        });
      } else {
        modalState.Student.bookPurchases.find(
          (bp) => bp.id === id
        ).editMode = false;

        this.setState(modalState);
      }
    }
  }

  handleSemesterChange = (selectedOption) => {
    let semester = this.state.semesters.find(
      (x) => x.value === selectedOption.value
    );

    this.setState((prevState) => {
      let status = Object.assign({}, prevState.status);
      status.selectedSemesterId = selectedOption.value;
      status.startDate = FormatDate(semester.startDate);
      status.endDate = FormatDate(semester.endDate);

      return { status };
    });
  };

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp("^" + escapedValue, "i");

    return Countries.filter((language) => regex.test(language.label));
  }

  getSuggestionValue(suggestion) {
    return suggestion.label;
  }

  renderSuggestion(suggestion) {
    let className = "";
    if (suggestion.value)
      className = "flag flag-" + suggestion.value.toString().toLowerCase();

    return (
      <div>
        <div className={className}></div> <span>{suggestion.label}</span>
      </div>
    );
  }

  renderBookPurchaseButton = (status, statusName) => {
    let color =
      status === "P" ? "#F9A02B" : status === "D" ? "#6bd098" : "#ef8157";

    return (
      <Badge style={{ fontSize: "small", backgroundColor: color }}>
        {statusName}
      </Badge>
    );
  };

  onCountryChange = (event, newValue) => {
    let modalState = this.state;

    modalState.Student.address.CountryId.Value = "";
    modalState.Student.address.State.Value = "";
    modalState.Student.address.States = [];
    modalState.Student.address.CountryName = newValue;

    this.setState(modalState);
  };

  onChange = (event, { newValue }) => {
    let modalState = this.state;

    modalState.value = newValue;
    modalState.Student.NationalityId.Value = "";

    this.setState(modalState);
  };

  onCountrySuggestionSelected = (event, { suggestion }) => {
    let modalState = this.state;

    modalState.Student.address.CountryId.Value = suggestion.value;

    modalState.Student.address.States = [];
    States.filter((e) => e.value === suggestion.value).map((state, index) =>
      modalState.Student.address.States.push({
        label: state.label,
        value: state.label,
      })
    );

    this.setState(modalState, () => {
      this.validateField("CountryId", suggestion.value);
    });
  };

  handleStateChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Student.address.State.Value = selectedOption.label;

    this.setState(modalState, () => {
      this.validateField("State", selectedOption.value);
    });
  };

  periodOptions = [
    { value: "M", label: "Morning" },
    { value: "E", label: "Evening" },
    { value: "MT", label: "Mon/Tue" },
    { value: "WT", label: "Wed/Thu" },
  ];

  setModalAttendanceProperties = (status, date, comment, subject, id) => {
    this.setState(
      {
        modalAttendanceStatus: status,
        modalAttendanceDate: date,
        modalAttendanceComment: comment,
        modalAttendanceSubject: subject,
        modalAttendanceId: id,
      },
      () => this.toggleModalAttendance()
    );
  };

  toggleModalAttendance = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { modalAttendanceIsOpen: !this.state.modalAttendanceIsOpen },
        resolve(true)
      );
    });
  };

  downloadPdfSubject(classId, className, semesterName, grade, maxGrade) {
    axios
      .get(
        "assignments/byStudentClass?studentId=" +
          this.props.id +
          "&classId=" +
          classId +
          "&page=1" +
          "&pageSize=99999"
      )
      .then((response) => {
        let assignments = [];
        response.data.data.items.map((assignment, index) =>
          assignments.push({
            date: FormatFullDateList(assignment.date),
            assignmentTypeName: assignment.assignmentType.name,
            grade: assignment.assignmentStudentViewModels[0].grade,
            name: assignment.name,
            maxGrade: assignment.maxGrade,
          })
        );

        assignments.push({
          date: "",
          assignmentTypeName: "",
          grade: grade,
          name: "Total:",
          maxGrade: maxGrade,
        });

        SavePdf(
          "Assignment - " +
            this.state.Student.Name.Value +
            " - " +
            className +
            " - " +
            semesterName,
          assignments,
          [
            {
              date: "Date",
              assignmentTypeName: "Category",
              name: "Name",
              grade: "Grade",
              maxGrade: "Max Grade",
            },
          ]
        );
      });
  }

  renderStatusButton = (selfEnroll) => {
    let color = "";

    switch (selfEnroll.selfEnrollLogList[0].status.order) {
      case 0:
        color = "#f9a02b";
        break;
      case 1:
        color = "#10b8f9";
        break;
      case 2:
        color = "#7ac947";
        break;
      case 3:
        color = "#dc3545";
        break;

      default:
        break;
    }

    return (
      <React.Fragment>
        <Badge
          id="selfEnrollStatus"
          style={{ fontSize: "large", backgroundColor: color }}
        >
          {selfEnroll.selfEnrollLogList[0].status.name}
        </Badge>

        <UncontrolledTooltip placement="right" target={"selfEnrollStatus"}>
          <label>
            {`
          Date: ${dateFns.format(
            dateFns.parseISO(selfEnroll.selfEnrollLogList[0].createdDate),
            "MM/dd/yyyy"
          )}
          `}
          </label>
          <label>
            {" "}
            {`User: ${selfEnroll.selfEnrollLogList[0].user.fullName}`}
          </label>

          <label> {`Semester: ${selfEnroll.semester}`}</label>
        </UncontrolledTooltip>
      </React.Fragment>
    );
  };

  onSwitchBlocked = (e) => {
    let mainState = this.state;
    mainState.Student.Blocked = e.target.checked;
    this.setState(mainState);
  };

  onSwitchCanScheduleAnyTest = (e) => {
    let mainState = this.state;
    mainState.Student.CanScheduleAnyTest = e.target.checked;
    this.setState(mainState);
  };

  setHTMLMessageDescriptionAndToggle(title, description) {
    let modalState = this.state;

    modalState.modalHTMLMessageDescription = description.includes(
      "<!DOCTYPE html>"
    )
      ? description
      : description.split("\n").map((item, key) => {
          return (
            <span key={key}>
              {item}
              <br />
            </span>
          );
        });

    modalState.modalHTMLMessageTitle = title;
    modalState.modalHTMLMessageIsOpen = true;

    this.setState(modalState);
  }

  print = async () => {
    const input = document.getElementById("htmlContainer");

    html2canvas(input).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");

      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("file.pdf");
    });

    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, "JPEG", 0, 0);
    //   pdf.save("download.pdf");
    // });
  };
}

export default StudentEdit2;
