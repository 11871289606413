/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import axios from "axios";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };
  }

  onCloseModal = () => {
    this.resetModal();
  };

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState({ email: "" }, resolve(true));
    });
  };

  validateForm() {
    let userNameValid = this.state.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );

    return userNameValid;
  }

  handleSubmit = async () => {
    let user = {
      Name: this.state.email,
    };

    await axios.post("users/forgotpassword", user).then((response) => {
      this.props.toggle().then(() => {
        this.resetModal();
      });
    });
  };

  errorClass() {
    return this.state.email.length === 0 ||
      this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ? ""
      : "is-invalid";
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                size={"sm"}
                backdrop="static"
                unmountOnClose={true}
                onClosed={this.onCloseModal}
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  Forgot Password
                </ModalHeader>
                <ModalBody>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="assignmentName">
                          Please enter your e-mail. You will receive a new
                          password.
                        </Label>
                        <Input
                          className={`form-control ${this.errorClass()}`}
                          type="email"
                          name="email"
                          autoComplete="nope"
                          value={this.state.email}
                          onChange={this.handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    disabled={!this.validateForm()}
                    onClick={this.handleSubmit}
                  >
                    Send
                  </Button>
                  <Button color="secondary" onClick={this.props.toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ForgotPassword;
