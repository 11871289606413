/* eslint-disable no-useless-constructor */
import React, { Component } from "react";

import { FormGroup, Label, Input } from "reactstrap";

class SearchInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let placeholder = this.props.placeholder || "Type here to search";

    return (
      <FormGroup style={{ marginLeft: "5px" }}>
        <Label>&nbsp;</Label>
        <Input
          type="search"
          className={`form-group`}
          placeholder={placeholder}
          name="Name"
          id="className"
          autoComplete="nope"
          value={this.props.searchInput}
          onChange={this.props.handleSearchInput}
        />
      </FormGroup>
    );
  }
}

export default SearchInput;
