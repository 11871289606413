/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import axios from "axios";
import { FormatFullDateList } from "helpers/Utils.js";
import confirm from "reactstrap-confirm";
import Can from "components/Can/Can";

class AssignmentView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assignments: [],
      hasChanged: false,
    };
  }

  refresh() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "assignments/byStudentClass?studentId=" +
            this.props.studentId +
            "&classId=" +
            this.props.classId +
            "&page=1" +
            "&pageSize=99999"
        )
        .then((response) => {
          let modalState = this.state;

          modalState.assignments = [];
          response.data.data.items.map((assignment, index) =>
            modalState.assignments.push({
              id: assignment.id,
              date: FormatFullDateList(assignment.date),
              assignmentTypeName: assignment.assignmentType.name,
              grade: assignment.assignmentStudentViewModels[0].grade,
              name: assignment.name,
              maxGrade: assignment.maxGrade,
              editMode: false,
              assignmentStudentId: assignment.assignmentStudentViewModels[0].id,
            })
          );

          this.setState(modalState, resolve(true));
        });
    });
  }

  onOpened() {
    this.refresh();
  }

  onCloseModal = () => {
    this.resetModal();
  };

  gradeEdit = (id) => {
    let modalState = this.state;

    const index = modalState.assignments.findIndex(
      (x) => x.assignmentStudentId === id
    );
    const assignments = [...modalState.assignments];
    const assignment = assignments[index];
    assignment.editMode = true;

    modalState.hasChanged = true;

    this.setState(modalState);
  };

  gradeSave = (id) => {
    let modalState = this.state;

    const index = modalState.assignments.findIndex(
      (x) => x.assignmentStudentId === id
    );
    const assignments = [...modalState.assignments];
    const assignment = assignments[index];
    assignment.editMode = false;

    this.setState(modalState, () => {
      let assignmentStudent = {
        Id: id,
        Grade: assignment.grade,
      };

      axios.put("assignments/UpdateAssignmentStudent", assignmentStudent);
    });
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          assignments: [],
          hasChanged: false,
        },
        resolve(true)
      );
    });
  };

  handleGradeInput(id, e) {
    let modalState = this.state;

    const index = modalState.assignments.findIndex(
      (x) => x.assignmentStudentId === id
    );
    const assignments = [...modalState.assignments];
    const assignment = assignments[index];
    assignment.grade = e.target.value;

    this.setState(modalState);
  }

  handleFocus = (event) => event.target.select();

  gradeOnBlur = async (id, e) => {
    const value = e.target.value;

    if (value === "") {
      e.target.value = "0";
      this.setState({
        assignments: this.state.assignments.map((el) =>
          el.assignmentStudentId === id ? { ...el, grade: "0" } : el
        ),
      });
    } else {
      let prevAssignment = this.state.assignments.find(
        (x) => x.assignmentStudentId === id
      );

      if (prevAssignment !== undefined) {
        if (value > prevAssignment.maxGrade) {
          await confirm({
            title: <>Warning</>,
            message:
              "The grade ( " +
              value +
              " ) is greater than the maximum grade ( " +
              prevAssignment.maxGrade +
              " )",
            cancelText: null,
          });

          this.setState({
            assignments: this.state.assignments.map((el) =>
              el.assignmentStudentId === id
                ? { ...el, grade: prevAssignment.maxGrade }
                : el
            ),
          });
        }
      }
    }
  };

  closeModalAndCallback() {
    this.props.toggleAssignmentModal().then(() => {
      if (
        this.state.hasChanged === true &&
        this.props.callback &&
        typeof this.props.callback === "function"
      ) {
        this.props.callback();
      }
    });
  }

  render() {
    let assignments = this.state.assignments.map((assignment) => {
      return (
        <tr
          key={assignment.assignmentStudentId}
          style={{
            backgroundColor:
              assignment.grade < assignment.maxGrade ? "#ffe9e9" : "#f5fff5",
          }}
        >
          <td>{assignment.date}</td>
          <td>{assignment.assignmentTypeName}</td>
          <td>{assignment.name}</td>

          <td>
            <Can
              feature="Student_Edit_Assignment"
              yes={() => (
                <React.Fragment>
                  {assignment.editMode === false && (
                    <strong>{assignment.grade}</strong>
                  )}

                  {assignment.editMode === true && (
                    <Input
                      type="number"
                      style={{ width: "120px" }}
                      name="grade"
                      id="grade"
                      autoComplete="nope"
                      value={assignment.grade}
                      onChange={this.handleGradeInput.bind(
                        this,
                        assignment.assignmentStudentId
                      )}
                      onFocus={this.handleFocus}
                      onBlur={this.gradeOnBlur.bind(
                        this,
                        assignment.assignmentStudentId
                      )}
                    />
                  )}
                </React.Fragment>
              )}
              no={() => <strong>{assignment.grade}</strong>}
            ></Can>
          </td>

          <td>
            <strong>{assignment.maxGrade}</strong>
          </td>

          <td>
            <Can
              feature="Student_Edit_Assignment"
              yes={() => (
                <React.Fragment>
                  {assignment.editMode === false && (
                    <Button
                      color="info"
                      size="sm"
                      className="mr-2"
                      title="Click to edit"
                      onClick={this.gradeEdit.bind(
                        this,
                        assignment.assignmentStudentId
                      )}
                    >
                      Edit
                    </Button>
                  )}

                  {assignment.editMode === true && (
                    <Button
                      color="success"
                      size="sm"
                      className="mr-2"
                      title="Click to edit"
                      onClick={this.gradeSave.bind(
                        this,
                        assignment.assignmentStudentId
                      )}
                    >
                      Save
                    </Button>
                  )}
                </React.Fragment>
              )}
            ></Can>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggleAssignmentModal.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                unmountOnClose={true}
                onOpened={this.onOpened.bind(this)}
                onClosed={this.onCloseModal}
                scrollable
              >
                <ModalHeader
                  toggle={this.props.toggleAssignmentModal.bind(this)}
                >
                  Assignment
                </ModalHeader>
                <ModalBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Category</th>
                        <th>Name</th>
                        <th>Marks</th>
                        <th>Grade</th>

                        <Can
                          feature="Student_Edit_Assignment"
                          yes={() => <th>Actions</th>}
                        ></Can>
                      </tr>
                    </thead>

                    <tbody>{assignments}</tbody>
                  </Table>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={this.closeModalAndCallback.bind(this)}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AssignmentView;
