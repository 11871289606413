/* eslint-disable array-callback-return */
import React from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { editMode } from "helpers/Utils.js";

class MessageEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();
  }

  render() {
    let isDisabled =
      this.props.editMode === editMode.EDIT && this.props.id !== undefined;

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggleMessageModal.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                onClosed={this.onCloseModal}
                onOpened={this.onOpened.bind(this)}
              >
                <ModalHeader toggle={this.props.toggleMessageModal.bind(this)}>
                  {this.props.editMode === editMode.INSERT
                    ? "New message"
                    : "View message"}

                  {this.props.StudentName && " for " + this.props.StudentName}
                </ModalHeader>
                <ModalBody>
                  {this.props.StudentId === undefined && (
                    <Row form>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="messageCampus">Campus</Label>
                          <Select
                            className={`form-group ${this.errorClass(
                              this.state.Message.CampusId.ErrorMsg
                            )}`}
                            value={
                              this.state.Message.CampusId.Value &&
                              this.state.campuses.find(
                                (x) =>
                                  x.value === this.state.Message.CampusId.Value
                              )
                            }
                            onChange={this.handleCampusChange}
                            options={this.state.campuses}
                            isDisabled={isDisabled}
                          />
                          <span className="text-danger">
                            {this.state.Message.CampusId.ErrorMsg}
                          </span>
                        </FormGroup>
                      </Col>

                      <Col md={3}>
                        <FormGroup>
                          <Label for="messageCourse">Level</Label>
                          <Select
                            className={`form-group ${this.errorClass(
                              this.state.Message.CourseId.ErrorMsg
                            )}`}
                            value={
                              this.state.Message.CourseId.Value &&
                              this.state.courses.find(
                                (x) =>
                                  x.value === this.state.Message.CourseId.Value
                              )
                            }
                            onChange={this.handleCourseChange}
                            options={this.state.courses}
                            isDisabled={isDisabled}
                          />
                          <span className="text-danger">
                            {this.state.Message.CourseId.ErrorMsg}
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="messagePeriod">Period</Label>
                          <Select
                            className={`form-group ${this.errorClass(
                              this.state.Message.Period.ErrorMsg
                            )}`}
                            value={
                              this.state.Message.Period.Value &&
                              this.periodOptions.find(
                                (x) =>
                                  x.value === this.state.Message.Period.Value
                              )
                            }
                            onChange={this.handlePeriodChange}
                            options={this.periodOptions}
                            isDisabled={isDisabled}
                          />
                          <span className="text-danger">
                            {this.state.Message.Period.ErrorMsg}
                          </span>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for="messagePeriod">Subject</Label>
                          <Select
                            className={`form-group ${this.errorClass(
                              this.state.Message.ClassId.ErrorMsg
                            )}`}
                            value={
                              this.state.Message.ClassId.Value &&
                              this.state.classes.find(
                                (x) =>
                                  x.value === this.state.Message.ClassId.Value
                              )
                            }
                            onChange={this.handleClasseChange}
                            options={this.state.classes}
                            isDisabled={
                              (this.state.Message.CampusId.Value === "" &&
                                this.state.Message.CourseId.Value === "" &&
                                this.state.Message.Period.Value === "") ||
                              isDisabled
                            }
                          />
                          <span className="text-danger">
                            {this.state.Message.Period.ErrorMsg}
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="messagePeriod">Title</Label>
                        <Input
                          className={`form-group ${this.errorClass(
                            this.state.Message.Title.ErrorMsg
                          )}`}
                          type="text"
                          name="Title"
                          id="Title"
                          autoComplete="nope"
                          value={this.state.Message.Title.Value}
                          onChange={this.handleUserInput}
                          placeholder="Type the title here..."
                          disabled={isDisabled}
                        />
                        <span className="text-danger">
                          {this.state.Message.Title.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="messagePeriod">Message</Label>
                        <Input
                          className={`form-group ${this.errorClass(
                            this.state.Message.Description.ErrorMsg
                          )}`}
                          type="textarea"
                          name="Description"
                          id="Description"
                          autoComplete="nope"
                          value={this.state.Message.Description.Value}
                          onChange={this.handleUserInput}
                          placeholder="Type the message here..."
                          disabled={isDisabled}
                        />
                        <span className="text-danger">
                          {this.state.Message.Description.ErrorMsg}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="exampleFile">Attachment</Label>
                        <Input
                          type="file"
                          accept="application/pdf"
                          name="file"
                          id="exampleFile"
                          style={{ zIndex: "0" }}
                          onChange={this.onFileChange}
                          isDisabled={isDisabled}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  {this.props.editMode === editMode.INSERT && (
                    <Button
                      color="primary"
                      onClick={this.addMessage.bind(this)}
                      disabled={!this.state.formValid}
                    >
                      Send
                    </Button>
                  )}{" "}
                  <Button
                    color="secondary"
                    onClick={this.props.toggleMessageModal.bind(this)}
                  >
                    {this.props.editMode === editMode.INSERT
                      ? "Cancel"
                      : "Close"}
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getMessage = () => {
    const message = {
      Id: this.getEntity(),
      Title: this.getEntity(),
      Description: this.getEntity(),
      CampusId: this.getEntity(),
      CourseId: this.getEntity(),
      Period: this.getEntity(),
      ClassId: this.getEntity(),
    };

    return message;
  };

  getModalState = () => {
    return {
      Message: this.getMessage(),
      formValid: false,
      campuses: [],
      courses: [],
      classes: [],
      fileContent: null,
      fileName: "",
    };
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  onFileChange = (event) => {
    let file = event.target.files[0];

    return new Promise((resolve, reject) => {
      let modalState = this.state;

      if (file !== undefined) {
        modalState.fileContent = file;
        modalState.fileName = file.name;
      } else {
        modalState.fileContent = null;
        modalState.fileName = "";
      }

      this.setState(modalState, resolve(true));
    });
  };

  validateField(fieldName, value) {
    let modalState = this.state;

    switch (fieldName) {
      case "Title":
        modalState.Message.Title.IsValid = value.length >= 1;
        modalState.Message.Title.ErrorMsg = modalState.Message.Title.IsValid
          ? ""
          : "Title is empty";
        break;

      case "Description":
        modalState.Message.Description.IsValid = value.length >= 1;
        modalState.Message.Description.ErrorMsg = modalState.Message.Description
          .IsValid
          ? ""
          : "Description is empty";
        break;

      default:
        break;
    }

    this.setState(modalState, this.validateForm());
  }

  validateForm() {
    let modalState = this.state;

    modalState.formValid =
      modalState.Message.Title.IsValid === true &&
      modalState.Message.Description.IsValid === true;

    this.setState(modalState);
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState.Message[name].Value = value;

    this.setState(modalState, () => {
      this.validateField(name, value);
    });
  };

  onOpened() {
    this.openModalMessage();
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  }

  async fillDocumentFileContent(message) {
    if (message.File !== null) {
      let base64File = await this.getBase64(message.File);
      let fileName = message.File.name;

      message.Document = {
        FileContentBase64: base64File,
        FileName: fileName,
      };
      message.File = null;
    }
  }

  async addMessage() {
    let modalState = this.state;

    let message = {
      Title: modalState.Message.Title.Value,
      Description: modalState.Message.Description.Value,
      File: this.state.fileContent,
    };

    if (modalState.Message.Period.Value)
      message.Period = modalState.Message.Period.Value;

    if (modalState.Message.CampusId.Value)
      message.Campus = { Id: modalState.Message.CampusId.Value };

    if (modalState.Message.CourseId.Value)
      message.Course = { Id: modalState.Message.CourseId.Value };

    if (modalState.Message.ClassId.Value)
      message.Classe = { Id: modalState.Message.ClassId.Value };

    if (this.props.StudentId)
      message.StudentList = [{ Id: this.props.StudentId }];

    await this.fillDocumentFileContent(message).then(() => {
      axios.post("messages", message).then((response) => {
        this.props.toggleMessageModal().then(() => {
          this.resetModal().then(() => {
            if (
              this.props.callback &&
              typeof this.props.callback === "function"
            ) {
              this.props.callback();
            }
          });
        });
      });
    });
  }

  refreshCampuses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.campuses.map((campus, index) =>
        modalState.campuses.push({
          value: campus.id,
          label: campus.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.courses.map((course, index) =>
        modalState.courses.push({
          value: course.id,
          label: course.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  openModalMessage() {
    let modalState = this.state;

    this.refreshCampuses().then(() => {
      this.refreshCourses().then(() => {
        if (
          this.props.editMode === editMode.INSERT &&
          this.props.id === undefined
        ) {
          this.setState(modalState);
        } else {
          axios.get("messages/byid?id=" + this.props.id).then((response) => {
            modalState.Message.Id.Value = response.data.data.id;

            modalState.Message.Title.Value = response.data.data.title;
            modalState.Message.Title.IsValid = true;

            modalState.Message.Description.Value =
              response.data.data.description;
            modalState.Message.Description.IsValid = true;

            if (response.data.data.period) {
              modalState.Message.Period.Value = response.data.data.period;
            }
            modalState.Message.Period.IsValid = true;

            if (response.data.data.campus) {
              modalState.Message.CampusId.Value = response.data.data.campus.id;
            }
            modalState.Message.CampusId.IsValid = true;

            if (response.data.data.course) {
              modalState.Message.CourseId.Value = response.data.data.course.id;
            }
            modalState.Message.CourseId.IsValid = true;

            if (response.data.data.classe) {
              modalState.Message.ClassId.Value = response.data.data.classe.id;
            }
            modalState.Message.ClassId.IsValid = true;

            this.setState(modalState, () => {
              this.refreshClasses();
            });
            this.validateForm();
          });
        }
      });
    });
  }

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  handleCampusChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Message.CampusId.Value = selectedOption.value;
    modalState.Message.ClassId.Value = "";

    this.setState(modalState, () => {
      this.refreshClasses();
    });
  };

  handleCourseChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Message.CourseId.Value = selectedOption.value;
    modalState.Message.ClassId.Value = "";

    this.setState(modalState, () => {
      this.refreshClasses();
    });
  };

  handlePeriodChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Message.Period.Value = selectedOption.value;
    modalState.Message.ClassId.Value = "";

    this.setState(modalState, () => {
      this.refreshClasses();
    });
  };

  handleClasseChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Message.ClassId.Value = selectedOption.value;

    this.setState(modalState);
  };

  refreshClasses = () => {
    return new Promise((resolve, reject) => {
      let courseId = this.state.Message.CourseId.Value;
      let periodId = this.state.Message.Period.Value;
      let campusId = this.state.Message.CampusId.Value;

      axios
        .get(
          "classes?" +
            "courseId=" +
            courseId +
            "&period=" +
            periodId +
            "&campusId=" +
            campusId +
            "&page=1&pageSize=999999&allCampuses=true"
        )
        .then((response) => {
          let mainState = this.state;

          mainState.classes = [];

          response.data.data.items.map((classe, index) =>
            mainState.classes.push({
              value: classe.id,
              label: classe.code,
            })
          );

          this.setState(mainState, resolve(true));
        });
    });
  };

  periodOptions = [
    { value: "M", label: "Morning" },
    { value: "A", label: "Afternoon" },
    { value: "E", label: "Evening" },
    { value: "MT", label: "Mon/Tue" },
    { value: "WT", label: "Wed/Thu" },
  ];
}

export default MessageEdit;
