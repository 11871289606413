/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  Label,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  FormGroup,
  CardFooter,
  Button,
} from "reactstrap";
import axios from "axios";
import * as dateFns from "date-fns";
import DatePicker from "react-datepicker";
import { SavePdf } from "components/Report/GeneratePdf.js";

class FinanceReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      teachers: [],
      StartDateSearch: new Date(),
    };
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>&nbsp;</Label>

                      <Button
                        style={{ marginLeft: "5px", marginTop: "0" }}
                        color="primary"
                        onClick={this.DownloadCsv.bind(this)}
                      >
                        Export to CSV
                      </Button>
                    </FormGroup>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    // this._refreshTeachers();
  }

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;

    mainState[name] = date;

    this.setState(mainState);
  };

  DownloadCsv() {
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");

    axios
      .get("dashboard/FinanceReportCsv?startDate=" + startDate, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "FinanceReport.csv");
        document.body.appendChild(link);
        link.click();
      });
  }
}

export default FinanceReport;
