import { BehaviorSubject } from "rxjs";
import { handleResponse } from "helpers/HandleResponse.js";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function login(name, password) {
  let userLogin = {
    Name: name,
    Password: password,
  };

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userLogin),
  };

  const isDevelopment = false;
  const url = isDevelopment
    ? "http://localhost:58794"
    : "https://api.lumos.edu";

  // : "http://back.sun.psisoftwarehouse.com";

  return fetch(url + "/users/authenticate", requestOptions)
    .then(handleResponse)
    .then((user) => {
      var userObj = {
        id: user.data.id,
        name: user.data.name,
        fullName: user.data.fullName,
        // role: user.data.role.name,
        // roleId: user.data.role.id,
        token: user.data.token,
      };

      localStorage.setItem("currentUser", JSON.stringify(userObj));
      currentUserSubject.next(userObj);

      return userObj;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  localStorage.removeItem("semesterId");
  localStorage.removeItem("campusId");
  currentUserSubject.next(null);
}
