/* eslint-disable no-eval */
/* eslint-disable no-useless-constructor */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
  FormGroup,
  Label,
  ButtonGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import axios from "axios";
import * as dateFns from "date-fns";
import Can from "components/Can/Can";
import confirm from "reactstrap-confirm";

class ModalAttendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();
  }

  getModalState = () => {
    return {
      date: dateFns.parseISO(this.props.date),
      status: this.props.status,
      comment: this.props.comment,
      subject: this.props.subject,
    };
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          centered
          onClosed={this.onCloseModal}
          size={"lg"}
          // style={{
          //   minWidth: "520px",
          //   width: "28%",
          // }}
        >
          <ModalHeader toggle={this.props.toggle}>
            Attendance - {this.state.subject}
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={3}>
                <FormGroup>
                  <Label>Date</Label>
                  <DatePicker
                    selected={this.state.date}
                    name="date"
                    dateFormat="MM/dd/yyyy"
                    disabled={true}
                  />
                </FormGroup>
              </Col>

              <Col md={9}>
                <FormGroup>
                  <Label for="tuitionCost">Status</Label>
                  <ButtonGroup style={{ marginTop: "-10px" }}>
                    <Button
                      color="success"
                      onClick={this.handleStatusChange.bind(this, "P")}
                      active={this.state.status === "P"}
                      style={{
                        opacity: this.state.status === "P" ? "1" : "0.3",
                      }}
                    >
                      Present
                    </Button>
                    <Button
                      color="warning"
                      onClick={this.handleStatusChange.bind(this, "T")}
                      active={this.state.status === "T"}
                      style={{
                        opacity: this.state.status === "T" ? "1" : "0.3",
                      }}
                    >
                      Tardy
                    </Button>
                    <Button
                      color="danger"
                      onClick={this.handleStatusChange.bind(this, "A")}
                      active={this.state.status === "A"}
                      style={{
                        opacity: this.state.status === "A" ? "1" : "0.3",
                      }}
                    >
                      Absent
                    </Button>
                    <Button
                      color="info"
                      onClick={this.handleStatusChange.bind(this, "E")}
                      active={this.state.status === "E"}
                      style={{
                        opacity: this.state.status === "E" ? "1" : "0.3",
                      }}
                    >
                      Excused
                    </Button>
                    <Button
                      color="info"
                      onClick={this.handleStatusChange.bind(this, "L")}
                      active={this.state.status === "L"}
                      style={{
                        opacity: this.state.status === "L" ? "1" : "0.3",
                      }}
                    >
                      L.E.
                    </Button>
                    <Button
                      color="text-gray"
                      onClick={this.handleStatusChange.bind(this, "NT")}
                      active={this.state.status === "NT"}
                      style={{
                        opacity: this.state.status === "NT" ? "0.6" : "0.3",
                      }}
                    >
                      n.y.t
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={12}>
                <FormGroup>
                  <Input
                    id="inputNote"
                    className={"form-group"}
                    type="textarea"
                    rows={5}
                    value={this.state.comment}
                    onChange={this.handleCommentChange.bind(this)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Can
              feature="Attendance_Student_Delete"
              yes={() => (
                <Button
                  color="danger"
                  onClick={this.deleteAttendance.bind(this)}
                  style={{ marginRight: "auto" }}
                >
                  Delete
                </Button>
              )}
            ></Can>
            <Button color="primary" onClick={this.saveAttendance.bind(this)}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={this.props.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  handleStatusChange(status) {
    return new Promise((resolve, reject) => {
      this.setState({ status: status }, resolve(true));
    });
  }

  handleCommentChange(e) {
    const value = e.target.value;

    return new Promise((resolve, reject) => {
      this.setState({ comment: value }, resolve(true));
    });
  }

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  saveAttendance() {
    let attendanceStudent = {
      Id: this.props.id,
      Status: this.state.status,
      Comment: this.state.comment,
    };

    axios
      .put("attendances/UpdateAttendanceStudent", attendanceStudent)
      .then((response) => {
        this.props.toggle().then(() => {
          this.resetModal().then(() => {
            if (
              this.props.callback &&
              typeof this.props.callback === "function"
            ) {
              this.props.callback();
            }
          });
        });
      });
  }

  async deleteAttendance() {
    let result = await confirm({
      title: <>Warning</>,
      message: "Do you want to delete this attendance?",
    });

    if (result) {
      axios
        .delete("attendances/DeleteAttendanceStudent/" + this.props.id)
        .then((response) => {
          this.props.toggle().then(() => {
            this.resetModal().then(() => {
              if (
                this.props.callback &&
                typeof this.props.callback === "function"
              ) {
                this.props.callback();
              }
            });
          });
        });
    }
  }
}

export default ModalAttendance;
