/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import { editMode } from "helpers/Utils.js";
import StudentClassView from "components/Student/Student.class.view";
import ClasseEdit from "components/Classe/Classe.component.edit.jsx";
import SearchInput from "../components/Search/Search.input.component";
import Dashboard2Teacher from "../components/Dashboard/Dashboard2.teacher.jsx";
import ReportClassAttendance from "../components/Report/Report.ClassAttendance";
import { authenticationService } from "services/Authentication.service.js";
import Can from "../components/Can/Can";
import confirm from "reactstrap-confirm";
import { SavePdf } from "components/Report/GeneratePdf.js";
import Select from "react-select";

class Classe extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();

    this.timeout = 0;
  }

  render() {
    const { currentUser } = this.state;

    let classes = this.state.classes.map((classe) => {
      return (
        <tr key={classe.id}>
          {/* <td>{classe.id}</td> */}

          <td>
            <a
              onClick={this.openModalClasse.bind(
                this,
                editMode.EDIT,
                classe.id
              )}
              href="#"
            >
              {classe.code}
            </a>
          </td>

          <td>{classe.campusName}</td>
          <td>{classe.teacherName}</td>
          <td>{classe.block}</td>
          <td>{classe.semesterName}</td>
          <td>{classe.totalSeats}</td>
          {/* <td>{classe.courseName}</td> */}
          <td>
            {/* <Button
              color="success"
              size="sm"
              className="mr-2"
              onClick={this.openModalClasse.bind(
                this,
                editMode.EDIT,
                classe.id
              )}
            >
              Edit
            </Button> */}
            {classe.hasStudents && (
              <React.Fragment>
                <Button
                  color="info"
                  size="sm"
                  className="mr-2"
                  onClick={this.toggleStudentClassViewModal.bind(
                    this,
                    classe.id
                  )}
                >
                  Students
                </Button>

                <Button
                  color="warning"
                  size="sm"
                  className="mr-2"
                  onClick={this.toggleDashboardTeacherModal.bind(this, classe)}
                >
                  Dashboard
                </Button>

                <Button
                  color="success"
                  size="sm"
                  onClick={this.toggleReportModal.bind(this, classe)}
                >
                  Report
                </Button>
              </React.Fragment>
            )}

            {!classe.hasStudents && currentUser !== null && (
              <Can
                feature="Subject_Delete"
                yes={() => (
                  <Button
                    color="danger"
                    size="sm"
                    onClick={this.deleteClasse.bind(this, classe.id)}
                  >
                    Delete
                  </Button>
                )}
              ></Can>
            )}
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup>
                      <Label>&nbsp;</Label>
                      <Button
                        style={{ margin: "0" }}
                        color="primary"
                        onClick={this.openModalClasse.bind(
                          this,
                          editMode.INSERT
                        )}
                      >
                        Add subject
                      </Button>
                    </FormGroup>

                    <SearchInput
                      searchInput={this.state.searchInput}
                      handleSearchInput={this.handleSearchInput}
                    />

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label for="studentClass">Items per page</Label>
                      <Select
                        className={"form-group"}
                        value={
                          this.state.itemsPerPage &&
                          this.itemsPerPageOptions.find(
                            (x) => x.value === this.state.itemsPerPage
                          )
                        }
                        onChange={this.handleItemsPerPageChange}
                        options={this.itemsPerPageOptions}
                      />
                    </FormGroup>
                  </Row>
                  {/* <CardTitle tag="h4">Campuses</CardTitle> */}
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th>Code</th>
                        <th>Campus</th>
                        <th>Teacher</th>
                        <th>Block</th>
                        <th>Semester</th>
                        <th>Seats</th>
                        {/* <th>Course</th> */}
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>{classes}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.currentPage}
                    totalItemsCount={this.state.totalResults}
                    onChange={this._refreshClasses.bind(this)}
                    csv={this.DownloadPdf.bind(this)}
                    pdf={this.DownloadPdfGroupBySubject.bind(this)}
                    pdf2={this.DownloadPdfGroupByClass.bind(this)}
                    pdf3={this.DownloadSubjects.bind(this)}
                    pdf4={this.DownloadPdfGroupByLevel.bind(this)}
                    buttonName="Group by Student"
                    pdfButtonName="Group by Subject"
                    pdfButtonName2="Group by Class"
                    pdfButtonName3="Subject List"
                    pdfButtonName4="Group by Level"
                    itemsPerPage={this.state.itemsPerPage}
                  />

                  <ClasseEdit
                    isOpen={this.state.openModal}
                    toggleClasseModal={this.toggleClasseModal.bind(this)}
                    editMode={this.state.editMode}
                    id={this.state.id}
                    callback={this._refreshClasses.bind(this)}
                    tables={this.props.tables}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <StudentClassView
            url={"students/byClass?classId="}
            searchId={this.state.selectClassIdForStudentClassViewModal}
            isOpen={this.state.openStudentClassViewModal}
            toggleStudentModal={this.toggleStudentClassViewModal.bind(
              this,
              this.state.selectClassIdForStudentClassViewModal
            )}
            tables={this.props.tables}
          />
          <Dashboard2Teacher
            isOpen={this.state.openDashboardTeacherModal}
            toggleStudentModal={this.toggleDashboardTeacherModal.bind(
              this,
              this.state.selectClassForDashboardTeacherModal
            )}
            classe={this.state.selectClassForDashboardTeacherModal}
          />
          <ReportClassAttendance
            isOpen={this.state.openReportModal}
            toggle={this.toggleReportModal.bind(this)}
            classe={this.state.selectClassForReportModal}
            tables={this.props.tables}
          />
        </div>
      </>
    );
  }

  handleItemsPerPageChange = (selectedOption) => {
    this.setState({ itemsPerPage: selectedOption.value, currentPage: 1 }, () =>
      this._refreshClasses()
    );
  };

  DownloadPdf() {
    axios.get("classes/allclassesreport").then((response) => {
      let students = [];
      response.data.data.map((student, index) => {
        students.push({
          name: student.name,
          classCode: "",
          grade: "",
        });

        student.studentClasses.map((studentClass, index) => {
          let grade =
            studentClass.classe.marks > 0
              ? (
                  (studentClass.marks / studentClass.classe.marks) *
                  100
                ).toFixed(2)
              : 0;

          students.push({
            name: "",
            classCode: studentClass.classe.code,
            grade: grade + "%" + (grade < 70 ? " *" : ""),
          });
        });
      });

      let semesterName = this.getCurrentSemester().name;
      let campus = this.getCurrentCampus();
      let campusComplement =
        campus && campus.name.length > 0 ? " - Campus " + campus.name : "";

      SavePdf("Students - " + semesterName + campusComplement, students, [
        {
          name: "Student",
          classCode: "Subject",
          grade: "Grade",
          status: "",
        },
      ]);
    });
  }

  getCurrentSemester = () => {
    let semesterId = JSON.parse(localStorage.getItem("semesterId"));
    if (semesterId)
      return this.props.tables.semesters.find((x) => x.id === semesterId);
  };

  getCurrentCampus = () => {
    let campusId = JSON.parse(localStorage.getItem("campusId"));
    if (campusId && campusId !== "all")
      return this.props.tables.campuses.find((x) => x.id === campusId);
  };

  DownloadPdfGroupBySubject() {
    axios.get("classes/allclassesreportgroupbysubject").then((response) => {
      let subjects = [];
      response.data.data.map((subject, index) => {
        subjects.push({
          classCode: subject.code,
          name: "",
          grade: "",
        });

        subject.students.map((student, index) => {
          let subject = student.studentClasses[0];

          subjects.push({
            classCode: "",
            name: student.name,
            grade:
              (subject &&
                subject.marks.toFixed(2) + (subject.marks < 70 ? " *" : "")) ||
              "",
          });
        });
      });

      let semesterName = this.getCurrentSemester().name;
      let campus = this.getCurrentCampus();
      let campusComplement =
        campus && campus.name.length > 0 ? " - Campus " + campus.name : "";

      SavePdf("Subjects - " + semesterName + campusComplement, subjects, [
        {
          classCode: "Subject",
          name: "Student",
          grade: "Grade",
        },
      ]);
    });
  }

  DownloadPdfGroupByClass() {
    axios.get("classes/allclassesreportgroupbyclass").then((response) => {
      let classes = [];

      let totalStudents = 0;
      let totalFailed = 0;

      response.data.data.map((classGroup, index) => {
        classes.push({
          classGroupName: classGroup.name,
          studentName: "",
          subject1: "01",
          subject2: "02",
          subject3: "03",
        });

        let hasFailed = 0;
        classGroup.students.map((student, index) => {
          let subject1 = student.studentClasses.find((x) =>
            x.classe.code.includes("01")
          );

          let subject2 = student.studentClasses.find((x) =>
            x.classe.code.includes("02")
          );

          let subject3 = student.studentClasses.find((x) =>
            x.classe.code.includes("03")
          );

          classes.push({
            classGroupName: "",
            studentName: student.name,
            subject1:
              (subject1 &&
                subject1.marks.toFixed(2) +
                  (subject1.marks < 70 ? " *" : "")) ||
              "",
            subject2:
              (subject2 &&
                subject2.marks.toFixed(2) +
                  (subject2.marks < 70 ? " *" : "")) ||
              "",
            subject3:
              (subject3 &&
                subject3.marks.toFixed(2) +
                  (subject3.marks < 70 ? " *" : "")) ||
              "",
          });

          if (
            (subject1 && subject1.marks < 70) ||
            (subject2 && subject2.marks < 70) ||
            (subject3 && subject3.marks < 70)
          )
            hasFailed++;
        });

        let total = classGroup.students.length;
        let percentFailed = ((hasFailed / total) * 100).toFixed(2);
        let percentSuccess = (100 - percentFailed).toFixed(2);

        totalStudents += total;
        totalFailed += hasFailed;

        classes.push({
          classGroupName: "Class Result:",
          studentName:
            "Passing: " +
            percentSuccess +
            "% - Failing: " +
            percentFailed +
            "% - Students: " +
            classGroup.students.length,
          subject1: "",
          subject2: "",
          subject3: "",
        });

        classes.push({
          classGroupName: "",
          studentName: "",
          subject1: "",
          subject2: "",
          subject3: "",
        });
      });

      let totalPercentFailed = ((totalFailed / totalStudents) * 100).toFixed(2);
      let totalPercentSuccess = (100 - totalPercentFailed).toFixed(2);

      classes.push({
        classGroupName: "Final Result:",
        studentName:
          "Passing: " +
          totalPercentSuccess +
          "% - Failing: " +
          totalPercentFailed +
          "% - Students: " +
          totalStudents,
        subject1: "",
        subject2: "",
        subject3: "",
      });

      let semesterName = this.getCurrentSemester().name;
      let campus = this.getCurrentCampus();
      let campusComplement =
        campus && campus.name.length > 0 ? " - Campus " + campus.name : "";

      SavePdf("Classes - " + semesterName + campusComplement, classes, [
        {
          classGroupName: "Class",
          studentName: "Student",
          subject1: "Grade 01",
          subject2: "Grade 02",
          subject3: "Grade 03",
        },
      ]);
    });
  }

  DownloadPdfGroupByLevel() {
    axios.get("classes/allclassesreportgroupbylevel").then((response) => {
      let classes = [];

      let totalStudents = 0;
      let totalFailed = 0;

      response.data.data.map((classGroup, index) => {
        classes.push({
          classGroupName: classGroup.name,
          studentName: "",
          subject1: "01",
          subject2: "02",
          subject3: "03",
        });

        let hasFailed = 0;
        classGroup.students.map((student, index) => {
          let subject1 = student.studentClasses.find((x) =>
            x.classe.code.includes("01")
          );

          let subject2 = student.studentClasses.find((x) =>
            x.classe.code.includes("02")
          );

          let subject3 = student.studentClasses.find((x) =>
            x.classe.code.includes("03")
          );

          classes.push({
            classGroupName: "",
            studentName: student.name,
            subject1:
              (subject1 &&
                subject1.marks.toFixed(2) +
                  (subject1.marks < 70 ? " *" : "")) ||
              "",
            subject2:
              (subject2 &&
                subject2.marks.toFixed(2) +
                  (subject2.marks < 70 ? " *" : "")) ||
              "",
            subject3:
              (subject3 &&
                subject3.marks.toFixed(2) +
                  (subject3.marks < 70 ? " *" : "")) ||
              "",
          });

          if (
            (subject1 && subject1.marks < 70) ||
            (subject2 && subject2.marks < 70) ||
            (subject3 && subject3.marks < 70)
          )
            hasFailed++;
        });

        let total = classGroup.students.length;
        let percentFailed = ((hasFailed / total) * 100).toFixed(2);
        let percentSuccess = (100 - percentFailed).toFixed(2);

        totalStudents += total;
        totalFailed += hasFailed;

        classes.push({
          classGroupName: "Class Result:",
          studentName:
            "Passing: " +
            percentSuccess +
            "% - Failing: " +
            percentFailed +
            "% - Students: " +
            classGroup.students.length,
          subject1: "",
          subject2: "",
          subject3: "",
        });

        classes.push({
          classGroupName: "",
          studentName: "",
          subject1: "",
          subject2: "",
          subject3: "",
        });
      });

      let totalPercentFailed = ((totalFailed / totalStudents) * 100).toFixed(2);
      let totalPercentSuccess = (100 - totalPercentFailed).toFixed(2);

      classes.push({
        classGroupName: "Final Result:",
        studentName:
          "Passing: " +
          totalPercentSuccess +
          "% - Failing: " +
          totalPercentFailed +
          "% - Students: " +
          totalStudents,
        subject1: "",
        subject2: "",
        subject3: "",
      });

      let semesterName = this.getCurrentSemester().name;
      let campus = this.getCurrentCampus();
      let campusComplement =
        campus && campus.name.length > 0 ? " - Campus " + campus.name : "";

      SavePdf("Classes - " + semesterName + campusComplement, classes, [
        {
          classGroupName: "Class",
          studentName: "Student",
          subject1: "Grade 01",
          subject2: "Grade 02",
          subject3: "Grade 03",
        },
      ]);
    });
  }

  async deleteClasse(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Are you sure you wish to delete this subject?",
    });

    if (result) {
      axios.delete("classes/" + id).then(() => {
        this._refreshClasses();
      });
    }
  }

  handleSearchInput = (e) => {
    const value = e.target.value;

    this.setState({ searchInput: value, currentPage: 1 }, () => {
      this._refreshClasses();
    });
  };

  toggleClasseModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openModal = !mainState.openModal;
      this.setState(mainState, resolve(true));
    });
  };

  openModalClasse = (mode, id) => {
    // this.toggleClasseModal();

    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.editMode = mode;
      mainState.id = id;
      mainState.openModal = !mainState.openModal;

      this.setState(mainState, resolve(true));
    });
  };

  toggleStudentClassViewModal = (classId) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.openStudentClassViewModal =
        !mainState.openStudentClassViewModal;
      mainState.selectClassIdForStudentClassViewModal = classId;

      this.setState(mainState, resolve(true));
    });
  };

  toggleDashboardTeacherModal = (classe) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.openDashboardTeacherModal =
        !mainState.openDashboardTeacherModal;
      mainState.selectClassForDashboardTeacherModal = classe;

      this.setState(mainState, resolve(true));
    });
  };

  toggleReportModal = (classe) => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;

      mainState.openReportModal = !mainState.openReportModal;
      mainState.selectClassForReportModal = classe;

      this.setState(mainState, resolve(true));
    });
  };

  getInitialState = () => {
    const initialState = {
      mainState: this.getMainState(),
      modalState: this.getModalState(),
    };

    return initialState;
  };

  onBlur = (event) => {
    let { modalState } = this.state;
    let value = modalState.Classe.TeacherId.Value;

    this.validateField("TeacherId", value);
  };

  getMainState = () => {
    return {
      classes: [],
      openModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      alert: { visible: false, color: "", message: "" },
      selectClassIdForStudentClassViewModal: "",
      selectClassForDashboardTeacherModal: {},
      selectClassForReportModal: {},
      openStudentClassViewModal: false,
      openDashboardTeacherModal: false,
      openReportModal: false,
      editMode: "",
      searchInput: "",
      currentUser: null,
    };
  };

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  componentDidMount() {
    this._refreshClasses();

    authenticationService.currentUser.subscribe((x) => {
      this.setState({ currentUser: x });
    });
  }

  _refreshClasses = (pageNumber) => {
    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      return new Promise((resolve, reject) => {
        let page = pageNumber || 1;
        let itemsPerPage = this.state.itemsPerPage;
        let search = this.state.searchInput;

        axios
          .get(
            "classes?search=" +
              search +
              "&page=" +
              page +
              "&pageSize=" +
              itemsPerPage
          )
          .then((response) => {
            let mainState = this.state;

            mainState.classes = [];
            response.data.data.items.map((classe, index) =>
              mainState.classes.push({
                id: classe.id,
                code: classe.code,
                campusName: classe.campus.name,
                teacherName: classe.teacher.name,
                semesterName: classe.semester.name,
                semesterId: classe.semester.id,
                days: classe.days,
                semesterStartDate: classe.semester.startDate,
                semesterEndDate: classe.semester.endDate,
                hasStudents: classe.hasStudents,
                block: classe.block,
                totalPoints: classe.totalPoints,
                totalSeats: classe.totalSeats,
              })
            );

            mainState.currentPage = pageNumber;
            mainState.totalResults = response.data.data.totalCount;

            this.setState(mainState, resolve(true));
          });
      });
    }, 500);
  };

  setCurrentPage(pageNumber) {
    let mainState = this.state;
    mainState.currentPage = pageNumber;
    this.setState(mainState);
  }

  itemsPerPageOptions = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 500, label: "500" },
  ];

  DownloadSubjects() {
    axios.get("classes?page=1&pageSize=999999").then((response) => {
      let classes = [];

      response.data.data.items.map((classe, index) =>
        classes.push({
          Code: classe.code,
          Campus: classe.campus.name,
          Teacher: classe.teacher.name,
          Semester: classe.semester.name,
          Block: classe.block,
          TotalPoints: classe.totalPoints,
        })
      );

      this.downloadCSVFromJson("Subjects.csv", classes);
    });
  }

  downloadCSVFromJson(filename, arrayOfJson) {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export default Classe;
