import jsPDF from "jspdf";
import "jspdf-autotable";
import logoreport from "logo-report.png";
import * as dateFns from "date-fns";

export function SavePdf(documentName, bodyArray, headerArray) {
  var doc = new jsPDF();
  var totalPagesExp = "{total_pages_count_string}";

  var img = new Image();
  img.src = logoreport;

  var pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.autoTable({
    head: headerArray,
    body: bodyArray,
    headStyles: {
      fillColor: [244, 130, 51],
    },
    didDrawPage: function (data) {
      // Header

      doc.setTextColor(40);
      doc.setFontStyle("normal");

      if (img) {
        doc.addImage(img, "JPEG", data.settings.margin.left, 15, 25, 10);
      }

      doc.setFontSize(10);

      let title =
        documentName && documentName.length > 0 ? documentName : "Report";
      doc.text(title, pageWidth / 2, 22, "center");

      // Footer
      var str = "Page " + doc.internal.getNumberOfPages();

      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        str = str + " of " + totalPagesExp;
      }

      doc.setFontSize(10);

      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      var pageSize = doc.internal.pageSize;
      var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

      doc.text(str, data.settings.margin.left, pageHeight - 10);

      doc.text(
        dateFns.format(new Date(), "MM/dd/yyyy"),
        pageWidth - data.settings.margin.right,
        pageHeight - 10,
        "right"
      );
    },
    margin: { top: 30 },
    didParseCell: function (data) {
      if (
        data.row.raw &&
        data.row.raw.classGroupName &&
        data.row.raw.classGroupName.length > ""
      ) {
        data.cell.styles.fontStyle = "bold";
      }

      if (
        data.row.raw &&
        ((data.row.raw.subject1 && data.row.raw.subject1.includes("*")) ||
          (data.row.raw.subject2 && data.row.raw.subject2.includes("*")) ||
          (data.row.raw.subject3 && data.row.raw.subject3.includes("*"))) &&
        data.cell.raw.includes("*")
      ) {
        data.cell.styles.textColor = "red";
      }
    },
  });

  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(documentName);
}
