/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import React from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  Table,
  CardTitle,
  CustomInput,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import * as dateFns from "date-fns";
import confirm from "reactstrap-confirm";
import uuid from "uuid";

class ApplicationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();

    this.timeout = 0;
  }

  getApplication = () => {
    const application = {
      PersonalFullName: "",
      OwnerId: "",
      StatusTypeId: "",
      SemesterId: "",
      CourseId: "",
      PeriodId: "",
      Description: "",
      LogList: [],
      I20FileList: [],
      TransactionList: [],
      I20NotRequired: false,
      I20: "",
      PersonalEmail2: "",
      CampusId: "",
      StartDate: null,
    };

    return application;
  };

  getModalState = () => {
    return {
      Application: this.getApplication(),
      StatusTypeList: [],
      SemesterList: [],
      CourseList: [],
      UserList: [],
      CampusList: [],
    };
  };

  onInputClick = (event) => {
    event.target.value = "";
  };

  render() {
    let logList = this.state.Application.LogList.map((log, index) => {
      return (
        <tr key={index}>
          <td>{log.description}</td>
          <td>{log.ownerName}</td>
          <td>{log.statusName}</td>
          <td>{log.userName}</td>
          <td>{dateFns.format(log.createdDate, "MM/dd/yyyy hh:mm:ss aa")}</td>
        </tr>
      );
    });

    let transactionList = this.state.Application.TransactionList.map(
      (transaction, index) => {
        return (
          <tr key={index}>
            <td>{transaction.transactionId}</td>
            <td>{"$" + transaction.amount.toFixed(2)}</td>
            <td>
              {dateFns.format(
                transaction.transactionDate,
                "MM/dd/yyyy hh:mm:ss aa"
              )}
            </td>
          </tr>
        );
      }
    );

    let i20FileList = this.state.Application.I20FileList.filter(
      (x) => x.toBeDeleted !== true
    ).map((i20File, index) => {
      return (
        <tr key={index}>
          <td>{i20File.fileName}</td>

          <td>
            <Button
              color="success"
              size="sm"
              className="mr-2"
              onClick={this.downloadI20.bind(this, i20File.id)}
            >
              Download
            </Button>

            <Button
              color="danger"
              size="sm"
              className="mr-2"
              onClick={this.i20Delete.bind(this, i20File.id)}
            >
              Delete
            </Button>
          </td>

          <td>
            {" "}
            <CustomInput
              className="defaultCheckBox"
              type="checkbox"
              id={i20File.id}
              checked={i20File.toBeSent == true}
              onChange={this.i20MarkToSend.bind(this, i20File.id)}
            />
          </td>
        </tr>
      );
    });

    let status = this.state.StatusTypeList.find(
      (x) => x.value === this.state.Application.StatusTypeId
    );

    let isSentStatus = status && status.label === "Sent";

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                style={{
                  maxWidth: "1100px",
                  width: "80%",
                }}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                onClosed={this.onCloseModal}
                onOpened={this.onOpened.bind(this)}
                scrollable
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  {"Log - " + this.props.applyName}
                  <strong>{this.state.Application.StatusTypeName}</strong>
                </ModalHeader>
                <ModalBody
                  style={{ backgroundColor: "#f4f3ef", paddingBottom: "0" }}
                >
                  <Row form>
                    <Col md={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5">Details</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <React.Fragment>
                            <Row form>
                              <Col md={10}>
                                <Label for="address">Name</Label>
                                <Input
                                  className={`form-group`}
                                  type="text"
                                  name="PersonalFullName"
                                  id="PersonalFullName"
                                  autoComplete="nope"
                                  value={
                                    this.state.Application.PersonalFullName
                                  }
                                  onChange={this.handleUserInput}
                                />
                              </Col>

                              <Col md={2}>
                                <Label for="address">I-20</Label>
                                <Input
                                  className={`form-group`}
                                  type="text"
                                  name="I20"
                                  id="I20"
                                  autoComplete="nope"
                                  value={this.state.Application.I20}
                                  onChange={this.handleUserInput}
                                />
                              </Col>
                            </Row>
                            <Row form>
                              <Col md={2}>
                                <FormGroup>
                                  <Label for="classeName">Status</Label>
                                  <Select
                                    className={`form-group`}
                                    value={this.state.StatusTypeList.find(
                                      (x) =>
                                        x.value ===
                                        this.state.Application.StatusTypeId
                                    )}
                                    options={this.state.StatusTypeList}
                                    onChange={this.handleStatusChange}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={4}>
                                <FormGroup>
                                  <Label for="classTeacher">Owner</Label>

                                  <Select
                                    className={`form-group`}
                                    value={this.state.UserList.find(
                                      (x) =>
                                        x.value ===
                                        this.state.Application.OwnerId
                                    )}
                                    options={this.state.UserList}
                                    onChange={this.handleOwnerChange}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={6}>
                                <Label for="address">E-mail</Label>
                                <Input
                                  className={`form-group`}
                                  type="text"
                                  name="PersonalEmail2"
                                  id="PersonalEmail2"
                                  autoComplete="nope"
                                  value={this.state.Application.PersonalEmail2}
                                  onChange={this.handleUserInput}
                                />
                              </Col>
                            </Row>

                            <Row form>
                              <Col md={3}>
                                <FormGroup>
                                  <Label for="classTeacher">Semester</Label>

                                  <Select
                                    className={`form-group`}
                                    value={this.state.SemesterList.find(
                                      (x) =>
                                        x.value ===
                                        this.state.Application.SemesterId
                                    )}
                                    options={this.state.SemesterList}
                                    onChange={this.handleSemesterChange}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={3}>
                                <FormGroup>
                                  <Label for="classTeacher">Level</Label>

                                  <Select
                                    className={`form-group`}
                                    value={this.state.CourseList.find(
                                      (x) =>
                                        x.value ===
                                        this.state.Application.CourseId
                                    )}
                                    options={this.state.CourseList}
                                    onChange={this.handleCourseChange}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={2}>
                                <FormGroup>
                                  <Label for="classTeacher">Period</Label>

                                  <Select
                                    className={`form-group`}
                                    value={this.periodOptions.find(
                                      (x) =>
                                        x.value ===
                                        this.state.Application.PeriodId
                                    )}
                                    options={this.periodOptions}
                                    onChange={this.handlePeriodChange}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={2}>
                                <FormGroup>
                                  <Label for="classTeacher">Campus</Label>

                                  <Select
                                    className={`form-group`}
                                    value={this.state.CampusList.find(
                                      (x) =>
                                        x.value ===
                                        this.state.Application.CampusId
                                    )}
                                    options={this.state.CampusList}
                                    onChange={this.handleCampusChange}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={2}>
                                <FormGroup>
                                  <Label for="startDate">Start Date</Label>
                                  <Input
                                    type="date"
                                    name="StartDate"
                                    id="startDate"
                                    value={this.state.Application.StartDate}
                                    onChange={this.handleUserInput}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row form>
                              <Col md={12}>
                                <FormGroup>
                                  <Label for="classeName">Note</Label>
                                  <Input
                                    className={`form-group`}
                                    type="textarea"
                                    name="Description"
                                    id="studentNote"
                                    autoComplete="nope"
                                    value={this.state.Application.Description}
                                    onChange={this.handleUserInput}
                                    placeholder={"Type the new note here..."}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            {isSentStatus && (
                              <Row form style={{ marginTop: "15px" }}>
                                <Col md={12}>
                                  <FormGroup>
                                    <CustomInput
                                      className="defaultCheckBox"
                                      type="checkbox"
                                      id="activeCheckBox"
                                      name="activeCheckBox"
                                      label="A document (I-20) is not required."
                                      checked={
                                        this.state.Application.I20NotRequired
                                      }
                                      onChange={this.onCheckI20NotRequired.bind(
                                        this
                                      )}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                          </React.Fragment>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  {isSentStatus &&
                    this.state.Application.I20NotRequired === false && (
                      <Row form>
                        <Col md={12}>
                          <Card>
                            <CardHeader>
                              <CardTitle tag="h5">I-20 Files</CardTitle>
                            </CardHeader>
                            <CardBody>
                              <Row form>
                                <Col md={2}>
                                  <FormGroup>
                                    <Button
                                      color="primary"
                                      style={{ margin: "0" }}
                                      onClick={this.uploadI20.bind(this)}
                                    >
                                      Add I-20
                                    </Button>
                                  </FormGroup>
                                </Col>
                              </Row>

                              {i20FileList.length > 0 && (
                                <Row form>
                                  <Table className="table table-striped">
                                    <thead style={{ whiteSpace: "unset" }}>
                                      <tr>
                                        <th>File Name</th>
                                        <th>Actions</th>
                                        <th>Send by email</th>
                                      </tr>
                                    </thead>
                                    <tbody>{i20FileList}</tbody>
                                  </Table>
                                </Row>
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}

                  <Row form>
                    <Col md={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5">Previous notes</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row form>
                            <Table className="table table-striped">
                              <thead style={{ whiteSpace: "unset" }}>
                                <tr>
                                  <th>Note</th>
                                  <th>Owner</th>
                                  <th>Status</th>
                                  <th>User</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>{logList}</tbody>
                            </Table>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <input
                    type="file"
                    id="selectI20"
                    accept="application/pdf"
                    hidden
                    onChange={this.onI20FileChange.bind(this)}
                    onClick={this.onInputClick}
                  />

                  {transactionList.length > 0 && (
                    <Row form>
                      <Col md={12}>
                        <Card>
                          <CardHeader>
                            <CardTitle tag="h5">Transactions</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <Row form>
                              <Table className="table table-striped">
                                <thead style={{ whiteSpace: "unset" }}>
                                  <tr>
                                    <th>Transaction Id</th>
                                    <th>Amount</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                <tbody>{transactionList}</tbody>
                              </Table>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={this.save.bind(this)}
                    disabled={!this.validateForm()}
                  >
                    Save
                  </Button>{" "}
                  <Button
                    color="secondary"
                    onClick={this.props.toggle.bind(this)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  downloadI20(id) {
    let i20File = this.state.Application.I20FileList.find((x) => x.id === id);

    if (i20File.fileContent) {
      const url = window.URL.createObjectURL(
        new Blob([i20File.fileContent], {
          type: "application/pdf",
        })
      );
      window.open(url);
    } else {
      axios
        .get("documents/getFileByDate?id=" + i20File.documentId, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", i20File.fileName);
          document.body.appendChild(link);

          link.click();
        });
    }
  }

  onInputClick = (event) => {
    event.target.value = "";
  };

  onI20FileChange = (event) => {
    let file = event.target.files[0];

    return new Promise((resolve, reject) => {
      let modalState = this.state;

      if (file !== undefined) {
        modalState.Application.I20FileList.push({
          id: uuid.v4(),
          fileContent: file,
          fileName: file.name,
          isNew: true,
          toBeSent: true,
        });

        this.setState(modalState, resolve(true));
      }
    });
  };

  uploadI20() {
    document.getElementById("selectI20").click();
  }

  async i20Delete(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Do you want to delete this I-20 file?",
    });

    if (result) {
      let modalState = this.state;

      let i20File = modalState.Application.I20FileList.find((x) => x.id === id);

      if (i20File != undefined) {
        if (i20File.isNew) {
          const newI20List = modalState.Application.I20FileList.filter(
            (x) => x.id !== id
          );

          modalState.Application.I20FileList = newI20List;
        } else {
          i20File.toBeDeleted = true;
        }
      }

      this.setState(modalState);
    }
  }

  i20MarkToSend = async (id, e) => {
    const value = e.target.checked;

    if (value == true) {
      let result = await confirm({
        title: <>Warning</>,
        message: "Do you want to check this I-20 to be sent by email?",
      });

      if (result) {
        let modalState = this.state;

        let i20File = modalState.Application.I20FileList.find(
          (x) => x.id === id
        );
        i20File.toBeSent = true;

        this.setState(modalState);
      }
    } else {
      let modalState = this.state;

      let i20File = modalState.Application.I20FileList.find((x) => x.id === id);
      i20File.toBeSent = false;

      this.setState(modalState);
    }
  };

  handleStatusChange = (selectedOption) => {
    let modalState = this.state;
    modalState.Application.StatusTypeId = selectedOption.value;
    this.setState(modalState);
  };

  handleSemesterChange = (selectedOption) => {
    let modalState = this.state;

    modalState.Application.SemesterId = selectedOption.value;

    let semester = this.state.SemesterList.find(
      (x) => x.value === selectedOption.value
    );

    if (semester) {
      modalState.Application.StartDate = dateFns.format(
        dateFns.parseISO(semester.startDate),
        "yyyy-MM-dd"
      );
    }

    this.setState(modalState);
  };

  handleCourseChange = (selectedOption) => {
    let modalState = this.state;
    modalState.Application.CourseId = selectedOption.value;
    this.setState(modalState);
  };

  handleCampusChange = (selectedOption) => {
    let modalState = this.state;
    modalState.Application.CampusId = selectedOption.value;
    this.setState(modalState);
  };

  handlePeriodChange = (selectedOption) => {
    let modalState = this.state;
    modalState.Application.PeriodId = selectedOption.value;
    this.setState(modalState);
  };

  handleOwnerChange = (selectedOption) => {
    let modalState = this.state;
    modalState.Application.OwnerId = selectedOption.value;
    this.setState(modalState);
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  validateForm() {
    let status = this.state.StatusTypeList.find(
      (x) => x.value === this.state.Application.StatusTypeId
    );

    let isSentStatus = status && status.label === "Sent";

    return (
      this.state.Application.PersonalFullName.length > 0 &&
      this.state.Application.StatusTypeId.length > 0 &&
      this.state.Application.SemesterId.length > 0 &&
      this.state.Application.OwnerId.length > 0 &&
      this.state.Application.Description.length > 0 &&
      this.state.Application.PersonalEmail2.length > 0 &&
      (isSentStatus === false ||
        this.state.Application.I20NotRequired === true ||
        this.state.Application.I20FileList.filter((x) => x.toBeDeleted !== true)
          .length > 0)
    );
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState.Application[name] = value;

    this.setState(modalState);
  };

  onOpened() {
    this.refreshSemesters().then(() => {
      this.refreshCourses().then(() => {
        this.refreshCampuses().then(() => {
          this.openApplicationModal();
        });
      });
    });
  }

  closeApplicationModal() {
    this.props.toggle().then(() => {
      this.resetModal().then(() => {
        if (this.props.callback && typeof this.props.callback === "function") {
          this.props.callback();
        }
      });
    });
  }

  refreshStatusTypeList = () => {
    return new Promise((resolve, reject) => {
      axios.get("applystatustypes").then((response) => {
        let mainState = this.state;

        mainState.StatusTypeList = [];

        response.data.data
          .filter(
            (x) =>
              x.name !== "Problem" &&
              x.name !== "Rejected/Closed" &&
              x.name !== "Pending"
          )
          .map((statusType, index) =>
            mainState.StatusTypeList.push({
              value: statusType.id,
              label: statusType.name,
            })
          );

        mainState.StatusTypeList.push({
          value: "-",
          label: "______________",
          isDisabled: true,
        });

        response.data.data
          .filter(
            (x) =>
              x.name === "Problem" ||
              x.name === "Rejected/Closed" ||
              x.name === "Pending"
          )
          .map((statusType, index) =>
            mainState.StatusTypeList.push({
              value: statusType.id,
              label: statusType.name,
            })
          );

        this.setState(mainState, resolve(true));
      });
    });
  };

  refreshSemesters = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.semesters.map((semester, index) =>
        modalState.SemesterList.push({
          value: semester.id,
          label: semester.name,
          startDate: semester.startDate,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.courses.map((course, index) =>
        modalState.CourseList.push({
          value: course.id,
          label: course.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshCampuses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      this.props.tables.campuses
        .filter(
          (x) =>
            x.name == "Salt Lake in Person" ||
            x.name == "Orem In Person" ||
            x.name == "Online Combined Campuses"
        )
        .map((campus, index) =>
          modalState.CampusList.push({
            value: campus.id,
            label: campus.name
              .replace("Salt Lake in Person", "Salt Lake City")
              .replace("Orem In Person", "Orem")
              .replace("Online Combined Campuses", "Online"),
          })
        );

      this.setState(modalState, resolve(true));
    });
  };

  refreshUserList = () => {
    return new Promise((resolve, reject) => {
      axios.get("users/getAllowedApplicationOwners").then((response) => {
        let mainState = this.state;

        mainState.UserList = [];

        response.data.data.map((user, index) =>
          mainState.UserList.push({
            value: user.id,
            label: user.fullName,
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  };

  openApplicationModal() {
    axios
      .get("apply/getapplylogs?id=" + this.props.id)
      .then((response) => {
        let modalState = this.state;

        modalState.Application.Id = response.data.data.id;

        modalState.Application.I20NotRequired =
          response.data.data.i20NotRequired;

        modalState.Application.I20 = response.data.data.i20;
        modalState.Application.PersonalEmail2 =
          response.data.data.personalEmail2;

        response.data.data.applyLogList.map((log, index) => {
          modalState.Application.LogList.push({
            id: log.id,
            description: log.description,
            ownerId: log.owner && log.owner.id,
            ownerName:
              log.owner &&
              log.owner.name &&
              log.owner.name.slice(0, log.owner.name.lastIndexOf("@")),
            statusName: log.status.name,
            statusId: log.status.id,
            userName:
              log.user &&
              log.user.name &&
              log.user.name.slice(0, log.user.name.lastIndexOf("@")),
            createdDate: dateFns.parseISO(log.createdDate),
          });
        });

        let lastItem = modalState.Application.LogList.sort(
          (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
        )[0];

        modalState.Application.OwnerId = lastItem.ownerId;
        modalState.Application.StatusTypeId = lastItem.statusId;

        modalState.Application.SemesterId = this.state.SemesterList.find(
          (x) => x.label === response.data.data.semester
        ).value;

        modalState.Application.PersonalFullName =
          response.data.data.personalFullName;

        modalState.Application.CourseId =
          response.data.data.course && response.data.data.course.id;

        modalState.Application.CampusId =
          (response.data.data.campusEntity &&
            response.data.data.campusEntity.id) ||
          this.state.CampusList.find(
            (x) => x.label === response.data.data.campus
          ).value;

        modalState.Application.StartDate =
          (response.data.data.startDate &&
            dateFns.format(
              dateFns.parseISO(response.data.data.startDate),
              "yyyy-MM-dd"
            )) ||
          dateFns.format(
            dateFns.parseISO(
              this.state.SemesterList.find(
                (x) => x.label === response.data.data.semester
              ).startDate
            ),
            "yyyy-MM-dd"
          );

        modalState.Application.PeriodId = response.data.data.shift;

        response.data.data.applyI20FileList.map((i20File, index) => {
          modalState.Application.I20FileList.push({
            id: i20File.id,
            documentId: i20File.document ? i20File.document.id : "",
            fileName: i20File.document ? i20File.document.fileName : "",
            toBeSent: i20File.toBeSent,
          });
        });

        response.data.data.applyTransactionList.map((transaction, index) => {
          modalState.Application.TransactionList.push({
            id: transaction.id,
            transactionId: transaction.transactionId,
            amount: transaction.amount,
            transactionDate: dateFns.parseISO(transaction.transactionDate),
          });
        });

        this.setState(modalState);
      })
      .then(() => {
        this.refreshStatusTypeList().then(() => {
          this.refreshUserList();
        });
      });
  }

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  }

  async fillI20Files(apply) {
    for (const i20 of apply.ApplyI20FileList) {
      if (i20.File !== null && i20.File !== undefined) {
        let base64File = await this.getBase64(i20.File);
        let fileName = i20.File.name;

        if (i20.documentId) {
          i20.Document = {
            Id: i20.documentId,
            FileContentBase64: base64File,
            FileName: fileName,
          };
        } else {
          i20.Document = {
            FileContentBase64: base64File,
            FileName: fileName,
          };
        }

        i20.File = null;
      } else if (i20.documentId) {
        i20.Document = {
          Id: i20.documentId,
        };
      }
    }
  }

  async save() {
    let status = this.state.StatusTypeList.find(
      (x) => x.value === this.state.Application.StatusTypeId
    );

    let semester = this.state.SemesterList.find(
      (x) => x.value === this.state.Application.SemesterId
    ).label;

    if (status.label === "New") {
      await confirm({
        title: <>Warning</>,
        message: "The 'New' status cannot be picked.",
        cancelText: null,
      });
    } else {
      let applyI20FileList = [];
      this.state.Application.I20FileList.filter(
        (x) => x.isNew || x.toBeDeleted || x.toBeSent
      ).map((i20File, index) =>
        applyI20FileList.push({
          Id: i20File.id,
          File: i20File.fileContent,
          ToBeDeleted: i20File.toBeDeleted,
          ToBeSent: i20File.toBeSent,
        })
      );

      let applyLogList = [];
      applyLogList.push({
        Description: this.state.Application.Description,
        Owner: { Id: this.state.Application.OwnerId },
        Status: { Id: this.state.Application.StatusTypeId },
      });

      let course;
      if (
        this.state.Application.CourseId &&
        this.state.Application.CourseId.length > 0
      )
        course = { Id: this.state.Application.CourseId };

      let campus;
      if (
        this.state.Application.CampusId &&
        this.state.Application.CampusId.length > 0
      )
        campus = { Id: this.state.Application.CampusId };

      let apply = {
        Id: this.props.id,
        PersonalFullName: this.state.Application.PersonalFullName,
        ApplyI20FileList: applyI20FileList,
        ApplyLogList: applyLogList,
        I20NotRequired: this.state.Application.I20NotRequired,
        Semester: semester,
        I20: this.state.Application.I20,
        PersonalEmail2: this.state.Application.PersonalEmail2,
        Course: course,
        Shift: this.state.Application.PeriodId,
        StartDate: this.state.Application.StartDate,
        CampusEntity: campus,
      };

      await this.fillI20Files(apply).then(() => {
        axios.post("apply/saveLog", apply).then((response) => {
          this.props.toggle().then(() => {
            this.resetModal().then(() => {
              if (
                this.props.callback &&
                typeof this.props.callback === "function"
              ) {
                this.props.callback();
              }
            });
          });
        });
      });
    }
  }

  onCheckI20NotRequired = async (e) => {
    const value = e.target.checked;

    let result =
      this.state.Application.I20NotRequired === true ||
      this.state.Application.I20FileList.filter((x) => x.toBeDeleted !== true)
        .length === 0 ||
      (await confirm({
        title: <>Warning</>,
        message:
          "This operation will delete all I-20 files associated with this application. Do you want to proceed?",
      }));

    if (result) {
      let modalState = this.state;
      modalState.Application.I20NotRequired = value;
      this.setState(modalState);
    }
  };

  periodOptions = [
    { value: "M", label: "Morning" },
    { value: "E", label: "Evening" },
    { value: "MT", label: "Mon/Tue" },
    { value: "WT", label: "Wed/Thu" },
  ];
}

export default ApplicationModal;
