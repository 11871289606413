import { BehaviorSubject } from "rxjs";

const subject = new BehaviorSubject();

export const permissionService = {
  setPermission: (jsonObj) => subject.next(jsonObj),
  getPermission: () => subject.asObservable(), //somente é necessário quando há alguma alteração que necessite um observer to subscribe
  get currentPermission() {
    return subject.value;
  },
};

const subjectAuth = new BehaviorSubject();

export const authService = {
  setAuth: (authToken) => subjectAuth.next(authToken),
  getAuth: () => subjectAuth.asObservable(), //somente é necessário quando há alguma alteração que necessite um observer to subscribe
  get currentAuth() {
    return subjectAuth.value;
  },
};
