/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import confirm from "reactstrap-confirm";
import { editMode, FormatFullDateList, FormatDate } from "helpers/Utils.js";

class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: [],
    };
  }

  render() {
    let settings = this.state.settings.map((setting) => {
      return (
        <tr key={setting.id}>
          <td>{setting.name}</td>

          <td>
            {setting.editMode === true && (
              <Input
                className={`form-group`}
                type="text"
                name="settingValue"
                autoComplete="nope"
                value={setting.newValue}
                onChange={this.changeSetting.bind(this, setting.id)}
              />
            )}

            {setting.editMode === false && (
              <Input
                className={`form-group`}
                type="text"
                name="settingValue"
                autoComplete="nope"
                value={setting.value}
                disabled={true}
              />
            )}
          </td>

          <td>
            <Button
              color="info"
              size="sm"
              className="ml-2"
              onClick={this.editSetting.bind(this, setting.id)}
              disabled={setting.editMode === true}
              style={{ opacity: setting.editMode === true ? "0.2" : "" }}
            >
              Edit
            </Button>

            <React.Fragment>
              <Button
                color="success"
                size="sm"
                className="ml-2"
                onClick={this.saveSetting.bind(this, setting.id)}
                disabled={setting.editMode === false}
                style={{ opacity: setting.editMode === false ? "0.2" : "" }}
              >
                Save
              </Button>
              <Button
                color="warning"
                size="sm"
                className="ml-2"
                onClick={this.cancelEditSetting.bind(this, setting.id)}
                disabled={setting.editMode === false}
                style={{ opacity: setting.editMode === false ? "0.2" : "" }}
              >
                Cancel
              </Button>
            </React.Fragment>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader></CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Value</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>{settings}</tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  async changeSetting(id, e) {
    const { value } = e.target;

    let modalState = this.state;

    modalState.settings.find((setting) => setting.id === id).newValue = value;

    this.setState(modalState);
  }

  saveSetting = (id) => {
    let modalState = this.state;

    let currentSetting = modalState.settings.find(
      (setting) => setting.id === id
    );

    let newValue = currentSetting.newValue;
    if (newValue === null || newValue === undefined)
      newValue = currentSetting.value;

    modalState.settings.find((setting) => setting.id === id).value = newValue;

    modalState.settings.find((setting) => setting.id === id).editMode = false;

    this.setState(modalState, () => {
      let setting = {
        Id: id,
        Value: newValue,
      };

      axios.put("settings", setting);
    });
  };

  async cancelEditSetting(id) {
    let modalState = this.state;

    let oldValue = modalState.settings.find(
      (setting) => setting.id === id
    ).value;

    modalState.settings.find((setting) => setting.id === id).newValue =
      oldValue;

    modalState.settings.find((setting) => setting.id === id).editMode = false;

    this.setState(modalState);
  }

  async editSetting(id) {
    let modalState = this.state;

    modalState.settings.find((setting) => setting.id === id).editMode = true;

    this.setState(modalState);
  }

  componentDidMount() {
    this._refreshSettings();
  }

  updateSemester() {
    let semester = {
      Id: this.state.modalState.Semester.Id.Value,
      Name: this.state.modalState.Semester.Name.Value,
      StartDate: this.state.modalState.Semester.StartDate.Value,
      EndDate: this.state.modalState.Semester.EndDate.Value,
    };

    axios.put("semesters", semester).then((response) => {
      this.toggleNewSemesterModal().then(() => {
        this._refreshSemesters().then(() => {
          this.resetModal();
        });
      });
    });
  }

  _refreshSettings = () => {
    return new Promise((resolve, reject) => {
      axios.get("settings/GetAll").then((response) => {
        let mainState = this.state;

        mainState.settings = [];
        response.data.data.map((setting, index) =>
          mainState.settings.push({
            id: setting.id,
            name: setting.name,
            value: setting.value,
            newValue: setting.value,
            editMode: false,
          })
        );

        this.setState(
          {
            mainState: mainState,
          },
          resolve(true)
        );
      });
    });
  };
}

export default Setting;
