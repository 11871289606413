/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import Countries from "Countries.json";
import States from "States.json";
import Select from "react-select";
import Autosuggest from "react-autosuggest";
import classnames from "classnames";
import confirm from "reactstrap-confirm";
import { editMode, FormatDate, FormatFullDateList } from "helpers/Utils.js";
import ClassTeacherView from "components/Classe/Class.teacher.view";
import Address from "components/Address/Address.component";
import SearchInput from "../components/Search/Search.input.component";
import { authenticationService } from "services/Authentication.service.js";
import Can from "../components/Can/Can";
import { SavePdf } from "components/Report/GeneratePdf.js";

class Teacher extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.timeout = 0;
  }

  render() {
    const { value, suggestions, activeTab } = this.state.modalState;

    const { currentUser } = this.state;

    const inputProps = {
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
    };

    let teachers = this.state.mainState.teachers.map((teacher) => {
      return (
        <tr key={teacher.id}>
          {/* <td>{teacher.id}</td> */}

          <td>
            <a
              onClick={this.openModalTeacher.bind(
                this,
                editMode.EDIT,
                teacher.id
              )}
              href="#"
            >
              {teacher.name}
            </a>
          </td>

          <td>{teacher.userEmail}</td>
          <td>{teacher.hireDate}</td>
          <td>
            {/* <Button
              color="success"
              size="sm"
              className="mr-2"
              onClick={this.openModalTeacher.bind(
                this,
                editMode.EDIT,
                teacher.id
              )}
            >
              Edit
            </Button> */}
            {/* <Button
              color="danger"
              size="sm"
              className="mr-2"
              onClick={this.deleteTeacher.bind(this, teacher.id)}
            >
              Delete
            </Button> */}
            <Button
              color="info"
              size="sm"
              onClick={this.toggleClassTeacherViewModal.bind(this, teacher.id)}
            >
              Subjects
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup>
                      <Label>&nbsp;</Label>

                      {currentUser !== null && (
                        <Can
                          feature="Teacher_Add"
                          yes={() => (
                            <Button
                              style={{ margin: "0" }}
                              color="primary"
                              onClick={this.openModalTeacher.bind(
                                this,
                                editMode.INSERT
                              )}
                            >
                              Add teacher
                            </Button>
                          )}
                        ></Can>
                      )}
                    </FormGroup>

                    <SearchInput
                      searchInput={this.state.mainState.searchInput}
                      handleSearchInput={this.handleSearchInput}
                    />
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th>Name</th>
                        <th>Associated User</th>
                        <th>Hire date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>{teachers}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.mainState.currentPage}
                    totalItemsCount={this.state.mainState.totalResults}
                    onChange={this.nextPage.bind(this)}
                    csv={this.DownloadPdf.bind(this)}
                    buttonName="Report"
                  />

                  <Modal
                    isOpen={this.state.mainState.openModal}
                    toggle={this.toggleNewTeacherModal.bind(this)}
                    centered={true}
                    autoFocus={true}
                    size={"lg"}
                    backdrop="static"
                    onClosed={this.onCloseModal}
                  >
                    <ModalHeader toggle={this.toggleNewTeacherModal.bind(this)}>
                      {this.state.modalState.editMode === editMode.INSERT
                        ? "Add a new teacher"
                        : "Edit a teacher"}
                    </ModalHeader>
                    <ModalBody>
                      <div>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "1",
                              })}
                              onClick={() => {
                                this.toggleTabs("1");
                              }}
                            >
                              Basic data
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "2",
                              })}
                              onClick={() => {
                                this.toggleTabs("2");
                              }}
                            >
                              Address
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent
                          activeTab={activeTab}
                          style={{ margin: "1rem 0" }}
                        >
                          <TabPane tabId="1">
                            <Row form>
                              <Col md={8}>
                                <FormGroup>
                                  <Label for="teacherName">Full name</Label>
                                  <Input
                                    className={`form-group ${this.errorClass(
                                      this.state.modalState.Teacher.Name
                                        .ErrorMsg
                                    )}`}
                                    type="text"
                                    name="Name"
                                    id="teacherName"
                                    autoComplete="nope"
                                    value={
                                      this.state.modalState.Teacher.Name.Value
                                    }
                                    onChange={this.handleUserInput}
                                  />
                                  <span className="text-danger">
                                    {
                                      this.state.modalState.Teacher.Name
                                        .ErrorMsg
                                    }
                                  </span>
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="teacherNickName">Nick name</Label>
                                  <Input
                                    className={`form-group ${this.errorClass(
                                      this.state.modalState.Teacher.NickName
                                        .ErrorMsg
                                    )}`}
                                    type="text"
                                    name="NickName"
                                    id="teacherNickName"
                                    autoComplete="nope"
                                    value={
                                      this.state.modalState.Teacher.NickName
                                        .Value
                                    }
                                    onChange={this.handleUserInput}
                                  />
                                  <span className="text-danger">
                                    {
                                      this.state.modalState.Teacher.NickName
                                        .ErrorMsg
                                    }
                                  </span>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="teacherBirthDate">
                                    Birth date
                                  </Label>
                                  <Input
                                    className={`form-group ${this.errorClass(
                                      this.state.modalState.Teacher.BirthDate
                                        .ErrorMsg
                                    )}`}
                                    type="date"
                                    name="BirthDate"
                                    id="teacherBirthDate"
                                    value={
                                      this.state.modalState.Teacher.BirthDate
                                        .Value
                                    }
                                    onChange={this.handleUserInput}
                                  />
                                  <span className="text-danger">
                                    {
                                      this.state.modalState.Teacher.BirthDate
                                        .ErrorMsg
                                    }
                                  </span>
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="teacherGender">Gender</Label>
                                  <Select
                                    className={`form-group ${this.errorClass(
                                      this.state.modalState.Teacher.Gender
                                        .ErrorMsg
                                    )}`}
                                    value={
                                      this.state.modalState.Teacher.Gender
                                        .Value &&
                                      this.options.find(
                                        (x) =>
                                          x.value ===
                                          this.state.modalState.Teacher.Gender
                                            .Value
                                      )
                                    }
                                    onChange={this.handleGenderChange}
                                    options={this.options}
                                  />
                                  <span className="text-danger">
                                    {
                                      this.state.modalState.Teacher.Gender
                                        .ErrorMsg
                                    }
                                  </span>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="teacherNationality">
                                    Nationality
                                  </Label>
                                  <Autosuggest
                                    className={`form-group ${this.errorClass(
                                      this.state.modalState.Teacher
                                        .NationalityId.ErrorMsg
                                    )}`}
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={
                                      this.onSuggestionsFetchRequested
                                    }
                                    onSuggestionsClearRequested={
                                      this.onSuggestionsClearRequested
                                    }
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={this.renderSuggestion}
                                    placeholder="Enter country name"
                                    inputProps={inputProps}
                                    onSuggestionSelected={
                                      this.onSuggestionSelected
                                    }
                                  />
                                  <span className="text-danger">
                                    {
                                      this.state.modalState.Teacher
                                        .NationalityId.ErrorMsg
                                    }
                                  </span>
                                </FormGroup>
                              </Col>
                              <Col md={4}>
                                <FormGroup>
                                  <Label for="teacherHireDate">Hire date</Label>
                                  <Input
                                    className={`form-group ${this.errorClass(
                                      this.state.modalState.Teacher.HireDate
                                        .ErrorMsg
                                    )}`}
                                    type="date"
                                    name="HireDate"
                                    id="teacherHireDate"
                                    value={
                                      this.state.modalState.Teacher.HireDate
                                        .Value
                                    }
                                    onChange={this.handleUserInput}
                                  />
                                  <span className="text-danger">
                                    {
                                      this.state.modalState.Teacher.HireDate
                                        .ErrorMsg
                                    }
                                  </span>
                                </FormGroup>
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId="2">
                            <Address
                              Address={this.state.modalState.Teacher.address}
                              handleAddressInput={this.handleAddressInput}
                              OnCountryChange={this.onCountryChange}
                              OnCountrySuggestionSelected={
                                this.onCountrySuggestionSelected
                              }
                              HandleStateChange={this.handleStateChange}
                              OnBlur={this.onCountryBlur}
                            />
                          </TabPane>
                        </TabContent>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={
                          this.state.modalState.editMode === editMode.INSERT
                            ? this.addTeacher.bind(this)
                            : this.updateTeacher.bind(this)
                        }
                        disabled={!this.state.modalState.formValid}
                      >
                        {this.state.modalState.editMode === editMode.INSERT
                          ? "Add"
                          : "Update"}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.toggleNewTeacherModal.bind(this)}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ClassTeacherView
            teacherId={
              this.state.mainState.selectTeacherIdForClassTeacherViewModal
            }
            isOpen={this.state.mainState.openClassTeacherViewModal}
            toggleClasseModal={this.toggleClassTeacherViewModal.bind(
              this,
              this.state.mainState.selectTeacherIdForClassTeacherViewModal
            )}
          />
        </div>
      </>
    );
  }

  onCountryBlur = (event) => {
    let modalState = this.state;

    this.validateField(
      "CountryId",
      modalState.modalState.Teacher.address.CountryId.Value
    );

    if (modalState.modalState.Teacher.address.State.Value)
      this.validateField(
        "State",
        modalState.modalState.Teacher.address.State.Value
      );
  };

  getInitialState = () => {
    const initialState = {
      mainState: this.getMainState(),
      modalState: this.getModalState(),
      currentUser: null,
    };

    return initialState;
  };

  getMainState = () => {
    const mainState = {
      teachers: [],
      openModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      alert: { visible: false, color: "", message: "" },
      selectTeacherIdForClassTeacherViewModal: "",
      openClassTeacherViewModal: false,
      searchInput: "",
    };

    return mainState;
  };

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getAddressEntity = () => {
    const address = {
      Id: this.getEntity(),
      Street: this.getEntity(),
      CountryId: this.getEntity(),
      City: this.getEntity(),
      Zip: this.getEntity(),
      HomePhone: this.getEntity(),
      CellPhone: this.getEntity(),
      Email: this.getEntity(),
      State: this.getEntity(),
      States: [],
      CountryName: "",
    };

    return address;
  };

  getTeacher = () => {
    const teacher = {
      Id: this.getEntity(),
      Name: this.getEntity(),
      NickName: this.getEntity(),
      HireDate: this.getEntity(),
      BirthDate: this.getEntity(),
      Gender: this.getEntity(),
      NationalityId: this.getEntity(),
      address: this.getAddressEntity(),
    };
    return teacher;
  };

  getModalState = () => {
    const modalState = {
      Teacher: this.getTeacher(),
      editMode: "",
      collapse: 1,
      cards: [1, 2],
      alert: { visible: false, color: "", message: "" },
      formValid: false,
      value: "",
      suggestions: [],
      activeTab: "1",
    };

    return modalState;
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          modalState: this.getModalState(),
        },
        resolve(true)
      );
    });
  };

  handleSearchInput = (e) => {
    const value = e.target.value;

    let { mainState } = this.state;

    mainState.searchInput = value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshTeachers();
    });
  };

  handleGenderChange = (selectedOption) => {
    let { modalState } = this.state;

    modalState.Teacher.Gender.Value = selectedOption.value;

    this.setState({ modalState: modalState }, () => {
      this.validateField("Gender", selectedOption.value);
    });
  };

  toggleClassTeacherViewModal = (teacherId) => {
    return new Promise((resolve, reject) => {
      let { mainState } = this.state;

      mainState.openClassTeacherViewModal =
        !mainState.openClassTeacherViewModal;
      mainState.selectTeacherIdForClassTeacherViewModal = teacherId;

      this.setState({ mainState: mainState }, resolve(true));
    });
  };

  validateField(fieldName, value) {
    let { modalState } = this.state;

    switch (fieldName) {
      case "Name":
        modalState.Teacher.Name.IsValid = value.length >= 1;
        modalState.Teacher.Name.ErrorMsg = modalState.Teacher.Name.IsValid
          ? ""
          : "Name is empty";
        break;
      case "NickName":
        modalState.Teacher.NickName.IsValid = value.length >= 1;
        modalState.Teacher.NickName.ErrorMsg = modalState.Teacher.NickName
          .IsValid
          ? ""
          : "NickName is empty";
        break;
      case "HireDate":
        modalState.Teacher.HireDate.IsValid = value.length >= 1;
        modalState.Teacher.HireDate.ErrorMsg = modalState.Teacher.HireDate
          .IsValid
          ? ""
          : "HireDate is empty";
        break;
      case "BirthDate":
        modalState.Teacher.BirthDate.IsValid = value.length >= 1;
        modalState.Teacher.BirthDate.ErrorMsg = modalState.Teacher.BirthDate
          .IsValid
          ? ""
          : "BirthDate is empty";
        break;
      case "Gender":
        modalState.Teacher.Gender.IsValid = value.length >= 1;
        modalState.Teacher.Gender.ErrorMsg = modalState.Teacher.Gender.IsValid
          ? ""
          : "Gender is empty";
        break;
      case "NationalityId":
        modalState.Teacher.NationalityId.IsValid = value.length >= 1;
        modalState.Teacher.NationalityId.ErrorMsg = modalState.Teacher
          .NationalityId.IsValid
          ? ""
          : "NationalityId is empty";
        break;
      case "Street":
        modalState.Teacher.address.Street.IsValid = value.length >= 1;
        modalState.Teacher.address.Street.ErrorMsg = modalState.Teacher.address
          .Street.IsValid
          ? ""
          : "Street is empty";
        break;
      case "CountryId":
        modalState.Teacher.address.CountryId.IsValid = value.length >= 1;
        modalState.Teacher.address.CountryId.ErrorMsg = modalState.Teacher
          .address.CountryId.IsValid
          ? ""
          : "CountryId is empty";
        break;
      case "City":
        modalState.Teacher.address.City.IsValid = value.length >= 1;
        modalState.Teacher.address.City.ErrorMsg = modalState.Teacher.address
          .City.IsValid
          ? ""
          : "City is empty";
        break;
      case "State":
        modalState.Teacher.address.State.IsValid = value.length >= 1;
        modalState.Teacher.address.State.ErrorMsg = modalState.Teacher.address
          .State.IsValid
          ? ""
          : "State is empty";
        break;
      case "Zip":
        modalState.Teacher.address.Zip.IsValid = value.length >= 1;
        modalState.Teacher.address.Zip.ErrorMsg = modalState.Teacher.address.Zip
          .IsValid
          ? ""
          : "Zip code is empty";
        break;
      case "CellPhone":
        modalState.Teacher.address.CellPhone.IsValid = value.length >= 1;
        modalState.Teacher.address.CellPhone.ErrorMsg = modalState.Teacher
          .address.CellPhone.IsValid
          ? ""
          : "Cell phone is empty";
        break;
      case "Email":
        modalState.Teacher.address.Email.IsValid = value.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        )
          ? true
          : false;
        modalState.Teacher.address.Email.ErrorMsg = modalState.Teacher.address
          .Email.IsValid
          ? ""
          : "Email is invalid";
        break;

      default:
        break;
    }

    this.setState(
      {
        modalState: modalState,
      },
      this.validateForm()
    );
  }

  validateForm() {
    let { modalState } = this.state;

    modalState.formValid =
      modalState.Teacher.Name.IsValid === true &&
      modalState.Teacher.NickName.IsValid === true &&
      modalState.Teacher.HireDate.IsValid === true &&
      modalState.Teacher.Gender.IsValid === true &&
      modalState.Teacher.NationalityId.IsValid === true &&
      modalState.Teacher.BirthDate.IsValid === true &&
      modalState.Teacher.address.Street.IsValid === true &&
      modalState.Teacher.address.CountryId.IsValid === true &&
      modalState.Teacher.address.City.IsValid === true &&
      modalState.Teacher.address.State.IsValid === true &&
      modalState.Teacher.address.Zip.IsValid === true &&
      modalState.Teacher.address.CellPhone.IsValid === true &&
      modalState.Teacher.address.Email.IsValid === true;

    this.setState({
      modalState: modalState,
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { modalState } = this.state;
    modalState.Teacher[name].Value = value;

    this.setState({ modalState: modalState }, () => {
      this.validateField(name, value);
    });
  };

  handleAddressInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { modalState } = this.state;
    modalState.Teacher.address[name].Value = value;

    this.setState({ modalState: modalState }, () => {
      this.validateField(name, value);
    });
  };

  onBlur = (event) => {
    let { modalState } = this.state;
    let value = modalState.Teacher.NationalityId.Value;

    this.validateField("NationalityId", value);
  };

  onChange = (event, { newValue }) => {
    let { modalState } = this.state;

    modalState.value = newValue;
    modalState.Teacher.NationalityId.Value = "";
  };

  onSuggestionsFetchRequested = ({ value }) => {
    let { modalState } = this.state;
    modalState.suggestions = this.getSuggestions(value);
    this.setState({
      modalState: modalState,
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    let { modalState } = this.state;
    modalState.Teacher.NationalityId.Value = suggestion.value;

    this.setState({ modalState: modalState }, () => {
      this.validateField("NationalityId", suggestion.value);
    });
  };

  onSuggestionsClearRequested = () => {
    let { modalState } = this.state;
    modalState.suggestions = [];

    this.setState({
      modalState: modalState,
    });
  };

  componentDidMount() {
    this._refreshTeachers();

    authenticationService.currentUser.subscribe((x) => {
      this.setState({ currentUser: x });
    });
  }

  toggleNewTeacherModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;
      mainState.openModal = !mainState.openModal;
      this.setState(
        {
          mainState: mainState,
        },
        resolve(true)
      );
    });
  };

  addTeacher() {
    let address = {
      Street: this.state.modalState.Teacher.address.Street.Value,
      CountryId: this.state.modalState.Teacher.address.CountryId.Value,
      City: this.state.modalState.Teacher.address.City.Value,
      Zip: this.state.modalState.Teacher.address.Zip.Value,
      HomePhone: this.state.modalState.Teacher.address.HomePhone.Value,
      CellPhone: this.state.modalState.Teacher.address.CellPhone.Value,
      Email: this.state.modalState.Teacher.address.Email.Value,
      State: this.state.modalState.Teacher.address.State.Value,
    };

    let teacher = {
      Name: this.state.modalState.Teacher.Name.Value,
      NickName: this.state.modalState.Teacher.NickName.Value,
      HireDate: this.state.modalState.Teacher.HireDate.Value,
      BirthDate: this.state.modalState.Teacher.BirthDate.Value,
      Gender: this.state.modalState.Teacher.Gender.Value,
      NationalityId: this.state.modalState.Teacher.NationalityId.Value,
      Address: address,
    };

    axios.post("teachers", teacher).then((response) => {
      this.toggleNewTeacherModal().then(() => {
        this._refreshTeachers();
        this.resetModal();
      });
    });
  }

  updateTeacher() {
    let address = {
      Id: this.state.modalState.Teacher.address.Id.Value,
      Street: this.state.modalState.Teacher.address.Street.Value,
      CountryId: this.state.modalState.Teacher.address.CountryId.Value,
      City: this.state.modalState.Teacher.address.City.Value,
      Zip: this.state.modalState.Teacher.address.Zip.Value,
      HomePhone: this.state.modalState.Teacher.address.HomePhone.Value,
      CellPhone: this.state.modalState.Teacher.address.CellPhone.Value,
      Email: this.state.modalState.Teacher.address.Email.Value,
      State: this.state.modalState.Teacher.address.State.Value,
    };

    let teacher = {
      Id: this.state.modalState.Teacher.Id.Value,
      Name: this.state.modalState.Teacher.Name.Value,
      NickName: this.state.modalState.Teacher.NickName.Value,
      HireDate: this.state.modalState.Teacher.HireDate.Value,
      BirthDate: this.state.modalState.Teacher.BirthDate.Value,
      Gender: this.state.modalState.Teacher.Gender.Value,
      NationalityId: this.state.modalState.Teacher.NationalityId.Value,
      Address: address,
    };

    axios.put("teachers", teacher).then((response) => {
      this.toggleNewTeacherModal().then(() => {
        this._refreshTeachers();
        this.resetModal();
      });
    });
  }

  openModalTeacher(mode, id) {
    let { modalState } = this.state;
    modalState.editMode = mode;

    this.setState({ modalState: modalState });

    if (mode === editMode.INSERT) {
      this.toggleNewTeacherModal();
    } else {
      axios.get("teachers/byid?id=" + id).then((response) => {
        modalState.Teacher.Id.Value = response.data.data.id;

        modalState.Teacher.Name.Value = response.data.data.name;
        modalState.Teacher.Name.IsValid = true;

        modalState.Teacher.NickName.Value = response.data.data.nickName;
        modalState.Teacher.NickName.IsValid = true;

        modalState.Teacher.BirthDate.Value = FormatDate(
          response.data.data.birthDate
        );
        modalState.Teacher.BirthDate.IsValid = true;

        modalState.Teacher.Gender.Value = response.data.data.gender;
        modalState.Teacher.Gender.IsValid = true;

        if (response.data.data.nationalityId) {
          modalState.Teacher.NationalityId.Value =
            response.data.data.nationalityId;
          modalState.Teacher.NationalityId.IsValid = true;
        }

        modalState.value =
          modalState.Teacher.NationalityId.Value &&
          Countries.find(
            (x) => x.value === modalState.Teacher.NationalityId.Value
          ).label;

        modalState.Teacher.HireDate.Value = FormatDate(
          response.data.data.hireDate
        );
        modalState.Teacher.HireDate.IsValid = true;

        if (response.data.data.address) {
          modalState.Teacher.address.Id.Value = response.data.data.address.id;
          modalState.Teacher.address.Id.IsValid = true;

          modalState.Teacher.address.Street.Value =
            response.data.data.address.street;
          modalState.Teacher.address.Street.IsValid = true;

          modalState.Teacher.address.City.Value =
            response.data.data.address.city;
          modalState.Teacher.address.City.IsValid = true;

          modalState.Teacher.address.State.Value =
            response.data.data.address.state;
          modalState.Teacher.address.State.IsValid = true;

          modalState.Teacher.address.Zip.Value = response.data.data.address.zip;
          modalState.Teacher.address.Zip.IsValid = true;

          modalState.Teacher.address.CountryId.Value =
            response.data.data.address.countryId;
          modalState.Teacher.address.CountryId.IsValid = true;

          modalState.Teacher.address.CountryName =
            modalState.Teacher.address.CountryId.Value &&
            Countries.find(
              (x) => x.value === modalState.Teacher.address.CountryId.Value
            ).label;

          modalState.Teacher.address.HomePhone.Value =
            response.data.data.address.homePhone;
          modalState.Teacher.address.HomePhone.IsValid = true;

          modalState.Teacher.address.CellPhone.Value =
            response.data.data.address.cellPhone;
          modalState.Teacher.address.CellPhone.IsValid = true;

          modalState.Teacher.address.Email.Value =
            response.data.data.address.email;
          modalState.Teacher.address.Email.IsValid = true;

          States.filter(
            (e) => e.value === response.data.data.address.countryId
          ).map((state, index) =>
            modalState.Teacher.address.States.push({
              label: state.label,
              value: state.label,
            })
          );
        }

        this.validateForm();

        this.setState(
          {
            modalState: modalState,
          },
          () => {
            this.toggleNewTeacherModal();
          }
        );
      });
    }
  }

  async deleteTeacher(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Are you sure you wish to delete this item?",
    });

    if (result) {
      axios.delete("teachers/" + id).then(() => {
        this._refreshTeachers();
      });
    }
  }

  _refreshTeachers() {
    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      return new Promise((resolve, reject) => {
        let page = this.state.mainState.currentPage;
        let itemsPerPage = this.state.mainState.itemsPerPage;
        let search = this.state.mainState.searchInput;

        axios
          .get(
            "teachers?search=" +
              search +
              "&page=" +
              page +
              "&pageSize=" +
              itemsPerPage
          )
          .then((response) => {
            let { mainState } = this.state;

            mainState.totalResults = response.data.data.totalCount;

            mainState.teachers = [];
            response.data.data.items.map((teacher, index) =>
              mainState.teachers.push({
                id: teacher.id,
                name: teacher.name,
                nickName: teacher.nickName,
                birthDate: teacher.birthDate,
                gender: teacher.gender,
                nationalityId: teacher.nationalityId,
                hireDate: FormatFullDateList(teacher.hireDate),
                userEmail: teacher.userEmail,
              })
            );

            this.setState(
              {
                mainState: mainState,
              },
              resolve(true)
            );
          });
      });
    }, 500);
  }

  options = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
  ];

  toggleTabs(tab) {
    let { modalState } = this.state;

    if (modalState.activeTab !== tab) modalState.activeTab = tab;

    this.setState({
      modalState: modalState,
    });
  }

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  setCurrentPage(pageNumber) {
    let { mainState } = this.state;
    mainState.currentPage = pageNumber;
    this.setState({ mainState: mainState });
  }

  nextPage(pageNumber) {
    let itemsPerPage = this.state.mainState.itemsPerPage;
    let search = this.state.mainState.searchInput;

    axios
      .get(
        "teachers?search=" +
          search +
          "&page=" +
          pageNumber +
          "&pageSize=" +
          itemsPerPage
      )
      .then((response) => {
        let { mainState } = this.state;

        mainState.teachers = [];
        response.data.data.items.map((teacher, index) =>
          mainState.teachers.push({
            id: teacher.id,
            name: teacher.name,
            nickName: teacher.nickName,
            birthDate: teacher.birthDate,
            gender: teacher.gender,
            nationalityId: teacher.nationalityId,
            hireDate: FormatFullDateList(teacher.hireDate),
            userEmail: teacher.userEmail,
          })
        );

        mainState.currentPage = pageNumber;
        mainState.totalResults = response.data.data.totalCount;

        this.setState({
          mainState: mainState,
        });
      });
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  getSuggestions(value) {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp("^" + escapedValue, "i");

    return Countries.filter((language) => regex.test(language.label));
  }

  getSuggestionValue(suggestion) {
    return suggestion.label;
  }

  renderSuggestion(suggestion) {
    let className = "";
    if (suggestion.value)
      className = "flag flag-" + suggestion.value.toString().toLowerCase();

    return (
      <div>
        <div className={className}></div> <span>{suggestion.label}</span>
      </div>
    );
  }

  onCountryChange = (event, newValue) => {
    let { modalState } = this.state;

    modalState.Teacher.address.CountryId.Value = "";
    modalState.Teacher.address.State.Value = "";
    modalState.Teacher.address.States = [];
    modalState.Teacher.address.CountryName = newValue;

    this.setState({
      modalState: modalState,
    });
  };

  onCountrySuggestionSelected = (event, { suggestion }) => {
    let { modalState } = this.state;

    modalState.Teacher.address.CountryId.Value = suggestion.value;

    modalState.Teacher.address.States = [];
    States.filter((e) => e.value === suggestion.value).map((state, index) =>
      modalState.Teacher.address.States.push({
        label: state.label,
        value: state.label,
      })
    );

    this.setState({
      modalState: modalState,
    });
  };

  handleStateChange = (selectedOption) => {
    let { modalState } = this.state;
    modalState.Teacher.address.State.Value = selectedOption.label;
    this.setState(
      {
        modalState: modalState,
      },
      () => {
        this.validateField("State", selectedOption.value);
      }
    );
  };

  DownloadPdf() {
    axios.get("teachers/getWithSubjects").then((response) => {
      let teachers = [];
      response.data.data.map((teacher, index) => {
        teachers.push({
          name: teacher.name,
          subjects: "",
        });

        teacher.classes.map((classe, index) =>
          teachers.push({
            name: "",
            subjects: classe.code,
          })
        );
      });

      SavePdf("Teachers", teachers, [
        {
          name: "Teacher",
          subjects: "Subjects",
        },
      ]);
    });
  }
}

export default Teacher;
