/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  CustomInput,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import axios from "axios";
import Pagination from "components/Pagination/Pagination.js";
import confirm from "reactstrap-confirm";
import { editMode } from "helpers/Utils.js";
import { authenticationService } from "services/Authentication.service.js";
import SearchInput from "../components/Search/Search.input.component";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.timeout = 0;
  }

  render() {
    let users = this.state.mainState.users.map((user) => {
      return (
        <tr key={user.id}>
          <td>
            <a
              onClick={this.openModalUser.bind(this, editMode.EDIT, user.id)}
              href="#"
            >
              {user.name}
            </a>
          </td>

          <td>{user.fullName}</td>

          <td>
            {user.campuses.map((campus, index) => {
              return (
                campus.name + (user.campuses.length - 1 === index ? "" : " / ")
              );
            })}
          </td>

          <td>
            {user.roles.map((role, index) => {
              return role.name + (user.roles.length - 1 === index ? "" : " / ");
            })}
          </td>
          <td>{user.active === true ? "True" : "False"}</td>

          <td>
            <Button
              color="danger"
              size="sm"
              className="mr-2"
              onClick={this.deleteUser.bind(this, user.id)}
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });

    let campuses = this.state.modalState.User.Campuses.map((campus, index) => {
      return (
        <ListGroupItem key={index}>
          <CustomInput
            type="switch"
            id={campus.id}
            name={campus.id}
            label={campus.name}
            checked={campus.checked}
            onChange={this.onSwitchCampusChange}
          />
        </ListGroupItem>
      );
    });

    let roles = this.state.modalState.User.Roles.map((role, index) => {
      return (
        <ListGroupItem key={index}>
          <CustomInput
            type="switch"
            id={role.id}
            name={role.id}
            label={role.name}
            checked={role.checked}
            onChange={this.onSwitchRoleChange}
          />
        </ListGroupItem>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup>
                      <Label>&nbsp;</Label>
                      <Button
                        style={{ margin: "0" }}
                        color="primary"
                        onClick={this.openModalUser.bind(this, editMode.INSERT)}
                      >
                        Add user
                      </Button>
                    </FormGroup>

                    <SearchInput
                      searchInput={this.state.mainState.searchInput}
                      handleSearchInput={this.handleSearchInput}
                    />
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>E-mail</th>
                        <th>Name</th>
                        <th>Campus</th>
                        <th>Role</th>
                        <th>Active</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>{users}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.mainState.currentPage}
                    totalItemsCount={this.state.mainState.totalResults}
                    onChange={this._refreshUsers.bind(this)}
                  />

                  <Modal
                    isOpen={this.state.mainState.openModal}
                    toggle={this.toggleNewUserModal.bind(this)}
                    centered={true}
                    autoFocus={true}
                    backdrop="static"
                    onClosed={this.onCloseModal}
                    size={"lg"}
                    scrollable
                  >
                    <ModalHeader toggle={this.toggleNewUserModal.bind(this)}>
                      {this.state.modalState.editMode === editMode.INSERT
                        ? "Add a new user"
                        : "Edit a user"}
                    </ModalHeader>
                    <ModalBody>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="userName">Name</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.User.FullName.ErrorMsg
                              )}`}
                              type="text"
                              name="FullName"
                              id="fullName"
                              autoComplete="nope"
                              value={this.state.modalState.User.FullName.Value}
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.User.FullName.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="userName">E-mail</Label>
                            <Input
                              className={`form-group ${this.errorClass(
                                this.state.modalState.User.Name.ErrorMsg
                              )}`}
                              type="text"
                              name="Name"
                              id="userName"
                              autoComplete="nope"
                              value={this.state.modalState.User.Name.Value}
                              onChange={this.handleUserInput}
                            />
                            <span className="text-danger">
                              {this.state.modalState.User.Name.ErrorMsg}
                            </span>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label>New password</Label>
                            <Input
                              type="text"
                              name="password"
                              id="password"
                              autoComplete="nope"
                              value={this.state.modalState.User.password.Value}
                              onChange={this.handleUserInput}
                              placeHolder="Leave blank for default password"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label>Confirm new password</Label>
                            <Input
                              type="text"
                              name="confirmPassword"
                              id="confirmPassword"
                              autoComplete="nope"
                              value={
                                this.state.modalState.User.confirmPassword.Value
                              }
                              onChange={this.handleUserInput}
                              placeHolder="Leave blank for default password"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={2}>
                          <FormGroup>
                            <div class="span3">
                              <label for="checkbox" class="checkbox">
                                <CustomInput
                                  style={{ marginTop: "15px" }}
                                  className="activeCheckBox"
                                  type="checkbox"
                                  id="activeCheckBox"
                                  name="activeCheckBox"
                                  label="Active"
                                  checked={this.state.modalState.User.Active}
                                  onChange={this.onCheckActiveChange.bind(this)}
                                />
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="userCampus">Campuses</Label>
                            <ListGroup>{campuses}</ListGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="userRole">Roles</Label>
                            <ListGroup>{roles}</ListGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      {this.state.modalState.editMode !== editMode.VIEW && (
                        <Button
                          color="primary"
                          onClick={
                            this.state.modalState.editMode === editMode.INSERT
                              ? this.addUser.bind(this)
                              : this.updateUser.bind(this)
                          }
                          disabled={
                            !this.state.modalState.formValid ||
                            !this.validPassword()
                          }
                        >
                          {this.state.modalState.editMode === editMode.INSERT
                            ? "Add"
                            : "Update"}
                        </Button>
                      )}

                      <Button
                        color="secondary"
                        onClick={this.toggleNewUserModal.bind(this)}
                      >
                        {this.state.modalState.editMode === editMode.VIEW
                          ? "Close"
                          : "Cancel"}
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  validPassword() {
    return (
      this.state.modalState.User.password.Value ===
      this.state.modalState.User.confirmPassword.Value
    );
  }

  getInitialState = () => {
    const initialState = {
      mainState: this.getMainState(),
      modalState: this.getModalState(),
      currentUser: null,
    };

    return initialState;
  };

  getMainState = () => {
    const mainState = {
      users: [],
      openModal: false,
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      alert: { visible: false, color: "", message: "" },
      searchInput: "",
    };

    return mainState;
  };

  getEntity = () => {
    const entity = {
      Value: "",
      IsValid: false,
      ErrorMsg: "",
    };
    return entity;
  };

  getUser = () => {
    const user = {
      Id: this.getEntity(),
      Name: this.getEntity(),
      FullName: this.getEntity(),
      Campuses: [],
      Roles: [],
      Active: false,
      password: this.getEntity(),
      confirmPassword: this.getEntity(),
    };
    return user;
  };

  getModalState = () => {
    const modalState = {
      User: this.getUser(),
      editMode: "",
      cards: [1, 2],
      alert: { visible: false, color: "", message: "" },
      formValid: false,
      value: "",
      roles: [],
    };

    return modalState;
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          modalState: this.getModalState(),
        },
        resolve(true)
      );
    });
  };

  onSwitchCampusChange = (e) => {
    let { modalState } = this.state;

    modalState.User.Campuses.find((x) => x.id === e.target.id).checked =
      e.target.checked;

    this.setState({ modalState: modalState });
  };

  onSwitchRoleChange = (e) => {
    let { modalState } = this.state;

    modalState.User.Roles.find((x) => x.id === e.target.id).checked =
      e.target.checked;

    this.setState({ modalState: modalState });
  };

  onCheckActiveChange = (e) => {
    let { modalState } = this.state;

    modalState.User.Active = e.target.checked;

    this.setState({ modalState: modalState });
  };

  validateField(fieldName, value) {
    let { modalState } = this.state;

    switch (fieldName) {
      case "Name":
        modalState.User.Name.IsValid = value.match(
          /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        )
          ? true
          : false;
        modalState.User.Name.ErrorMsg = modalState.User.Name.IsValid
          ? ""
          : "E-mail is invalid";
        break;

      case "FullName":
        modalState.User.FullName.IsValid = value.length >= 1;
        modalState.User.FullName.ErrorMsg = modalState.User.FullName.IsValid
          ? ""
          : "Name is empty";
        break;

      default:
        break;
    }

    this.setState(
      {
        modalState: modalState,
      },
      this.validateForm()
    );
  }

  validateForm() {
    let { modalState } = this.state;

    modalState.formValid =
      modalState.User.Name.IsValid === true &&
      modalState.User.FullName.IsValid === true;

    this.setState({
      modalState: modalState,
    });
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let { modalState } = this.state;
    modalState.User[name].Value = value;

    this.setState({ modalState: modalState }, () => {
      this.validateField(name, value);
    });
  };

  componentDidMount() {
    this._refreshUsers();

    authenticationService.currentUser.subscribe((x) => {
      this.setState({ currentUser: x });
    });
  }

  toggleNewUserModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state.mainState;
      mainState.openModal = !mainState.openModal;
      this.setState(
        {
          mainState: mainState,
        },
        resolve(true)
      );
    });
  };

  addUser() {
    let campuses = [];
    this.state.modalState.User.Campuses.filter((x) => x.checked === true).map(
      (campus, index) =>
        campuses.push({
          Id: campus.id,
        })
    );

    let roles = [];
    this.state.modalState.User.Roles.filter((x) => x.checked === true).map(
      (role, index) =>
        roles.push({
          Id: role.id,
        })
    );

    let user = {
      Name: this.state.modalState.User.Name.Value,
      FullName: this.state.modalState.User.FullName.Value,
      Active: this.state.modalState.User.Active,
      Campuses: campuses,
      Roles: roles,
      Password: this.state.modalState.User.password.Value,
    };

    axios.post("users", user).then((response) => {
      this.toggleNewUserModal().then(() => {
        this._refreshUsers().then(() => {
          this.resetModal();
        });
      });
    });
  }

  updateUser() {
    let campuses = [];
    this.state.modalState.User.Campuses.filter((x) => x.checked === true).map(
      (campus, index) =>
        campuses.push({
          Id: campus.id,
        })
    );

    let roles = [];
    this.state.modalState.User.Roles.filter((x) => x.checked === true).map(
      (role, index) =>
        roles.push({
          Id: role.id,
        })
    );

    let user = {
      Id: this.state.modalState.User.Id.Value,
      Name: this.state.modalState.User.Name.Value,
      FullName: this.state.modalState.User.FullName.Value,
      Active: this.state.modalState.User.Active,
      Campuses: campuses,
      Roles: roles,
      Password: this.state.modalState.User.password.Value,
    };

    axios.put("users", user).then((response) => {
      this.resetModal().then(() => {
        this.toggleNewUserModal().then(() => {
          this._refreshUsers();
        });
      });
    });
  }

  refreshRoles = () => {
    return new Promise((resolve, reject) => {
      let { modalState } = this.state;

      this.props.tables.roles.map((role, index) =>
        modalState.User.Roles.push({
          id: role.id,
          name: role.name,
          checked: false,
        })
      );

      this.setState(
        {
          modalState: modalState,
        },
        resolve(true)
      );
    });
  };

  refreshCampuses = () => {
    return new Promise((resolve, reject) => {
      let { modalState } = this.state;

      this.props.tables.campuses.map((campus, index) =>
        modalState.User.Campuses.push({
          id: campus.id,
          name: campus.name,
          checked: false,
        })
      );

      this.setState(
        {
          modalState: modalState,
        },
        resolve(true)
      );
    });
  };

  openModalUser(mode, id) {
    let { modalState } = this.state;
    modalState.editMode = mode;

    this.setState({ modalState: modalState });

    this.refreshRoles().then(() => {
      this.refreshCampuses().then(() => {
        if (mode === editMode.INSERT) {
          this.toggleNewUserModal();
        } else {
          axios.get("users/byid?id=" + id).then((response) => {
            modalState.User.Id.Value = response.data.data.id;

            modalState.User.Name.Value = response.data.data.name;
            modalState.User.Name.IsValid = true;

            this.validateField("Name", modalState.User.Name.Value);

            modalState.User.FullName.Value = response.data.data.fullName || "";
            modalState.User.FullName.IsValid = true;

            this.validateField("FullName", modalState.User.FullName.Value);

            response.data.data.campuses.forEach((campus) => {
              modalState.User.Campuses.find(
                (x) => x.id === campus.id
              ).checked = true;
            });

            response.data.data.roles.forEach((role) => {
              modalState.User.Roles.find(
                (x) => x.id === role.id
              ).checked = true;
            });

            modalState.User.Active = response.data.data.active;

            this.validateForm();

            this.setState(
              {
                modalState: modalState,
              },
              () => {
                this.toggleNewUserModal();
              }
            );
          });
        }
      });
    });
  }

  async deleteUser(id) {
    let result = await confirm({
      title: <>Warning</>,
      message: "Are you sure you wish to delete this user?",
    });

    if (result) {
      axios.delete("users/" + id).then(() => {
        this._refreshUsers();
      });
    }
  }

  _refreshUsers = (pageNumber) => {
    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      return new Promise((resolve, reject) => {
        let page = pageNumber || 1;
        let itemsPerPage = this.state.mainState.itemsPerPage;
        let search = this.state.mainState.searchInput;

        axios
          .get(
            "users?search=" +
              search +
              "&page=" +
              page +
              "&pageSize=" +
              itemsPerPage
          )
          .then((response) => {
            let { mainState } = this.state;

            mainState.users = [];
            response.data.data.items.map((user, index) =>
              mainState.users.push({
                id: user.id,
                name: user.name,
                fullName: user.fullName,
                active: user.active,
                roles: user.roles,
                campuses: user.campuses,
              })
            );

            mainState.totalResults = response.data.data.totalCount;
            mainState.currentPage = pageNumber;

            this.setState(
              {
                mainState: mainState,
              },
              resolve(true)
            );
          });
      });
    }, 500);
  };

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  setCurrentPage(pageNumber) {
    let { mainState } = this.state;
    mainState.currentPage = pageNumber;
    this.setState({ mainState: mainState });
  }

  handleSearchInput = (e) => {
    const value = e.target.value;

    let { mainState } = this.state;

    mainState.searchInput = value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshUsers();
    });
  };
}

export default User;
