/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  CardHeader,
  FormGroup,
  Label,
  Badge,
} from "reactstrap";
import axios from "axios";
import { editMode } from "helpers/Utils.js";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";
import * as dateFns from "date-fns";
import { SavePdf } from "components/Report/GeneratePdf.js";
import Pagination from "components/Pagination/Pagination.js";
import DatePicker from "react-datepicker";
import Select from "react-select";

class TokenReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      students: [],
      openModal: false,
      StartDateSearch: dateFns.startOfYear(new Date()),
      EndDateSearch: dateFns.endOfWeek(new Date()),
      studentId: "",
      tokenStatusId: "all",
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
    };
  };

  render() {
    let students = this.state.students.map((student, index) => {
      return (
        <tr key={index}>
          <td>
            <a
              onClick={this.openStudentEditModal.bind(this, student.id)}
              href="#"
            >
              {student.name}
            </a>
          </td>

          <td>{student.token}</td>
          <td>{student.i20}</td>
          <td>{student.level}</td>
          <td>{student.campus}</td>

          <td>
            {dateFns.format(student.createdDate, "MM/dd/yyyy hh:mm:ss aa")}
          </td>

          <td>
            {dateFns.format(student.expirationDate, "MM/dd/yyyy hh:mm:ss aa")}
          </td>

          <td>
            {student.signatureDate &&
              dateFns.format(student.signatureDate, "MM/dd/yyyy hh:mm:ss aa")}
          </td>

          <td>{student.userName}</td>

          <td>
            <Badge
              style={{ fontSize: "medium" }}
              color={
                student.tokenStatus === 1
                  ? "success"
                  : student.tokenStatus === 2
                  ? "danger"
                  : "info"
              }
            >
              {student.tokenStatus === 1
                ? "Signed"
                : student.tokenStatus === 2
                ? "Expired"
                : "Pending"}
            </Badge>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.EndDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "EndDateSearch"
                        )}
                        name="EndDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Status</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.tokenStatusId &&
                            this.tokenStatusOptions.find(
                              (x) => x.value === this.state.tokenStatusId
                            )
                          }
                          onChange={this.handleTokenStatusChange}
                          options={this.tokenStatusOptions}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Token</th>
                        <th>I-20</th>
                        <th>Level</th>
                        <th>Campus</th>
                        <th>Created Date</th>
                        <th>Expiration Date</th>
                        <th>Signature Date</th>
                        <th>User</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    <tbody>{students}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.currentPage}
                    totalItemsCount={this.state.totalResults}
                    onChange={this._refreshStudents.bind(this)}
                    pdf={this.DownloadPdf.bind(this, "pdf")}
                    csv={this.DownloadPdf.bind(this, "csv")}
                    buttonName="Export to CSV"
                    pdfButtonName="Export to PDF"
                  />

                  <StudentEdit2
                    isOpen={this.state.openModal}
                    toggleStudentModal={this.toggleStudentModal.bind(this)}
                    editMode={editMode.EDIT}
                    id={this.state.studentId}
                    callback={this._refreshStudents.bind(this)}
                    tables={this.props.tables}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  handleTokenStatusChange = (selectedOption) => {
    let mainState = this.state;

    mainState.tokenStatusId = selectedOption.value;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshStudents();
    });
  };

  componentDidMount() {
    this._refreshStudents();
  }

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;

    mainState[name] = date;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshStudents();
    });
  };

  toggleStudentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openModal = !mainState.openModal;
      this.setState(mainState, resolve(true));
    });
  };

  openStudentEditModal = (id) => {
    this.toggleStudentModal();
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.studentId = id;
      this.setState(mainState, resolve(true));
    });
  };

  _refreshStudents(pageNumber) {
    let page = pageNumber || 1;
    let itemsPerPage = this.state.itemsPerPage;
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");
    let tokenStatusId = this.state.tokenStatusId;

    if (tokenStatusId === "all") tokenStatusId = "";

    return new Promise((resolve, reject) => {
      axios
        .get(
          "dashboard/TokenReport?" +
            "startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&tokenStatus=" +
            tokenStatusId +
            "&page=" +
            page +
            "&pageSize=" +
            itemsPerPage
        )
        .then((response) => {
          let mainState = this.state;

          mainState.students = [];
          response.data.data.items.map((student, index) =>
            mainState.students.push({
              id: student.id,
              name: student.name,
              token: student.token,
              userName:
                student.userName &&
                student.userName.slice(0, student.userName.lastIndexOf("@")),
              expirationDate: dateFns.parseISO(student.expirationDate),
              signatureDate:
                student.signatureDate &&
                dateFns.parseISO(student.signatureDate),
              createdDate: dateFns.parseISO(student.createdDate),
              tokenStatus: student.tokenStatus,
              i20: student.i20,
              campus: student.campusName,
              level: student.level,
            })
          );

          mainState.currentPage = pageNumber;
          mainState.totalResults = response.data.data.totalCount;

          this.setState(mainState, resolve(true));
        });
    });
  }

  DownloadPdf(type) {
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");
    let tokenStatusId = this.state.tokenStatusId;

    if (tokenStatusId === "all") tokenStatusId = "";

    axios
      .get(
        "dashboard/TokenReport?" +
          "startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&tokenStatus=" +
          tokenStatusId +
          "&page=1&pageSize=9999999"
      )
      .then((response) => {
        let students = [];
        response.data.data.items.map((student, index) =>
          students.push({
            id: student.id,
            name: student.name,
            token: student.token,
            userName: student.userName,
            expirationDate: dateFns.format(
              dateFns.parseISO(student.expirationDate),
              "MM/dd/yyyy hh:mm:ss aa"
            ),
            signatureDate:
              student.signatureDate &&
              dateFns.format(
                dateFns.parseISO(student.signatureDate),
                "MM/dd/yyyy hh:mm:ss aa"
              ),
            createdDate: dateFns.format(
              dateFns.parseISO(student.createdDate),
              "MM/dd/yyyy hh:mm:ss aa"
            ),
            tokenStatus:
              student.tokenStatus === 1
                ? "Signed"
                : student.tokenStatus === 2
                ? "Expired"
                : "Pending",
            i20: student.i20,
            campus: student.campusName,
            level: student.level,
          })
        );

        if (type === "pdf") {
          SavePdf("Tokens", students, [
            {
              name: "Name",
              token: "Token",
              i20: "I-20",
              campus: "Campus",
              level: "Level",
              createdDate: "Created Date",
              expirationDate: "Expiration Date",
              signatureDate: "Signature Date",
              userName: "User",
              tokenStatus: "Status",
            },
          ]);
        } else {
          let studentCsv = [];

          students.forEach((item) => {
            studentCsv.push({
              Name: item.name,
              Token: item.token,
              I20: item.i20,
              Campus: item.campus,
              Level: item.level,
              CreatedDate: item.createdDate,
              ExpirationDate: item.expirationDate,
              SignatureDate: item.signatureDate,
              User: item.userName,
              Status: item.tokenStatus,
            });
          });

          this.downloadCSVFromJson("Tokens.csv", studentCsv);
        }
      });
  }

  downloadCSVFromJson = (filename, arrayOfJson) => {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  tokenStatusOptions = [
    { value: "all", label: "All" },
    { value: "1", label: "Signed" },
    { value: "2", label: "Expired" },
    { value: "3", label: "Pending" },
  ];
}

export default TokenReport;
