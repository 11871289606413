import Dashboard from "views/Dashboard.jsx";
import AssignmentType from "views/AssignmentType.jsx";
import Assignment from "views/Assignment.jsx";
import Attendance from "views/Attendance.jsx";
import Campus from "views/Campus.jsx";
import Classe from "views/Classe.jsx";
import Course from "views/Course.jsx";
import Semester from "views/Semester.jsx";
import Holiday from "views/Holiday.jsx";
import Partner from "views/Partner.jsx";
import Student from "views/Student.jsx";
import Teacher from "views/Teacher.jsx";
import User from "views/User.jsx";
import Message from "views/Message.jsx";
import Tasks from "./views/Tasks";
import Setting from "./views/Setting";
import LessonPlan from "./views/LessonPlan";
import LevelChangeReport from "./components/Report/LevelChange";
import FailingStudentsReport from "./components/Report/FailingStudentsReport";
import ExpiringPassports from "./components/Report/ExpiringPassports";
import MissingSelfEnrollment from "./components/Report/MissingSelfEnrollment";
import TransferOut from "./components/Report/TransferOut";
import HRSSNLetter from "./components/Report/HRSSNLetter";
import BlockedStudents from "./components/Report/BlockedStudents";
import TokenReport from "./components/Report/TokenReport";
import ApplicationReport from "./components/Report/ApplicationReport";
import ReferralReport from "./components/Report/ReferralReport";
import SelfEnrollmentReport from "./components/Report/SelfEnrollmentReport";
import MapReport from "./components/Report/MapReport";
import AbsencesReport from "./components/Report/AbsencesReport";
import TestRetakeReport from "./components/Report/TestRetakeReport";
import TeachingStatus from "./components/Report/TeachingStatus";
import FinanceReport from "./components/Report/FinanceReport";
import Role from "./views/Role";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-bar-32",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/assignment",
    name: "Assignment",
    icon: "nc-icon nc-single-copy-04",
    component: Assignment,
    layout: "/admin",
  },
  {
    path: "/attendance",
    name: "Attendance",
    icon: "nc-icon nc-time-alarm",
    component: Attendance,
    layout: "/admin",
  },

  {
    path: "/message",
    name: "Message",
    icon: "nc-icon nc-email-85",
    component: Message,
    layout: "/admin",
  },
  {
    path: "/lessonplan",
    name: "Lesson Plan",
    icon: "nc-icon nc-bullet-list-67",
    component: LessonPlan,
    layout: "/admin",
  },
  {
    path: "/map",
    name: "Map",
    icon: "nc-icon nc-chart-bar-32",
    component: Map,
    layout: "/admin",
  },
  {
    name: "Report",
    icon: "fas fa-file-pdf",
    layout: "menu",
    path: ":report",
    children: [
      {
        path: "/absencesreport",
        name: "Absences",
        component: AbsencesReport,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/applicationreport",
        name: "Application",
        component: ApplicationReport,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/BlockedStudentsReport",
        name: "Blocked Students",
        component: BlockedStudents,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/expiringpassports",
        name: "Expiring Passports",
        component: ExpiringPassports,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/failingStudents",
        name: "Failing Students",
        component: FailingStudentsReport,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/FinanceReport",
        name: "Finance",
        component: FinanceReport,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/HRSSNLetter",
        name: "HR SSN Letter",
        component: HRSSNLetter,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/levelchange",
        name: "Level Change",
        component: LevelChangeReport,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/mapreport",
        name: "Map",
        component: MapReport,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/missingselfenrollmentreport",
        name: "Missing Self-Enr.",
        component: MissingSelfEnrollment,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/referralreport",
        name: "Referral",
        component: ReferralReport,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/selfenrollmentreport",
        name: "Self-Enrollment",
        component: SelfEnrollmentReport,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/student",
        name: "Student",
        component: Student,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/teachingstatus",
        name: "Teaching Status",
        component: TeachingStatus,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/testretakereport",
        name: "Missed Test Requests",
        component: TestRetakeReport,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/tokenreport",
        name: "Token",
        component: TokenReport,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
      {
        path: "/transferoutreport",
        name: "Transfer Out",
        component: TransferOut,
        layout: "/admin",
        icon: "fas fa-file-pdf",
      },
    ],
  },
  {
    name: "Administer",
    icon: "nc-icon nc-single-02",
    layout: "menu",
    path: ":administer",
    children: [
      {
        path: "/assigntype",
        name: "Assignment Type",
        icon: "nc-icon nc-tile-56",
        component: AssignmentType,
        layout: "/admin",
      },
      {
        path: "/campus",
        name: "Campus",
        icon: "nc-icon nc-bank",
        component: Campus,
        layout: "/admin",
      },
      {
        path: "/level",
        name: "Level",
        icon: "nc-icon nc-paper",
        component: Course,
        layout: "/admin",
      },
      {
        path: "/holiday",
        name: "Holiday",
        icon: "nc-icon nc-calendar-60",
        component: Holiday,
        layout: "/admin",
      },
      {
        path: "/partner",
        name: "Partner",
        icon: "nc-icon nc-calendar-60",
        component: Partner,
        layout: "/admin",
      },
      {
        path: "/role",
        name: "Role",
        icon: "nc-icon nc-settings",
        component: Role,
        layout: "/admin",
      },
      {
        path: "/semester",
        name: "Semester",
        icon: "nc-icon nc-calendar-60",
        component: Semester,
        layout: "/admin",
      },
      {
        path: "/setting",
        name: "Settings",
        icon: "nc-icon nc-settings",
        component: Setting,
        layout: "/admin",
      },
      {
        path: "/subject",
        name: "Subject",
        icon: "nc-icon nc-globe",
        component: Classe,
        layout: "/admin",
      },
      {
        path: "/tasks",
        name: "Tasks",
        icon: "nc-icon nc-settings",
        component: Tasks,
        layout: "/admin",
      },
      {
        path: "/teacher",
        name: "Teacher",
        icon: "nc-icon nc-hat-3",
        component: Teacher,
        layout: "/admin",
      },
      {
        path: "/user",
        name: "User",
        icon: "nc-icon nc-lock-circle-open",
        component: User,
        layout: "/admin",
      },
    ],
  },
];
export default routes;
