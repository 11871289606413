import React from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar">
      <div className="filler" style={{ width: `${progress}%` }}>
        <span className="progress-label">{`${progress.toFixed(2)}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
