/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import { FormatFullDateList, editMode } from "helpers/Utils.js";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";

class RenewI20 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      students: [],
      openStudentModal: false,
      openMultipleMessageModal: false,
      studentId: "",
    };
  }

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          students: [],
          openStudentModal: false,
          studentId: "",
        },
        resolve(true)
      );
    });
  };

  refresh() {
    return new Promise((resolve, reject) => {
      axios.get("dashboard/StudentsI20Renew").then((response) => {
        let studentsToRenewI20 = [];
        response.data.data.studentsI20Renew.map((student, index) =>
          studentsToRenewI20.push({
            id: student.id,
            name: student.name,
            i20: student.i20,
            i20EndDate: FormatFullDateList(student.i20EndDate),
            daysLeft: student.total,
            status: student.status,
            campusName: student.campusName,
          })
        );

        this.setState(
          {
            students: studentsToRenewI20,
          },
          resolve(true)
        );
      });
    });
  }

  onOpened() {
    this.refresh();
  }

  toggleStudentModal = () => {
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.openStudentModal = !mainState.openStudentModal;
      this.setState(mainState, resolve(true));
    });
  };

  openStudentEditModal = (id) => {
    this.toggleStudentModal();
    return new Promise((resolve, reject) => {
      let mainState = this.state;
      mainState.studentId = id;
      this.setState(mainState, resolve(true));
    });
  };

  toggleMessageModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { openMultipleMessageModal: !this.state.openMultipleMessageModal },
        resolve(true)
      );
    });
  };

  render() {
    let students = this.state.students.map((student) => {
      return (
        <tr key={student.id}>
          <td>
            <a
              onClick={this.openStudentEditModal.bind(this, student.id)}
              href="#"
            >
              {student.name}
            </a>
          </td>
          <td>{student.status}</td>
          <td>{student.i20}</td>
          <td>{student.i20EndDate}</td>
          <td>
            <strong>{student.daysLeft}</strong>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggleRenewI20Modal.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                unmountOnClose={true}
                onOpened={this.onOpened.bind(this)}
                scrollable={true}
                onClosed={this.onCloseModal}
              >
                <ModalHeader toggle={this.props.toggleRenewI20Modal.bind(this)}>
                  I-20 Renew / Next 30 Days
                </ModalHeader>
                <ModalBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Status</th>
                        {/* <th>Campus</th> */}
                        <th>I20</th>
                        <th>I20 End Date</th>
                        <th>Days left</th>
                      </tr>
                    </thead>

                    <tbody>{students}</tbody>
                  </Table>
                </ModalBody>
                <ModalFooter>
                  {/* <Button
                    color="info"
                    onClick={this.toggleMessageModal.bind(this)}
                    style={{ marginRight: "auto" }}
                  >
                    Multiple Message
                  </Button> */}
                  <Button
                    color="primary"
                    onClick={this.props.toggleRenewI20Modal.bind(this)}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
            <StudentEdit2
              isOpen={this.state.openStudentModal}
              toggleStudentModal={this.toggleStudentModal.bind(this)}
              editMode={editMode.EDIT}
              id={this.state.studentId}
              callback={this.refresh.bind(this)}
              tables={this.props.tables}
            />
            {/* <MultipleMessage
              isOpen={this.state.openMultipleMessageModal}
              toggleMessageModal={this.toggleMessageModal.bind(this)}
              tables={this.props.tables}
              students={this.state.students}
            /> */}
          </Row>
        </div>
      </>
    );
  }
}

export default RenewI20;
