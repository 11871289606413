/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";

class ReportModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onCloseModal = () => {
    this.resetModal();
  };

  print = () => {
    const input = document.getElementById("tableReport");

    const pdf = new jsPDF("l");
    if (pdf) {
      domtoimage.toPng(input).then((imgData) => {
        pdf.addImage(imgData, "PNG", 25, 25);

        pdf.setProperties({
          title: this.props.title,
        });

        pdf.save(this.props.title + ".pdf");
      });
    }
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState({}, resolve(true));
    });
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                unmountOnClose={true}
                onClosed={this.props.onClosed.bind(this)}
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  {this.props.title}
                </ModalHeader>
                <ModalBody id="modalBody">{this.props.table}</ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={this.print.bind(this)}
                    disabled={this.props.buttonPrintDisabled}
                  >
                    Print
                  </Button>
                  <Button
                    color="secondary"
                    onClick={this.props.toggle.bind(this)}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default ReportModal;
