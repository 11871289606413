/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  Label,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Button,
  Input,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import * as dateFns from "date-fns";
import DatePicker from "react-datepicker";
import { SaveTemplateLetterPdf } from "components/Report/TemplateLetterPdf.js";
import Autosuggest from "react-autosuggest";
import { swalmessageService } from "services/Message.service.js";

class HRSSNLetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();

    this.timeout = 0;
  }

  getMainState = () => {
    return {
      date: new Date(),
      jobTitleId: "all",
      templateLetterId: "all",
      studentId: "",
      value: "",
      campusName: "",
      suggestions: [],
      jobTitles: [],
      templateLetters: [],
      letterDescription: "",
    };
  };

  onChange = (event, { newValue }) => {
    let modalState = this.state;

    modalState.value = newValue;
    modalState.studentId = "";
    modalState.campusName = "";

    this.setState(modalState);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      return new Promise((resolve, reject) => {
        axios.get(`students/byname/?name=${value}`).then((response) => {
          let students = [];

          response.data.data.map((student, index) =>
            students.push({
              id: student.Id,
              name: student.Name,
              campusName: student.CampusName,
            })
          );

          this.setState(
            {
              suggestions: students,
            },
            resolve(true)
          );
        });
      });
    }, 1000);
  };

  onSuggestionsClearRequested = () => {
    let modalState = this.state;
    modalState.suggestions = [];

    this.setState(modalState);
  };

  onSuggestionSelected = (event, { suggestion }) => {
    let modalState = this.state;
    modalState.studentId = suggestion.id;
    modalState.campusName = suggestion.campusName;

    this.setState(modalState, () => {
      axios
        .get(`students/check-existing-ssn-letter?studentId=${suggestion.id}`)
        .then((response) => {
          if (response.data.data == true) {
            swalmessageService.sendMessage(
              "This student already has an SSN letter. Please verify."
            );
          }
        });
    });
  };

  getSuggestionValue(suggestion) {
    return suggestion.name;
  }

  renderSuggestion(suggestion) {
    return <span>{suggestion.name}</span>;
  }

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      value,
      onChange: this.onChange,
    };

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Date</Label>

                      <DatePicker
                        selected={this.state.date}
                        onChange={this.handleDateInput.bind(this, "date")}
                        name="date"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <Col md={3}>
                      <FormGroup style={{ marginLeft: "5px" }}>
                        <Label>Student</Label>
                        <Autosuggest
                          className={`form-group `}
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequested
                          }
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          placeholder="Enter student name"
                          inputProps={inputProps}
                          onSuggestionSelected={this.onSuggestionSelected}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Template</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.templateLetterId &&
                            this.state.templateLetters.find(
                              (x) => x.value === this.state.templateLetterId
                            )
                          }
                          onChange={this.handleTemplateLetterChange}
                          options={this.state.templateLetters}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Job Title</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.jobTitleId &&
                            this.state.jobTitles.find(
                              (x) => x.value === this.state.jobTitleId
                            )
                          }
                          onChange={this.handleJobTypeChange}
                          options={this.state.jobTitles}
                        />
                      </FormGroup>
                    </Col>

                    <FormGroup>
                      <Label>&nbsp;</Label>

                      <Button
                        style={{ marginLeft: "5px", marginTop: "0" }}
                        color="primary"
                        onClick={this._generate.bind(this)}
                        disabled={!this.validateForm()}
                      >
                        Generate
                      </Button>
                    </FormGroup>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="classeName">Description</Label>
                        <Input
                          style={{ minHeight: "400px" }}
                          className={`form-group`}
                          type="textarea"
                          name="letterDescription"
                          id="letterDescription"
                          autoComplete="nope"
                          value={this.state.letterDescription}
                          onChange={this.handleUserInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <Row form>
                        <FormGroup>
                          <Label>&nbsp;</Label>

                          <Button
                            style={{ marginLeft: "5px", marginTop: "0" }}
                            color="primary"
                            onClick={this._previewAndSave.bind(this, false)}
                            disabled={!this.validateForm()}
                          >
                            Preview
                          </Button>
                        </FormGroup>
                        <FormGroup>
                          <Label>&nbsp;</Label>

                          <Button
                            style={{ marginLeft: "5px", marginTop: "0" }}
                            color="primary"
                            onClick={this._previewAndSave.bind(this, true)}
                            disabled={!this.validateForm()}
                          >
                            Save
                          </Button>
                        </FormGroup>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  componentDidMount() {
    this._refreshTemplateLetters().then(() => {
      this._refreshJobTitles();
    });
  }

  _refreshTemplateLetters() {
    return new Promise((resolve, reject) => {
      axios.get("templateLetters/getall").then((response) => {
        let templateLetters = [];
        response.data.data
          .filter((x) => x.name !== "Final Grade Email")
          .map((tl, index) =>
            templateLetters.push({
              value: tl.id,
              label: tl.name,
              description: tl.description,
            })
          );

        this.setState({ templateLetters: templateLetters }, resolve(true));
      });
    });
  }

  _refreshJobTitles() {
    return new Promise((resolve, reject) => {
      axios.get("jobTitles/getall").then((response) => {
        let jobTitles = [];
        response.data.data.map((jt, index) =>
          jobTitles.push({
            value: jt.id,
            label: jt.name,
          })
        );

        this.setState({ jobTitles: jobTitles }, resolve(true));
      });
    });
  }

  handleDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;

    mainState[name] = date;

    this.setState(mainState);
  };

  handleJobTypeChange = (selectedOption) => {
    let mainState = this.state;

    mainState.jobTitleId = selectedOption.value;

    this.setState(mainState);
  };

  handleTemplateLetterChange = (selectedOption) => {
    let mainState = this.state;

    mainState.templateLetterId = selectedOption.value;

    this.setState(mainState);
  };

  _generate() {
    return new Promise((resolve, reject) => {
      let description = this.state.templateLetters.find(
        (x) => x.value === this.state.templateLetterId
      ).description;

      let jobTitle = this.state.jobTitles.find(
        (x) => x.value === this.state.jobTitleId
      ).label;

      description = description
        .replaceAll("[date]", dateFns.format(this.state.date, "MM/dd/yyyy"))
        .replaceAll("[studentName]", this.state.value)
        .replaceAll("[jobTitle]", jobTitle)
        .replaceAll(
          "[campusName]",
          (this.state.campusName &&
            this.state.campusName
              .replace("Salt Lake in Person", "Salt Lake City")
              .replace("Orem In Person", "Orem")) ||
            ""
        );

      this.setState({ letterDescription: description }, resolve(true));
    });
  }

  _previewAndSave(save) {
    let documentTypeId = this.props.tables.documentTypes.find(
      (x) => x.name === "Other"
    ).id;

    SaveTemplateLetterPdf(
      this.state.studentId,
      this.state.value,
      this.state.letterDescription,
      documentTypeId,
      save
    );
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState[name] = value;

    this.setState(modalState);
  };

  validateForm() {
    return (
      this.state.studentId.length > 0 &&
      this.state.value.length > 0 &&
      this.state.templateLetterId.length > 0 &&
      this.state.templateLetterId !== "all" &&
      this.state.jobTitleId.length > 0 &&
      this.state.jobTitleId !== "all"
    );
  }
}

export default HRSSNLetter;
