/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import React from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  Table,
  CardTitle,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import * as dateFns from "date-fns";
import confirm from "reactstrap-confirm";

class ReferralModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getModalState();

    this.timeout = 0;
  }

  getReferral = () => {
    const referral = {
      StatusTypeId: "",
      PaymentTypeId: "",
      Description: "",
      LogList: [],
    };

    return referral;
  };

  getModalState = () => {
    return {
      Referral: this.getReferral(),
      StatusTypeList: [],
    };
  };

  render() {
    let logList = this.state.Referral.LogList.map((log, index) => {
      return (
        <tr key={index}>
          <td>{log.description}</td>
          <td>{log.statusName}</td>
          <td>{log.userName}</td>
          <td>{dateFns.format(log.createdDate, "MM/dd/yyyy hh:mm:ss aa")}</td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Modal
                style={{
                  maxWidth: "1100px",
                  width: "80%",
                }}
                isOpen={this.props.isOpen}
                toggle={this.props.toggle.bind(this)}
                centered={true}
                autoFocus={true}
                size={"lg"}
                backdrop="static"
                onClosed={this.onCloseModal}
                onOpened={this.onOpened.bind(this)}
                scrollable
              >
                <ModalHeader toggle={this.props.toggle.bind(this)}>
                  {"Applicant: " + this.props.title}
                </ModalHeader>
                <ModalBody
                  style={{ backgroundColor: "#f4f3ef", paddingBottom: "0" }}
                >
                  <Row form>
                    <Col md={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5">Details</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <React.Fragment>
                            <Row form>
                              <Col md={2}>
                                <FormGroup>
                                  <Label for="classeName">Status</Label>
                                  <Select
                                    className={`form-group`}
                                    value={this.state.StatusTypeList.find(
                                      (x) =>
                                        x.value ===
                                        this.state.Referral.StatusTypeId
                                    )}
                                    options={this.state.StatusTypeList}
                                    onChange={this.handleStatusChange}
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={2}>
                                <FormGroup>
                                  <Label for="classTeacher">Payment Type</Label>

                                  <Select
                                    className={`form-group`}
                                    value={this.paymentOptions.find(
                                      (x) =>
                                        x.value ===
                                        this.state.Referral.PaymentTypeId
                                    )}
                                    options={this.paymentOptions}
                                    onChange={this.handlePaymentChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row form>
                              <Col md={12}>
                                <FormGroup>
                                  <Label for="classeName">Note</Label>
                                  <Input
                                    className={`form-group`}
                                    type="textarea"
                                    name="Description"
                                    id="studentNote"
                                    autoComplete="nope"
                                    value={this.state.Referral.Description}
                                    onChange={this.handleUserInput}
                                    placeholder={"Type the new note here..."}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </React.Fragment>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md={12}>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h5">Previous notes</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Row form>
                            <Table className="table table-striped">
                              <thead style={{ whiteSpace: "unset" }}>
                                <tr>
                                  <th>Note</th>
                                  <th>Status</th>
                                  <th>User</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>{logList}</tbody>
                            </Table>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="primary"
                    onClick={this.save.bind(this)}
                    disabled={!this.validateForm()}
                  >
                    Save
                  </Button>{" "}
                  <Button
                    color="secondary"
                    onClick={this.props.toggle.bind(this)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  handleStatusChange = (selectedOption) => {
    let modalState = this.state;
    modalState.Referral.StatusTypeId = selectedOption.value;
    this.setState(modalState);
  };

  handlePaymentChange = (selectedOption) => {
    let modalState = this.state;
    modalState.Referral.PaymentTypeId = selectedOption.value;
    this.setState(modalState);
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(this.getModalState(), resolve(true));
    });
  };

  validateForm() {
    return (
      this.state.Referral.StatusTypeId.length > 0 &&
      this.state.Referral.Description.length > 0
    );
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    let modalState = this.state;
    modalState.Referral[name] = value;

    this.setState(modalState);
  };

  onOpened() {
    this.openReferralModal();
  }

  closeReferralModal() {
    this.props.toggle().then(() => {
      this.resetModal().then(() => {
        if (this.props.callback && typeof this.props.callback === "function") {
          this.props.callback();
        }
      });
    });
  }

  refreshStatusTypeList = () => {
    return new Promise((resolve, reject) => {
      axios.get("referralstatustypes").then((response) => {
        let mainState = this.state;

        mainState.StatusTypeList = [];

        response.data.data.map((statusType, index) =>
          mainState.StatusTypeList.push({
            value: statusType.id,
            label: statusType.name,
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  };

  openReferralModal() {
    axios
      .get("referral/getreferrallogs?id=" + this.props.id)
      .then((response) => {
        let modalState = this.state;

        modalState.Referral.Id = response.data.data.id;

        response.data.data.referralLogList.map((log, index) => {
          modalState.Referral.LogList.push({
            id: log.id,
            description: log.description,
            statusName: log.status.name,
            statusId: log.status.id,
            userName:
              log.user.name &&
              log.user.name.slice(0, log.user.name.lastIndexOf("@")),
            createdDate: dateFns.parseISO(log.createdDate),
          });
        });

        let lastItem = modalState.Referral.LogList.sort(
          (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
        )[0];

        modalState.Referral.StatusTypeId = lastItem.statusId;

        modalState.Referral.PaymentTypeId = response.data.data.paymentTypeId;

        this.setState(modalState);
      })
      .then(() => {
        this.refreshStatusTypeList();
      });
  }

  onCloseModal = () => {
    this.resetModal();
  };

  errorClass(error) {
    return error.length === 0 ? "" : "is-invalid";
  }

  async save() {
    let status = this.state.StatusTypeList.find(
      (x) => x.value === this.state.Referral.StatusTypeId
    );

    if (status.label === "New") {
      await confirm({
        title: <>Warning</>,
        message: "The 'New' status cannot be picked.",
        cancelText: null,
      });
    } else {
      let referralLogList = [];
      referralLogList.push({
        Description: this.state.Referral.Description,
        Status: { Id: this.state.Referral.StatusTypeId },
      });

      let referral = {
        Id: this.props.id,
        ReferralLogList: referralLogList,
        PaymentTypeId: this.state.Referral.PaymentTypeId,
      };

      await axios.post("referral/saveLog", referral).then((response) => {
        this.props.toggle().then(() => {
          this.resetModal().then(() => {
            if (
              this.props.callback &&
              typeof this.props.callback === "function"
            ) {
              this.props.callback();
            }
          });
        });
      });
    }
  }

  paymentOptions = [
    { value: "P", label: "Pending" },
    { value: "H", label: "Check" },
    { value: "R", label: "Credit" },
  ];
}

export default ReferralModal;
